import { useCallback } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trpc } from 'src/lib/trpc';
import Navigation from 'src/components/Navigation';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';
import EmptyComponent from '../../../../components/EmptyComponent';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { useYouTubeSearchParams } from '../../util/useYoutubeSearchParams';
import YoutubeAccountSelectModal from '../../../../components/YouTubeConnect/YouTubeAdAccountSelectModal';
import { Customer } from '../../../../components/YouTubeConnect/types';

type YouTubeAuthState = {
  status: 'accountSelect';
  customers: Customer[];
  youtubeIntegrationID: number;
};

const YoutubeOauthExchange = () => {
  const { LL } = useI18nContext();
  const navigate = useNavigate();
  const { getCode } = useYouTubeSearchParams();
  const user = useUserAndOrganisation();
  const code = getCode();

  const authState = useLoaderData() as YouTubeAuthState;

  // Connect YouTube accounts mutation
  const connectAccountsMutation =
    trpc.youtube.connectYoutubeAccounts.useMutation({
      onSuccess: () => {
        navigate('/settings/integrations');
      },
      onError: () => {
        toast.error('Failed to connect accounts', {
          className: 'toast-danger',
        });
        navigate('/settings/integrations');
      },
    });

  // Connect selected account(s)
  const connectAccount = useCallback(
    (selectedCustomers: Customer[]) => {
      if (authState.status !== 'accountSelect') {
        return;
      }
      const customerMap = selectedCustomers.map((x) => ({
        id: x.id,
        name: x.descriptiveName,
      }));

      // Call the proper mutation to connect selected accounts
      connectAccountsMutation.mutate({
        customers: customerMap,
        youtubeID: authState.youtubeIntegrationID,
      });
    },
    [authState, connectAccountsMutation]
  );

  return (
    <div className="flex w-full flex-col gap-4">
      <Navigation guest={!user.data} />
      {code == null && (
        <EmptyComponent
          title={LL.youtube.oauth.exchange.error.title()}
          description={LL.youtube.oauth.exchange.error.noCodeError()}
          action={
            <Link
              to={'/settings/integrations'}
              className="font-semibold text-primary"
            >
              {LL.youtube.oauth.exchange.error.reauthenticate()}
            </Link>
          }
        />
      )}
      {authState.status === 'accountSelect' && (
        <YoutubeAccountSelectModal
          youtubeAuthState={authState}
          onClose={() => navigate('/settings/integrations')}
          onSelectAccounts={connectAccount}
        />
      )}
    </div>
  );
};

export default YoutubeOauthExchange;
