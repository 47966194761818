import { FC } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import RefreshCw02 from 'src/assets/svgicons/line/refresh-cw-02.svg';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useRefetchData } from '../../util/useInsightsQueries';

const InsightsRefreshDataButton: FC = () => {
  const { refetch, isDisabled, isProcessing, isLoading } = useRefetchData();
  const { recordEvent } = useNewAnalyticsEvent();

  const label = isProcessing || isLoading ? 'Syncing...' : 'Sync';

  return (
    <Button
      variant="secondary"
      size="small"
      className={isProcessing || isLoading ? 'cursor-wait' : ''}
      onPress={() => {
        refetch();
        void recordEvent({
          action: 'Sync clicked',
          target: 'Insights Account',
        });
      }}
      isDisabled={isDisabled || isProcessing || isLoading}
    >
      <RefreshCw02
        id="icon"
        className={cn(
          'size-4',
          isProcessing || isLoading ? 'animate-spin' : ''
        )}
      />
      {label}
    </Button>
  );
};

export default InsightsRefreshDataButton;
