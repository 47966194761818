import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  getAllMetricsAndAttributesForPlatform,
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS,
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS,
  InsightsFacebookTimeSeriesMetricName,
  InsightsFacebookWizardMetric,
} from '@magicbrief/common';
import { clsx } from 'clsx';
import {
  Button as RACButton,
  Checkbox,
  Dialog,
  DialogTrigger,
  Input as AriaInput,
  Modal,
  ModalOverlay,
  SearchField,
  Text,
  ListBox,
  Heading,
  TextField,
  Label,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { Button } from '@magicbrief/ui/src/components/button';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { Popover, PopoverTrigger } from '@magicbrief/ui/src/components/popover';
import { ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import SearchSm from 'src/assets/svgicons/duotone/search-sm.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import Edit05 from 'src/assets/svgicons/line/edit-05.svg';
import { useInsightsPlatform } from 'src/pages/Insights/util/useInsightsPersistentState';
import {
  useInsightsAdAccount,
  useInsightsAds,
} from 'src/pages/Insights/util/useInsightsQueries';
import { useMetricSearch } from 'src/pages/Insights/util/useMetricSearch';
import {
  parseValueForMetric,
  useParseMetric,
} from 'src/pages/Insights/util/useParseMetric';
import Input from 'src/components/Input';
import { useInsightsJob } from 'src/pages/Insights/util/useInsightsJob';
import { InsightsMetricLabelTooltip } from '../../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { useInsightsAdBreakdown } from '../useInsightsAdBreakdown';
import { InsightsRenamePresetModal } from '../../InsightsFilter/components/InsightsRenamePresetModal';
import { InsightsDeletePresetModal } from '../../InsightsFilter/components/InsightsDeletePresetModal';

interface InsightsDisplayMetricModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isGraphMetrics?: boolean;
}

export function AdBreakdownMetricModal({
  isOpen,
  setIsOpen,
  isGraphMetrics = false,
}: InsightsDisplayMetricModalProps) {
  const { accountUuid } = useParams();
  const { zIndex } = useInsightsAdBreakdown();
  const {
    accountCustomEvents: customEvents,
    accountCustomConversions: customConversions,
  } = useInsightsAdAccount({ accountUuid });

  const searchRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
      style={{ zIndex }}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] w-[970px] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog className="relative grid h-[800px] rounded-2xl border border-purple-200 bg-white shadow focus-visible:outline-none lg:h-auto">
          {({ close }) => (
            <>
              <DisplayFilterModalHeader isGraphMetrics={isGraphMetrics} />
              <div className="flex h-[500px]">
                <SelectedDisplayFilters
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                  isGraphMetrics={isGraphMetrics}
                />
                <DisplayFiltersSelection
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                  isGraphMetrics={isGraphMetrics}
                />
              </div>
              <DisplayFilterModalFooter
                close={close}
                isGraphMetrics={isGraphMetrics}
              />
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

export type InsightsPreset = {
  name: string;
  uuid: string;
  metrics: string[];
};

function DisplayFilterModalHeader({
  isGraphMetrics,
}: {
  isGraphMetrics: boolean;
}) {
  const { accountUuid } = useParams();

  const { preset, setPreset, zIndex, setTableMetrics } =
    useInsightsAdBreakdown();

  const [isOpen, setIsOpen] = useState(false);
  const [editPreset, setEditPreset] = useState<InsightsPreset | null>(null);
  const [deletePreset, setDeletePreset] = useState<InsightsPreset | null>(null);

  const { data: presets = [] } =
    trpc.insights.getInsightsMetricPresets.useQuery({
      insightsAdAccountUuid: accountUuid ?? '',
    });

  if (isGraphMetrics) {
    return (
      <div className="border-b border-solid border-b-purple-300 px-6 py-4">
        <h2 className="text-xl font-semibold text-primary">
          Customise Visible Metrics
        </h2>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center gap-3 border-b border-solid border-b-purple-300 px-6 py-4">
        <Text className="text-sm font-semibold text-primary/50">
          Column view:
        </Text>

        {presets.length === 0 ? (
          <TooltipTrigger delay={200} closeDelay={0}>
            <RACButton className="flex h-10 min-w-[100px] cursor-not-allowed items-center gap-6 rounded-md border border-solid border-purple-200 px-3 py-2.5 text-sm font-medium text-primary opacity-50">
              <span className="grow">
                {presets?.find((p) => p.uuid === preset)?.name ?? 'Custom'}
              </span>
              <ChevronDown className="size-4" />
            </RACButton>
            <Tooltip offset={8}>Create a custom preset view</Tooltip>
          </TooltipTrigger>
        ) : (
          <PopoverTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
            <RACButton
              className={cn(
                'flex h-10 min-w-[100px] items-center gap-6 rounded-md border border-solid border-purple-200 px-3 py-2.5 text-sm font-medium text-primary',
                'disabled:cursor-not-allowed disabled:opacity-50'
              )}
              isDisabled={presets.length === 0}
            >
              <span className="grow">
                {presets?.find((p) => p.uuid === preset)?.name ?? 'Custom'}
              </span>
              <ChevronDown className="size-4" />
            </RACButton>

            <Popover
              placement="bottom start"
              className="max-h-[300px] w-fit min-w-[272px] overflow-y-auto"
              style={{ zIndex }}
            >
              <ListBox
                className="p-2"
                onAction={(presetKey) => {
                  const preset = presets?.find((p) => p.uuid === presetKey);

                  setPreset(presetKey);
                  setTableMetrics(preset?.metrics ?? []);
                  setIsOpen(false);
                }}
              >
                {presets?.map((preset) => (
                  <ListBoxItem
                    key={preset.uuid}
                    id={preset.uuid}
                    className="flex items-center gap-1"
                  >
                    <span className="shrink-0 grow">{preset.name}</span>
                    <RACButton
                      className="rounded-md p-1 hover:bg-purple-50"
                      onPress={() => {
                        setEditPreset(preset);
                        setIsOpen(false);
                      }}
                    >
                      <Edit05 className="size-4" />
                    </RACButton>
                    <RACButton
                      className="rounded-md p-1 hover:bg-red-50 hover:text-red-600"
                      onPress={() => {
                        setDeletePreset(preset);
                        setIsOpen(false);
                      }}
                    >
                      <XClose className="size-4" />
                    </RACButton>
                  </ListBoxItem>
                ))}
              </ListBox>
            </Popover>
          </PopoverTrigger>
        )}
      </div>
      {editPreset ? (
        <InsightsRenamePresetModal
          editPreset={editPreset}
          setEditPreset={setEditPreset}
        />
      ) : null}
      {deletePreset ? (
        <InsightsDeletePresetModal
          deletePreset={deletePreset}
          setDeletePreset={setDeletePreset}
        />
      ) : null}
    </>
  );
}

function DisplayFilterModalFooter({
  close,
  isGraphMetrics,
}: {
  close: () => void;
  isGraphMetrics: boolean;
}) {
  const trpcUtils = trpc.useUtils();
  const { accountUuid } = useParams();
  const { tableMetrics: metrics, preset } = useInsightsAdBreakdown();

  const [isCreatePresetOpen, setIsCreatePresetOpen] = useState(false);

  const { data: presets } = trpc.insights.getInsightsMetricPresets.useQuery({
    insightsAdAccountUuid: accountUuid ?? '',
  });

  const hasPresetChanged =
    preset !== 'custom'
      ? !isEqual(presets?.find((p) => p.uuid === preset)?.metrics, metrics)
      : false;

  const updatePreset = trpc.insights.updateInsightsMetricPreset.useMutation({
    onSuccess: () => {
      void trpcUtils.insights.getInsightsMetricPresets.invalidate();
      toast.success('Preset successfully updated');
    },
    onError: () => {
      toast.error('Error updating preset');
    },
  });

  return (
    <>
      <div
        className={cn(
          'flex items-center gap-2 rounded-b-[inherit] border-t border-solid border-t-purple-300 bg-white px-6 py-4',
          isGraphMetrics ? 'justify-end' : 'justify-between'
        )}
      >
        {isGraphMetrics ? null : (
          <div className="space-x-2">
            <Button
              variant="secondary"
              size="small"
              onPress={() => {
                updatePreset.mutate({
                  insightsAdAccountUuid: accountUuid ?? '',
                  presetUuid: preset as string,
                  metrics,
                });
              }}
              loading={updatePreset.isLoading}
              isDisabled={preset === 'custom' || !hasPresetChanged}
            >
              Update Preset
            </Button>
            <DialogTrigger>
              <Button
                variant="secondary"
                size="small"
                onPress={() => setIsCreatePresetOpen(true)}
              >
                Save as New Preset
              </Button>

              <CreatePresetModal
                isOpen={isCreatePresetOpen}
                setIsOpen={setIsCreatePresetOpen}
              />
            </DialogTrigger>
          </div>
        )}

        <div className="space-x-2">
          <MagicBriefButton
            className="w-fit"
            variant="text"
            size="small"
            onPress={close}
          >
            Cancel
          </MagicBriefButton>
          <MagicBriefButton
            className="w-fit"
            variant="primary"
            size="small"
            onPress={close}
          >
            Update Metrics
          </MagicBriefButton>
        </div>
      </div>
    </>
  );
}

const SelectedDisplayFilters: React.FC<
  CustomEventsAndConversionsProps & { isGraphMetrics: boolean }
> = ({ customEvents, customConversions, isGraphMetrics }) => {
  const { tableMetrics, setTableMetrics, chartMetrics, setChartMetrics } =
    useInsightsAdBreakdown();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const display = isGraphMetrics ? chartMetrics : tableMetrics;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);

      if (isGraphMetrics) {
        setChartMetrics(newDisplayOrder);
      } else {
        setTableMetrics(newDisplayOrder);
      }
    }
  };

  if (display.length === 0) {
    return (
      <div className="flex w-[300px] min-w-[300px] flex-col gap-4 border-r border-solid border-r-purple-200 px-6 pt-3">
        <span className="text-lg font-semibold text-primary">Selected</span>
        <span className="text-md font-medium text-primary opacity-50">
          No metrics selected.
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-[500px] w-[300px] min-w-[300px] flex-col gap-2 overflow-y-auto overflow-x-hidden border-r border-solid border-r-purple-200 px-6 py-3">
      <span className="text-lg font-semibold text-primary">Selected</span>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={display} strategy={verticalListSortingStrategy}>
          {display.map((id) => (
            <DraggableDisplayFilter
              key={id}
              id={id}
              customEvents={customEvents}
              customConversions={customConversions}
              isGraphMetrics={isGraphMetrics}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface CustomEventsAndConversionsProps {
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}

interface DraggableDisplayFilterProps extends CustomEventsAndConversionsProps {
  id: string;
  isGraphMetrics: boolean;
}

const DraggableDisplayFilter: React.FC<DraggableDisplayFilterProps> = ({
  id,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const { tableMetrics, setTableMetrics, chartMetrics, setChartMetrics } =
    useInsightsAdBreakdown();
  const platform = useInsightsPlatform();
  const { getMetricLabelAsText } = useParseMetric();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex cursor-grab items-center gap-1.5 rounded-md border border-solid border-purple-200 bg-violet-50 px-2.5 py-1.5"
      style={styles}
      aria-label="selected display metric"
      {...attributes}
      {...listeners}
    >
      <DotsGrid className="size-4 text-purple-300 focus:outline-none" />
      <Text
        slot="label"
        className="grow truncate text-sm font-semibold text-primary"
      >
        {getMetricLabelAsText(platform, id, customEvents, customConversions)}
      </Text>

      <RACButton
        onPress={() => {
          if (isGraphMetrics) {
            setChartMetrics(chartMetrics.filter((m) => m !== id));
          } else {
            setTableMetrics(tableMetrics.filter((m) => m !== id));
          }
        }}
        className="focus:outline-none"
      >
        <XClose className="size-[18px] rounded text-primary/50 outline-none hover:bg-purple-200" />
      </RACButton>
    </div>
  );
};

type Category = {
  type: 'list' | 'grid';
  metrics: Array<string>;
};

const DisplayFiltersSelection: React.FC<
  CustomEventsAndConversionsProps & {
    isGraphMetrics: boolean;
  }
> = ({ customEvents, customConversions, isGraphMetrics }) => {
  const { LL } = useI18nContext();
  const platform = useInsightsPlatform();
  const searchRef = useRef<HTMLInputElement>(null);

  const {
    results: items,
    onChange,
    query,
  } = useMetricSearch({
    platform,
    builtinMetrics: getAllMetricsAndAttributesForPlatform(platform),
    customEvents,
    customConversions,
  });

  const mostRelevantSearchMetrics = items.slice(0, 10);
  const categorisedMetrics = (
    items as Array<InsightsFacebookTimeSeriesMetricName>
  ).reduce<Record<string, Category>>((acc, curr) => {
    switch (true) {
      case DEPRECATED_METRICS.some((x) => x === curr):
        return acc;

      case customEvents.some((ce) => curr.includes(ce)): {
        if (acc['Custom Events']) {
          acc['Custom Events']['metrics'].push(curr);
        } else {
          acc['Custom Events'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;
      }

      case customConversions.some((cc) => curr.includes(cc.facebookId)):
        if (acc['Custom Conversions']) {
          acc['Custom Conversions']['metrics'].push(curr);
        } else {
          acc['Custom Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case STANDARD_CONVERSIONS_CATEGORY_METRICS.some((x) => curr.includes(x)):
        if (acc['Standard Conversions']) {
          acc['Standard Conversions']['metrics'].push(curr);
        } else {
          acc['Standard Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case CLICKS_CATEGORY_METRICS.includes(curr):
        if (acc['Clicks']) {
          acc['Clicks']['metrics'].push(curr);
        } else {
          acc['Clicks'] = { type: 'list', metrics: [curr] };
        }
        break;

      case VIDEO_CATEGORY_METRICS.includes(curr):
        if (acc['Video']) {
          acc['Video']['metrics'].push(curr);
        } else {
          acc['Video'] = { type: 'list', metrics: [curr] };
        }
        break;

      case ENGAGEMENT_CATEGORY_METRICS.includes(curr):
        if (acc['Engagement']) {
          acc['Engagement']['metrics'].push(curr);
        } else {
          acc['Engagement'] = { type: 'list', metrics: [curr] };
        }
        break;

      case WIZARD_SCORE_CATEGORY_METRICS.includes(
        curr as InsightsFacebookWizardMetric
      ):
        if (acc['Wizard Scores']) {
          acc['Wizard Scores']['metrics'].push(curr);
        } else {
          acc['Wizard Scores'] = { type: 'list', metrics: [curr] };
        }
        break;

      case AD_DETAILS_CATEGORY_METRICS.includes(curr):
        if (acc['Ad Details']) {
          acc['Ad Details']['metrics'].push(curr);
        } else {
          acc['Ad Details'] = { type: 'list', metrics: [curr] };
        }
        break;

      case PERFORMANCE_CATEGORY_METRICS.includes(curr):
        if (acc['Performance']) {
          acc['Performance']['metrics'].push(curr);
        } else {
          acc['Performance'] = { type: 'list', metrics: [curr] };
        }
        break;

      default:
        if (acc['Other']) {
          acc['Other']['metrics'].push(curr);
        } else {
          acc['Other'] = {
            type: 'list',
            metrics: [curr],
          };
        }
    }

    return acc;
  }, {});

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <div className="size-full">
      <div className="flex flex-col gap-4 border-b border-solid border-b-purple-200 px-6 pb-6 pt-3">
        <span className="text-lg font-semibold text-primary">
          Search Metrics
        </span>
        <SearchField className="relative" aria-label="display metric search">
          <Icon className="absolute left-3 top-1/2 size-5 -translate-y-1/2 text-primary">
            <SearchSm />
          </Icon>
          <AriaInput
            ref={searchRef}
            type="text"
            className="text-md w-full rounded-md border border-solid border-purple-200 bg-white py-2.5 pl-10 pr-3 font-medium text-primary shadow-sm focus:outline-none"
            onChange={(e) => onChange(e.target.value)}
            placeholder={LL.insights.metrics.search()}
          />
        </SearchField>
      </div>

      <div className="flex h-[373px] flex-col gap-10 overflow-auto px-6 pt-6">
        {items.length === 0 && (
          <p className="primary text-sm italic text-purple-400">
            {`${LL.insights.metrics.noResults()} for '${query}'`}
          </p>
        )}

        {mostRelevantSearchMetrics.length > 0 && query.length > 0 && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="Most Relevant"
            metrics={mostRelevantSearchMetrics.filter((m) => m !== 'tag') ?? []}
            withCount={false}
            isGraphMetrics={isGraphMetrics}
          />
        )}

        {platform === 'tiktok' && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="All Metrics"
            metrics={getAllMetricsAndAttributesForPlatform(platform)}
            isGraphMetrics={isGraphMetrics}
          />
        )}

        {platform === 'facebook' && (
          <>
            {categorisedMetrics['Performance'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Performance"
                metrics={categorisedMetrics['Performance'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Wizard Scores'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Wizard Scores"
                metrics={categorisedMetrics['Wizard Scores'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Clicks'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Clicks"
                metrics={categorisedMetrics['Clicks'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}

            {categorisedMetrics['Video'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Video"
                metrics={categorisedMetrics['Video'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Engagement'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Engagement"
                metrics={categorisedMetrics['Engagement'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Ad Details'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Ad Details"
                metrics={categorisedMetrics['Ad Details'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Standard Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Standard Conversions"
                metrics={
                  categorisedMetrics['Standard Conversions'].metrics ?? []
                }
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Custom Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Conversions"
                metrics={categorisedMetrics['Custom Conversions'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Custom Events'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Events"
                metrics={categorisedMetrics['Custom Events'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Override the alphabetical sort for Wizard scores and orders
// it in the correct sequence as per the conversion funnel
const wizardScoreSortMap = {
  wizardHookScore: 1,
  wizardHoldScore: 2,
  wizardClickScore: 3,
  wizardBuyScore: 4,
};

interface ListCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
  withCount?: boolean;
  isGraphMetrics: boolean;
}

const ListCategoryGroup: React.FC<ListCategoryGroupProps> = ({
  name,
  metrics,
  withCount = true,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const { accountUuid, reportUuid } = useParams();
  const { currency } = useInsightsAdAccount({ accountUuid });
  const platform = useInsightsPlatform();
  const job = useInsightsJob();
  const { tableMetrics, setTableMetrics, chartMetrics, setChartMetrics } =
    useInsightsAdBreakdown();

  const display = isGraphMetrics ? chartMetrics : tableMetrics;

  const header = withCount ? `${name} (${metrics.length})` : name;

  const parsedMetrics =
    name === 'Wizard Scores'
      ? metrics.sort(
          (a, b) =>
            wizardScoreSortMap[a as InsightsFacebookWizardMetric] -
            wizardScoreSortMap[b as InsightsFacebookWizardMetric]
        )
      : metrics;

  const { ads } = useInsightsAds(
    accountUuid
      ? { type: 'account', uuid: accountUuid, jobStatus: job?.status }
      : {
          type: 'report',
          uuid: reportUuid,
        }
  );

  const referenceMetrics = ads.data?.pages?.[0]?.[0]?.metrics
    ? ads.data.pages[0][0].metrics
    : {};

  const handleToggleMetric = (metric: string) => {
    if (isGraphMetrics) {
      setChartMetrics(
        chartMetrics.includes(metric)
          ? chartMetrics.filter((m) => m !== metric)
          : [...chartMetrics, metric]
      );
    } else {
      setTableMetrics(
        tableMetrics.includes(metric)
          ? tableMetrics.filter((m) => m !== metric)
          : [...tableMetrics, metric]
      );
    }
  };

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{header}</span>
      <div className="space-y-0.5">
        {parsedMetrics.map((metric) => {
          return (
            <Checkbox
              key={metric}
              isSelected={display.includes(metric)}
              onChange={() => handleToggleMetric(metric)}
              className="group/checkbox flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <div
                className={clsx(
                  /** Base */
                  'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                  /** Selected */
                  'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
                )}
              >
                <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                  <Check />
                </Icon>
              </div>
              <span className="grow truncate text-sm font-medium text-primary">
                <InsightsMetricLabelTooltip
                  platform={platform}
                  metric={metric}
                  customConversions={customConversions}
                  customEvents={customEvents}
                  onPress={() => handleToggleMetric(metric)}
                />
              </span>
              <div className="flex items-center justify-center rounded bg-[#E5DEFF] px-1.5 py-1">
                <span className="self-center text-sm font-medium text-primary">
                  {referenceMetrics[metric] &&
                  currency &&
                  !Array.isArray(referenceMetrics[metric])
                    ? parseValueForMetric(
                        platform,
                        metric,
                        referenceMetrics[metric],
                        currency
                      )
                    : '-'}
                </span>
              </div>
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

interface GridCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
  isGraphMetrics: boolean;
}

const GridCategoryGroup: React.FC<GridCategoryGroupProps> = ({
  name,
  metrics,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const platform = useInsightsPlatform();
  const { tableMetrics, setTableMetrics, chartMetrics, setChartMetrics } =
    useInsightsAdBreakdown();

  const display = isGraphMetrics ? chartMetrics : tableMetrics;

  const groupMetrics = metrics.reduce(
    (acc: Record<string, Array<string>>, curr) => {
      const splitIndex = getNthOccurrence(curr, ':', 2);
      const metricCategory = curr.slice(splitIndex + 1);

      if (acc[metricCategory]) {
        acc[metricCategory].push(curr);
      } else {
        acc[metricCategory] = [curr];
      }

      return acc;
    },
    {}
  );

  const customTrigger = ({ children }: PropsWithChildren) => (
    <RACButton className="text-xs font-medium text-primary">
      {children}
    </RACButton>
  );

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{name}</span>

      <div className="grid grid-cols-[250px_repeat(3,1fr)]">
        <div />
        {['Total', 'Value', 'Cost'].map((header) => (
          <span
            key={header}
            className="p-2 text-center text-xxs font-semibold text-primary"
          >
            {header}
          </span>
        ))}
      </div>

      {Object.entries(groupMetrics).map(([key]) => {
        return (
          <div
            key={key}
            className="grid grid-cols-[250px_repeat(3,1fr)] items-center justify-center"
          >
            <InsightsMetricLabelTooltip
              metric={`action:total:${key}`}
              customConversions={customConversions}
              customEvents={customEvents}
              customTrigger={customTrigger}
              platform={platform}
            />
            {['total', 'value', 'cost_per'].map((item) => {
              const metric = `action:${item}:${key}`;
              const isDisabled = !metrics.includes(metric);

              return (
                <Checkbox
                  key={item}
                  isSelected={display.includes(metric)}
                  onChange={() => {
                    if (isGraphMetrics) {
                      setChartMetrics(
                        chartMetrics.includes(metric)
                          ? chartMetrics.filter((m) => m !== metric)
                          : [...chartMetrics, metric]
                      );
                    } else {
                      setTableMetrics(
                        tableMetrics.includes(metric)
                          ? tableMetrics.filter((m) => m !== metric)
                          : [...tableMetrics, metric]
                      );
                    }
                  }}
                  className="group/checkbox mx-auto flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                  isDisabled={isDisabled}
                >
                  <div
                    className={clsx(
                      /** Base */
                      'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                      /** Selected */
                      'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100',
                      /** Disabled */
                      'group-disabled/checkbox:bg-purple-100'
                    )}
                  >
                    <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                      <Check />
                    </Icon>
                  </div>
                </Checkbox>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const DEPRECATED_METRICS = ['thumbstop'];

const CLICKS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> = [
  // Clicks (All)
  'clicks',
  // CPC (All)
  'cpc',
  // CTR (All)
  'ctr',
  // Clicks (Links)
  'action:total:link_click',
  // Clicks (Unique, Links)
  'action:unique:link_click',
  // Cost Per Click (Unique, Links)
  'action:cost_per_unique:link_click',
  // CPC (Links)
  // 'costPerLinkClick', // Deprecated
  // CTR (Links)
  'clickThroughRateAll',
  // Clicks (Outbound)
  'outboundClicks',
  // CPC (Outbound)
  'costPerOutboundClick',
  // Clicks (Unique, Outbound)
  'action:unique:outbound_click',
  // Cost Per Click (Unique, Outbound)
  // 'action:cost_per_unique:outbound_click', // Duplicates column level costPerUniqueOutboundClick
  // CTR (Outbound)
  'outboundClicksCtr',
  // Clicks (Unique, All)
  'uniqueClicks',
  // CPC (Unique, All)
  'costPerUniqueClick',
  // CTR (Unique, All)
  'clickThroughRateUnique',
  // CPC (Unique, Links)
  'costPerUniqueLinkClick',
  // CTR (Unique, Links)
  'clickThroughRateUniqueLinks',
  // CPC (Unique, Outbound)
  'costPerUniqueOutboundClick',
  // CTR (Unique, Outbound)
  'clickThroughRateUniqueOutbound',
  // Click to Purchase Ratio
  'clickToPurchaseRatio',
  // Click to Purchase Ratio (Unique)
  'clickToPurchaseRatioUnique',
  // Click to Registration Completed Ratio
  'clickToRegistrationCompletedRatio',
  // Click to Website Purchase Ratio
  // Click to Add-To-Cart Ratio
  'clickToAddToCartRatio',
  // Cost Per Link Clicks
  'action:cost_per:link_click',
  // Cost Per Inline Link Click
  'costPerInlineLinkClick',
  // Link Clicks Value
  'action:value:link_click',
  // Inline Link Clicks
  'inlineLinkClicks',
  // Inline Link Clicks CTR
  'inlineLinkClickCtr',
  // Click to Conversion Ratio
  'clickToConversionRatio',
  // Click to Trial Started Ratio
  'clickToTrialStartedRatio',
  // Click to Subscriptions Ratio
  'clickToSubscriptionsRatio',
  // Click to Contact Ratio
  'clickToContactRatio',
];

const AD_DETAILS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Ad Name
    'adName',
    // Ad ID
    // Ad Set Name
    'adSetName',
    // Ad Set ID
    // Campaign Name
    'campaignName',
    // Campaign ID
    // Created At
    'adCreatedAt',
    // Updated At
    'adUpdatedAt',
    // Start At
    'adStartAt',
    // End At
    'adEndAt',
    // Creative Type
    'creativeType',
  ];

const VIDEO_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> = [
  //   Video Plays (All)
  'videoPlays',
  // Video Average Play Time
  'videoAvgTimeWatched',
  //   Video Plays (2 Second Plays)
  'video2SecondContinuousPlays',
  // Cost Per 2 Second Video Play
  'costPerVideo2SecondContinuousPlay',
  // Cost Per Unique 2 Second Video Play
  // Video Plays (3 Second Plays)
  'action:total:video_view',
  // Cost Per 3 Second Video Play
  'action:cost_per:video_view',
  // Video 25% Watched Views
  'video25PercentWatchedViews',
  // Video 25% Watched Rate
  // Video 50% Watched Views
  'video50PercentWatchedViews',
  // Video 50% Watched Rate
  // Video 75% Watched Views
  'video75PercentWatchedViews',
  // Video 75% Watched Rate
  // Video 95% Watched Views
  'video95PercentWatchedViews',
  // Video 95% Watched Rate
  // Video 100% Watched Views
  'video100PercentWatchedViews',
  // Video 100% Watched Rate
  // Thruplays
  'thruplay',
  // Cost Per Thruplay
  'costPerThruplay',
  // CTR (Thruplay)
  'thruplayClickThroughRate',
  // 15 Sec/3 Sec Video Retention
  'video15Sec3SecVideoRetention',
  // Thruplay Rate
  'holdplay',
  // Thumbstop Rate
  'thumbstopRatio',
  // First Frame Retention
  'firstFrameRetention',
  // Cost Per Video Thruplay
  'costPerVideoThruplay',
  // Video Plays
  'videoPlays',
  // Video 30 Sec Watched Views
  'video30SecWatchedViews',
  // Video Avg Time Watched
  'videoAvgTimeWatched',
  // Thumbstop
  'thumbstop',
];

const ENGAGEMENT_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Post Engagements
    'action:total:post_engagement',
    // Post Reactions
    'action:total:post_reaction',
    // Post Saves
    'action:total:onsite_conversion.post_save',
    // Post Shares
    'action:total:post',
    // Post Comments
    'action:total:comment',
    // Cost Per Post Engagement
    'action:cost_per:post_engagement',
    // Page Follows or Likes
    'action:total:like',
    // Cost Per Page Follow or Like
    'action:cost_per:like',
    // Page Engagements
    'action:total:page_engagement',
    // Cost Per Page Engagement
    'action:cost_per:page_engagement',
    // Cost Per Post Shares
    'action:cost_per:post',
    // Cost Per Post Saves
    'action:cost_per:onsite_conversion.post_save',
    // Cost Per Post Reactions
    'action:cost_per:post_reaction',
    // Cost Per Post Comments
    'action:cost_per:comment',
    // Cost Per Pixel
    'cpp',
    // Cost Per Inline Post Engagement
    'costPerInlinePostEngagement',
    // Engagement
    'engagement',
    // Engagement Rate
    'engagementRate',
    // Page Engagement Value
    'action:value:page_engagement',
    // Post Comments Value
    'action:value:comment',
    // Post Engagement Value
    'action:value:post_engagement',
    // Post Reactions Value
    'action:value:post_reaction',
    // Post Saves Value
    'action:value:onsite_conversion.post_save',
    // Post Shares Value
    'action:value:post',
    // See More Rate
    'seeMoreRate',
    // Canvas Avg View Time
    'canvasAvgViewTime',
    // Canvas Avg View %
    'canvasAvgViewPercent',
    // Interest
    'interest',
    // Cost Per Result
    'cpr',
  ];

const PERFORMANCE_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Reach
    'reach',
    // Reach (Full View)
    'fullViewReach',
    // Frequency
    'frequency',
    // Impressions
    'impressions',
    // Impressions (Full View)
    'fullViewImpressions',
    // CPM
    'cpm',
    // PPM
    'purchasesPerMille',
    // RPM
    'revenuePerMille',
    // IPM
    'installsPerMille',
    // Delivery
    'effectiveStatus',
    // Ad Set Delivery
    // Spend
    'spend',
    // Spend %
    'spendPercentage',
    // ROAS
    'roas',
    // ROAS (Website Purchases)
    // AOV
    'avgOrderValue',
    // Purchase Value %
    'purchaseValuePercentage',
    // Ad Recall Lift (People)
    // Cost Per Ad Recall Lift (People)
    // Conversions
    'conversions',
    // Add-to-Cart To Purchase Ratio
    'addToCartToPurchaseRatio',
  ];

const WIZARD_SCORE_CATEGORY_METRICS =
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS;

const STANDARD_CONVERSIONS_CATEGORY_METRICS =
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS;

/**
 * Finds the index of the n-th occurence of a substring wtihin a string
 */
function getNthOccurrence(str: string, substring: string, n: number) {
  let count = 0;
  let position = 0;

  while (count < n) {
    position = str.indexOf(substring, position);

    if (position === -1) {
      return -1;
    }

    count++;
    position++;
  }

  return position - 1;
}

const CreatePresetModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();
  const { tableMetrics: metrics, setPreset, zIndex } = useInsightsAdBreakdown();

  const inputRef = useRef<HTMLInputElement>(null);
  const [presetName, setPresetName] = useState('');

  const createPreset = trpc.insights.createInsightsMetricPreset.useMutation({
    onSuccess: (response) => {
      void trpcUtils.insights.getInsightsMetricPresets.invalidate();
      setIsOpen(false);
      setPreset(response.uuid);
      toast.success('Preset successfully created');
    },
    onError: (e) => {
      toast.error(e.message ?? 'Error creating preset');
    },
  });

  useLayoutEffect(() => {
    inputRef.current?.focus();

    return () => setPresetName('');
  }, [isOpen]);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
        style={{ zIndex: zIndex + 1 }}
      >
        <Dialog
          className="relative flex w-[480px] flex-col gap-6 overflow-y-auto rounded-2xl border border-purple-200 bg-white p-6 shadow focus-visible:outline-none"
          aria-label="create table metrics preset modal"
        >
          <Heading className="text-xl font-semibold text-primary">
            Save as New Preset
          </Heading>
          <TextField className="flex flex-col gap-1">
            <Label className="text-sm font-semibold text-primary">
              Preset Name
            </Label>
            <Input
              ref={inputRef}
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              name="preset"
              placeholder="e.g. Performance Metrics"
            />
          </TextField>

          <div className="flex items-center justify-end gap-2.5">
            <Button variant="secondary" size="small" slot="close">
              Cancel
            </Button>
            <Button
              size="small"
              onPress={() => {
                createPreset.mutate({
                  name: presetName,
                  metrics,
                  insightsAdAccountUuid: accountUuid ?? '',
                });
              }}
              loading={createPreset.isLoading}
            >
              Create Preset
            </Button>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
