import { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from '@magicbrief/ui/src/components/popover';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import { Button } from '@magicbrief/ui/src/components/button';
import { ListBox, Text } from 'react-aria-components';
import { ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import Plus from 'src/assets/svgicons/line/plus.svg';
import { trpc } from 'src/lib/trpc';
import { useInsightsAdBreakdown } from '../useInsightsAdBreakdown';

export const AdBreakdownMetricPresets = ({
  setIsMetricModalOpen,
}: {
  setIsMetricModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { accountUuid } = useParams();
  const { preset, setPreset, setTableMetrics, zIndex } =
    useInsightsAdBreakdown();

  const [isOpen, setIsOpen] = useState(false);

  const { data: presets } = trpc.insights.getInsightsMetricPresets.useQuery({
    insightsAdAccountUuid: accountUuid ?? '',
  });

  const label = presets?.find((p) => p.uuid === preset)?.name ?? 'Custom';

  return (
    <PopoverTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
      <TooltipTrigger delay={300} closeDelay={0}>
        <Button
          variant="secondary"
          size="small"
          className="rounded-md text-xs"
          aria-label="Select metric preset"
        >
          {label}
          <ChevronDown className="size-4" />
        </Button>
        <Tooltip className="text-xs" style={{ zIndex }}>
          Choose visible metrics in table columns
        </Tooltip>
      </TooltipTrigger>

      <Popover placement="bottom left" style={{ zIndex: zIndex }}>
        <PopoverDialog className="flex flex-col rounded-md" style={{ zIndex }}>
          <Text className="px-4 pt-3 text-xs font-medium uppercase text-primary/50">
            Select preset columns view
          </Text>
          <ListBox
            className="border-b border-solid border-purple-200 px-1.5 py-1"
            onAction={(key) => {
              const selectedPresetMetrics = presets?.find(
                (p) => p.uuid === key
              )?.metrics;

              if (selectedPresetMetrics) {
                setPreset(key);
                setTableMetrics(selectedPresetMetrics);
                setIsOpen(false);
              }
            }}
          >
            {presets?.map((p) => (
              <ListBoxItem key={p.uuid} id={p.uuid}>
                {p.name}
              </ListBoxItem>
            ))}
          </ListBox>
          <div className="px-2 py-1">
            <Button
              variant="text"
              size="small"
              slot="close"
              onPress={() => setIsMetricModalOpen(true)}
              className="w-full justify-start font-medium hover:bg-purple-100"
            >
              <Plus className="size-4" /> Create New Preset
            </Button>
          </div>
        </PopoverDialog>
      </Popover>
    </PopoverTrigger>
  );
};
