import { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  ArrayElement,
  getMetricPositivity,
  INSIGHTS_AD_SPEND_THRESHOLD,
  INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM,
  InsightsFacebookWizardMetric,
  IntRange,
  isInsightsFacebookMetricNumeric,
} from '@magicbrief/common';
import {
  OnChangeFn,
  RowSelectionState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Button,
  Menu,
  MenuItem,
  MenuTrigger,
  Pressable,
  ResizableTableContainer,
} from 'react-aria-components';
import { useAtomValue } from 'jotai';
import { toast } from 'react-toastify';
import { Popover } from '@magicbrief/ui/src/components/popover';
import {
  TooltipTrigger,
  Tooltip as TooltipUI,
} from '@magicbrief/ui/src/components/tooltip';
import { TableCheckbox } from 'src/components/Table';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import DotsVertical from 'src/assets/svgicons/line/dots-vertical.svg';
import { Tooltip } from '../../../../components/Tooltip/Tooltip';
import {
  GetFacebookAdAccountResponse,
  GetFacebookAdsStatisticsResponse,
  GetManyFacebookAdsWithInsightsResponse,
} from '../../../../types/insights';
import { useInsightsDetailContext } from '../../InsightsDetailOutlet/context';
import { INSIGHTS_STYLES } from '../../util/constants';
import { getSentimentColor } from '../../util/getSentimentColor';
import {
  useInsightsColumnSize,
  useInsightsComparisons,
  useInsightsDisplay,
  useInsightsGroup,
  useInsightsLayout,
  useInsightsPlatform,
  useInsightsSelectedGridItems,
  useInsightsSelectedItems,
  useInsightsSort,
  useInsightsStoreDispatch,
  useInsightsTableColorScheme,
} from '../../util/useInsightsPersistentState';
import {
  getMetricValue,
  getValueForMetric,
  parseValueForMetric,
} from '../../util/useParseMetric';
import {
  WizardScoreMeter,
  WizardScoreTooltip,
} from '../InsightsCard/components/InsightsCardInsights/WizardScore';
import { InsightsCardStatus } from '../InsightsCard/components/InsightsCardStatus/InsightsCardStatus';
import { InsightsMedia } from '../InsightsMedia/InsightsMedia';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { InsightsAdBreakdownPanel } from '../InsightsAdBreakdownPanel/InsightsAdBreakdownPanel';
import { tableColorPreferenceAtom } from '../../Insights.atoms';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../const';
import { InsightsFilterSet } from '../../routes/InsightsCompare/types';
import { InsightsGroupDetailView } from '../InsightsGroupDetailView/InsightsGroupDetailView';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import { MemoizedTableBody, TableBody } from './TableBody';
import { DraggableTableFooterHeader } from './TableFooterHeader';
import { DraggableTableHeader, TableHeader } from './TableHeader';
import {
  INSIGHTS_TABLE_NULL_ROW_ID,
  NEGATIVE_TABLE_COLORS,
  NEUTRAL_TABLE_COLORS,
  POSITIVE_TABLE_COLORS,
} from './const';
import { parseColumnWidthId } from './util';

export type Props = {
  headerClassName?: string;
  currency: GetFacebookAdAccountResponse['currency'];
  ads: Array<ArrayElement<GetManyFacebookAdsWithInsightsResponse>>;
  statistics: GetFacebookAdsStatisticsResponse | null;
  selectedKeys?: Array<string | null>;
  onSelectionChange?: (keys: Array<string | null>) => void;
  showSummaryRow?: boolean;
  customEvents: string[] | null | undefined;
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined;
  type: 'overview' | 'compare' | 'analysis';
  setShowNewGroupModal?: React.Dispatch<
    React.SetStateAction<boolean | InsightsFilterSet>
  >;
};

const columnHelper = createColumnHelper<ArrayElement<Props['ads']>>();

export const InsightsTable: FC<Props> = ({
  currency,
  ads,
  statistics,
  headerClassName,
  onSelectionChange,
  selectedKeys,
  customEvents,
  customConversions,
  showSummaryRow = true,
  type,
  setShowNewGroupModal,
}) => {
  const { LL } = useI18nContext();
  const { params } = useInsightsSearchParams();
  const showDetails = useInsightsDetailContext();
  const platform = useInsightsPlatform();
  const display = useInsightsDisplay();
  const sort = useInsightsSort();
  const layout = useInsightsLayout();
  const columnSizing = useInsightsColumnSize();
  const dispatch = useInsightsStoreDispatch();
  const comparisons = useInsightsComparisons();
  const providerTableColorPreference = useInsightsTableColorScheme();
  const selectedChartAds = useInsightsSelectedItems();
  const selectedGridAds = useInsightsSelectedGridItems();
  const grouping = useInsightsGroup();
  const storageTableColorPreference = useAtomValue(tableColorPreferenceAtom);

  const tableColorPreference =
    providerTableColorPreference ?? storageTableColorPreference;

  const [selectedAdBreakdown, setSelectedAdBreakdown] = useState<string | null>(
    null
  );
  const [selectedSummary, setSelectedSummary] = useState<string | null>(null);

  const group = ads[0]?.type === 'group';

  const locale = window.navigator.languages
    ? window.navigator.languages[0]
    : window.navigator.language;

  const numberOfAds =
    layout === 'grid'
      ? ads.length
      : ads.length < INSIGHTS_TABLE_SELECTION_MAX
        ? ads.length
        : INSIGHTS_TABLE_SELECTION_MAX;

  const checkIndeterminate = () => {
    switch (layout) {
      case 'table':
        return selectedChartAds.length > 0;
      case 'grid':
        if (selectedGridAds === 'default') return true;
        if (selectedGridAds.mode === 'all') return true;
        if (selectedGridAds.mode === 'standard') {
          return selectedGridAds.selected.length > 0;
        }
        break;
      default:
        return false;
    }
  };

  const columns = [
    columnHelper.display({
      id: 'adSelection',
      header: () => (
        <TableCheckbox
          className="cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
          isIndeterminate={checkIndeterminate()}
          onChange={() => {
            switch (layout) {
              case 'table':
                dispatch({
                  type: 'setSelected',
                  value:
                    selectedChartAds.length === 0
                      ? ads
                          ?.slice(0, numberOfAds)
                          .map((x) => (x.type === 'ad' ? x.uuid : x.group))
                      : [],
                });
                break;

              case 'grid':
                if (selectedGridAds === 'default') {
                  dispatch({
                    type: 'setSelectedGridMode',
                    value: 'all',
                  });
                  return;
                }

                switch (selectedGridAds.mode) {
                  case 'all':
                    dispatch({
                      type: 'setSelectedGridMode',
                      value: 'standard',
                    });
                    break;

                  case 'standard':
                    if (selectedGridAds.selected.length === 0) {
                      dispatch({
                        type: 'setSelectedGridMode',
                        value: 'all',
                      });
                    } else {
                      dispatch({
                        type: 'setSelectedGrid',
                        value: [],
                      });
                    }
                }

                break;
              default:
                break;
            }
          }}
        />
      ),
      minSize: 52,
      maxSize: 52,
      cell: (cell) => {
        const selectionIdx = cell.row.getIsSelected()
          ? (selectedKeys?.findIndex((x) =>
              x ? x === cell.row.id : x === INSIGHTS_TABLE_NULL_ROW_ID
            ) ?? -1)
          : -1;
        const styles = INSIGHTS_STYLES[selectionIdx];

        return (
          <div
            style={{
              width: `calc(var(--header-${parseColumnWidthId(cell.column.id)}-size) * 1px)`,
            }}
          >
            {!!onSelectionChange && (
              <TableCheckbox
                selectedClassName={
                  layout === 'table' ? cn(styles?.checkbox, styles?.border) : ''
                }
                indeterminateClassName={cn(styles?.checkbox, styles?.border)}
                isSelected={cell.row.getIsSelected()}
                isDisabled={!cell.row.getCanSelect()}
                onChange={cell.row.getToggleSelectedHandler()}
              />
            )}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => (row.type === 'ad' ? row.name : row.group), {
      id: 'adName',
      header: () => {
        if (type === 'analysis') return 'Name';

        const selectedCount =
          layout === 'table'
            ? selectedChartAds === 'default'
              ? INSIGHTS_TABLE_SELECTION_MAX
              : selectedChartAds.length
            : selectedGridAds === 'default'
              ? INSIGHTS_TABLE_SELECTION_MAX
              : selectedGridAds.mode === 'all'
                ? 'All'
                : selectedGridAds.mode === 'standard'
                  ? selectedGridAds.selected.length
                  : 0;

        return <div>{selectedCount} ads selected</div>;
      },
      size: 300,
      minSize: 150,
      cell: (cell) => {
        const item = cell.row.original;
        const selectionIdx = cell.row.getIsSelected()
          ? (selectedKeys?.findIndex((x) =>
              x ? x === cell.row.id : x === INSIGHTS_TABLE_NULL_ROW_ID
            ) ?? -1)
          : -1;
        const styles = INSIGHTS_STYLES[selectionIdx];

        const matchingComparison =
          comparisons && item.platform === 'facebook' && item.type === 'group'
            ? comparisons?.find((comparison) => comparison.id === item.group)
            : undefined;

        return (
          <div
            style={{
              width: `calc(var(--header-${parseColumnWidthId(cell.column.id)}-size) * 1px)`,
            }}
            className="flex flex-row items-center gap-3 overflow-hidden p-2"
          >
            <div className="flex grow flex-row items-center gap-3 overflow-hidden">
              <InsightsMedia
                display="table"
                adUuid={item.type === 'ad' ? item.uuid : item.ads?.[0]?.uuid}
                assets={
                  item.type === 'ad'
                    ? (item.creative?.assets ?? [])
                    : (item.assets ?? [])
                }
                className={cn(
                  'h-8 w-8 shrink-0',
                  styles && 'border border-solid',
                  styles && styles.border
                )}
                showControls={false}
                creativeType={
                  item.type === 'ad' ? item.creative.creativeType : 'unknown'
                }
                isPreview
                mediaId={null}
              />

              {item.type === 'ad' ? (
                <TooltipTrigger>
                  <Button
                    onPress={() => {
                      showDetails({
                        uuid: item.uuid,
                        data: {
                          ad: item,
                          pagination: null,
                        },
                      });
                    }}
                    className="truncate font-medium text-gray-900"
                  >
                    {item.name}
                  </Button>
                  <TooltipUI className="max-w-[75vw]">{item.name}</TooltipUI>
                </TooltipTrigger>
              ) : (
                <>
                  <div className="grow truncate">
                    <TooltipTrigger>
                      <Pressable aria-label="group name">
                        <div
                          className={cn(
                            'truncate text-sm font-medium text-gray-900',
                            !item.name && 'italic text-gray-700'
                          )}
                          role="button"
                        >
                          {item.name ?? 'None'}
                        </div>
                      </Pressable>
                      <TooltipUI className="max-w-[75vw]">
                        {item.name ?? 'None'}
                      </TooltipUI>
                    </TooltipTrigger>
                    <div className="flex items-center overflow-hidden truncate text-xs font-normal text-gray-600">
                      {LL.library.numAds({ count: item.count })}
                    </div>
                  </div>
                  <MenuTrigger>
                    <Button className="rounded-md p-2 outline-none transition-colors hover:bg-purple-50">
                      <DotsVertical className="size-4 text-primary" />
                    </Button>

                    <Popover className="p-1.5">
                      <Menu className="outline-none">
                        {setShowNewGroupModal && !!comparisons ? (
                          <MenuItem
                            className="ont-medium select-none rounded-md px-3 py-1.5 text-sm text-primary outline-none hover:bg-purple-100"
                            onAction={() => {
                              if (matchingComparison) {
                                setShowNewGroupModal(matchingComparison);
                              }
                            }}
                          >
                            Edit
                          </MenuItem>
                        ) : null}
                        <MenuItem
                          className="select-none rounded-md px-3 py-1.5 text-sm font-medium text-primary outline-none hover:bg-purple-100 focus:bg-purple-100 disabled:opacity-50"
                          onAction={() => setSelectedAdBreakdown(item.group)}
                          isDisabled={item.count === 0}
                        >
                          Ad Group Breakdown
                        </MenuItem>
                        <MenuItem
                          className="select-none rounded-md px-3 py-1.5 text-sm font-medium text-primary outline-none hover:bg-purple-100 disabled:opacity-50"
                          onAction={() => setSelectedSummary(item.group)}
                          isDisabled={item.count === 0}
                        >
                          Performance Summary
                        </MenuItem>
                      </Menu>
                    </Popover>
                  </MenuTrigger>

                  <InsightsAdBreakdownPanel
                    selectedAdBreakdown={selectedAdBreakdown}
                    setSelectedAdBreakdown={setSelectedAdBreakdown}
                    adGroup={item}
                    expression={
                      comparisons ? matchingComparison?.filter : undefined
                    }
                    comparisonId={
                      comparisons ? matchingComparison?.id : undefined
                    }
                    currency={currency}
                  />

                  <InsightsGroupDetailView
                    selectedSummary={selectedSummary}
                    setSelectedSummary={setSelectedSummary}
                    adGroup={item}
                    currency={currency}
                    group={grouping}
                  />
                </>
              )}
            </div>
          </div>
        );
      },
      footer: showSummaryRow
        ? (cell) => (
            <div
              style={{
                width: `calc(var(--header-${parseColumnWidthId(cell.header?.id)}-size) * 1px)`,
              }}
              className="flex h-14 items-center p-2 font-semibold text-purple-800"
            >
              {LL.insights.metrics.summary()}
            </div>
          )
        : undefined,
    }),
    ...display
      .filter((metric) => !['adName'].includes(metric))
      .map((metric) => {
        if (metric === 'effectiveStatus') {
          return columnHelper.accessor('metrics.effectiveStatus', {
            id: 'effectiveStatus',
            header: () => <span className="truncate">Ad Delivery</span>,
            size: 160,
            minSize: 130,
            cell: (cell) => {
              const value = cell.getValue() as string;
              return (
                <div
                  style={{
                    width: `calc(var(--header-${parseColumnWidthId(cell.column?.id)}-size) * 1px)`,
                  }}
                  className="flex flex-row items-center gap-2 p-2"
                >
                  {!!value && (
                    <InsightsCardStatus
                      isTooltipDisabled
                      effectiveStatus={value}
                    />
                  )}
                  <span className="flex-auto truncate">
                    {value
                      ? LL.insights.effectiveStatus[
                          value as keyof typeof LL.insights.effectiveStatus
                        ]()
                      : LL.insights.metrics.none()}
                  </span>
                </div>
              );
            },
            footer: showSummaryRow
              ? () => <div className="flex flex-row items-center gap-2"></div>
              : undefined,
          });
        }
        return columnHelper.accessor(
          (row) => getValueForMetric(metric, row.metrics),
          {
            id: metric,
            header: (header) => (
              <InsightsMetricLabelTooltip
                platform={platform}
                metric={metric}
                onPress={header.column.getToggleSortingHandler()}
                customEvents={customEvents}
                customConversions={customConversions}
              />
            ),
            size: 175,
            minSize: 100,
            cell: (cell) => {
              const metric = cell.column.id;

              const isWizardScore = metric.startsWith('wizard');

              const isSpend = metric === 'spend';
              const isNumericalMetric = isInsightsFacebookMetricNumeric(metric);

              let diffFromWeightedAvg = null;

              let sentimentColor = '#FFFFFF';
              if (!isSpend && !isWizardScore && isNumericalMetric) {
                const rawMetricValue = cell.getValue();
                const average = statistics?.[metric]?.weighted_avg;
                const min = statistics?.[metric]?.minThreshold;
                const max = statistics?.[metric]?.maxThreshold;
                const spend = cell.row.original.metrics.spend as number;
                const isOverMinSpend = spend > INSIGHTS_AD_SPEND_THRESHOLD;

                if (
                  isOverMinSpend &&
                  tableColorPreference !== 'none' &&
                  typeof rawMetricValue === 'number' &&
                  average != null &&
                  min != null &&
                  max != null
                ) {
                  diffFromWeightedAvg = average
                    ? (rawMetricValue - average) / average
                    : 0;

                  const lowerTail = average - min;
                  const upperTail = max - average;

                  const changeOutcome = getMetricPositivity('facebook', metric);

                  const positiveColors =
                    tableColorPreference === 'neutral-negative'
                      ? NEUTRAL_TABLE_COLORS
                      : POSITIVE_TABLE_COLORS;

                  const negativeColors =
                    tableColorPreference === 'positive-neutral'
                      ? NEUTRAL_TABLE_COLORS
                      : NEGATIVE_TABLE_COLORS;

                  if (rawMetricValue > average) {
                    const percentage = (rawMetricValue - average) / upperTail;
                    const percentageAsWholeNumber = (percentage *
                      100) as IntRange<0, 100>;

                    sentimentColor = getSentimentColor({
                      percentage: percentageAsWholeNumber,
                      colors:
                        changeOutcome === 'positive'
                          ? positiveColors
                          : negativeColors,
                      defaultColor: '#FFFFFF',
                      gradient: false,
                    });
                  } else if (rawMetricValue < average) {
                    const percentage = (average - rawMetricValue) / lowerTail;
                    const percentageAsWholeNumber = (percentage *
                      100) as IntRange<0, 100>;

                    sentimentColor = getSentimentColor({
                      percentage: percentageAsWholeNumber,
                      colors:
                        changeOutcome === 'positive'
                          ? negativeColors
                          : positiveColors,
                      defaultColor: '#FFFFFF',
                      gradient: false,
                    });
                  } else {
                    sentimentColor = '#FFFFFF';
                  }
                }
              }

              const formattedDiffFromWeightedAvg =
                diffFromWeightedAvg != null
                  ? Intl.NumberFormat(locale, {
                      style: 'percent',
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(diffFromWeightedAvg)
                  : null;

              const parsedValue = getMetricValue(
                cell.row.original.platform,
                cell.column.id,
                cell.row.original.metrics,
                currency
              );

              return (
                <Tooltip
                  delay={0}
                  isDisabled={diffFromWeightedAvg == null}
                  style={{ zIndex: 100 }}
                >
                  <Button
                    className="size-full items-center truncate p-2 text-left font-normal"
                    style={{
                      backgroundColor: sentimentColor,
                    }}
                  >
                    {parsedValue == null ? (
                      <div className="rounded px-2.5 py-1.5">
                        {LL.insights.metrics.none()}
                      </div>
                    ) : isWizardScore && parsedValue != null ? (
                      <WizardScoreTooltip
                        score={Number(parsedValue)}
                        type={metric as InsightsFacebookWizardMetric}
                      >
                        <WizardScoreMeter
                          score={Number(parsedValue)}
                          withBackground={false}
                        />
                      </WizardScoreTooltip>
                    ) : (
                      <div
                        className="truncate rounded px-2.5 py-1.5"
                        style={{
                          background: sentimentColor,
                        }}
                        title={parsedValue ?? 'None'}
                      >
                        <span className="truncate">
                          {parsedValue ?? LL.insights.metrics.none()}
                        </span>
                      </div>
                    )}
                  </Button>
                  <p>
                    {diffFromWeightedAvg != null && (
                      <>
                        Diff from avg{' '}
                        <span style={{ color: sentimentColor }}>
                          {formattedDiffFromWeightedAvg}
                        </span>
                      </>
                    )}
                  </p>
                </Tooltip>
              );
            },
            footer: showSummaryRow
              ? (cell) => {
                  const metric = cell.column.id;
                  const sum =
                    statistics?.[metric as keyof typeof statistics]?.sum;
                  const average =
                    statistics?.[metric as keyof typeof statistics]
                      ?.weighted_avg;
                  const parsedSum = parseValueForMetric(
                    platform,
                    metric,
                    sum ?? 0,
                    currency
                  );
                  const parsedAvg = parseValueForMetric(
                    platform,
                    metric,
                    average ?? 0,
                    currency
                  );

                  const reference =
                    INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
                      metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
                    ];

                  return (
                    <div
                      style={{
                        width: `calc(var(--header-${parseColumnWidthId(cell.header?.id)}-size) * 1px)`,
                      }}
                      className="flex h-14 w-full flex-col justify-center p-2"
                    >
                      <span>
                        {reference?.accumulation === 'sum'
                          ? (parsedSum ?? '--')
                          : '--'}
                      </span>

                      {!!reference?.accumulation && (
                        <span className="text-xxs text-primary">{`Weighted avg. ${parsedAvg}`}</span>
                      )}
                    </div>
                  );
                }
              : undefined,
          }
        );
      }),
  ];

  const containerRef = useRef<HTMLDivElement>(null);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsScrolled(containerRef.current.scrollLeft > 0);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const rowSelection: RowSelectionState =
    selectedKeys?.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr ?? INSIGHTS_TABLE_NULL_ROW_ID] = true;
      return acc;
    }, {}) ?? {};

  const onSortingChange: OnChangeFn<SortingState> = (updater) => {
    const newSorting = typeof updater === 'function' ? updater(sort) : updater;

    // Prevent the app from crashing if user has already added tag to the table metrics
    // Tag has been manually filtered from the metric picker
    if (newSorting[0].id === 'tag') {
      toast.error('Cannot sort on this property');
      return;
    }

    dispatch({
      type: 'setSort',
      value: newSorting,
    });
    params.set('sort', encodeURIComponent(JSON.stringify(newSorting)));
  };

  const table = useReactTable({
    columns,
    data: ads,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting: sort,
      rowSelection,
      columnOrder: display,
      columnSizing: columnSizing ?? {},
      columnVisibility: {
        /* Do not show checkboxes for Analysis */
        adSelection: type !== 'analysis',
      },
    },
    onSortingChange,
    enableSortingRemoval: false,
    onColumnOrderChange: (order) =>
      dispatch({
        type: 'setDisplay',
        value: order as Array<string>,
      }),
    getRowId: (row) =>
      row.type === 'ad' ? row.uuid : (row.group ?? INSIGHTS_TABLE_NULL_ROW_ID),
    enableMultiRowSelection: true,
    onRowSelectionChange: (updater) => {
      const selected =
        typeof updater === 'function' ? updater(rowSelection) : updater;

      onSelectionChange?.(
        Object.entries(selected).reduce<Array<string | null>>(
          (acc, [key, val]) => {
            if (val) {
              acc.push(key === INSIGHTS_TABLE_NULL_ROW_ID ? null : key);
            }
            return acc;
          },
          []
        )
      );
    },
    onColumnSizingChange: (updater) => {
      const newSizing =
        typeof updater === 'function' ? updater(columnSizing) : updater;
      dispatch({ type: 'setColumnSizing', value: newSizing });
    },
    getSortedRowModel: getSortedRowModel(),
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',

    initialState: {
      columnPinning: {
        left: ['adSelection', 'adName'],
      },
    },
  });

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);
      dispatch({ type: 'setDisplay', value: newDisplayOrder });
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and pass the column sizes down as CSS variables to the <table> element.
   *
   * @see {@link https://tanstack.com/table/latest/docs/guide/column-sizing}
   * @see {@link https://codesandbox.io/p/devbox/github/tanstack/table/tree/main/examples/react/column-resizing-performant?embed=1&file=%2Fsrc%2Fmain.tsx%3A193%2C1-197%2C13&theme=dark}
   */
  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      const cssVarId = parseColumnWidthId(header.id);
      colSizes[`--header-${cssVarId}-size`] = header.getSize();
      colSizes[`--col-${cssVarId}-size`] = header.column.getSize();
    }
    return colSizes;
    // We assume Mr Tanstack knows what they're doing here...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  /**
   * The purpose of these refs are to set the height of the "underlay" table rows.
   * TanStack Table's column resizing behaviour becomes inconsistent when we force the table to fit the
   * width of its parent/container element especially when the table has few columns.
   * This specifically fits our use case as users can add/remove and re-arrange columns.
   */
  const headerRowRef = useRef<HTMLTableRowElement>(null);
  const bodyRowRef = useRef<HTMLTableRowElement>(null);
  const footerRowRef = useRef<HTMLTableRowElement>(null);

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <ResizableTableContainer
        ref={containerRef}
        className="relative box-border w-full flex-auto overflow-auto bg-white"
      >
        {/* Underlay Table */}
        <div className="absolute flex w-full flex-col">
          <div
            className={cn(
              'sticky top-0 border-b border-solid border-b-gray-200 bg-purple-50',
              headerClassName
            )}
            style={{ height: headerRowRef.current?.clientHeight ?? 49 }}
          />
          {ads.map((ad) => {
            const key = ad.type === 'ad' ? ad.uuid : ad.group;

            return (
              <div
                key={key}
                className="w-full border-b border-solid border-b-secondary bg-white"
                style={{ height: bodyRowRef.current?.clientHeight ?? 56 }}
              />
            );
          })}
          {/* Compare does not have a footer */}
          {type !== 'compare' && (
            <div className="sticky bottom-0 bg-purple-50" />
          )}
        </div>

        <table
          style={{
            ...columnSizeVars, // Define column sizes on the <table> element
            width: table.getTotalSize(),
          }}
          className="w-full border-separate border-spacing-0 bg-white text-purple-800"
        >
          <thead className="z-[2]">
            {table.getHeaderGroups().map((headerGroup) => {
              const headers = headerGroup.headers;
              const numberOfFixedHeaders = type === 'analysis' ? 1 : 2;
              //  Analysis does not have charts so we don't have a checkbox/selection column
              const fixedHeaders = headers.slice(0, numberOfFixedHeaders);
              const restHeaders = headers.slice(numberOfFixedHeaders);
              const numberOfCols = headerGroup.headers.length;

              return (
                <tr
                  ref={headerRowRef}
                  className="z-0"
                  key={headerGroup.id}
                  role="row"
                >
                  {fixedHeaders.map((header, idx) => {
                    const isPinned = header.column.getIsPinned();
                    const showBorder =
                      idx === fixedHeaders.length - 1 && isScrolled;

                    return (
                      <TableHeader
                        key={header.id}
                        className={cn(
                          headerClassName,
                          showBorder ? 'border-r border-r-purple-200' : ''
                        )}
                        header={header}
                        isDataGrouped={!!group}
                        style={{
                          zIndex:
                            isPinned === 'left'
                              ? 1 + numberOfCols - idx
                              : numberOfCols - idx,
                        }}
                      />
                    );
                  })}

                  <SortableContext
                    items={display}
                    strategy={horizontalListSortingStrategy}
                  >
                    {restHeaders.map((header, idx) => {
                      return (
                        <DraggableTableHeader
                          key={header.id}
                          className={headerClassName}
                          header={header}
                          isDataGrouped={!!group}
                          style={{
                            zIndex: numberOfCols - fixedHeaders.length - idx,
                          }}
                        />
                      );
                    })}
                  </SortableContext>
                </tr>
              );
            })}
          </thead>
          {table.getState().columnSizingInfo.isResizingColumn ? (
            <MemoizedTableBody
              table={table}
              ref={bodyRowRef}
              isScrolled={isScrolled}
            />
          ) : (
            <TableBody table={table} ref={bodyRowRef} isScrolled={isScrolled} />
          )}
          {showSummaryRow && (
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => {
                const headers = footerGroup.headers;
                const first = headers[0];
                const restHeaders = headers.slice(1);
                return (
                  <tr
                    ref={footerRowRef}
                    className="sticky bottom-0 left-0 z-10"
                    key={footerGroup.id}
                  >
                    <DraggableTableFooterHeader header={first} />
                    {restHeaders.map((header, idx) => {
                      return (
                        <SortableContext
                          key={header.id}
                          items={display}
                          strategy={horizontalListSortingStrategy}
                        >
                          <DraggableTableFooterHeader
                            className={cn(
                              'align-top',
                              idx === 0 && isScrolled
                                ? 'border-r border-solid border-r-purple-200'
                                : ''
                            )}
                            header={header}
                          />
                        </SortableContext>
                      );
                    })}
                  </tr>
                );
              })}
            </tfoot>
          )}
        </table>
      </ResizableTableContainer>
    </DndContext>
  );
};
