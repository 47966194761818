import React, { forwardRef } from 'react';
import { ArrayElement } from '@magicbrief/common';
import { Header, flexRender } from '@tanstack/react-table';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { cn } from 'src/lib/cn';
import { Props } from './InsightsTable';
import { parseColumnWidthId } from './util';

const TableFooterHeader = forwardRef<
  HTMLTableCellElement,
  {
    header: Header<ArrayElement<Props['ads']>, unknown>;
    className?: string;
    style?: React.CSSProperties;
  }
>(({ header, className, style }, ref) => {
  const isPinned = header.column.getIsPinned();

  const styles: React.CSSProperties = {
    width: `calc(var(--header-${parseColumnWidthId(header.column?.id)}-size) * 1px)`,
    zIndex: isPinned ? 1 : 0,
    left:
      isPinned === 'left' ? `${header.column.getStart('left')}px` : undefined,
    ...style,
  };

  return (
    <th
      ref={ref}
      style={styles}
      className={cn(
        'sticky bg-purple-50 text-left text-sm text-gray-900',
        className
      )}
    >
      {header.isPlaceholder
        ? null
        : flexRender(header.column.columnDef.footer, header.getContext())}
    </th>
  );
});

TableFooterHeader.displayName = 'TableFooterHeader';

export const DraggableTableFooterHeader: React.FC<{
  header: Header<ArrayElement<Props['ads']>, unknown>;
  className?: string;
}> = ({ header, className }) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });

  const isPinned = header.column.getIsPinned();

  const style: React.CSSProperties = {
    transition: 'width transform 0.2s ease-in-out',
    transform: CSS.Translate.toString(transform), // translate instead of transform to avoid squishing
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 2 : isPinned ? 1 : 0,
  };

  return (
    <TableFooterHeader
      ref={setNodeRef}
      header={header}
      style={style}
      className={className}
    />
  );
};
