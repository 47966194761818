import React from 'react';
import {
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  MenuTrigger,
  MenuTriggerProps,
  PopoverProps,
} from 'react-aria-components';
import classNames from 'classnames';
import { cn } from 'src/lib/cn';
import Check from 'src/assets/svgicons/line/check.svg';
import { Icon } from '../Icon';
import { AriaPopover } from '../AriaPopover/AriaPopover';
import { usePopoverState } from '../AriaPopover/usePopoverState';

type Props = Omit<MenuTriggerProps, 'children'> & {
  children: [React.ReactElement, ...React.ReactNode[]];
  /** blocks menu from opening */
  isDisabled?: boolean;
  placement?: PopoverProps['placement'];
  className?: string;
  style?: React.CSSProperties;
};

const AriaMenuItem: React.FC<
  Omit<MenuItemProps, 'className'> & {
    className?: string;
    contentClassName?: string;
    checkboxClassName?: string;
    icon?: React.ReactNode;
    danger?: boolean;
    showCheckbox?: boolean;
  }
> = ({
  className,
  contentClassName,
  checkboxClassName,
  children,
  icon,
  danger,
  showCheckbox = true,
  ...props
}) => (
  <MenuItem
    {...props}
    className={cn(
      'group box-border flex cursor-default items-center gap-3 overflow-hidden rounded px-3 py-2 text-sm font-medium outline-none',
      danger
        ? 'text-danger focus:bg-red-50'
        : 'text-primary focus:bg-purple-100',
      className
    )}
  >
    {(params) => (
      <>
        {params.selectionMode !== 'none' && showCheckbox && (
          <span
            className={cn(
              'size-4 shrink-0 [&>*]:h-full [&>*]:w-full',
              checkboxClassName
            )}
          >
            {icon ?? (
              <Icon className="hidden group-selected:block">
                <Check />
              </Icon>
            )}
          </span>
        )}

        {!params.isSelected && icon && (
          <span className="size-5 shrink-0 [&>*]:size-full">{icon}</span>
        )}

        <span className={contentClassName}>
          {typeof children === 'function' ? children(params) : children}
        </span>
      </>
    )}
  </MenuItem>
);

type AriaMenuListProps<T> = MenuProps<T>;

function AriaMenuList<T extends object>({
  className,
  ...rest
}: AriaMenuListProps<T>) {
  return (
    <Menu
      {...rest}
      className={classNames(
        'outline-none focus:outline-none focus-visible:outline-none',
        className
      )}
    />
  );
}

type AriaMenuCompositeComponent = React.FC<Props> & {
  Item: typeof AriaMenuItem;
  List: typeof AriaMenuList;
};

export const AriaMenu: AriaMenuCompositeComponent = ({
  children,
  className,
  onOpenChange,
  placement,
  isDisabled,
  style,
  ...rest
}) => {
  const [popoverState, setPopoverState] = usePopoverState();

  return (
    <MenuTrigger
      {...rest}
      onOpenChange={(isOpen) => {
        if (!isDisabled) {
          setPopoverState(isOpen ? 'animate' : 'initial');
        }
        onOpenChange?.(isOpen);
      }}
    >
      {children[0]}
      <AriaPopover
        className={classNames(
          className,
          'overflow-auto rounded-md bg-white p-1 shadow-md outline-none ring-1 ring-purple-300 focus:outline-none'
        )}
        state={popoverState}
        onStateChange={setPopoverState}
        placement={placement}
        style={style}
      >
        {...children.slice(1)}
      </AriaPopover>
    </MenuTrigger>
  );
};

AriaMenu.List = AriaMenuList;
AriaMenu.Item = AriaMenuItem;
