import {
  Select as AriaSelect,
  SelectProps,
  SelectValue as AriaSelectValue,
  SelectValueProps as AriaSelectValueProps,
  Text,
  PopoverProps as AriaPopoverProps,
  ListBoxProps as AriaListBoxProps,
  ValidationResult,
} from 'react-aria-components';
import { Description, FieldError, Label } from './field';
import { Button, ButtonProps } from './button';
import { Popover } from './popover';
import { ListBox, ListBoxItem } from './list-box';
import { cn } from '../lib/cn';

const Select = AriaSelect;

const SelectItem = ListBoxItem;

const SelectButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      variant="secondary"
      size="small"
      className={cn(
        [
          'flex w-full items-center justify-between font-medium',
          // Disabled
          'disabled:pointer-events-auto disabled:cursor-not-allowed',
        ],
        className
      )}
    />
  );
};

const SelectValue = <T extends object>(props: AriaSelectValueProps<T>) => {
  return (
    <AriaSelectValue
      {...props}
      className={cn(
        'line-clamp-1 text-primary data-[placeholder]:text-[#917DD2]',
        /* Description */
        '[&>[slot=description]]:hidden',
        props.className
      )}
    />
  );
};

interface SelectContentProps<T>
  extends Omit<AriaPopoverProps, 'children' | 'style'>,
    Omit<AriaListBoxProps<T>, 'style'> {
  className?: string;
  popoverClassName?: string;
}

const SelectContent = <T extends object>({
  className,
  popoverClassName,
  ...props
}: SelectContentProps<T>) => {
  return (
    <Popover className={popoverClassName} {...props}>
      <ListBox className={className} {...props} />
    </Popover>
  );
};

export { Select, SelectButton, SelectValue, SelectContent, SelectItem };
