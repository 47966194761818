import { useId } from 'react';
import { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { AssetMedia } from '../../InsightsMedia/InsightsMedia';

export const InsightsGroupAssetView = ({
  adGroup,
}: {
  adGroup: InsightsAdGroupWithInsights;
}) => {
  const mediaId = useId();

  const assets = adGroup?.assets ?? [];

  return (
    <div className="flex size-full grow items-center justify-center bg-purple-100">
      <AssetMedia
        display="full"
        className="aspect-auto w-[400px] justify-center overflow-hidden rounded-lg"
        asset={assets?.[0]}
        mediaId={mediaId}
        openAdPreview={() => null}
      />
    </div>
  );
};
