import { createContext, useContext } from 'react';
import {
  groupSchema,
  InsightsFacebookGroup,
  metricFilterSchema,
  migrateFormerColumnMetricsToActionMetric,
  stringifyInsightsFacebookActionMetric,
  InsightsFilterV2,
  convertFiltersV1ToV2,
  insightsFilterV2,
  type ActiveMetric,
  type InsightsTableColorSchema,
  insightsFilterExpressionSchema,
} from '@magicbrief/common';
import { atom, Atom, useSetAtom, WritableAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import { SyncStorage } from 'jotai/vanilla/utils/atomWithStorage';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { z } from 'zod';
import { InsightsFilterSet } from '../routes/InsightsCompare/types';

export type InsightsSort = {
  id: string;
  desc: boolean;
};

type InsightsAttributionWindow = 'default' | 'custom';

interface InsightsStatePersisted {
  display?: Array<string>;
  sort?: Array<InsightsSort>;
  filter?: Array<InsightsFilterV2> | Array<ActiveMetric>;
  group?: InsightsFacebookGroup | null;
  columnSizing?: Record<string, number>;
  graphMetrics?: Array<string>;
  gridMetrics?: Array<string>;
  selected?: Array<string | null> | 'default';
  selectedGrid?: SelectedGridState;
  layout?: InsightsLayout;
  tableColorScheme?: InsightsTableColorSchema | null;
  comparisons?: Array<InsightsFilterSet>;
  attributionWindow?: InsightsAttributionWindow;
}

export type SelectedGridAll = {
  mode: 'all';
  except: Array<string | null>;
};

export type SelectedGridStandard = {
  mode: 'standard';
  selected: Array<string | null>;
};

type SelectedGridState = 'default' | SelectedGridAll | SelectedGridStandard;

export interface InsightsState {
  comparisons: Array<InsightsFilterSet> | undefined;
  display: Array<string>;
  sort: Array<InsightsSort>;
  filter: Array<InsightsFilterV2>;
  group: InsightsFacebookGroup | null;
  columnSizing: Record<string, number>;
  graphMetrics: Array<string>;
  gridMetrics: Array<string>;
  selected: Array<string | null> | 'default';
  selectedGrid: SelectedGridState;
  layout: InsightsLayout;
  tableColorScheme: InsightsTableColorSchema | null;
  attributionWindow: InsightsAttributionWindow;
}

export type InsightsView = 'overview' | 'analysis' | 'compare';
export type InsightsLayout = 'table' | 'grid' | 'full-grid';

type InsightsStateAction =
  | {
      type: 'setDisplay';
      value: Array<string>;
    }
  | {
      type: 'setColumnSizing';
      value: Record<string, number>;
    }
  | { type: 'toggleDisplay'; value: string }
  | {
      type: 'setFilter';
      value: Array<InsightsFilterV2>;
    }
  | { type: 'addFilter'; value: InsightsFilterV2 }
  | { type: 'removeFilter'; value: InsightsFilterV2 }
  | { type: 'setComparisons'; value: Array<InsightsFilterSet> }
  | { type: 'setSort'; value: Array<InsightsSort> }
  | { type: 'addSort'; value: InsightsSort }
  | { type: 'removeSort'; value: InsightsSort }
  | { type: 'toggleSort'; value: InsightsSort }
  | {
      type: 'setGroup';
      value: InsightsFacebookGroup | null;
    }
  | { type: 'addGraphMetric'; value: string }
  | { type: 'removeGraphMetric'; value: string }
  | { type: 'setGraphMetrics'; value: Array<string> }
  | { type: 'addGridMetric'; value: string }
  | { type: 'removeGridMetric'; value: string }
  | { type: 'setGridMetrics'; value: Array<string> }
  | { type: 'setSelected'; value: Array<string | null> | 'default' }
  | { type: 'setSelectedGrid'; value: Array<string | null> }
  | { type: 'setSelectedGridMode'; value: 'default' | 'standard' | 'all' }
  | { type: 'setLayout'; value: InsightsLayout }
  | {
      type: 'setTableColorScheme';
      value: InsightsTableColorSchema;
    }
  | {
      type: 'setAttributionWindow';
      value: InsightsAttributionWindow;
    };

function getDefaultInsightsFilter(
  platform: 'facebook' | 'tiktok'
): Array<InsightsFilterV2> {
  switch (platform) {
    case 'facebook':
      return [
        { field: 'effectiveStatus', operation: 'in', values: ['Active'] },
      ];
    case 'tiktok':
      return [{ field: 'delivery', operation: 'in', values: ['ACTIVE'] }];
    default:
      platform satisfies never;
      return [];
  }
}

const DEFAULT_INSIGHTS_SORT: Array<InsightsSort> = [
  { id: 'spend', desc: true },
];

function getDefaultInsightsDisplay(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return [
        'spend',
        'impressions',
        'clicks',
        'cpm',
        'cpc',
        'clickThroughRateLinks', // Click through rate (links)
      ];
    case 'tiktok':
      return ['spend', 'impressions', 'clicks', 'cpm', 'cpc'];
    default:
      platform satisfies never;
      return [];
  }
}

const DEFAULT_INSIGHTS_GRAPH_METRICS: Array<string> = [
  'spend',
  'impressions',
  'clicks',
];

type InsightsPersistentStateContextValue = {
  all: WritableAtom<InsightsState, [InsightsStateAction], void>;
  platform: 'facebook' | 'tiktok';
  comparisons: Atom<Array<InsightsFilterSet> | undefined>;
  filter: Atom<Array<InsightsFilterV2>>;
  display: Atom<Array<string>>;
  columnSizing: Atom<Record<string, number>>;
  sort: Atom<Array<InsightsSort>>;
  group: Atom<InsightsFacebookGroup | null>;
  graphMetrics: Atom<Array<string>>;
  gridMetrics: Atom<Array<string>>;
  selected: Atom<Array<string | null> | 'default'>;
  selectedGrid: Atom<SelectedGridState>;
  layout: Atom<InsightsLayout>;
  tableColorScheme: Atom<InsightsTableColorSchema | null>;
  attributionWindow: Atom<InsightsAttributionWindow>;
};

export const InsightsPersistentStateContext =
  createContext<InsightsPersistentStateContextValue | null>(null);

export function createInsightsDefaultPersistentState(
  platform: 'facebook' | 'tiktok',
  view?: InsightsView | null
): InsightsState {
  return {
    // Do not apply to Comparison groups
    filter: view === 'compare' ? [] : getDefaultInsightsFilter(platform),
    display: getDefaultInsightsDisplay(platform),
    sort: DEFAULT_INSIGHTS_SORT,
    group: null,
    columnSizing: {},
    graphMetrics: DEFAULT_INSIGHTS_GRAPH_METRICS,
    gridMetrics: DEFAULT_INSIGHTS_GRAPH_METRICS,
    selected: 'default',
    selectedGrid: 'default',
    layout: 'table',
    // We only provide a fallback for reports, others rely on local storage
    tableColorScheme: null,
    comparisons: [],
    attributionWindow: 'default',
  };
}

function hydrateSelectedGridState(state: Partial<InsightsStatePersisted>) {
  if (state.selectedGrid === 'default') return 'default';

  if (state.selectedGrid?.mode === 'standard') {
    const selected = Array.isArray(state.selectedGrid.selected)
      ? state.selectedGrid.selected.reduce<Array<string | null>>((acc, x) => {
          if (!x) {
            acc.push(x);
            return acc;
          }
          if (state.group === 'adName') {
            if (x.startsWith('adName:')) {
              acc.push(x.replace(/^adName:/, ''));
              return acc;
            } else if (x.startsWith('postId:')) {
              return acc;
            }
          }

          acc.push(x);

          return acc;
        }, [])
      : [];

    return {
      mode: 'standard',
      selected,
    } as SelectedGridStandard;
  }

  if (state.selectedGrid?.mode === 'all') {
    const except = Array.isArray(state.selectedGrid.except)
      ? state.selectedGrid.except.reduce<Array<string | null>>((acc, x) => {
          if (!x) {
            acc.push(x);
            return acc;
          }
          if (state.group === 'adName') {
            if (x.startsWith('adName:')) {
              acc.push(x.replace(/^adName:/, ''));
              return acc;
            } else if (x.startsWith('postId:')) {
              return acc;
            }
          }

          acc.push(x);

          return acc;
        }, [])
      : [];

    return {
      mode: 'all',
      except,
    } as SelectedGridAll;
  }

  return 'default';
}

function hydrateInsightsPersistentState(
  platform: 'facebook' | 'tiktok',
  value: Partial<InsightsStatePersisted>
): InsightsState {
  const display = value.display
    ? [
        ...new Set(
          value.display
            .map((x) => {
              // Capture bugged metric used in some cases
              if (x === 'link_clicks') {
                return null;
              }
              const converted = migrateFormerColumnMetricsToActionMetric(x);
              return converted
                ? stringifyInsightsFacebookActionMetric(converted)
                : x;
            })
            .filter((x): x is Exclude<typeof x, null> => !!x)
        ),
      ]
    : [];

  const selected = Array.isArray(value.selected)
    ? value.selected.reduce<Array<string | null>>((acc, x) => {
        if (!x) {
          acc.push(x);
          return acc;
        }
        if (value.group === 'adName') {
          if (x.startsWith('adName:')) {
            acc.push(x.replace(/^adName:/, ''));
            return acc;
          } else if (x.startsWith('postId:')) {
            return acc;
          }
        }

        acc.push(x);
        return acc;
      }, [])
    : (value.selected ?? 'default');

  const selectedGrid = hydrateSelectedGridState(value);

  const graphMetrics = value.graphMetrics ?? value.sort?.map((x) => x.id) ?? [];

  const gridMetrics = value.gridMetrics ?? value.display ?? [];

  const layout = value.layout ?? 'table';

  const sort =
    value.sort?.reduce<InsightsSort[]>((acc, x) => {
      const converted = migrateFormerColumnMetricsToActionMetric(x.id);

      const value = {
        ...x,
        id: converted ? stringifyInsightsFacebookActionMetric(converted) : x.id,
      };

      if (!acc.some((y) => y.id === value.id)) {
        acc.push(value);
      }

      return acc;
    }, []) ?? [];

  const filter = value.filter ? convertFiltersV1ToV2(value.filter) : [];

  const attributionWindow = value.attributionWindow ?? 'default';

  const group = value.group ?? null;

  const migrated: InsightsState = {
    ...createInsightsDefaultPersistentState(platform),
    ...value,
    selected,
    selectedGrid,
    display,
    sort,
    graphMetrics,
    gridMetrics,
    filter,
    layout,
    attributionWindow,
    group,
    columnSizing: Object.entries(value.columnSizing ?? {}).reduce<
      Record<string, number>
    >((acc, curr) => {
      const converted = migrateFormerColumnMetricsToActionMetric(curr[0]);
      if (converted) {
        acc[stringifyInsightsFacebookActionMetric(converted)] = curr[1];
      } else {
        acc[curr[0]] = curr[1];
      }
      return acc;
    }, {}),
  };
  return migrated;
}

function reducer(
  state: InsightsState,
  { value, type }: InsightsStateAction
): InsightsState {
  switch (type) {
    case 'setDisplay':
      return {
        ...state,
        display: value,
      };
    case 'setSort': {
      const sortMetric = value[0].id;

      if (state.gridMetrics.includes(sortMetric)) {
        return {
          ...state,
          sort: value,
          gridMetrics: [
            sortMetric,
            ...state.gridMetrics.filter((x) => x !== sortMetric),
          ],
        };
      } else {
        return {
          ...state,
          sort: value,
          gridMetrics: [sortMetric, ...state.gridMetrics],
        };
      }
    }
    case 'setFilter':
      return {
        ...state,
        filter: value,
      };
    case 'setGroup':
      return {
        ...state,
        group: value,
        selected: value !== state.group ? 'default' : state.selected,
        selectedGrid: value !== state.group ? 'default' : state.selectedGrid,
      };
    case 'toggleDisplay': {
      const oldValue = state.display;
      const idx = oldValue?.findIndex((x) => x === value);
      return {
        ...state,
        display:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'addFilter': {
      const currentFilters = convertFiltersV1ToV2(state.filter);

      // Find the index of the filter that deeply equals the incoming filter
      const matchingDeeplyEqualIndex = currentFilters.findIndex((filter) =>
        isEqual(filter, value)
      );

      // Do not mutate any filter state if the user adds the exact same filter
      if (matchingDeeplyEqualIndex !== -1) {
        return state;
      }

      return {
        ...state,
        filter: [...currentFilters, value],
      };
    }
    case 'removeFilter': {
      const currentFilters = convertFiltersV1ToV2(state.filter);

      const newFilters = currentFilters.filter(
        (filter) => !isEqual(filter, value)
      );

      return {
        ...state,
        filter: newFilters,
      };
    }
    case 'addSort': {
      return {
        ...state,
        sort: [...state.sort, value],
      };
    }
    case 'removeSort': {
      const currentSort = state.sort;
      const newSort = currentSort.filter((sort) => sort.id !== value.id);

      return {
        ...state,
        sort: newSort,
      };
    }
    case 'toggleSort': {
      const oldValue = state.sort;
      const idx = oldValue?.findIndex((x) => x.id === value.id);
      return {
        ...state,
        sort:
          idx === -1
            ? [...oldValue, value]
            : [...oldValue.slice(0, idx), ...oldValue.slice(idx + 1)],
      };
    }
    case 'setColumnSizing': {
      return {
        ...state,
        columnSizing: value,
      };
    }

    case 'addGraphMetric': {
      return {
        ...state,
        graphMetrics: [...state.graphMetrics, value],
      };
    }
    case 'removeGraphMetric': {
      const updatedGraphMetrics = state.graphMetrics.filter(
        (metric) => metric !== value
      );

      return {
        ...state,
        graphMetrics: updatedGraphMetrics,
      };
    }
    case 'setGraphMetrics': {
      return {
        ...state,
        graphMetrics: value,
      };
    }
    case 'addGridMetric': {
      return {
        ...state,
        gridMetrics: [...state.gridMetrics, value],
      };
    }
    case 'removeGridMetric': {
      const removedMetricIndex = state.gridMetrics.indexOf(value);
      const updatedGridMetrics = state.gridMetrics.filter(
        (metric) => metric !== value
      );

      // If the graph metrics list is empty, leave sort as is
      if (updatedGridMetrics.length === 0) {
        return {
          ...state,
          gridMetrics: updatedGridMetrics,
        };
      }

      return {
        ...state,
        gridMetrics: updatedGridMetrics,
        // If we're removing the first metric, we need to set the sort to the next one
        sort:
          removedMetricIndex === 0
            ? [{ id: updatedGridMetrics[0], desc: true }]
            : state.sort,
      };
    }
    case 'setGridMetrics': {
      return {
        ...state,
        gridMetrics: value,
        sort: [{ id: value[0], desc: true }],
      };
    }
    case 'setSelected': {
      return {
        ...state,
        selected: value,
      };
    }

    case 'setSelectedGrid': {
      if (state.selectedGrid === 'default') {
        return {
          ...state,
          selectedGrid: {
            mode: 'standard',
            selected: value,
          },
        };
      }

      if (state.selectedGrid.mode === 'all') {
        const selectedKey = value[0];

        const isPresent = state.selectedGrid.except.includes(selectedKey);

        if (isPresent) {
          return {
            ...state,
            selectedGrid: {
              mode: 'all',
              except: state.selectedGrid.except.filter(
                (x) => x !== selectedKey
              ),
            },
          };
        } else {
          return {
            ...state,
            selectedGrid: {
              mode: 'all',
              except: [...state.selectedGrid.except, selectedKey],
            },
          };
        }
      } else if (state.selectedGrid.mode === 'standard') {
        return {
          ...state,
          selectedGrid: {
            mode: 'standard',
            selected: value,
          },
        };
      }

      return state;
    }

    case 'setSelectedGridMode': {
      switch (value) {
        case 'all': {
          return {
            ...state,
            selectedGrid: {
              mode: 'all',
              except: [],
            },
          };
        }

        case 'standard': {
          return {
            ...state,
            selectedGrid: {
              mode: 'standard',
              selected: [],
            },
          };
        }

        case 'default': {
          return {
            ...state,
            selectedGrid: 'default',
          };
        }

        default: {
          const _exhaustiveCheck: never = value;
          throw new Error(
            `Unhandled selected grid mode state: ${_exhaustiveCheck}`
          );
        }
      }
    }

    case 'setLayout': {
      return {
        ...state,
        layout: value,
      };
    }

    case 'setTableColorScheme':
      return {
        ...state,
        tableColorScheme: value,
      };

    case 'setComparisons':
      return {
        ...state,
        comparisons: value,
      };

    case 'setAttributionWindow':
      return {
        ...state,
        attributionWindow: value,
      };
  }
}

/**
 * Insights Hydration Validation Schemas
 */
const storedInsightsReportSchema = z.object({
  columnSizing: z.record(z.string(), z.number()).optional(),
  selected: z.array(z.string().nullable()).or(z.literal('default')).optional(),
  layout: z.enum(['table', 'grid', 'full-grid']).optional(),
}) satisfies z.ZodType<
  Partial<Pick<InsightsStatePersisted, 'columnSizing' | 'selected' | 'layout'>>
>;

const storedInsightsSchema: z.ZodType<
  Partial<
    Pick<
      InsightsStatePersisted,
      | 'columnSizing'
      | 'selected'
      | 'layout'
      | 'filter'
      | 'group'
      | 'comparisons'
    >
  >
> = storedInsightsReportSchema.extend({
  filter: metricFilterSchema.or(z.array(insightsFilterV2)).optional(),
  group: groupSchema,
  comparisons: z
    .array(
      z.object({
        id: z.string(),
        name: z.string(),
        filter: insightsFilterExpressionSchema,
      })
    )
    .optional(),
});

export function createInsightsReportStorage(
  platform: 'facebook' | 'tiktok',
  mergeValue: InsightsState
): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsStatePersisted>;

      if (!storedInsightsReportSchema.safeParse(value).success) {
        return hydrateInsightsPersistentState(platform, mergeValue);
      }

      return hydrateInsightsPersistentState(platform, {
        ...value,
        ...mergeValue, // Incoming server report state takes priority
      });
    } catch (e) {
      // Error occurred, reset the UI state to report default
      const defaultState = mergeValue;
      storage.setItem(key, mergeValue);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const { columnSizing, selected, layout } = value;
    return storage.setItem(key, {
      columnSizing,
      selected,
      layout,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

export function createInsightsStandardStorage(
  platform: 'facebook' | 'tiktok',
  mergeValue?: Partial<InsightsState>
): SyncStorage<InsightsState> {
  const storage = createJSONStorage<InsightsState>(() => localStorage);
  const getItem = (key: string, initialValue: InsightsState) => {
    try {
      const value = storage.getItem(
        key,
        initialValue
      ) as Partial<InsightsStatePersisted>;

      if (!storedInsightsSchema.safeParse(value).success) {
        return createInsightsDefaultPersistentState(platform);
      }

      return hydrateInsightsPersistentState(platform, {
        ...value,
        ...mergeValue,
      });
    } catch (e) {
      // Error occurred, reset the UI state to default
      const defaultState = createInsightsDefaultPersistentState(platform);
      storage.setItem(key, defaultState);
      return defaultState;
    }
  };
  const setItem = (key: string, value: InsightsState) => {
    const {
      columnSizing,
      display,
      sort,
      filter,
      graphMetrics,
      gridMetrics,
      group,
      selected,
      selectedGrid,
      layout,
      comparisons,
      attributionWindow,
    } = value;
    return storage.setItem(key, {
      display,
      sort,
      filter,
      columnSizing,
      graphMetrics,
      gridMetrics,
      group,
      selected,
      selectedGrid,
      layout,
      comparisons,
      attributionWindow,
    } as InsightsState);
  };
  return { ...storage, getItem, setItem };
}

type CreateInsightsAtomParams =
  | {
      type: 'standard';
      accountUuid: string;
      view: InsightsView;
      initialState?: Partial<InsightsState>;
    }
  | {
      type: 'report';
      reportUuid: string;
      initialState: inferProcedureOutput<
        AppRouter['insightsReports']['getInsightsReport']
      >['config'];
    }
  | {
      type: 'comparison-report';
      reportUuid: string;
      initialState: inferProcedureOutput<
        AppRouter['insightsComparisonReports']['getOne']
      >;
    };

export function createInsightsAtom(
  platform: 'tiktok' | 'facebook',
  params: CreateInsightsAtomParams
): WritableAtom<InsightsState, [InsightsStateAction], void> {
  const storageAtom =
    params.type === 'report'
      ? atomWithStorage<InsightsState>(
          `insights_report_${params.reportUuid}`,
          {
            comparisons: undefined,
            filter: convertFiltersV1ToV2(
              params.initialState?.filter.metric ?? []
            ),
            display: params.initialState?.filter.display ?? [],
            sort: params.initialState?.filter?.sort ?? [],
            group: params.initialState?.group ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.filter?.graphMetrics ??
              params.initialState?.filter?.sort?.map((x) => x.id as string) ??
              [],
            gridMetrics: params.initialState?.filter?.gridMetrics ?? [],
            selected: params.initialState?.filter.selected ?? 'default',
            selectedGrid: params.initialState?.filter.selectedGrid ?? 'default',
            layout: params.initialState?.filter?.layout ?? 'table',
            tableColorScheme:
              params.initialState?.tableColorScheme ?? 'positive-neutral',
            attributionWindow:
              params.initialState?.attributionWindow ?? 'default',
          },
          createInsightsReportStorage(platform, {
            comparisons: undefined,
            filter: convertFiltersV1ToV2(
              params.initialState?.filter.metric ?? []
            ),
            display: params.initialState?.filter.display ?? [],
            sort: params.initialState?.filter?.sort ?? [],
            group: params.initialState?.group ?? null,
            columnSizing: {},
            graphMetrics:
              params.initialState?.filter?.graphMetrics ??
              params.initialState?.filter?.sort?.map((x) => x.id) ??
              [],
            gridMetrics: params.initialState?.filter?.gridMetrics ?? [],
            selected: params.initialState?.filter.selected ?? 'default',
            selectedGrid: params.initialState?.filter.selectedGrid ?? 'default',
            layout: params.initialState?.filter?.layout ?? 'table',
            tableColorScheme:
              params.initialState?.tableColorScheme ?? 'positive-neutral',
            attributionWindow:
              params.initialState?.attributionWindow ?? 'default',
          }),
          { getOnInit: true }
        )
      : params.type === 'comparison-report'
        ? atomWithStorage<InsightsState>(
            `insights_comparison_report_${params.reportUuid}`,
            {
              filter: [],
              comparisons: params.initialState.comparisons,
              display: params.initialState?.visibleFields ?? [],
              sort: params.initialState?.sort ?? [],
              group:
                (params.initialState?.group as InsightsFacebookGroup) ?? null,
              columnSizing: {},
              graphMetrics:
                params.initialState?.graphMetrics ??
                params.initialState?.sort?.map((x) => x.id) ??
                [],
              gridMetrics: [], // Not required for Comparison
              selected: 'default',
              selectedGrid: 'default',
              layout:
                (params.initialState?.layout as InsightsLayout) ?? 'table',
              tableColorScheme:
                (params.initialState
                  ?.tableColorScheme as InsightsTableColorSchema) ??
                'positive-neutral',
              attributionWindow:
                (params.initialState
                  ?.attributionWindow as InsightsAttributionWindow) ??
                'default',
            },
            createInsightsReportStorage(platform, {
              filter: [],
              comparisons: params.initialState.comparisons,
              display: params.initialState.visibleFields ?? [],
              sort: params.initialState?.sort ?? [],
              group:
                (params.initialState?.group as InsightsFacebookGroup) ?? null,
              columnSizing: {},
              graphMetrics:
                params.initialState?.graphMetrics ??
                params.initialState?.sort?.map((x) => x.id) ??
                [],
              gridMetrics: [], // Not required for Comparison
              selected: params.initialState?.selected ?? 'default',
              selectedGrid: 'default',
              layout:
                (params.initialState?.layout as InsightsLayout) ?? 'table',
              tableColorScheme:
                (params.initialState
                  ?.tableColorScheme as InsightsTableColorSchema) ??
                'positive-neutral',
              attributionWindow:
                (params.initialState
                  ?.attributionWindow as InsightsAttributionWindow) ??
                'default',
            }),
            { getOnInit: true }
          )
        : atomWithStorage<InsightsState>(
            `insights_account_${params.accountUuid}_${params.view}`,
            createInsightsDefaultPersistentState(platform, params.view),
            createInsightsStandardStorage(platform, params.initialState),
            { getOnInit: true }
          );

  const storageAtomWithReducer = atom<
    InsightsState,
    [InsightsStateAction],
    void
  >(
    (get) => get(storageAtom),
    (get, set, action) => {
      set(storageAtom, reducer(get(storageAtom), action));
    }
  );

  return storageAtomWithReducer;
}

export function useInsightsPlatform() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = ctx.platform;
  return value;
}

export function useInsightsFilter() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.filter);
  return value;
}

export function useInsightsSort() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.sort);
  return value;
}

export function useInsightsDisplay() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.display);
  return value;
}

export function useInsightsGroup() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.group);
  return value;
}

export function useInsightsColumnSize() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.columnSizing);
  return value;
}

export function useInsightsGraphMetrics() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.graphMetrics);
  return value;
}

export function useInsightsGridMetrics() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.gridMetrics);
  return value;
}

export function useInsightsSelectedItems() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.selected);
  return value;
}

export function useInsightsSelectedGridItems() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.selectedGrid);
  return value;
}

export function useInsightsLayout() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.layout);
  return value;
}

export function useInsightsTableColorScheme() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.tableColorScheme);
  return value;
}

export function useInsightsComparisons() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.comparisons);
  return value;
}

export function useInsightsAttributionWindow() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const value = useAtomValue(ctx.attributionWindow);
  return value;
}

export function useInsightsStoreDispatch() {
  const ctx = useContext(InsightsPersistentStateContext);
  if (!ctx) {
    throw new Error(
      'InsightsPersistentStateContext accessed outside InsightsPersistentStateContextProvider'
    );
  }
  const dispatch = useSetAtom(ctx.all);
  return dispatch;
}
