import React, { useEffect, useRef } from 'react';
import { useAnimate } from 'motion/react';
import classNames from 'classnames';
import { uuidv4 } from '@magicbrief/common';

type Props = {
  amount: number;
  className: string;
};

export const SquareLoaders: React.FC<Props> = ({ amount, className }) => {
  const keys = useRef(
    Array.from({ length: amount }).map((_, i) => ({
      key: uuidv4(),
      delayStart: (i + 1) * 0.05,
      delayRepeat: (i + 1) * 0.1,
    }))
  );
  return (
    <>
      {keys.current.map((x) => (
        <SquareLoader
          key={x.key}
          delayRepeat={x.delayRepeat}
          delayStart={x.delayStart}
          className={className}
        />
      ))}
    </>
  );
};

export const SquareLoader: React.FC<
  Omit<Props, 'amount'> & { delayStart: number; delayRepeat: number }
> = ({ className, delayRepeat, delayStart }) => {
  const [scope, animate] = useAnimate();
  useEffect(() => {
    void animate(
      scope.current,
      {
        opacity: 1,
      },
      {
        duration: 1,
        delay: delayStart,
      }
    ).then(() => {
      if (scope.current) {
        void animate(
          scope.current,
          {
            opacity: [1, 0.3],
          },
          {
            duration: 1,
            repeat: Infinity,
            repeatType: 'reverse',
            ease: 'easeInOut',
            delay: delayRepeat,
          }
        );
      }
    });
  }, [animate, scope, delayRepeat, delayStart]);

  return (
    <div
      ref={scope}
      className={classNames('rounded-lg bg-primary/10 opacity-0', className)}
    />
  );
};
