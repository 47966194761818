import { useEffect, useRef } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import {
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
  Text,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSetAtom } from 'jotai';
import { trpc } from 'src/lib/trpc';
import Alert from 'src/components/AlertV2/Alert';
import { presetAtom } from '../../InsightsTable/TableMetricPresets.atoms';
import type { InsightsPreset } from '../InsightsFilterDisplayMenuV2';

interface InsightsDeletePresetModalProps {
  deletePreset: InsightsPreset | null;
  setDeletePreset: React.Dispatch<React.SetStateAction<InsightsPreset | null>>;
}

export const InsightsDeletePresetModal = ({
  deletePreset,
  setDeletePreset,
}: InsightsDeletePresetModalProps) => {
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();
  const setPreset = useSetAtom(presetAtom);

  const inputRef = useRef<HTMLInputElement>(null);

  const deletePresetMutation =
    trpc.insights.deleteInsightsMetricPreset.useMutation({
      onSuccess: () => {
        void trpcUtils.insights.getInsightsMetricPresets.invalidate();
        setDeletePreset(null);
        setPreset('custom');
        toast.success('Preset successfully deleted');
      },
      onError: () => {
        toast.error('Error deleting preset');
      },
    });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  if (!deletePreset) return null;

  return (
    <ModalOverlay
      isOpen={!!deletePreset}
      onOpenChange={(open) => {
        if (!open) {
          setDeletePreset(null);
        }
      }}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative flex w-[480px] flex-col gap-6 overflow-y-auto rounded-2xl border border-purple-200 bg-white p-6 shadow focus-visible:outline-none"
          aria-label="delete metrics preset modal"
        >
          <Heading className="text-xl font-semibold text-primary">
            Delete Custom Preset
          </Heading>

          <div className="flex flex-col gap-4">
            <Text className="text-sm font-medium text-primary/50">
              Are you sure you want to delete “{deletePreset.name}”?
            </Text>
            <Alert
              variant="danger"
              title="This will permanently delete the preset. Your table will revert to the default column layout."
            />
          </div>

          <div className="flex items-center justify-end gap-2.5">
            <Button variant="secondary" size="small" slot="close">
              Cancel
            </Button>
            <Button
              variant="destructive"
              size="small"
              onPress={() => {
                deletePresetMutation.mutate({
                  insightsAdAccountUuid: accountUuid ?? '',
                  presetUuid: deletePreset.uuid,
                });
              }}
              loading={deletePresetMutation.isLoading}
            >
              Delete
            </Button>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
