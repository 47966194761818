import { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Heading, Text } from 'react-aria-components';
import { useParams } from 'react-router-dom';
import {
  useInsightsDisplay,
  useInsightsPlatform,
} from 'src/pages/Insights/util/useInsightsPersistentState';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import {
  getMetricLabelAsText,
  getMetricValue,
} from 'src/pages/Insights/util/useParseMetric';

interface Props {
  adGroup: InsightsAdGroupWithInsights;
}

export const AdBreakdownOverview = ({ adGroup }: Props) => {
  const platform = useInsightsPlatform();
  const { accountUuid } = useParams();
  const { accountCustomEvents, accountCustomConversions, currency } =
    useInsightsAdAccount({ accountUuid });

  const tableMetrics = useInsightsDisplay();

  return (
    <div
      className={cn(
        'flex items-center justify-between gap-4 rounded-xl border border-solid border-purple-200',
        // Apply right border to all children except the last
        '[&>*:not(:last-child)]:border-r [&>*:not(:last-child)]:border-solid [&>*:not(:last-child)]:border-purple-200'
      )}
    >
      <AdBreakdownCard label="Number of Ads" value={adGroup.count.toString()} />
      {tableMetrics.slice(0, 2).map((metric) => {
        const label = getMetricLabelAsText(
          platform,
          metric,
          accountCustomEvents ?? [],
          accountCustomConversions ?? []
        );
        const parsedValue = getMetricValue(
          platform,
          metric,
          adGroup.metrics,
          currency ?? 'USD'
        );

        return (
          <AdBreakdownCard
            key={metric}
            label={label ?? ''}
            value={parsedValue ? parsedValue : '-'}
          />
        );
      })}
    </div>
  );
};

const AdBreakdownCard = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className="my-3 flex w-full flex-col gap-1.5 px-4">
      <Heading className="text-sm font-medium text-[#917DD2]">{label}</Heading>
      <Text className="text-xl font-semibold text-primary">{value}</Text>
    </div>
  );
};
