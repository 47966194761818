import { forwardRef } from 'react';
import classNames from 'classnames';
import { Dialog } from 'react-aria-components';
import { AnimatePresence } from 'motion/react';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { MotionModalOverlay } from './components/MotionModalOverlay';
import { MotionModal } from './components/MotionModal';

type FullscreenThreshold = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'always';

export type BaseModalProps = React.PropsWithChildren<{
  /**
   * Whether the Modal is open or not.
   */
  show: boolean;
  /**
   * Called when the Modal is dismissed via an outside click or by pressing Escape.
   * Typically used to close the dialog by setting `open` to false.
   *
   * Set this to null or undefined to prevent closure of the Modal via the Escape key or outside clicks.
   */
  onClose: (() => void) | null;
  /**
   * CSS classes to be applied to the Modal.
   */
  modalClassName?: string;
  /**
   * Called when the Modal's exit transition has completed.
   */
  afterClose?: () => void;
  /**
   * Custom overlay classname to override the default background colour and opacity.
   */
  overlayClassName?: string;
  /**
   * CSS classes to be applied to the Dialog, which represents the contents of the modal.
   */
  dialogClassName?: string;
  /**
   * At what threhsold the modal should stretch to fill the entire viewport, or not at all. False by default.
   */
  fullscreenThreshold?: FullscreenThreshold | false;
  /**
   * Constrains the maxmimum possible height of the modal to the size of the viewport.
   * False by default. Set to true when you want your modal to scroll internally rather
   * than on the page.
   */
  constrainToViewport?: boolean;
}> &
  React.HTMLAttributes<HTMLDivElement>;

const baseFullscreenPanelStyle = 'grow';
const fullscreenPanelThresholdStyles = (
  threshold: FullscreenThreshold,
  constrainToViewport: boolean
): string => {
  return {
    xs: `${baseFullscreenPanelStyle} xs:grow-0 ${
      constrainToViewport ? 'xs:h-full' : 'xs:h-auto'
    }`,
    sm: `${baseFullscreenPanelStyle} sm:grow-0 ${
      constrainToViewport ? 'sm:h-full' : 'sm:h-auto'
    }`,
    md: `${baseFullscreenPanelStyle} md:grow-0 ${
      constrainToViewport ? 'md:h-full' : 'md:h-auto'
    }`,
    lg: `${baseFullscreenPanelStyle} lg:grow-0 ${
      constrainToViewport ? 'lg:h-full' : 'lg:h-auto'
    }`,
    xl: `${baseFullscreenPanelStyle} xl:grow-0 ${
      constrainToViewport ? 'xl:h-full' : 'xl:h-auto'
    }`,
    always: baseFullscreenPanelStyle,
  }[threshold];
};

const baseFullscreenWrapperStyle = 'items-stretch p-0';
const fullscreenWrapperThresholdStyles = (
  threshold: FullscreenThreshold,
  constrainToViewport: boolean
): string => {
  return {
    xs: `${baseFullscreenWrapperStyle} xs:items-center xs:p-4 ${
      constrainToViewport ? 'xs:h-full' : 'xs:h-auto'
    }`,
    sm: `${baseFullscreenWrapperStyle} sm:items-center sm:p-4 ${
      constrainToViewport ? 'sm:h-full' : 'sm:h-auto'
    }`,
    md: `${baseFullscreenWrapperStyle} md:items-center md:p-4 ${
      constrainToViewport ? 'md:h-full' : 'md:h-auto'
    }`,
    lg: `${baseFullscreenWrapperStyle} lg:items-center lg:p-4 ${
      constrainToViewport ? 'lg:h-full' : 'lg:h-auto'
    }`,
    xl: `${baseFullscreenWrapperStyle} xl:items-center xl:p-4 ${
      constrainToViewport ? 'xl:h-full' : 'xl:h-auto'
    }`,
    always: baseFullscreenWrapperStyle,
  }[threshold];
};

const BaseModal = forwardRef<HTMLDivElement, BaseModalProps>(
  (
    {
      show,
      children,
      onClose,
      modalClassName,
      afterClose,
      overlayClassName,
      dialogClassName,
      fullscreenThreshold = false,
      constrainToViewport = false,
      ...divProps
    },
    ref
  ) => {
    return (
      <AnimatePresence onExitComplete={afterClose}>
        {show && (
          <MotionModalOverlay
            isOpen
            onOpenChange={(open) => {
              if (!open) {
                onClose?.();
              }
            }}
            className={cn(
              fullscreenThreshold
                ? fullscreenWrapperThresholdStyles(
                    fullscreenThreshold,
                    constrainToViewport
                  )
                : 'items-center p-4 sm:p-0',
              constrainToViewport ? 'h-screen' : '',
              overlayClassName
            )}
          >
            <MotionModal
              className={cn(
                cn(
                  'flex outline-none',
                  fullscreenThreshold
                    ? fullscreenPanelThresholdStyles(
                        fullscreenThreshold,
                        constrainToViewport
                      )
                    : '',
                  constrainToViewport ? 'max-h-screen' : ''
                ),
                modalClassName
              )}
            >
              <Dialog
                {...divProps}
                aria-label="Dialog"
                role="dialog"
                ref={ref}
                className={classNames(
                  'flex-auto outline-none focus:outline-none focus-visible:outline-none',
                  dialogClassName
                )}
              >
                {children}
              </Dialog>
            </MotionModal>
          </MotionModalOverlay>
        )}
      </AnimatePresence>
    );
  }
);

BaseModal.displayName = 'BaseModal';
export default BaseModal;
