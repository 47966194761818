import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import {
  ArrayElement,
  InsightsFilter,
  InsightsFilterExpression,
} from '@magicbrief/common';
import { Dialog, Modal } from 'react-aria-components';
import { useMatch, useParams } from 'react-router-dom';
import { Spinner } from '@magicbrief/ui/src/components/spinner';
import { DrawerOverlay } from 'src/components/Drawer/Drawer';
import { drawerContentVariants } from 'src/components/Drawer/Drawer.styles';
import { cn } from 'src/lib/cn';
import { getZIndexByClassName } from 'src/utils/getZIndexByClassName';
import { trpc } from 'src/lib/trpc';
import { getNextPageParam } from 'src/pages/Insights/util/useInsightsQueries';
import {
  GetFacebookAdAccountResponse,
  GetManyFacebookAdsWithInsightsResponse,
} from 'src/types/insights';
import {
  hasInsightsAdPreviewAtom,
  insightsAdPreviewAtom,
} from '../../Insights.atoms';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import {
  InsightsSort,
  useInsightsFilter,
  useInsightsGroup,
  useInsightsSort,
} from '../../util/useInsightsPersistentState';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import { AdBreakdownHeader } from './components/AdBreakdownHeader';
import { AdBreakdownOverview } from './components/AdBreakdownOverview';
import { AdBreakdownTable } from './components/AdBreakdownTable';
import { AdBreakdownChart } from './components/AdBreakdownChart';
import { AdBreakdownTableSettings } from './components/AdBreakdownTableSettings';
import { AdBreakdownMetricPresets } from './components/AdBreakdownMetricPresets';
import { InsightsAdBreakdownProvider } from './InsightsAdBreakdownPanel.context';
import type {
  InsightsAdGroupWithInsights,
  InsightsMetricStatistic,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

// Constants for drawer dimensions
const MIN_DRAWER_WIDTH = 970;
const DEFAULT_DRAWER_WIDTH = 970;
const MAX_DRAWER_WIDTH_PERCENT = 0.85; // 85% of viewport width

export function InsightsAdBreakdownPanel({
  selectedAdBreakdown,
  setSelectedAdBreakdown,
  adGroup,
  currency,
  expression,
  comparisonId,
}: {
  selectedAdBreakdown: string | null;
  setSelectedAdBreakdown: Dispatch<SetStateAction<string | null>>;
  adGroup: InsightsAdGroupWithInsights;
  currency: GetFacebookAdAccountResponse['currency'];
  expression?: InsightsFilter[] | InsightsFilterExpression;
  comparisonId?: string;
}) {
  const hasInsightsAdPreview = useAtomValue(hasInsightsAdPreviewAtom);
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);

  // Track drawer width
  const drawerWidth = useRef(DEFAULT_DRAWER_WIDTH);
  // Store the modal element for direct manipulation
  const modalRef = useRef<HTMLDivElement>(null);
  // Track drag state
  const isDragging = useRef(false);
  const startX = useRef(0);

  /**
   * This is to prevent the floating chat button from appearing over the breakdown panel
   * and the insights ad preview mounted within it
   */
  const chatBubbleZIndex = getZIndexByClassName(
    'PylonChat-bubbleFrameContainer'
  );
  const groupBreakdownZIndex = chatBubbleZIndex ? chatBubbleZIndex + 1 : 1000;

  // Handle drag start
  const handleMouseDown = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    isDragging.current = true;
    startX.current = e.clientX;

    // Add event listeners for move and up events
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // Handle drag movement
  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging.current) return;

    // Calculate delta from initial click position
    const deltaX = startX.current - e.clientX;

    // Calculate new width (drag left to increase, right to decrease)
    const newWidth = drawerWidth.current + deltaX;

    // Apply constraints
    const maxWidth = window.innerWidth * MAX_DRAWER_WIDTH_PERCENT;
    const constrainedWidth = Math.max(
      MIN_DRAWER_WIDTH,
      Math.min(maxWidth, newWidth)
    );

    // Update drawer width
    drawerWidth.current = constrainedWidth;

    // Update the modal's width directly
    if (modalRef.current) {
      modalRef.current.style.width = `${constrainedWidth}px`;
    }

    // Update starting point for next move
    startX.current = e.clientX;
  };

  // Handle drag end
  const handleMouseUp = () => {
    isDragging.current = false;

    // Remove event listeners
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  if (selectedAdBreakdown !== adGroup.group) return null;

  return (
    <DrawerOverlay
      isOpen={!!selectedAdBreakdown}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          // Clear the insights ad preview when we close the drawer
          if (hasInsightsAdPreview) {
            setInsightsAdPreview(null);
          }

          // Reset width to default
          drawerWidth.current = DEFAULT_DRAWER_WIDTH;

          // Update modal width
          if (modalRef.current) {
            modalRef.current.style.width = `${DEFAULT_DRAWER_WIDTH}px`;
          }
        }

        setSelectedAdBreakdown(null);
      }}
      isKeyboardDismissDisabled={hasInsightsAdPreview}
      style={{ zIndex: groupBreakdownZIndex }}
      aria-label="Ad breakdowns panel"
    >
      <Modal
        ref={modalRef}
        className={cn(
          drawerContentVariants({ side: 'right' }),
          'overflow-auto'
        )}
        style={{ width: `${drawerWidth.current}px` }}
      >
        <Dialog
          className="relative flex size-full flex-col overflow-y-auto px-6 outline-0 focus-visible:outline-none"
          id="insights-ad-breakdown"
        >
          <AdBreakdownHeader adGroup={adGroup} className="mb-16 mt-6" />
          <AdBreakdownOverview adGroup={adGroup} />

          <AdBreakdownChartTable
            adGroup={adGroup}
            currency={currency}
            expression={expression}
            comparisonId={comparisonId}
            zIndex={Number(groupBreakdownZIndex)}
          />

          {/* Resize handle */}
          <button
            className="group absolute inset-y-0 left-0 w-4 cursor-col-resize"
            onMouseDown={handleMouseDown}
          >
            <div
              className={cn(
                'absolute inset-y-0 left-0 w-1 transition-colors',
                'hover:bg-primary/75 group-hover:bg-primary/50',
                isDragging.current ? 'bg-primary' : ''
              )}
            />
          </button>
        </Dialog>
      </Modal>
    </DrawerOverlay>
  );
}

const AdBreakdownChartTable = ({
  adGroup,
  currency,
  expression,
  comparisonId,
  zIndex,
}: {
  adGroup: InsightsAdGroupWithInsights;
  currency: GetFacebookAdAccountResponse['currency'];
  expression: InsightsFilter[] | InsightsFilterExpression | undefined;
  comparisonId: string | undefined;
  zIndex: number;
}) => {
  const { accountUuid, reportUuid } = useParams();
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );
  const { forTimePeriod, attributionWindow } =
    useInsightsSearchParams().getParsedValues();
  const filter = useInsightsFilter();
  const initialSort = useInsightsSort();
  const group = useInsightsGroup();

  const [sort, setSort] = useState(initialSort);

  const comparisonReportQuery =
    trpc.insightsAds.getManyAdsFromComparisonReport.useInfiniteQuery(
      {
        reportUuid: reportUuid ?? '',
        comparisonId: comparisonId ?? '',
        count: INSIGHTS_PAGE_TAKE_LIMIT,
      },
      {
        enabled: !!reportUuid && !!isSharedComparisonReport && !!comparisonId,
        getNextPageParam,
        keepPreviousData: true,
        trpc: { abortOnUnmount: true, context: { skipBatch: true } },
      }
    );

  const reportQuery = trpc.insightsAds.getManyAdsFromReport.useInfiniteQuery(
    {
      uuid: reportUuid ?? '',
      count: INSIGHTS_PAGE_TAKE_LIMIT,
      ungroup: {
        group: group ?? '',
        value: adGroup.name,
      },
      sort,
    },
    {
      // If user is authenticated or user is viewing a shared report
      enabled: !!reportUuid && !!isSharedReport && !!group,
      getNextPageParam,
      keepPreviousData: true,
      trpc: { abortOnUnmount: true, context: { skipBatch: true } },
    }
  );

  const accountQuery = trpc.insightsAds.getManyAds.useInfiniteQuery(
    {
      accountUuid: accountUuid ?? '',
      filter: expression && !group ? expression : filter,
      sort,
      forTimePeriod,
      count: INSIGHTS_PAGE_TAKE_LIMIT,
      attributionWindow,
      ungroup:
        expression && !group
          ? undefined
          : {
              group: group ?? '',
              value: adGroup.group,
            },
    },
    {
      // If user is authenticated or user is viewing a shared report
      enabled:
        !isSharedReport &&
        !isSharedComparisonReport &&
        !!accountUuid &&
        (!!group || !!expression),
      getNextPageParam,
      keepPreviousData: true,
      trpc: { abortOnUnmount: true, context: { skipBatch: true } },
    }
  );

  const statistics = trpc.insightsAds.getStatisticsForAds.useQuery(
    {
      accountUuid: accountUuid ?? '',
      filter: expression && !group ? expression : filter,
      forTimePeriod,
      attributionWindow,
      ungroup:
        expression && !group
          ? undefined
          : {
              group: group ?? '',
              value: adGroup.group,
            },
    },
    {
      // If user is authenticated or user is viewing a shared report
      enabled:
        (!!accountUuid && (!!group || !!expression)) ||
        (!!reportUuid && !!isSharedReport && !!group) ||
        (!!reportUuid && !!isSharedComparisonReport && !!comparisonId),
      keepPreviousData: true,
      trpc: { abortOnUnmount: true },
    }
  );

  const adsQuery = isSharedComparisonReport
    ? comparisonReportQuery
    : isSharedReport
      ? reportQuery
      : accountQuery;

  const ads = adsQuery.data?.pages.flat() ?? [];

  const onLoadMore = async () => {
    if (!adsQuery.isLoading && !adsQuery.isFetching && !adsQuery.isError) {
      return adsQuery.fetchNextPage();
    }
  };

  if (adsQuery.isLoading || statistics.isLoading) {
    return (
      <div className="mt-16 flex items-center justify-center text-primary">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 py-8">
      <ChartTableContent
        ads={ads}
        currency={currency}
        isLoading={adsQuery.isLoading}
        isFetchingNextPage={adsQuery.isFetchingNextPage}
        hasNextPage={!!adsQuery.hasNextPage}
        onLoadMore={onLoadMore}
        statistics={statistics.data}
        sort={sort}
        setSort={setSort}
        zIndex={zIndex}
      />
    </div>
  );
};

const ChartTableContent = ({
  ads,
  currency,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  statistics,
  zIndex,
  sort,
  setSort,
}: {
  ads: Array<ArrayElement<GetManyFacebookAdsWithInsightsResponse>>;
  currency: string;
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  statistics: Partial<Record<string, InsightsMetricStatistic>> | undefined;
  zIndex: number;
  sort: InsightsSort[];
  setSort: Dispatch<SetStateAction<InsightsSort[]>>;
}) => {
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  const [isMetricModalOpen, setIsMetricModalOpen] = useState(false);

  return (
    <InsightsAdBreakdownProvider
      ads={ads}
      sort={sort}
      setSort={setSort}
      zIndex={zIndex}
    >
      <AdBreakdownChart ads={ads} currency={currency} isLoading={isLoading} />

      {!isSharedReport && !isSharedComparisonReport && (
        <div className="flex items-center gap-3">
          <AdBreakdownMetricPresets
            setIsMetricModalOpen={setIsMetricModalOpen}
          />
          <AdBreakdownTableSettings
            isMetricModalOpen={isMetricModalOpen}
            setIsMetricModalOpen={setIsMetricModalOpen}
          />
        </div>
      )}

      <AdBreakdownTable
        ads={ads}
        currency={currency}
        isLoading={isLoading}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={!!hasNextPage}
        onLoadMore={onLoadMore}
        statistics={statistics}
      />
    </InsightsAdBreakdownProvider>
  );
};
