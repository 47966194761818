import {
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useMatch } from 'react-router-dom';
import Toolbar from 'src/components/Toolbar/Toolbar';
import { Icon } from 'src/components/Icon';
import XCircle from 'src/assets/svgicons/duocolor/x-circle.svg';
import Menu01 from 'src/assets/svgicons/duocolor/menu-01.svg';
import InsightsSidebar from 'src/pages/Insights/components/InsightsSidebar';
import { BaseButton } from 'src/components/Button';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';

type Props = PropsWithChildren<{
  subtitle: ReactNode | string | null;
  title?: string;
  icon?: ReactNode;
  actions: ReactNode | null;
  className?: string;
}>;

const InsightsListBaseToolbar: FC<Props> = ({
  actions,
  className,
  children,
  subtitle,
  title = '',
  icon,
}) => {
  const { LL } = useI18nContext();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const onOpenSidebar = useCallback(() => setIsSidebarOpen(true), []);
  const onCloseSidebar = useCallback(() => setIsSidebarOpen(false), []);

  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  return (
    <>
      <Toolbar
        className={cn(
          'sticky z-100 box-border flex flex-col gap-2 2xl:min-h-20',
          actions ? 'min-h-32 pt-3' : 'h-[72px]',
          isSharedReport || isSharedComparisonReport ? 'min-h-20 pt-3' : '',
          className
        )}
      >
        <div
          className={cn(
            'flex min-w-0 flex-auto flex-col justify-between px-5 lg:px-10 2xl:flex-row',
            isSharedReport || isSharedComparisonReport ? 'flex-row' : 'flex-col'
          )}
        >
          <div
            className={cn(
              'flex min-w-0 flex-row items-center gap-4 truncate text-primary xl:gap-8',
              actions ? 'pb-4 lg:pb-3' : 'h-full'
            )}
          >
            <BaseButton
              className="inline-flex items-center justify-center rounded-md p-2 text-primary focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white xl:hidden"
              onClick={onOpenSidebar}
              icon={
                <Icon className="block size-6">
                  <Menu01 />
                </Icon>
              }
            />
            <div className="flex min-w-0 flex-row gap-3 font-semibold text-primary">
              {icon && <div className="flex-centered flex size-6">{icon}</div>}
              <div className="flex min-w-0 flex-col font-semibold text-primary">
                <span className="truncate">{title}</span>
                {subtitle && (
                  <span className="hidden text-xxs text-primary/40 md:block">
                    {subtitle}
                  </span>
                )}
              </div>
            </div>
          </div>

          {actions}
        </div>

        {children}
      </Toolbar>
      <Transition.Root show={isSidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-300" onClose={onCloseSidebar}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-80">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute -right-14 top-2 flex w-16 justify-center">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={onCloseSidebar}
                    >
                      <span className="sr-only">
                        {LL.insights.sidebar.mobile.closeText()}
                      </span>
                      <Icon className="size-6 text-white" aria-hidden="true">
                        <XCircle />
                      </Icon>
                    </button>
                  </div>
                </Transition.Child>
                <InsightsSidebar />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
export default InsightsListBaseToolbar;
