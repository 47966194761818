import { Spinner } from '@magicbrief/ui/src/components/spinner';
import startCase from 'lodash/startCase';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import Alert from 'src/components/AlertV2/Alert';
import Card from 'src/components/Card';
import {
  GetBreakdownsForFacebookAdResponse,
  GetBreakdownsForManyFacebookAdsResponse,
} from 'src/types/insights';

const ageGroupSortMap = {
  '18-24': 1,
  '25-34': 2,
  '35-44': 3,
  '45-54': 4,
  '55-64': 5,
  '65+': 6,
  Unknown: 7,
};

type AgeGroup = keyof typeof ageGroupSortMap;

type BreakdownAgeGenderMap = {
  [ageGender: string]: {
    age: AgeGroup;
    male: { impressions: number | null };
    female: { impressions: number | null };
    unknown: { impressions: number | null };
  };
};

type Props = {
  breakdowns:
    | GetBreakdownsForFacebookAdResponse
    | GetBreakdownsForManyFacebookAdsResponse
    | undefined;
  isFetchingBreakdowns: boolean;
  isBreakdownsError: boolean;
};

export function BreakdownAgeGenderChart({
  breakdowns,
  isFetchingBreakdowns,
  isBreakdownsError,
}: Props) {
  if (isFetchingBreakdowns) {
    return (
      <Card className="mt-2 flex h-48 flex-auto items-center justify-center bg-gray-50 p-3 text-primary shadow-sm">
        <Spinner />
      </Card>
    );
  }

  if (isBreakdownsError) {
    return (
      <Alert
        variant="danger"
        title="Error getting impressions data"
        className="mt-2"
      />
    );
  }

  const extractedAgeGenderBreakdownMap =
    breakdowns?.reduce<BreakdownAgeGenderMap>((acc, item) => {
      if (item.breakdownType !== 'age,gender') {
        return acc;
      }

      const ageGenderValues = item?.breakdownValue?.split(',');

      if (ageGenderValues == null || ageGenderValues.length !== 2) {
        return acc;
      }

      const age = ageGenderValues[0];
      const gender = ageGenderValues[1];

      if (age == null || gender == null) {
        return acc;
      }

      acc[age] = {
        ...(acc[age] ?? {}),
        age: age as AgeGroup,
        ...(gender === 'male' && {
          male: {
            impressions: item.impressions ?? null,
          },
        }),
        ...(gender === 'female' && {
          female: {
            impressions: item.impressions ?? null,
          },
        }),
        ...(gender === 'unknown' && {
          unknown: {
            impressions: item.impressions ?? null,
          },
        }),
      };

      return acc;
    }, {});

  const sortedAgeGenderBreakdownValues = extractedAgeGenderBreakdownMap
    ? Object.values(extractedAgeGenderBreakdownMap)
        .sort(
          (a, b) =>
            ageGroupSortMap[a.age as AgeGroup] -
            ageGroupSortMap[b.age as AgeGroup]
        )
        .filter(
          (x) =>
            !!x?.male?.impressions ||
            !!x?.female?.impressions ||
            !!x?.unknown?.impressions
        )
    : [];

  if (!isFetchingBreakdowns && sortedAgeGenderBreakdownValues.length === 0) {
    return (
      <Alert
        variant="warning"
        title="Impressions data not available for this period"
        className="mt-2"
      />
    );
  }

  return (
    <Card className="mt-2 h-48 flex-auto p-3 shadow-sm">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={sortedAgeGenderBreakdownValues}
          barCategoryGap={8}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis
            className="text-xs opacity-40 [&_.recharts-cartesian-axis-tick_text]:fill-primary"
            dataKey="age"
            type="category"
            axisLine={false}
            tickLine={false}
            tickMargin={8}
            includeHidden
          />
          <Tooltip cursor={false} content={<BreakdownChartTooltip />} />
          <Bar
            dataKey="male.impressions"
            fill="#60A5FA"
            barSize={8}
            radius={2}
          />
          <Bar
            dataKey="female.impressions"
            fill="#8B5CF6"
            barSize={8}
            radius={2}
          />
          <Bar
            dataKey="unknown.impressions"
            fill="#FA8A89"
            barSize={8}
            radius={2}
          />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

function BreakdownChartTooltip(props: React.ComponentProps<typeof Tooltip>) {
  const { payload, label } = props;

  return (
    <div className="flex flex-col overflow-hidden rounded-md border border-solid border-purple-100 bg-white shadow-sm">
      <div className="flex items-center justify-center bg-primary p-1">
        <span className="text-xs font-semibold text-white">{label}</span>
      </div>

      <ul className="ml-0.5 p-2 text-xs">
        {payload?.map((item) => {
          const key = item.dataKey?.toString().split('.')[0] ?? '';

          return (
            <li
              key={item.dataKey}
              className="flex items-center justify-between gap-6"
            >
              <div className="flex items-center gap-1.5">
                <div
                  className="size-2 rounded-sm"
                  style={{ backgroundColor: item.color }}
                />
                <span className="">{startCase(key)}</span>
              </div>
              <span className="text-gray-600">
                {item.payload[key]?.impressions.toLocaleString() ?? ''}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
