import { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { inferProcedureOutput } from '@trpc/server';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { getQueryKey } from '@trpc/react-query';
import { Icon } from 'src/components/Icon';
import { BaseModal } from 'src/components/Modal';
import { AriaButton } from 'src/components/Button';
import { useI18nContext } from 'src/i18n/i18n-react';
import Input from 'src/components/Input/Input';
import TextArea from 'src/components/TextArea/TextArea';
import XClose from 'src/assets/svgicons/duocolor/x-close.svg';
import Upload01 from 'src/assets/svgicons/duocolor/upload-01.svg';
import { trpc } from 'src/lib/trpc';
import { Checkbox } from 'src/components/Checkbox';
import { useUploadThumbnail } from 'src/utils/useUploadThumbnail';

type CommonProps = {
  show: boolean;
  onClose: () => void;
} & React.PropsWithChildren;

type DirectoryIdProps = {
  directoryId: number;
  directoryUuid?: never;
};

type DirectoryUuidProps = {
  directoryId?: never;
  directoryUuid: string;
};

type Props = CommonProps & (DirectoryIdProps | DirectoryUuidProps);

interface FormState {
  name: string;
  description: string;
  isFree: boolean;
}

const INITIAL_FORM_STATE: FormState = {
  name: '',
  description: '',
  isFree: true,
};

const CreatePackModal: React.FunctionComponent<Props> = ({
  directoryId,
  directoryUuid,
  show,
  onClose,
}) => {
  const { LL } = useI18nContext();
  const queryClient = useQueryClient();
  const uploadInput = useRef<HTMLInputElement>(null);

  // Form state
  const [formState, setFormState] = useState<FormState>(INITIAL_FORM_STATE);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Image upload state
  const [isUploading, setIsUploading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const { onImageChange, thumbnailUrl, setThumbnailUrl } = useUploadThumbnail();

  const createAdPack = trpc.adPacks.createAdPack.useMutation({
    onSuccess(data) {
      toast.success(LL.packs.create.success());
      queryClient.setQueriesData<
        inferProcedureOutput<AppRouter['adPacks']['getAdPacks']>
      >(getQueryKey(trpc.adPacks.getAdPacks, undefined, 'query'), (oldData) => {
        return oldData ? [...oldData, data] : oldData;
      });
      handleReset();
      onClose();
    },
    onError(error) {
      toast.error(error.message);
      setIsSubmitting(false);
    },
  });

  const handleFormChange = (name: keyof FormState, value: string | boolean) => {
    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);

      // Create preview immediately
      const objectUrl = URL.createObjectURL(file);
      setPreviewUrl(objectUrl);

      // Handle actual upload
      await onImageChange(e);

      // Keep the preview URL until the actual thumbnail URL is set
      if (!thumbnailUrl) {
        setPreviewUrl(objectUrl);
      }
    } catch (error) {
      Sentry.captureException(error);
      toast.error('Failed to upload image');
      // Clear preview on error
      setPreviewUrl('');
    } finally {
      setIsUploading(false);
    }
  };

  const validateForm = (): boolean => {
    if (!formState.name.trim()) {
      toast.error('Name is required');
      return false;
    }
    if (!formState.description.trim()) {
      toast.error('Description is required');
      return false;
    }
    if (!thumbnailUrl) {
      toast.error('Thumbnail image is required');
      return false;
    }
    return true;
  };

  const handleCreatePack = async () => {
    if (isSubmitting || isUploading) return;

    if (!validateForm()) return;

    try {
      setIsSubmitting(true);

      createAdPack.mutate({
        directoryId,
        directoryUuid,
        details: {
          name: formState.name.trim(),
          description: formState.description.trim(),
          thumbnailUrl,
          isFree: formState.isFree,
        },
      });
    } catch (error) {
      Sentry.captureException(error);
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    handleReset();
    onClose();
  };

  const handleReset = () => {
    setFormState(INITIAL_FORM_STATE);
    setThumbnailUrl('');
    setPreviewUrl('');
    setIsSubmitting(false);
    setIsUploading(false);
  };

  const ImageUploader = () => (
    <button
      className="relative aspect-[19/10] w-[35%] shrink-0"
      onClick={() => uploadInput.current?.click()}
      disabled={isUploading}
      type="button"
    >
      <div className="absolute inset-0 overflow-hidden rounded-md">
        {(previewUrl || thumbnailUrl) && (
          <img
            className="size-full object-cover"
            src={previewUrl || thumbnailUrl}
            alt="Ad pack preview"
          />
        )}

        <div
          className={`absolute inset-0 flex flex-col items-center justify-center transition-opacity duration-200 ${
            isUploading
              ? 'opacity-100'
              : previewUrl || thumbnailUrl
                ? 'opacity-0 hover:opacity-50'
                : 'opacity-100'
          } ${previewUrl || thumbnailUrl ? 'bg-black/50' : 'border border-dashed border-secondary bg-purple-50'}`}
        >
          {isUploading ? (
            <>
              <div className="mb-2 size-6 animate-spin rounded-full border-2 border-white border-t-transparent" />
              <span className="text-xs text-white">Uploading...</span>
            </>
          ) : (
            <>
              <Icon
                className={`${previewUrl || thumbnailUrl ? 'text-white' : 'text-primary'}`}
              >
                <Upload01 />
              </Icon>
              <span
                className={`mt-2 text-xs ${previewUrl || thumbnailUrl ? 'text-white' : 'text-primary'}`}
              >
                {previewUrl || thumbnailUrl
                  ? 'Change Image'
                  : LL.packs.create.thumbnailPlaceholder()}
              </span>
            </>
          )}
        </div>
      </div>

      <input
        className="hidden"
        max={1}
        type="file"
        name="thumbnail"
        ref={uploadInput}
        onChange={handleImageChange}
        accept="image/png,image/jpeg,image/jpg"
        disabled={isUploading}
      />
    </button>
  );

  return (
    <BaseModal
      show={show}
      panelClassName="bg-white rounded-xl relative w-full sm:w-11/12 max-w-xl p-5"
      onClose={handleClose}
    >
      <button
        className="absolute right-6 top-6 size-6 appearance-none text-text-secondary"
        onClick={handleClose}
      >
        <Icon>
          <XClose />
        </Icon>
      </button>
      <div className="flex flex-col gap-2">
        <h3 className="text-[24px] font-bold leading-8 text-primary">
          {LL.packs.create.title()}
        </h3>

        <div className="flex aspect-[19/10] flex-row gap-3 py-4">
          <ImageUploader />

          <div className="flex grow flex-col gap-2">
            <Checkbox
              checked={formState.isFree}
              onChange={(e) => handleFormChange('isFree', e.target.checked)}
              disabled={isSubmitting}
            >
              Free
            </Checkbox>

            <Input
              value={formState.name}
              placeholder={LL.packs.create.namePlaceholder()}
              label=""
              name="Name"
              onChange={(e) => handleFormChange('name', e.target.value)}
              disabled={isSubmitting}
            />

            <TextArea
              rows={9}
              label=""
              name="Description"
              value={formState.description}
              placeholder={LL.packs.create.descriptionPlaceholder()}
              onChange={(e) => handleFormChange('description', e.target.value)}
              disabled={isSubmitting}
            />
          </div>
        </div>

        <AriaButton
          onPress={handleCreatePack}
          loading={isSubmitting || isUploading}
          isDisabled={isSubmitting || isUploading}
        >
          {LL.packs.create.create()}
        </AriaButton>

        <AriaButton
          variant="white"
          onPress={handleClose}
          isDisabled={isSubmitting || isUploading}
        >
          {LL.cancel()}
        </AriaButton>
      </div>
    </BaseModal>
  );
};

export default CreatePackModal;
