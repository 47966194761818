import { useEffect, useRef, useState } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import {
  Dialog,
  Heading,
  Label,
  Modal,
  ModalOverlay,
  TextField,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trpc } from 'src/lib/trpc';
import Input from 'src/components/Input';
import type { InsightsPreset } from '../InsightsFilterDisplayMenuV2';

interface InsightsRenamePresetModalProps {
  editPreset: InsightsPreset | null;
  setEditPreset: React.Dispatch<React.SetStateAction<InsightsPreset | null>>;
}

export const InsightsRenamePresetModal = ({
  editPreset,
  setEditPreset,
}: InsightsRenamePresetModalProps) => {
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();

  const [presetName, setPresetName] = useState(editPreset?.name);
  const inputRef = useRef<HTMLInputElement>(null);

  const renamePreset = trpc.insights.renameInsightsMetricPreset.useMutation({
    onSuccess: () => {
      void trpcUtils.insights.getInsightsMetricPresets.invalidate();
      setEditPreset(null);
      toast.success('Preset successfully renamed');
    },
    onError: () => {
      toast.error('Error renaming preset');
    },
  });

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  if (!editPreset) return null;

  return (
    <ModalOverlay
      isOpen={!!editPreset}
      onOpenChange={(open) => {
        if (!open) {
          setEditPreset(null);
        }
      }}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative flex w-[480px] flex-col gap-6 overflow-y-auto rounded-2xl border border-purple-200 bg-white p-6 shadow focus-visible:outline-none"
          aria-label="edit metrics preset modal"
        >
          <Heading className="text-xl font-semibold text-primary">
            Rename Preset
          </Heading>

          <TextField className="flex flex-col gap-1">
            <Label className="text-sm font-semibold text-primary">
              Preset Name
            </Label>
            <Input
              ref={inputRef}
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              name="preset"
              placeholder="e.g. Performance Metrics"
            />
            <span className="text-xs font-medium text-primary/50">
              Currently Editing: {editPreset?.name}
            </span>
          </TextField>

          <div className="flex items-center justify-end gap-2.5">
            <Button variant="secondary" size="small" slot="close">
              Cancel
            </Button>
            <Button
              size="small"
              onPress={() => {
                if (presetName) {
                  renamePreset.mutate({
                    insightsAdAccountUuid: accountUuid ?? '',
                    presetUuid: editPreset.uuid,
                    updatedName: presetName,
                  });
                }
              }}
              loading={renamePreset.isLoading}
            >
              Save Changes
            </Button>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
