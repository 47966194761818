import { PropsWithChildren } from 'react';
import classNames from 'classnames';

type Props = {
  className?: string;
};

const ModalBody: React.FunctionComponent<Props & PropsWithChildren> = ({
  children,
  className,
}): JSX.Element => {
  return (
    <div className={classNames('mt-6 text-wrap px-6 text-sm', className)}>
      {children}
    </div>
  );
};

export default ModalBody;
