import React, { forwardRef, useState } from 'react';
import { Heading } from 'react-aria-components';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/cn';
import { Icon } from 'src/components/Icon';
import CheckCircle from 'src/assets/svgicons/duotone/check-circle.svg';
import InfoCircle from 'src/assets/svgicons/duotone/info-circle.svg';
import AlertTriangle from 'src/assets/svgicons/duotone/alert-triangle.svg';
import XCircle from 'src/assets/svgicons/duotone/x-circle.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { AriaButton } from '../Button';

const alertVariants = cva(
  'flex items-center gap-3 rounded-xl border border-solid p-4 text-sm',
  {
    variants: {
      variant: {
        success: 'border-green-200 bg-green-50 text-green-800',
        info: 'border-blue-200 bg-blue-50 text-blue-800',
        warning: 'border-yellow-200 bg-yellow-50 text-yellow-800',
        danger: 'border-red-200 bg-red-50 text-red-800',
      },
    },
  }
);

const contentVariants = cva('mt-1 text-sm font-normal', {
  variants: {
    variant: {
      success: 'text-green-700',
      info: 'text-blue-700',
      warning: 'text-yellow-700',
      danger: 'text-red-700',
    },
  },
});

const onCloseVariants = cva('ml-auto !size-fit !p-1', {
  variants: {
    variant: {
      success: 'text-green-800',
      info: 'text-blue-800',
      warning: 'text-yellow-800',
      danger: 'text-red-800',
    },
  },
});

type Props = Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> &
  VariantProps<typeof alertVariants> & {
    title: React.ReactNode;
    icon?: React.ReactNode;
    onClose?: () => void;
    dismissable?: boolean;
  };

const Alert = forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      variant,
      title,
      children,
      onClose,
      icon,
      dismissable,
      ...props
    },
    ref
  ) => {
    const [show, setShow] = useState(true);

    if (!show) return null;
    return (
      <div
        ref={ref}
        className={cn(alertVariants({ variant }), className)}
        {...props}
      >
        {icon ?? <AlertIcon variant={variant} />}
        <div>
          <Heading level={6}>{title}</Heading>
          {children && (
            <div className={contentVariants({ variant })}>{children}</div>
          )}
        </div>
        {(onClose || dismissable) && (
          <AriaButton
            className={onCloseVariants({ variant })}
            variant="text"
            icon={<XClose />}
            onPress={() => {
              onClose?.();
              if (dismissable) {
                setShow(false);
              }
            }}
          />
        )}
      </div>
    );
  }
);
Alert.displayName = 'Alert';

export default Alert;

const AlertIcon = ({
  variant,
}: {
  variant: VariantProps<typeof alertVariants>['variant'];
}) => {
  switch (variant) {
    case 'success':
      return (
        <Icon className="text-green-500">
          <CheckCircle />
        </Icon>
      );
    case 'info':
      return (
        <Icon className="text-blue-500">
          <InfoCircle />
        </Icon>
      );
    case 'warning':
      return (
        <Icon className="text-yellow-500">
          <AlertTriangle />
        </Icon>
      );
    case 'danger':
      return (
        <Icon className="text-red-500">
          <XCircle />
        </Icon>
      );
  }
};
