const defaultUnavailableMessage =
  'We use Pylon to process requests, please disable your adblocker on MagicBrief, reload and try again. If you cannot see the popup, email support@magicbrief.com';

export function showNewMessage({
  message,
  unavailableMessage = defaultUnavailableMessage,
}: {
  message: string;
  unavailableMessage?: string;
}) {
  if (window.pylon) {
    window.Pylon('showNewMessage', message);
  } else {
    alert(unavailableMessage);
  }
}
