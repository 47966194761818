import {
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS,
  type InsightsFacebookTimeSeriesMetricName,
} from '@magicbrief/common';

export const INSIGHTS_TABLE_SELECTION_MAX = 8;

export const INSIGHTS_WIZARD_TACOMETER_RED_COLOURS = ['#991B1B', '#EF4444'];
export const INSIGHTS_WIZARD_TACOMETER_YELLOW_COLOURS = ['#EAB308', '#FDE047'];
export const INSIGHTS_WIZARD_TACOMETER_GREEN_COLOURS = ['#30A46C', '#5DE8A5'];

export const INSIGHTS_CHART_ZERO_BAR_HEIGHT = 4;

export const INSIGHTS_GRID_INITIAL_COUNT = 16;

export const CLICKS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Clicks (All)
    'clicks',
    // CPC (All)
    'cpc',
    // CTR (All)
    'ctr',
    // Clicks (Links)
    'action:total:link_click',
    // Clicks (Unique, Links)
    'action:unique:link_click',
    // Cost Per Click (Unique, Links)
    'action:cost_per_unique:link_click',
    // CPC (Links)
    // 'costPerLinkClick', // Deprecated
    // CTR (Links)
    'clickThroughRateAll',
    // Clicks (Outbound)
    'outboundClicks',
    // CPC (Outbound)
    'costPerOutboundClick',
    // Clicks (Unique, Outbound)
    'action:unique:outbound_click',
    // Cost Per Click (Unique, Outbound)
    // 'action:cost_per_unique:outbound_click', // Duplicates column level costPerUniqueOutboundClick
    // CTR (Outbound)
    'outboundClicksCtr',
    // Clicks (Unique, All)
    'uniqueClicks',
    // CPC (Unique, All)
    'costPerUniqueClick',
    // CTR (Unique, All)
    'clickThroughRateUnique',
    // CPC (Unique, Links)
    'costPerUniqueLinkClick',
    // CTR (Unique, Links)
    'clickThroughRateUniqueLinks',
    // CPC (Unique, Outbound)
    'costPerUniqueOutboundClick',
    // CTR (Unique, Outbound)
    'clickThroughRateUniqueOutbound',
    // Click to Purchase Ratio
    'clickToPurchaseRatio',
    // Click to Purchase Ratio (Unique)
    'clickToPurchaseRatioUnique',
    // Click to Registration Completed Ratio
    'clickToRegistrationCompletedRatio',
    // Click to Website Purchase Ratio
    // Click to Add-To-Cart Ratio
    'clickToAddToCartRatio',
    // Cost Per Link Clicks
    'action:cost_per:link_click',
    // Cost Per Inline Link Click
    'costPerInlineLinkClick',
    // Link Clicks Value
    'action:value:link_click',
    // Inline Link Clicks
    'inlineLinkClicks',
    // Inline Link Clicks CTR
    'inlineLinkClickCtr',
    // Click to Conversion Ratio
    'clickToConversionRatio',
    // Click to Trial Started Ratio
    'clickToTrialStartedRatio',
    // Click to Subscriptions Ratio
    'clickToSubscriptionsRatio',
    // Click to Contact Ratio
    'clickToContactRatio',
  ];

export const AD_DETAILS_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Ad Name
    'adName',
    // Ad ID
    // Ad Set Name
    'adSetName',
    // Ad Set ID
    // Campaign Name
    'campaignName',
    // Campaign ID
    // Created At
    'adCreatedAt',
    // Updated At
    'adUpdatedAt',
    // Start At
    'adStartAt',
    // End At
    'adEndAt',
    // Creative Type
    'creativeType',
  ];

export const VIDEO_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    //   Video Plays (All)
    'videoPlays',
    // Video Average Play Time
    'videoAvgTimeWatched',
    //   Video Plays (2 Second Plays)
    'video2SecondContinuousPlays',
    // Cost Per 2 Second Video Play
    'costPerVideo2SecondContinuousPlay',
    // Cost Per Unique 2 Second Video Play
    // Video Plays (3 Second Plays)
    'action:total:video_view',
    // Cost Per 3 Second Video Play
    'action:cost_per:video_view',
    // Video 25% Watched Views
    'video25PercentWatchedViews',
    // Video 25% Watched Rate
    // Video 50% Watched Views
    'video50PercentWatchedViews',
    // Video 50% Watched Rate
    // Video 75% Watched Views
    'video75PercentWatchedViews',
    // Video 75% Watched Rate
    // Video 95% Watched Views
    'video95PercentWatchedViews',
    // Video 95% Watched Rate
    // Video 100% Watched Views
    'video100PercentWatchedViews',
    // Video 100% Watched Rate
    // Thruplays
    'thruplay',
    // Cost Per Thruplay
    'costPerThruplay',
    // CTR (Thruplay)
    'thruplayClickThroughRate',
    // 15 Sec/3 Sec Video Retention
    'video15Sec3SecVideoRetention',
    // Thruplay Rate
    'holdplay',
    // Thumbstop Rate
    'thumbstopRatio',
    // First Frame Retention
    'firstFrameRetention',
    // Cost Per Video Thruplay
    'costPerVideoThruplay',
    // Video Plays
    'videoPlays',
    // Video 30 Sec Watched Views
    'video30SecWatchedViews',
    // Video Avg Time Watched
    'videoAvgTimeWatched',
    // Thumbstop
    'thumbstop',
  ];

export const ENGAGEMENT_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Post Engagements
    'action:total:post_engagement',
    // Post Reactions
    'action:total:post_reaction',
    // Post Saves
    'action:total:onsite_conversion.post_save',
    // Post Shares
    'action:total:post',
    // Post Comments
    'action:total:comment',
    // Cost Per Post Engagement
    'action:cost_per:post_engagement',
    // Page Follows or Likes
    'action:total:like',
    // Cost Per Page Follow or Like
    'action:cost_per:like',
    // Page Engagements
    'action:total:page_engagement',
    // Cost Per Page Engagement
    'action:cost_per:page_engagement',
    // Cost Per Post Shares
    'action:cost_per:post',
    // Cost Per Post Saves
    'action:cost_per:onsite_conversion.post_save',
    // Cost Per Post Reactions
    'action:cost_per:post_reaction',
    // Cost Per Post Comments
    'action:cost_per:comment',
    // Cost Per Pixel
    'cpp',
    // Cost Per Inline Post Engagement
    'costPerInlinePostEngagement',
    // Engagement
    'engagement',
    // Engagement Rate
    'engagementRate',
    // Page Engagement Value
    'action:value:page_engagement',
    // Post Comments Value
    'action:value:comment',
    // Post Engagement Value
    'action:value:post_engagement',
    // Post Reactions Value
    'action:value:post_reaction',
    // Post Saves Value
    'action:value:onsite_conversion.post_save',
    // Post Shares Value
    'action:value:post',
    // See More Rate
    'seeMoreRate',
    // Canvas Avg View Time
    'canvasAvgViewTime',
    // Canvas Avg View %
    'canvasAvgViewPercent',
    // Interest
    'interest',
    // Cost Per Result
    'cpr',
  ];

export const PERFORMANCE_CATEGORY_METRICS: Array<InsightsFacebookTimeSeriesMetricName> =
  [
    // Reach
    'reach',
    // Reach (Full View)
    'fullViewReach',
    // Frequency
    'frequency',
    // Impressions
    'impressions',
    // Impressions (Full View)
    'fullViewImpressions',
    // CPM
    'cpm',
    // PPM
    'purchasesPerMille',
    // RPM
    'revenuePerMille',
    // IPM
    'installsPerMille',
    // Delivery
    'effectiveStatus',
    // Ad Set Delivery
    // Spend
    'spend',
    // Spend %
    'spendPercentage',
    // ROAS
    'roas',
    // ROAS (Website Purchases)
    // AOV
    'avgOrderValue',
    // Purchase Value %
    'purchaseValuePercentage',
    // Ad Recall Lift (People)
    // Cost Per Ad Recall Lift (People)
    // Conversions
    'conversions',
    // Add-to-Cart To Purchase Ratio
    'addToCartToPurchaseRatio',
  ];

export const WIZARD_SCORE_CATEGORY_METRICS =
  INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_METRICS;
