import React, { useEffect, useId, useRef, useState } from 'react';
import { InsightsAdFacebook } from '@magicbrief/server/src/insights/classes/platform-services/insights-facebook.service';
import { InsightsAdTikTok } from '@magicbrief/server/src/insights/classes/platform-services/insights-tiktok.service';
import {
  InsightsAdGroupWithInsights,
  InsightsAdWithInsights,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { Group } from '@visx/group';
import { BarGroup } from '@visx/shape';
import { AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { GridRows } from '@visx/grid';
import { useParentSize } from '@visx/responsive';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';
import { Text } from '@visx/text';
import { ScaleLinear } from '@visx/vendor/d3-scale';
import * as d3 from 'd3';
import { BarGroup as BarGroupType, BarGroupBar } from '@visx/shape/lib/types';
import { SimulationNodeDatum } from 'd3';
import { INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE } from '@magicbrief/common';
import { useMatch } from 'react-router-dom';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Heading } from 'react-aria-components';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import Lightning01 from 'src/assets/svgicons/line/lightning-01.svg';
import Database01 from 'src/assets/svgicons/line/database-01.svg';
import Cursor01 from 'src/assets/svgicons/line/cursor-01.svg';
import {
  getValueForMetric,
  parseValueForMetric,
  useParseMetric,
} from '../../util/useParseMetric';
import { AdMetadataCell } from '../InsightsCard/components/InsightsCardInsights/AdMetadataCell';
import {
  useInsightsComparisons,
  useInsightsPlatform,
} from '../../util/useInsightsPersistentState';
import { INSIGHTS_STYLES } from '../../util/constants';
import { sortInsightsGroupsClientSide } from '../../util/sortInsightsItemsClientSide';
import { INSIGHTS_CHART_ZERO_BAR_HEIGHT } from '../const';
import { InsightsChartTick } from './components/InsightsChartTick';

type Props = {
  data: Array<InsightsAdWithInsights | InsightsAdGroupWithInsights>;
  selectionOrder: string[];
  currency: string;
  margin?: { top: number; left: number; right: number; bottom: number };
  keys: string[];
  customEvents: string[] | null | undefined;
  customConversions:
    | Array<{ facebookId: string; name: string }>
    | null
    | undefined;
  isFetching: boolean;
  adPreviewPortalId?: string;
};

const DEFAULT_MARGIN = { top: 0, right: 0, bottom: 150, left: 0 };
const GRID_STROKE = '#F9F8FF';
const DEFAULT_BAR_GROUP_PADDING = 0.2;
const BASE_BAR_PADDING = 0.4;
const MAX_BAR_WIDTH = 12;

let tooltipTimeout: number;

export const InsightsComparisonBarChart: React.FC<Props> = ({
  data,
  currency,
  margin = DEFAULT_MARGIN,
  keys,
  customEvents,
  customConversions,
  selectionOrder,
  isFetching,
  adPreviewPortalId,
}) => {
  const sortedData = sortInsightsGroupsClientSide(
    data,
    keys.map((id) => ({ id, desc: true }))
  );

  // Scale the bar padding based on the number of metrics
  const DEFAULT_BAR_PADDING = BASE_BAR_PADDING - 0.02 * keys.length;

  const id = useId();
  const platform = useInsightsPlatform();
  const comparisons = useInsightsComparisons();
  const { getMetricLabelAsText } = useParseMetric();

  const isCompare = useMatch('/insights/accounts/:accountUuid/compare');

  const { parentRef, width, height } = useParentSize();

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  // Logic for calculating padding of bars and bar groups begins here
  const barsPerGroup = keys.length;
  const numGroupBars = sortedData.length;

  const barPaddingWidth =
    (DEFAULT_BAR_PADDING * MAX_BAR_WIDTH) / (1 - DEFAULT_BAR_PADDING);

  const groupBarWidth =
    barsPerGroup * MAX_BAR_WIDTH + (barsPerGroup - 1) * barPaddingWidth;

  const availableSpace = xMax - groupBarWidth * numGroupBars;
  const newPadding = availableSpace / (numGroupBars + 1);
  const paddingRatio = newPadding / (newPadding + groupBarWidth);
  const padding = Math.max(DEFAULT_BAR_GROUP_PADDING, paddingRatio);
  // Logic for calculating padding of bars and bar groups begins here

  const groupScale = scaleBand<string>({
    domain: sortedData.map((x) => (x.type === 'ad' ? x.uuid : (x.group ?? ''))),
  });

  const barScale = scaleBand<string>({
    domain: keys,
    paddingInner: DEFAULT_BAR_PADDING,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: COLOR_RANK_SCALE,
  });

  const yAxisScale = scaleLinear<number>({
    // Dummy scale to satisfy BarGroup required yScale prop
    domain: [0, 100],
  });

  const {
    showTooltip,
    hideTooltip,
    tooltipOpen,
    tooltipData,
    tooltipLeft,
    tooltipTop,
  } = useTooltip<BarGroupBar<string>>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  const yAxisScales = new Map<string, ScaleLinear<number, number>>(
    keys.map((k) => {
      const vals = sortedData.reduce<number[]>((acc, x) => {
        const v = getValueForMetric(k, x.metrics) ?? 0;
        if (typeof v === 'number') {
          acc.push(v);
        }
        return acc;
      }, []);
      const max = Math.max(...vals);
      const upper = max + 0.1 * max || 0.1; // or 0.1 in case upper limit is also zero
      const scale = scaleLinear<number>({
        domain: [0, upper],
      });
      scale.range([yMax, 0]);
      return [k, scale];
    })
  );

  // update scale output dimensions
  groupScale.rangeRound([0, xMax]).padding(padding);
  barScale.rangeRound([0, groupScale.bandwidth()]);

  const graphData = sortedData.map((x) =>
    keys.reduce<{ identifier: string } & Record<string, number | string>>(
      (acc, key) => {
        const v = getValueForMetric(key, x.metrics) ?? 0;
        if (typeof v === 'number') {
          acc[key] = v;
        }
        return acc;
      },
      {
        identifier: x.type === 'ad' ? x.uuid : (x.group ?? ''),
      }
    )
  );

  const rectRef = useRef<SVGRectElement | null>(null);

  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');

  const hasNoSelectedGraphMetrics = keys.length === 0;
  const hasNoSelectedAds = sortedData.length === 0;

  if (hasNoSelectedGraphMetrics || hasNoSelectedAds) {
    const title = hasNoSelectedGraphMetrics
      ? 'No metrics selected'
      : 'No ads selected';
    const description = hasNoSelectedGraphMetrics
      ? 'Select metrics to compare your ads performance'
      : 'Select rows to view performance analysis';

    return (
      <div
        className={cn(
          'relative flex h-[445px] min-h-[445px] w-full items-end gap-2 rounded-b-xl border-x border-b border-solid border-purple-200 bg-white',
          isSharedReport ? 'rounded-t-xl' : ''
        )}
        ref={parentRef}
      >
        {!isFetching && selectionOrder.length === 0 && (
          <div className="absolute z-50 flex size-full flex-col items-center justify-center gap-4">
            <div className="flex flex-col items-center gap-10">
              <div className="flex flex-col items-center gap-4">
                <Database01 className="size-10 text-primary" />
                <div className="flex flex-col items-center">
                  <Heading className="text-center text-xl font-semibold text-primary">
                    {title}
                  </Heading>
                  <span className="text-center text-base font-medium text-primary/50">
                    {description}
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2 rounded-full border border-solid border-purple-400 bg-purple-50 px-4 py-3">
                <Cursor01 className="size-5 text-primary" />
                <span className="shrink-0 flex-nowrap text-sm font-medium text-primary">
                  Click row checkboxes in the table to select
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  const isWizardScoreTooltip =
    tooltipData &&
    tooltipData.key in INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE;

  return (
    <div
      className={cn(
        'relative h-[445px] w-full rounded-b-xl border-x border-b border-solid border-purple-200 bg-white',
        isSharedReport ? 'rounded-t-xl border-t' : ''
      )}
      ref={parentRef}
    >
      {isCompare && comparisons?.length === 1 ? (
        <div className="absolute bottom-0 left-1/2 flex -translate-x-1/2 translate-y-1/2 items-center gap-2 rounded-full border border-solid border-purple-400 bg-purple-50 px-3 py-2.5">
          <Lightning01 className="size-5 text-primary" />
          <span className="flex-nowrap text-sm font-medium text-primary">
            Tip: Create one more group to compare
          </span>
        </div>
      ) : null}

      <svg
        className="rounded-[inherit]"
        id="comparison-bar-chart"
        ref={containerRef}
        width={width}
        height={height}
      >
        <rect
          ref={rectRef}
          x={0}
          y={0}
          width={width}
          height={height}
          fill="#FFF"
        />
        <GridRows
          left={margin.left}
          scale={[...yAxisScales.values()][0]}
          width={innerWidth}
          numTicks={6}
          stroke={GRID_STROKE}
          strokeOpacity={1}
          pointerEvents="none"
          offset={margin.top}
        />
        <Group id="the-group" top={margin.top} left={margin.left}>
          <BarGroup
            data={graphData}
            keys={keys}
            height={yMax}
            x0={(x) => x.identifier}
            x0Scale={groupScale}
            x1Scale={barScale}
            yScale={yAxisScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => {
                return (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.x0}`}
                    left={barGroup.x0}
                  >
                    {barGroup.bars.map((bar) => {
                      const scale = yAxisScales.get(bar.key);
                      const y = scale ? scale(bar.value) : 0;
                      const height = yMax - (y || 0);
                      const width = Math.min(bar.width, 16);
                      return (
                        <rect
                          key={`bar-group-bar-rect-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          x={bar.x}
                          y={
                            height > 0
                              ? y
                              : yMax - INSIGHTS_CHART_ZERO_BAR_HEIGHT
                          }
                          data-graph-metric={bar.key}
                          className="z-0"
                          id={`bar-group-bar-rect-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`}
                          onMouseLeave={() => {
                            tooltipTimeout = window.setTimeout(() => {
                              hideTooltip();
                            }, 300);

                            const otherMetricNodes = document.querySelectorAll(
                              `[data-graph-metric]:not([data-graph-metric="${bar.key}"])`
                            );

                            otherMetricNodes.forEach((x) =>
                              (
                                x as SVGRectElement | SVGTextElement
                              ).setAttribute('opacity', '1')
                            );
                          }}
                          onMouseMove={(event) => {
                            if (tooltipTimeout) clearTimeout(tooltipTimeout);
                            // TooltipInPortal expects coordinates to be relative to containerRef
                            // localPoint returns coordinates relative to the nearest SVG, which
                            // is what containerRef is set to in this example.
                            const eventSvgCoords = localPoint(event);
                            const left = barGroup.x0 + bar.x + width / 2;
                            showTooltip({
                              tooltipData: bar,
                              tooltipTop: eventSvgCoords?.y,
                              tooltipLeft: left,
                            });
                            const otherMetricNodes = document.querySelectorAll(
                              `[data-graph-metric]:not([data-graph-metric="${bar.key}"])`
                            );

                            otherMetricNodes.forEach((x) => {
                              (
                                x as SVGRectElement | SVGTextElement
                              ).setAttribute('opacity', '0.25');
                            });
                          }}
                          width={width}
                          height={
                            height > 0 ? height : INSIGHTS_CHART_ZERO_BAR_HEIGHT
                          }
                          fill={bar.color}
                          rx={4}
                          stroke="transparent"
                          strokeWidth={barPaddingWidth}
                        />
                      );
                    })}
                  </Group>
                );
              })
            }
          </BarGroup>
          <BarGroup
            data={graphData}
            keys={keys}
            height={yMax}
            x0={(x) => x.identifier}
            x0Scale={groupScale}
            x1Scale={barScale}
            yScale={yAxisScale}
            color={colorScale}
          >
            {(barGroups) => {
              return (
                <InsightsComparisonBarChartLabelsLayer
                  platform={platform}
                  barGroups={barGroups}
                  rectRef={rectRef.current}
                  yAxisScales={yAxisScales}
                  currency={currency}
                  yMax={yMax}
                />
              );
            }}
          </BarGroup>
        </Group>
        <AxisBottom
          top={yMax + margin.top}
          tickClassName="font-medium text-xs text-gray-900"
          tickComponent={(tickRendererProps) => {
            const { formattedValue } = tickRendererProps;

            const matchIdx = sortedData.findIndex((d) =>
              d.type === 'ad'
                ? d.uuid === formattedValue
                : (d.group ?? '') === formattedValue
            );
            const match = sortedData[matchIdx];

            if (!match) {
              return null;
            }

            const selectionIdx = selectionOrder.findIndex(
              (s) => s === formattedValue
            );
            const styles =
              selectionIdx !== -1 ? INSIGHTS_STYLES[selectionIdx] : undefined;

            return (
              <InsightsChartTick
                id={id}
                sortedData={sortedData}
                ad={match}
                styles={styles}
                tickRendererProps={tickRendererProps}
                adPreviewPortalId={adPreviewPortalId}
              />
            );
          }}
          scale={groupScale}
          stroke={GRID_STROKE}
          tickStroke="transparent"
        />
        {tooltipOpen && tooltipData && (
          <TooltipInPortal
            top={tooltipTop}
            left={tooltipLeft}
            style={{}}
            className="pointer-events-none absolute flex min-h-16 flex-row gap-3 rounded-lg border border-solid border-purple-200 bg-white px-2.5 py-2 shadow-sm"
          >
            <div
              style={{ backgroundColor: colorScale(tooltipData.key) }}
              className="min-h-16 w-1.5 flex-auto rounded-md"
            />
            <div className="flex flex-col justify-between">
              <div className="text-sm font-medium text-gray-900">
                {getMetricLabelAsText(
                  platform,
                  tooltipData.key,
                  customEvents,
                  customConversions
                )}
              </div>
              <AdMetadataCell
                title={null}
                value={parseValueForMetric(
                  platform,
                  tooltipData.key,
                  tooltipData.value,
                  currency,
                  isWizardScoreTooltip
                    ? {
                        numeric: {
                          maximumFractionDigits: 0,
                        },
                      }
                    : undefined
                )}
                diffFromWeightedAvg={null}
                sentimentColor={undefined}
                isWizardScore={false}
              />
            </div>
          </TooltipInPortal>
        )}
      </svg>
    </div>
  );
};

type InsightsComparisonBarChartLabelsLayerProps = {
  barGroups: Array<BarGroupType<string>>;
  rectRef: SVGRectElement | null;
  currency: string;
  yAxisScales: Map<string, ScaleLinear<number, number>>;
  yMax: number;
  platform: 'facebook' | 'tiktok';
};

function forceClamp<T extends SimulationNodeDatum>(min: number, max: number) {
  let nodes: T[];
  const force = () => {
    nodes.forEach((n) => {
      if (n.y === undefined) return;
      if (n.y > max) n.y = max;
      if (n.y < min) n.y = min;
    });
  };
  force.initialize = (_: T[]) => (nodes = _);
  return force;
}

interface InsightsComparisonBarChartLabelTextNodeDatum
  extends SimulationNodeDatum {
  id: string;
  index: number;
  text: string;
  targetX: number;
  targetY: number;
  metric: string;
}

const InsightsComparisonBarChartLabelsLayer: React.FC<
  InsightsComparisonBarChartLabelsLayerProps
> = ({ barGroups, currency, yAxisScales, yMax, platform }) => {
  const [textNodes, setTextNodes] = useState<
    Array<InsightsComparisonBarChartLabelTextNodeDatum>
  >([]);

  useEffect(() => {
    const textNodes = barGroups.reduce<
      Array<InsightsComparisonBarChartLabelTextNodeDatum>
    >((acc, barGroup) => {
      const textNodes = barGroup.bars
        .map((bar) => {
          if (bar.value === 0) {
            return null;
          }
          const scale = yAxisScales.get(bar.key);
          const targetY = scale ? (scale(bar.value) as number) : 0;
          const width = Math.min(bar.width, 16);
          const isWizardScore =
            bar.key in INSIGHTS_FACEBOOK_TIME_SERIES_WIZARD_REFERENCE;

          return {
            id: `bar-group-bar-label-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`,
            metric: bar.key,
            index: bar.index,
            text:
              bar.value === null
                ? 'None'
                : (parseValueForMetric(platform, bar.key, bar.value, currency, {
                    currency: {
                      maximumFractionDigits: 0,
                    },
                    numeric: {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: isWizardScore ? 0 : 1,
                    },
                    percentage: {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                    },
                  }) ?? 'Unknown'),
            targetX: barGroup.x0 + bar.x + width / 2,
            targetY: targetY - 5,
          };
        })
        .filter((x): x is Exclude<typeof x, null> => x !== null);

      // The minimum Y value for the bar group, represents the
      // furthest *upward* the label should be placed. The top of the chart is zero.
      const groupMinY = textNodes.reduce<number>((groupMin, bar) => {
        const targetY = bar.targetY;
        if (Number.isNaN(groupMin)) {
          return targetY;
        }
        return Math.min(groupMin, targetY);
      }, NaN);

      /**
       * Create a d3 collision simulation to approximate non-overlapping positions for bar text labels
       * @see {@link https://chrissardegna.com/blog/lessons-in-d3-labeling/}
       */
      const simulation = d3
        .forceSimulation<InsightsComparisonBarChartLabelTextNodeDatum>(
          textNodes
        )
        .force(
          'collide',
          d3.forceCollide(12) // Font size of label as collision radius
        )
        .force('x', d3.forceX(0).strength(1)) // Treat all labels in the group as being in the same x position
        .force(
          'y',
          d3
            .forceY<InsightsComparisonBarChartLabelTextNodeDatum>(
              (d) => d.targetY // Pull toward natural position just above top of bar
            )
            .strength(1)
        )
        .force(
          'y1',
          d3
            .forceY<InsightsComparisonBarChartLabelTextNodeDatum>(290) // Pull label downward to provide some variance
            .strength((d) => 0.1 * d.index) // Resist downward pull if first bar of group, for aesthetic reasons, subsequent bars have more downward pull
        )
        .force(
          'clamp',
          forceClamp<InsightsComparisonBarChartLabelTextNodeDatum>(
            Math.min(22, groupMinY + 20), // Any less and we clip above the top of the chart. groupMinY + 20 represents a nice threshold for very short groups with small value bars and avoids labels being pushed upward very high.
            290 // Any more and we drop below the x axis bound
          ) // Keep position with desirable chart bounds
        )
        .stop();

      // More iterations on the simulation achieves a more desirable outcome
      // Our chart has a maximum 25 labels, so we don't need much
      for (let i = 0; i < 300; i++) {
        simulation.tick();
      }
      acc.push(...textNodes);
      return acc;
    }, [] as Array<InsightsComparisonBarChartLabelTextNodeDatum>);

    setTextNodes(textNodes);
  }, [barGroups, currency, yAxisScales, yMax, platform]);

  return (
    <svg width="100%" height="100%">
      {textNodes.map((x) => (
        <Text
          data-graph-metric={x.metric}
          width={55}
          key={x.id}
          id={x.id}
          y={x.y}
          x={x.targetX} // Retain target X, even at cost of potential overlaps, sim is too greedy at changing this
          textAnchor="middle"
          paintOrder="stroke"
          className="relative fill-[#7561B5] text-xs font-medium"
          strokeWidth={3}
          stroke="#FFF"
        >
          {x.text}
        </Text>
      ))}
    </svg>
  );
};

export function InsightsGraphMetricSkeleton() {
  return (
    <div className="flex flex-col items-center gap-4">
      <div className="flex size-full items-end justify-center gap-1">
        <div className="h-[50px] w-3 animate-pulse rounded-md bg-gray-50" />
        <div className="h-[150px] w-3 animate-pulse rounded-md bg-gray-50" />
        <div className="h-[250px] w-3 animate-pulse rounded-md bg-gray-50" />
        <div className="h-[95px] w-3 animate-pulse rounded-md bg-gray-50" />
      </div>

      <div className="size-[72px] animate-pulse rounded-lg bg-gray-50" />
    </div>
  );
}
