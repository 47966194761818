import { Dispatch, SetStateAction } from 'react';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from '@magicbrief/ui/src/components/popover';
import { cn } from '@magicbrief/ui/src/lib/cn';
import {
  Button as AriaButton,
  Group,
  Label,
  Separator,
} from 'react-aria-components';
import { Button } from '@magicbrief/ui/src/components/button';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useParams } from 'react-router-dom';
import { CSS } from '@dnd-kit/utilities';
import { InsightsTableColorSchema } from '@magicbrief/common';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import { useParseMetric } from 'src/pages/Insights/util/useParseMetric';
import { useInsightsPlatform } from 'src/pages/Insights/util/useInsightsPersistentState';
import Plus from 'src/assets/svgicons/line/plus.svg';
import ChevronDown from 'src/assets/svgicons/duocolor/chevron-down.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { COLOR_SWATCHES, ColorSwatchOption } from '../../InsightsTable/const';
import { ColorSwatch } from '../../InsightsTable/TableSettingsPopover';
import { useInsightsAdBreakdown } from '../useInsightsAdBreakdown';
import { AdBreakdownMetricModal } from './AdBreakdownMetricModal';

export const AdBreakdownTableSettings = ({
  isMetricModalOpen,
  setIsMetricModalOpen,
}: {
  isMetricModalOpen: boolean;
  setIsMetricModalOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { LL } = useI18nContext();
  const { zIndex, tableColorPreference, setTableColorPreference } =
    useInsightsAdBreakdown();

  const handleSetColorScheme = (color: ColorSwatchOption) => {
    setTableColorPreference(color.key as InsightsTableColorSchema);
  };

  return (
    <>
      <PopoverTrigger>
        <AriaButton
          className={cn(
            /* Base */
            'flex min-h-9 w-fit items-center gap-1 text-nowrap rounded-md border border-solid border-purple-200 bg-white px-2.5 py-2 shadow-xs transition-colors focus:outline-none',
            /* Typography */
            'text-xs font-semibold text-primary',
            /* Hover */
            'hover:bg-purple-50'
          )}
        >
          {LL.insights.table.tableSettings()}
          <ChevronDown className="size-4" />
        </AriaButton>

        <Popover placement="bottom left" offset={8} style={{ zIndex }}>
          <PopoverDialog className="flex w-[250px] flex-col rounded-md bg-white p-0">
            <Group className="flex items-center justify-between gap-1 px-3 py-2">
              <Label className="text-xs font-medium text-primary">
                {LL.insights.table.colorFormatting()}
              </Label>
              <div className="flex items-center gap-1">
                {COLOR_SWATCHES.map((color) => (
                  <ColorSwatch
                    key={color.key}
                    color={color}
                    tableColorScheme={tableColorPreference}
                    handleSetColorScheme={handleSetColorScheme}
                  />
                ))}
              </div>
            </Group>
            <Separator className="bg-purple-200" />
            <Group className="w-full space-y-2 px-3 py-2">
              <DraggableTableMetrics />
            </Group>
            <Separator className="bg-purple-200" />
            <Group className="w-full space-y-2 px-3 py-1">
              <Button
                variant="text"
                size="small"
                className="h-8 w-full rounded p-0 text-xs"
                onPress={() => {
                  setIsMetricModalOpen(true);
                }}
              >
                <Plus className="size-4" />
                Add Metric
              </Button>
            </Group>
          </PopoverDialog>
        </Popover>
      </PopoverTrigger>

      <AdBreakdownMetricModal
        isOpen={isMetricModalOpen}
        setIsOpen={setIsMetricModalOpen}
      />
    </>
  );
};

function DraggableTableMetrics() {
  const { tableMetrics: display, setTableMetrics } = useInsightsAdBreakdown();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);

      setTableMetrics(newDisplayOrder);
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={display} strategy={verticalListSortingStrategy}>
        <div className="max-h-[300px] space-y-0 overflow-x-hidden">
          {display.map((id) => (
            <DraggableMetric key={id} id={id} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

function DraggableMetric({ id }: { id: string }) {
  const { tableMetrics, setTableMetrics } = useInsightsAdBreakdown();
  const platform = useInsightsPlatform();
  const { accountUuid } = useParams();
  const { getMetricLabelAsText } = useParseMetric();
  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({ accountUuid });
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex items-center gap-1.5 rounded-md py-1.5 hover:cursor-grabbing"
      style={styles}
      aria-label="selected display metric"
      {...attributes}
      {...listeners}
    >
      <button className="cursor-grab text-purple-300 active:cursor-grabbing focus:outline-none">
        <DotsGrid className="size-4" />
      </button>
      <span className="grow truncate text-xs font-medium text-primary">
        {getMetricLabelAsText(
          platform,
          id,
          accountCustomEvents ?? [],
          accountCustomConversions ?? []
        )}
      </span>
      <AriaButton
        className="size-4 rounded-sm text-primary/50 outline-none hover:bg-purple-50"
        onPress={() => setTableMetrics(tableMetrics.filter((m) => m !== id))}
      >
        <XClose className="size-4 text-primary opacity-50" />
      </AriaButton>
    </div>
  );
}
