import { useCallback, useRef } from 'react';
import { InsightsFacebookGroup, InsightsFilterV2 } from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';
import { captureException } from '@sentry/react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import dayjs from 'src/lib/dayjs';
import { useI18nContext } from '../../../i18n/i18n-react';
import { trpc } from '../../../lib/trpc';
import { GetFacebookAdWithInsightsResponse } from '../../../types/insights';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../components/const';
import { INSIGHTS_PAGE_TAKE_LIMIT } from './constants';
import {
  InsightsSort,
  useInsightsFilter,
  useInsightsGroup,
  useInsightsSort,
  useInsightsStoreDispatch,
} from './useInsightsPersistentState';
import { useInsightsSearchParams } from './useInsightsSearchParams';
import { useInsightsJob } from './useInsightsJob';

type UseInsightsAdsProps =
  | {
      type: 'account';
      uuid: string | undefined;
      jobStatus: 'processing' | 'failed' | 'completed' | undefined;
    }
  | {
      type: 'report';
      uuid: string | undefined;
    };

export function useInsightsAds(params: UseInsightsAdsProps) {
  const { getParsedValues } = useInsightsSearchParams();
  const metric = useInsightsFilter();
  const sort = useInsightsSort();
  const group = useInsightsGroup();
  const { forTimePeriod, attributionWindow } = getParsedValues();

  return useInsightsAdsQuery({
    sort,
    metric,
    group,
    forTimePeriod,
    attributionWindow,
    ...params,
  });
}

export function getNextPageParam(lastPage: unknown[], pages: unknown[][]) {
  if (pages.length === 0) {
    return undefined;
  }

  if (lastPage.length < INSIGHTS_PAGE_TAKE_LIMIT) {
    return undefined;
  }

  return (pages.length - 1) * INSIGHTS_PAGE_TAKE_LIMIT + lastPage.length;
}

type UseInsightsAdsQueryProps = UseInsightsAdsProps & {
  sort: Array<InsightsSort>;
  metric: Array<InsightsFilterV2>;
  group: InsightsFacebookGroup | null;
  forTimePeriod: ForTimePeriodInput;
  attributionWindow: 'default' | 'custom' | null;
};

export function useInsightsAdsQuery({
  sort,
  metric,
  group,
  forTimePeriod,
  attributionWindow,
  ...rest
}: UseInsightsAdsQueryProps) {
  const { LL } = useI18nContext();
  const dispatch = useInsightsStoreDispatch();
  const job = useInsightsJob();

  const params = {
    accountUuid: rest.uuid ?? '',
    count: INSIGHTS_PAGE_TAKE_LIMIT,
    group: group ?? undefined,
    attributionWindow: attributionWindow ?? 'default',
    sort,
    filter: metric,
    forTimePeriod,
    ungroup: undefined,
  };
  const prevParams = useRef(params);

  const accountAds = trpc.insightsAds.getManyAds.useInfiniteQuery(params, {
    enabled: !!rest.uuid && rest.type === 'account',
    getNextPageParam,
    keepPreviousData: true,
    onSuccess: (data) => {
      // Only run this side effect on initial load - handles selection after a job finishes
      if (job?.status === 'completed' && data.pages.length === 1) {
        const keys = data.pages[0]
          .slice(0, INSIGHTS_TABLE_SELECTION_MAX)
          .map((x) => (x.type === 'ad' ? x.uuid : x.group));
        dispatch({
          type: 'setSelected',
          value: keys,
        });
        dispatch({
          type: 'setSelectedGridMode',
          value: 'default',
        });
      }
    },
    select: (data) => {
      const haveParamsChanged = !isEqual(params, prevParams.current);

      // This makes it reactive to filter changes but not when coming back to the page
      if (haveParamsChanged) {
        const keys = data.pages[0]
          .slice(0, INSIGHTS_TABLE_SELECTION_MAX)
          .map((x) => (x.type === 'ad' ? x.uuid : x.group));
        dispatch({
          type: 'setSelected',
          value: keys,
        });
        dispatch({
          type: 'setSelectedGridMode',
          value: 'default',
        });
        prevParams.current = params;
      }
      return data;
    },
    onError: (error) => {
      const msg = LL.errors.genericWithDetail({
        detail: error instanceof Error ? error.message : 'Unknown error',
      });
      captureException(error, (scope) => {
        scope.setTransactionName('insightsAds.getManyAds');
        return scope;
      });
      toast.error(msg, { className: 'toast-danger' });
    },
    trpc: {
      context: {
        skipBatch: true,
      },
    },
  });

  const reportAds = trpc.insightsAds.getManyAdsFromReport.useInfiniteQuery(
    {
      uuid: rest.uuid ?? '',
      count: INSIGHTS_PAGE_TAKE_LIMIT,
      sort,
      ungroup: undefined,
    },
    {
      enabled: !!rest.uuid && rest.type === 'report',
      getNextPageParam,
      keepPreviousData: true,
      onError: (error) => {
        const msg = LL.errors.genericWithDetail({
          detail: error instanceof Error ? error.message : 'Unknown error',
        });
        captureException(error, (scope) => {
          scope.setTransactionName('insightsAds.getManyAdsFromReport');
          return scope;
        });
        toast.error(msg, { className: 'toast-danger' });
      },
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  );

  const ads = rest.type === 'report' ? reportAds : accountAds;

  const onLoadMore = async () => {
    if (!ads.isLoading && !ads.isFetching && !ads.isError) {
      return ads.fetchNextPage();
    }
  };

  const isAdsEmpty =
    ads.isSuccess &&
    !ads.isFetching &&
    (ads.data.pages.length === 0 || ads.data.pages[0].length === 0);

  const getAdAtFlatIndex = useCallback(
    (index: number): { ad: GetFacebookAdWithInsightsResponse } | null => {
      const pageIndex = Math.floor(index / INSIGHTS_PAGE_TAKE_LIMIT);
      const withinPageIndex = index - pageIndex * INSIGHTS_PAGE_TAKE_LIMIT;
      if (
        ads.data &&
        index >= 0 &&
        pageIndex < ads.data.pages.length &&
        withinPageIndex < ads.data.pages[pageIndex]?.length
      ) {
        const ad = ads.data.pages[pageIndex][withinPageIndex];
        if (ad.type === 'ad') {
          return { ad };
        }
        return null;
      }
      return null;
    },
    [ads.data]
  );

  return {
    ads,
    onLoadMore,
    isAdsEmpty,
    getAdAtFlatIndex,
  };
}

export function useInsightsAdAccount({
  accountUuid,
  shouldFetch = true,
}: {
  accountUuid: string | undefined;
  shouldFetch?: boolean;
}) {
  const adAccount = trpc.insightsAccounts.getAdAccount.useQuery(
    { uuid: accountUuid || '' },
    {
      enabled: shouldFetch && !!accountUuid,
    }
  );

  const accountCustomEvents = adAccount.data?.customEvents?.map((x) => x.name);
  const accountCustomConversions = adAccount.data?.customConversions;

  const currency = adAccount.data?.currency ?? null;

  return {
    adAccount,
    currency,
    accountCustomEvents,
    accountCustomConversions,
  };
}

export function useInsightsAccountLevelStatistics(params: UseInsightsAdsProps) {
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod, attributionWindow } = getParsedValues();
  const metric = useInsightsFilter();
  const group = useInsightsGroup();

  return useInsightsStatisticsQuery({
    metric,
    forTimePeriod,
    group,
    attributionWindow,
    ...params,
  });
}

export function useInsightsStatisticsQuery(
  params: UseInsightsAdsProps & Omit<UseInsightsAdsQueryProps, 'sort'>
) {
  const statistics = trpc.insightsAds.getStatisticsForAds.useQuery(
    {
      accountUuid: params.uuid || '',
      forTimePeriod: params.forTimePeriod,
      filter: params.metric,
      attributionWindow: params.attributionWindow ?? undefined,
      group: params.group ?? undefined,
    },
    {
      enabled: !!params.uuid && params.type === 'account',
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  );

  const statisticsForReport = trpc.insightsAds.getStatisticsForReport.useQuery(
    {
      uuid: params.uuid || '',
      ungroup: undefined,
    },
    {
      enabled: !!params.uuid && params.type === 'report',
      trpc: {
        context: {
          skipBatch: true,
        },
      },
    }
  );

  return {
    statistics: params.type === 'account' ? statistics : statisticsForReport,
  };
}

export function useRefetchData() {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();
  const { accountUuid } = useParams();
  const { getParsedValues } = useInsightsSearchParams();
  const { forTimePeriod } = getParsedValues();

  const { adAccount } = useInsightsAdAccount({
    accountUuid,
  });

  const syncQuery = trpc.insightsAccounts.getAdAccountSyncStatus.useQuery(
    { adAccountUuid: accountUuid ?? '' },
    { enabled: !!accountUuid }
  );

  const job = syncQuery.data?.find((x) => {
    if (x.forTimePeriod.datePreset === 'custom') {
      return isEqual(x.forTimePeriod, forTimePeriod);
    }
    return x.forTimePeriod.datePreset === forTimePeriod.datePreset;
  });

  const isInsightsOrgAccountDisabled =
    adAccount?.data?.config?.status === 'disabled';

  const fifteenMinutesAgo = dayjs().subtract(15, 'minute');

  const isDisabled =
    isInsightsOrgAccountDisabled ||
    adAccount.isFetching ||
    adAccount.data == null ||
    (job && job.asOfTime.valueOf() - fifteenMinutesAgo.valueOf() > 0);

  const refetchAdAccountData =
    trpc.insightsAccounts.refetchAdAccountData.useMutation({
      async onSuccess(jobData) {
        if (accountUuid) {
          trpcUtils.insightsAccounts.getAdAccountSyncStatus.setData(
            { adAccountUuid: accountUuid },
            jobData
          );
        }
      },
      async onMutate({ adAccountUuid }) {
        await trpcUtils.insightsAccounts.getAdAccountSyncStatus.cancel({
          adAccountUuid: adAccountUuid,
        });
      },
      onError: async (error) => {
        const msg = LL.errors.genericWithDetail({
          detail: error instanceof Error ? error.message : 'Unknown error',
        });
        captureException(error, (scope) => {
          scope.setTransactionName('refetchAdAccountData');
          return scope;
        });
        toast.error(msg, { className: 'toast-danger' });
      },
    });

  const refetch = (params?: {
    adAccountUuid: string;
    forTimePeriod: ForTimePeriodInput;
  }) => {
    if (adAccount.data && !isInsightsOrgAccountDisabled) {
      refetchAdAccountData.mutate({
        adAccountUuid: params?.adAccountUuid ?? adAccount.data.uuid,
        forTimePeriod: params?.forTimePeriod ?? forTimePeriod,
      });
    }
  };

  return {
    refetch,
    isDisabled,
    isLoading: refetchAdAccountData.isLoading,
    isProcessing: job?.status === 'processing',
  };
}

type UseInsightsAdQueryProps =
  | {
      type: 'account';
      accountUuid: string;
      adUuid: string;
      forTimePeriod: ForTimePeriodInput;
      attributionWindow: string | undefined;
    }
  | {
      type: 'report';
      reportUuid: string;
      adUuid: string;
    };

export function useInsightsAdQuery(params: UseInsightsAdQueryProps) {
  const adViaAccount = trpc.insightsAds.getAd.useQuery(
    {
      uuid: params.adUuid,
      forTimePeriod:
        params.type === 'account'
          ? params.forTimePeriod
          : { datePreset: 'last_14d' },
      attributionWindow:
        params.type === 'account' ? params.attributionWindow : undefined,
    },
    {
      enabled:
        params.type === 'account' && !!params.adUuid && !!params.accountUuid,
    }
  );

  const adViaReport = trpc.insightsAds.getAdFromReport.useQuery(
    {
      reportUuid: params.type === 'report' ? params.reportUuid : '',
      adUuid: params.adUuid,
    },
    {
      enabled:
        params.type === 'report' && !!params.reportUuid && !!params.adUuid,
    }
  );

  return params.type === 'account' ? adViaAccount : adViaReport;
}
