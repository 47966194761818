import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  OnChangeFn,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useSetAtom } from 'jotai';
import {
  ArrayElement,
  getMetricFormat,
  getMetricPositivity,
  INSIGHTS_AD_SPEND_THRESHOLD,
  INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM,
  InsightsFacebookWizardMetric,
  IntRange,
  isInsightsFacebookMetricNumeric,
} from '@magicbrief/common';
import {
  Button,
  Checkbox,
  composeRenderProps,
  ResizableTableContainer,
} from 'react-aria-components';
import InfiniteScroll from 'react-infinite-scroller';
import { useParams } from 'react-router-dom';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Spinner } from '@magicbrief/ui/src/components/spinner';
import {
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { toast } from 'react-toastify';
import Checkerboard from 'src/assets/imgs/checker.jpeg';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { insightsAdPreviewAtom } from 'src/pages/Insights/Insights.atoms';
import { useInsightsPlatform } from 'src/pages/Insights/util/useInsightsPersistentState';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import { useI18nContext } from 'src/i18n/i18n-react';
import {
  getMetricValue,
  getValueForMetric,
  parseValueForMetric,
} from 'src/pages/Insights/util/useParseMetric';
import Image from 'src/components/Image';
import { TableCheckbox } from 'src/components/Table';
import { INSIGHTS_STYLES } from 'src/pages/Insights/util/constants';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import { Tooltip } from 'src/components/Tooltip/Tooltip';
import { getSentimentColor } from 'src/pages/Insights/util/getSentimentColor';
import {
  GetFacebookAdAccountResponse,
  GetManyFacebookAdsWithInsightsResponse,
} from 'src/types/insights';
import {
  INSIGHTS_TABLE_NULL_ROW_ID,
  NEGATIVE_TABLE_COLORS,
  NEUTRAL_TABLE_COLORS,
  POSITIVE_TABLE_COLORS,
} from '../../InsightsTable/const';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../../const';
import { TableHeader } from '../../InsightsTable/TableHeader';
import { InsightsMetricLabelTooltip } from '../../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { useInsightsAdBreakdown } from '../useInsightsAdBreakdown';
import { DraggableTableFooterHeader } from '../../InsightsTable/TableFooterHeader';
import {
  WizardScoreMeter,
  WizardScoreTooltip,
} from '../../InsightsCard/components/InsightsCardInsights/WizardScore';
import type {
  InsightsAdWithInsights,
  InsightsMetricStatistic,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

const columnHelper =
  createColumnHelper<ArrayElement<AdBreakdownTableProps['ads']>>();

const locale = window.navigator.languages
  ? window.navigator.languages[0]
  : window.navigator.language;

interface AdBreakdownTableProps {
  ads: Array<ArrayElement<GetManyFacebookAdsWithInsightsResponse>>;
  currency: GetFacebookAdAccountResponse['currency'];
  isLoading: boolean;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  statistics: Partial<Record<string, InsightsMetricStatistic>> | undefined;
}

export function AdBreakdownTable({
  ads,
  currency,
  isLoading,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  statistics,
}: AdBreakdownTableProps) {
  if (isLoading) {
    return (
      <div className="flex h-[400px] w-full grow animate-pulse flex-col rounded-xl border border-solid border-purple-200 bg-gray-50 shadow" />
    );
  }

  return (
    <div className="relative flex h-[500px] w-full flex-auto overflow-auto rounded-xl border border-solid border-purple-200 bg-white">
      <AdBreakdownTableContent
        ads={ads as Array<ArrayElement<GetManyFacebookAdsWithInsightsResponse>>}
        currency={currency}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        onLoadMore={onLoadMore}
        statistics={statistics}
      />
    </div>
  );
}

function AdBreakdownTableContent({
  ads,
  currency,
  isFetchingNextPage,
  hasNextPage,
  onLoadMore,
  statistics,
}: {
  ads: Array<ArrayElement<GetManyFacebookAdsWithInsightsResponse>>;
  currency: GetFacebookAdAccountResponse['currency'];
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
  statistics: Partial<Record<string, InsightsMetricStatistic>> | undefined;
}) {
  const platform = useInsightsPlatform();
  const { accountUuid } = useParams();
  const { LL } = useI18nContext();
  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({ accountUuid });

  const {
    selectedKeys,
    setSelectedKeys,
    tableMetrics,
    tableColorPreference,
    sort,
    setSort,
  } = useInsightsAdBreakdown();

  const containerRef = useRef<HTMLDivElement>(null);

  const [isScrolled, setIsScrolled] = useState(false);

  const customTrigger = ({ children }: PropsWithChildren) => (
    <span className="text-xs font-semibold text-primary">{children}</span>
  );

  const rowSelection: RowSelectionState =
    selectedKeys?.reduce<Record<string, boolean>>((acc, curr) => {
      acc[curr ?? INSIGHTS_TABLE_NULL_ROW_ID] = true;
      return acc;
    }, {}) ?? {};

  const onSortingChange: OnChangeFn<SortingState> = (updater) => {
    const newSorting = typeof updater === 'function' ? updater(sort) : updater;

    // Prevent the app from crashing if user has already added tag to the table metrics
    // Tag has been manually filtered from the metric picker
    if (newSorting[0].id === 'tag') {
      toast.error('Cannot sort on this property');
      return;
    }

    setSort(newSorting);
  };

  const headerRowRef = useRef<HTMLTableRowElement>(null);
  const bodyRowRef = useRef<HTMLTableRowElement>(null);
  const footerRowRef = useRef<HTMLTableRowElement>(null);

  const columns = [
    columnHelper.display({
      id: 'adSelection',
      header: () => {
        return (
          <TableCheckbox
            className="cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
            isIndeterminate={selectedKeys?.length > 0}
            onChange={() => {
              if (selectedKeys?.length > 0) {
                setSelectedKeys([]);
              } else {
                setSelectedKeys(
                  ads
                    ?.slice(0, INSIGHTS_TABLE_SELECTION_MAX)
                    ?.map((ad) =>
                      ad.type === 'ad'
                        ? ad.uuid
                        : (ad.group ?? INSIGHTS_TABLE_NULL_ROW_ID)
                    )
                );
              }
            }}
          />
        );
      },
      minSize: 52,
      maxSize: 52,
      cell: (cell) => {
        const selectionIdx = cell.row.getIsSelected()
          ? (selectedKeys?.findIndex((x) =>
              x ? x === cell.row.id : x === INSIGHTS_TABLE_NULL_ROW_ID
            ) ?? -1)
          : -1;
        const styles = INSIGHTS_STYLES[selectionIdx];

        return (
          <TableCheckbox
            selectedClassName={cn(styles?.checkbox, styles?.border)}
            indeterminateClassName={cn(styles?.checkbox, styles?.border)}
            isSelected={cell.row.getIsSelected()}
            isDisabled={!cell.row.getCanSelect()}
            onChange={cell.row.getToggleSelectedHandler()}
          />
        );
      },
      footer: (cell) => (
        <div
          style={{
            width: `calc(var(--header-${cell.header?.id}-size) * 1px)`,
          }}
          className="flex h-14 items-center p-2 font-semibold text-purple-800"
        >
          {LL.insights.metrics.summary()}
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'adName',
      header: () => {
        const label =
          selectedKeys.length === 1
            ? `1 ad selected`
            : `${selectedKeys.length} ads selected`;

        return (
          <span className="text-xs font-semibold text-primary">{label}</span>
        );
      },
      minSize: 80,
      size: 300,
      cell: (cell) => {
        const item = cell.row.original;

        return (
          <div className="flex items-center gap-3 overflow-hidden">
            {item.type === 'ad' && <TableMediaPreview ad={item} />}
            <span
              className="truncate text-sm font-medium text-black"
              style={{ maxWidth: cell.column.getSize() }}
            >
              {item.name}
            </span>
          </div>
        );
      },
    }),

    ...tableMetrics.map((metric) => {
      return columnHelper.accessor(
        (row) => getValueForMetric(metric, row.metrics),
        {
          id: metric,
          size: 160,
          minSize: 130,
          header: (header) => (
            <InsightsMetricLabelTooltip
              platform={platform}
              metric={metric}
              onPress={header.column.getToggleSortingHandler()}
              customEvents={accountCustomEvents ?? []}
              customConversions={accountCustomConversions ?? []}
              customTrigger={customTrigger}
            />
          ),
          cell: (cell) => {
            const metric = cell.column.id;

            const isWizardScore = metric.startsWith('wizard');

            const isSpend = metric === 'spend';
            const isNumericalMetric = isInsightsFacebookMetricNumeric(metric);

            let diffFromWeightedAvg = null;

            let sentimentColor = '#FFFFFF';
            if (!isSpend && !isWizardScore && isNumericalMetric) {
              const rawMetricValue = cell.getValue();
              const average = statistics?.[metric]?.weighted_avg;
              const min = statistics?.[metric]?.minThreshold;
              const max = statistics?.[metric]?.maxThreshold;
              const spend = cell.row.original.metrics.spend as number;
              const isOverMinSpend = spend > INSIGHTS_AD_SPEND_THRESHOLD;

              if (
                isOverMinSpend &&
                tableColorPreference !== 'none' &&
                typeof rawMetricValue === 'number' &&
                average != null &&
                min != null &&
                max != null
              ) {
                diffFromWeightedAvg = average
                  ? (rawMetricValue - average) / average
                  : 0;

                const lowerTail = average - min;
                const upperTail = max - average;

                const changeOutcome = getMetricPositivity('facebook', metric);

                const positiveColors =
                  tableColorPreference === 'neutral-negative'
                    ? NEUTRAL_TABLE_COLORS
                    : POSITIVE_TABLE_COLORS;

                const negativeColors =
                  tableColorPreference === 'positive-neutral'
                    ? NEUTRAL_TABLE_COLORS
                    : NEGATIVE_TABLE_COLORS;

                if (rawMetricValue > average) {
                  const percentage = (rawMetricValue - average) / upperTail;
                  const percentageAsWholeNumber = (percentage *
                    100) as IntRange<0, 100>;

                  sentimentColor = getSentimentColor({
                    percentage: percentageAsWholeNumber,
                    colors:
                      changeOutcome === 'positive'
                        ? positiveColors
                        : negativeColors,
                    defaultColor: '#FFFFFF',
                    gradient: false,
                  });
                } else if (rawMetricValue < average) {
                  const percentage = (average - rawMetricValue) / lowerTail;
                  const percentageAsWholeNumber = (percentage *
                    100) as IntRange<0, 100>;

                  sentimentColor = getSentimentColor({
                    percentage: percentageAsWholeNumber,
                    colors:
                      changeOutcome === 'positive'
                        ? negativeColors
                        : positiveColors,
                    defaultColor: '#FFFFFF',
                    gradient: false,
                  });
                } else {
                  sentimentColor = '#FFFFFF';
                }
              }
            }

            const formattedDiffFromWeightedAvg =
              diffFromWeightedAvg != null
                ? Intl.NumberFormat(locale, {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }).format(diffFromWeightedAvg)
                : null;

            const parsedValue = getMetricValue(
              cell.row.original.platform,
              cell.column.id,
              cell.row.original.metrics,
              currency
            );

            return (
              <Tooltip
                delay={0}
                isDisabled={diffFromWeightedAvg == null}
                style={{ zIndex: 100 }}
              >
                <Button
                  className="size-full items-center truncate p-2 text-left font-normal"
                  style={{
                    backgroundColor: sentimentColor,
                  }}
                >
                  {parsedValue == null ? (
                    <div className="rounded px-2.5 py-1.5">
                      {LL.insights.metrics.none()}
                    </div>
                  ) : isWizardScore && parsedValue != null ? (
                    <WizardScoreTooltip
                      score={Number(parsedValue)}
                      type={metric as InsightsFacebookWizardMetric}
                    >
                      <WizardScoreMeter
                        score={Number(parsedValue)}
                        withBackground={false}
                      />
                    </WizardScoreTooltip>
                  ) : (
                    <div
                      className="truncate rounded px-2.5 py-1.5"
                      style={{
                        background: sentimentColor,
                      }}
                      title={parsedValue ?? 'None'}
                    >
                      <span className="truncate">
                        {parsedValue ?? LL.insights.metrics.none()}
                      </span>
                    </div>
                  )}
                </Button>
                <p>
                  {diffFromWeightedAvg != null && (
                    <>
                      Diff from avg{' '}
                      <span style={{ color: sentimentColor }}>
                        {formattedDiffFromWeightedAvg}
                      </span>
                    </>
                  )}
                </p>
              </Tooltip>
            );
          },
          footer: (cell) => {
            const metric = cell.column.id;
            const sum = statistics?.[metric as keyof typeof statistics]?.sum;
            const average =
              statistics?.[metric as keyof typeof statistics]?.weighted_avg;
            const parsedSum = parseValueForMetric(
              platform,
              metric,
              sum ?? 0,
              currency ?? ''
            );
            const parsedAvg = parseValueForMetric(
              platform,
              metric,
              average ?? 0,
              currency ?? ''
            );

            const reference =
              INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM[platform][
                metric as keyof (typeof INSIGHTS_TIME_SERIES_ALL_REFERENCE_BY_PLATFORM)[typeof platform]
              ];

            return (
              <div
                style={{
                  width: `calc(var(--header-${cell.header?.id}-size) * 1px)`,
                }}
                className="flex h-14 w-full flex-col justify-center p-2"
              >
                <span>
                  {reference?.accumulation === 'sum'
                    ? (parsedSum ?? '--')
                    : '--'}
                </span>

                {!!reference?.accumulation && (
                  <span className="text-xxs text-primary">{`Weighted avg. ${parsedAvg}`}</span>
                )}
              </div>
            );
          },
        }
      );
    }),
  ];

  const table = useReactTable({
    data: ads ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting: sort,
      rowSelection,
    },
    onSortingChange,
    onRowSelectionChange: (updater) => {
      const selected =
        typeof updater === 'function' ? updater(rowSelection) : updater;

      if (Object.keys(selected).length > INSIGHTS_TABLE_SELECTION_MAX) return;

      setSelectedKeys(
        Object.entries(selected).reduce<Array<string | null>>(
          (acc, [key, val]) => {
            if (val) {
              acc.push(key === INSIGHTS_TABLE_NULL_ROW_ID ? null : key);
            }
            return acc;
          },
          []
        )
      );
    },
    getRowId: (row) =>
      row.type === 'ad' ? row.uuid : (row.group ?? INSIGHTS_TABLE_NULL_ROW_ID),
    enableMultiRowSelection: true,
    columnResizeMode: 'onChange',
    columnResizeDirection: 'ltr',
    enableSortingRemoval: false,
    initialState: {
      columnPinning: {
        left: ['adSelection', 'adName'],
      },
    },
  });

  const columnSizeVars = useMemo(() => {
    const headers = table.getFlatHeaders();
    const colSizes: { [key: string]: number } = {};
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]!;
      colSizes[`--header-${header.id}-size`] = header.getSize();
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
    }
    return colSizes;
    // We assume Mr Tanstack knows what they're doing here...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnSizingInfo, table.getState().columnSizing]);

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        setIsScrolled(containerRef.current.scrollLeft > 0);
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <ResizableTableContainer
      ref={containerRef}
      className="relative box-border w-full flex-auto overflow-auto bg-white"
    >
      {/* Underlay Table */}
      <div className="absolute flex w-full flex-col">
        <div
          className={cn(
            'sticky top-0 border-b border-solid border-b-gray-200 bg-purple-50'
          )}
          style={{ height: headerRowRef.current?.clientHeight ?? 48 }}
        />
        {ads.map((ad) => {
          const key = ad.type === 'ad' ? ad.uuid : ad.group;

          return (
            <div
              key={key}
              className="w-full border-b border-solid border-b-secondary bg-white"
              style={{ height: bodyRowRef.current?.clientHeight ?? 49 }}
            />
          );
        })}

        <div
          className="sticky bottom-0 bg-purple-50"
          style={{ height: footerRowRef.current?.clientHeight ?? 56 }}
        />
      </div>

      <InfiniteScroll
        pageStart={0}
        className="flex w-full flex-auto"
        initialLoad={false}
        loadMore={onLoadMore}
        hasMore={hasNextPage}
        useWindow={false}
        threshold={1200}
      >
        <table
          className="border-separate border-spacing-0 rounded-xl bg-white text-purple-800"
          style={{
            ...columnSizeVars, // Define column sizes on the <table> element
            width: table.getTotalSize(),
          }}
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              const headers = headerGroup.headers;
              const numberOfFixedHeaders = 2;
              const fixedHeaders = headers.slice(0, numberOfFixedHeaders);
              const restHeaders = headers.slice(numberOfFixedHeaders);

              return (
                <tr ref={headerRowRef} key={headerGroup.id} role="row">
                  {fixedHeaders.map((header, idx) => {
                    const showBorder =
                      idx === fixedHeaders.length - 1 && isScrolled;

                    return (
                      <TableHeader
                        key={header.id}
                        className={cn(
                          'bg-purple-50',
                          showBorder ? 'border-r border-r-purple-200' : ''
                        )}
                        header={header}
                        isDataGrouped={false}
                      />
                    );
                  })}

                  <SortableContext
                    items={tableMetrics}
                    strategy={horizontalListSortingStrategy}
                  >
                    {restHeaders.map((header) => {
                      return (
                        <ResizeableTableHeader
                          key={header.id}
                          header={header}
                          className="bg-purple-50"
                          isDataGrouped={false}
                        />
                      );
                    })}
                  </SortableContext>
                </tr>
              );
            })}
          </thead>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr
                  key={row.id}
                  ref={bodyRowRef}
                  className="group/ad select-none overflow-hidden text-sm font-normal text-gray-900"
                >
                  {row.getVisibleCells().map((cell, idx) => {
                    const isPinned = cell.column.getIsPinned();
                    const styles: CSSProperties = {
                      position: isPinned ? 'sticky' : 'relative',
                      width: cell.column.getSize(),
                      zIndex: isPinned ? 1 : 0,
                      left:
                        isPinned === 'left'
                          ? `${cell.column.getStart('left')}px`
                          : undefined,
                    };
                    const showBorder = idx === 1 && isScrolled;

                    return (
                      <td
                        key={cell.id}
                        className={cn(
                          'border-b border-solid border-b-purple-200 bg-white align-middle',
                          showBorder ? 'border-r border-r-purple-200' : ''
                        )}
                        style={styles}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            {isFetchingNextPage && (
              <tr className="h-[49px]">
                <td className="sticky left-0 flex items-center p-4">
                  <div className="flex gap-2">
                    <Spinner />
                    <span className="text-sm font-semibold">
                      {LL.loading()}
                    </span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => {
              const headers = footerGroup.headers;
              const first = headers[0];
              const restHeaders = headers.slice(1);
              return (
                <tr
                  key={footerGroup.id}
                  ref={footerRowRef}
                  className="sticky bottom-0 left-0 z-10"
                >
                  <DraggableTableFooterHeader header={first} />
                  {restHeaders.map((header, idx) => {
                    return (
                      <DraggableTableFooterHeader
                        key={header.id}
                        className={cn(
                          'align-top',
                          idx === 0 && isScrolled
                            ? 'border-r border-solid border-r-purple-200'
                            : ''
                        )}
                        header={header}
                      />
                    );
                  })}
                </tr>
              );
            })}
          </tfoot>
        </table>
      </InfiniteScroll>
    </ResizableTableContainer>
  );
}

interface TableMediaPreviewProps {
  ad: InsightsAdWithInsights;
  className?: string;
}

function TableMediaPreview({ ad, className }: TableMediaPreviewProps) {
  const { recordEvent } = useNewAnalyticsEvent();
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);
  const assets = ad.creative.assets ?? [];

  if (!assets.length)
    return (
      <div
        className="size-8 rounded-md"
        style={{ backgroundImage: `url(${Checkerboard})` }}
      />
    );

  const asset = assets[0];
  const creativeType = ad.creative?.creativeType;
  const uuid = ad.uuid;

  const handleOpenAdPreview = () => {
    void recordEvent({
      target: 'Insights Preview',
      action: 'Opened',
      metadata: {
        object_url: `${window.location.href}?libraryUuid=${uuid}`,
      },
    });

    setInsightsAdPreview({
      assets,
      creativeType,
      uuid,
      portalId: 'insights-ad-breakdown',
    });
  };

  return (
    <Button
      className={cn(
        'group/thumbnail relative flex aspect-square size-8 items-center rounded-md bg-gray-50 hover:cursor-zoom-in focus:outline-none',
        className
      )}
      onPress={handleOpenAdPreview}
    >
      <Image
        className="size-[inherit] rounded-md object-cover"
        src={
          asset.cachedThumbnailUrl ??
          asset.thumbnailUrl ??
          (asset.mediaType === 'image'
            ? (asset.cachedUrl ?? asset.url)
            : null) ??
          ''
        }
        fallbackSrc={asset.thumbnailUrl ?? ''}
        alt="thumbnail"
      />
    </Button>
  );
}

interface Props {
  header: Header<ArrayElement<AdBreakdownTableProps['ads']>, unknown>;
  className?: string;
  isDataGrouped: boolean;
  style?: React.CSSProperties;
}

export const ResizeableTableHeader = ({
  header,
  className,
  isDataGrouped,
}: Props) => {
  const { isDragging, setNodeRef, transform } = useSortable({
    id: header.column.id,
  });

  const { chartMetrics: graphMetrics, setChartMetrics: setGraphMetrics } =
    useInsightsAdBreakdown();

  const isPinned = header.column.getIsPinned();
  const styles: React.CSSProperties = {
    position: 'sticky',
    zIndex: isDragging ? 4 : isPinned === 'left' ? 3 : 2,
    top: 0,
    left:
      isPinned === 'left' ? `${header.column.getStart('left')}px` : undefined,
    width: `calc(var(--header-${header?.id}-size) * 1px)`,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition: 'width transform 0.2s ease-in-out',
  };

  const platform = useInsightsPlatform();
  const graphMetricIndex = graphMetrics?.findIndex(
    (metric) => metric === header.column.id
  );
  const hasGraphMetric = graphMetricIndex !== -1;
  const graphMetricOrder = hasGraphMetric ? graphMetricIndex + 1 : null;

  const format = getMetricFormat(platform, header.column.id);

  const rankedColor =
    hasGraphMetric &&
    graphMetricOrder &&
    graphMetricOrder < INSIGHTS_TABLE_SELECTION_MAX + 1 &&
    (format === 'currency' || format === 'numeric' || format === 'percentage')
      ? COLOR_RANK_SCALE[graphMetricOrder - 1]
      : undefined;

  const handleToggleSort = (val: boolean) => {
    const metricId = header.column.id;

    if (graphMetrics?.length >= 5 && val) return;

    const newChartMetrics = val
      ? [...(graphMetrics ?? []), metricId]
      : graphMetrics?.filter((x) => x !== metricId);

    setGraphMetrics(newChartMetrics);
  };

  return (
    <TableHeader
      ref={setNodeRef}
      header={header}
      isDataGrouped={isDataGrouped}
      style={styles}
      className={className}
    >
      <Checkbox
        isSelected={!!graphMetricOrder}
        onChange={handleToggleSort}
        slot="selection"
        className={composeRenderProps(className, (className) =>
          cn(
            'group/checkbox flex cursor-pointer items-center justify-center disabled:cursor-not-allowed disabled:opacity-50',
            className
          )
        )}
        isDisabled={
          !graphMetrics?.includes(header.id) &&
          graphMetrics?.length >= INSIGHTS_TABLE_SELECTION_MAX
        }
      >
        <div
          className={cn(
            /** Base */
            'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
            /** Selected */
            'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
          )}
          style={{
            backgroundColor: hasGraphMetric ? rankedColor : 'transparent',
            borderColor: hasGraphMetric ? rankedColor : 'rgb(64 34 164 / 0.5)',
          }}
        >
          <span className="hidden group-selected/checkbox:block">
            {graphMetricOrder}
          </span>
        </div>
      </Checkbox>
    </TableHeader>
  );
};
