import { camelCase } from 'lodash';

/**
 * In CSS, colons (:) are used to denote pseudo-classes. This causes issues with TanStack Table which derives its column sizing using CSS variables.
 * Since all action metrics are delimited with colons, we transform them to camel case like the rest of the metrics to avoid issues with column resizing.
 */
export function parseColumnWidthId(metric: string) {
  return metric.startsWith('action:')
    ? camelCase(metric.replace(/[:-]/g, ' '))
    : metric;
}
