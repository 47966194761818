import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import {
  Button as RACButton,
  Dialog,
  Header,
  Section,
  Separator,
  Collection,
} from 'react-aria-components';
import { AnimatePresence, motion } from 'motion/react';
import { useDebounce } from 'use-debounce';
import {
  ArrayElement,
  getIsMetricEnumerable,
  getMetricFormat,
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS_AND_SUBVALUES,
  INSIGHTS_FACEBOOK_TIME_SERIES_MULTI_OPTION_FIELDS,
  INSIGHTS_FACEBOOK_TIME_SERIES_STANDARD_METRICS,
  INSIGHTS_FACEBOOK_TIME_SERIES_TEXT_FIELDS,
  INSIGHTS_TIK_TOK_FILTERABLE_AD_ATTRIBUTES,
  INSIGHTS_TIKTOK_ALL_FRONTEND_AVAILABLE_METRICS_AND_ATTRIBUTES,
  InsightsFilter,
  isInsightsFacebookFilterTag,
} from '@magicbrief/common';
import { FuseResult } from 'fuse.js';
import { Button } from '@magicbrief/ui/src/components/button';
import { Popover } from 'src/components/Popover/AriaPopover';
import FilterLines from 'src/assets/svgicons/duotone/filter-lines.svg';
import ChevronLeft from 'src/assets/svgicons/line/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import Input from 'src/components/Input';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { cn } from 'src/lib/cn';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import {
  useInsightsDisplay,
  useInsightsFilter,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { getMetricLabelAsText } from '../../util/useParseMetric';
import {
  useInsightsMetricsList,
  useMetricFuse,
} from '../../util/useMetricSearch';
import { InsightsTagSelectionModal } from '../InsightsTagSelectionModal';
import { InsightsFilterForm } from './components/InsightsFilterMetricItemEdit';

type Props = {
  customEvents: string[] | null;
  customConversions: Array<{ facebookId: string; name: string }> | null;
};

export const InsightsFilterMetricMenu: FC<Props> = ({
  customConversions,
  customEvents,
}) => {
  const { LL } = useI18nContext();
  const metrics = useInsightsFilter();

  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <Button
        ref={triggerRef}
        variant="secondary"
        size="small"
        onPress={() => setIsOpen((s) => !s)}
      >
        <FilterLines className="size-4" />
        <span className="hidden lg:block">{LL.filters()}</span>
        {metrics.length > 0 && (
          <span className="size-5 rounded-full bg-purple-800 text-xxs font-semibold text-white">
            {metrics.length}
          </span>
        )}
      </Button>

      <Popover
        shouldCloseOnInteractOutside={(el) => {
          // Temporary workaround to stop metric value combobox interactions from closing the dialog
          return !el.closest('#metric-edit-multi-option-select-options');
        }}
        className="flex overflow-hidden will-change-contents"
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={setIsOpen}
      >
        <Dialog className="flex flex-auto outline-none">
          <InsightsFilterMetricMenuContent
            close={() => setIsOpen(false)}
            customConversions={customConversions}
            customEvents={customEvents}
          />
        </Dialog>
      </Popover>
    </>
  );
};

const BASE_FACEBOOK_FILTERS: Array<InsightsFilter['field']> = [
  'campaignName',
  'adName',
  'creativeType',
  'effectiveStatus',
  'landingPage',
];

const SUGGESTED_FACEBOOK_METRIC_FILTERS: Array<InsightsFilter['field']> = [
  'avgOrderValue',
  'addToCartToPurchaseRatio',
  'cpm',
  'conversions',
  'effectiveStatus',
  'frequency',
  'installsPerMille',
  'impressions',
  'fullViewImpressions',
  'purchasesPerMille',
  'purchaseValuePercentage',
];

const ALL_FACEBOOK_METRIC_FILTERS: Array<InsightsFilter['field']> = [
  ...SUGGESTED_FACEBOOK_METRIC_FILTERS,
  ...[
    ...INSIGHTS_FACEBOOK_TIME_SERIES_STANDARD_METRICS,
    ...INSIGHTS_FACEBOOK_TIME_SERIES_TEXT_FIELDS,
    ...INSIGHTS_FACEBOOK_TIME_SERIES_MULTI_OPTION_FIELDS,
    ...INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS_AND_SUBVALUES,
  ].filter(
    (
      x
    ): x is Exclude<
      InsightsFilter['field'],
      ArrayElement<typeof SUGGESTED_FACEBOOK_METRIC_FILTERS>
    > => !SUGGESTED_FACEBOOK_METRIC_FILTERS.some((y) => y === x)
  ),
];

const BASE_TIKTOK_FILTERS: Array<InsightsFilter['field']> = [
  'campaign_name',
  'ad_name',
  'delivery',
];

const SUGGESTED_TIKTOK_METRIC_FILTERS: Array<InsightsFilter['field']> = [
  'cpm',
  'conversion',
  'delivery',
  'frequency',
  'impressions',
  'result',
];

const ALL_TIKTOK_METRIC_FILTERS: Array<InsightsFilter['field']> = [
  ...SUGGESTED_TIKTOK_METRIC_FILTERS,
  ...Object.keys(INSIGHTS_TIK_TOK_FILTERABLE_AD_ATTRIBUTES),
  ...INSIGHTS_TIKTOK_ALL_FRONTEND_AVAILABLE_METRICS_AND_ATTRIBUTES.filter(
    (
      x
    ): x is Exclude<
      InsightsFilter['field'],
      ArrayElement<typeof SUGGESTED_TIKTOK_METRIC_FILTERS>
    > => !SUGGESTED_TIKTOK_METRIC_FILTERS.some((y) => y === x)
  ),
];

const FALLBACK_METRICS: string[] = [];

function getBaseFiltersForPlatform(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return BASE_FACEBOOK_FILTERS;
    case 'tiktok':
      return BASE_TIKTOK_FILTERS;
    default:
      return FALLBACK_METRICS;
  }
}

function getSuggestedFiltersForPlatform(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return SUGGESTED_FACEBOOK_METRIC_FILTERS;
    case 'tiktok':
      return SUGGESTED_TIKTOK_METRIC_FILTERS;
    default:
      return FALLBACK_METRICS;
  }
}

function getAllFiltersForPlatform(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return ALL_FACEBOOK_METRIC_FILTERS;
    case 'tiktok':
      return ALL_TIKTOK_METRIC_FILTERS;
    default:
      return FALLBACK_METRICS;
  }
}

type InsightsFilterMetricMenuContentState =
  | { mode: 'initial' }
  | { mode: 'performance'; viewAll: boolean }
  | {
      mode: 'search';
      query: string;
      lastState: InsightsFilterMetricMenuContentState;
    }
  | { mode: 'edit'; selectedMetric: string };

const InsightsFilterMetricMenuContent: FC<Props & { close: () => void }> = ({
  customConversions,
  customEvents,
  close,
}) => {
  const searchRef = useRef<HTMLInputElement | null>(null);
  const display = useInsightsDisplay();
  const dispatch = useInsightsStoreDispatch();
  const platform = useInsightsPlatform();
  const [view, setView] = useState<InsightsFilterMetricMenuContentState>({
    mode: 'initial',
  });
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const { recordEvent } = useNewAnalyticsEvent();

  const defaultValue =
    view.mode === 'edit'
      ? defaultValueForInsightsFilterTarget(platform, view.selectedMetric)
      : undefined;

  useEffect(() => {
    searchRef.current?.focus();
  }, []);

  const filter = useInsightsFilter();
  const selectedTags = filter.reduce<string[]>((acc, curr) => {
    if (isInsightsFacebookFilterTag(curr)) {
      for (const value of curr.values) {
        if (!acc.includes(value)) acc.push(value);
      }
    }
    return acc;
  }, []);

  return (
    <>
      <div className="relative flex flex-1 flex-col">
        {view.mode !== 'edit' && (
          <div className="z-10 bg-white p-2">
            <Input
              onChange={(ev) => {
                setView((s) => {
                  if (!ev.target.value && s.mode === 'search') {
                    return s.lastState;
                  }
                  if (s.mode === 'search') {
                    return { ...s, query: ev.target.value };
                  }
                  return {
                    mode: 'search',
                    query: ev.target.value,
                    lastState: s,
                  };
                });
              }}
              ref={searchRef}
              name="insights-filter-menu-search"
              placeholder="Search for filters..."
            />
          </div>
        )}
        <div className="relative flex min-h-0 flex-1 flex-col">
          {view.mode === 'edit' ? (
            <div className="relative w-[230px] p-3 sm:w-auto">
              <InsightsFilterForm
                platform={platform}
                customConversions={customConversions}
                customEvents={customEvents}
                onSaveComplete={(filters) => {
                  if (!filters.length) return;
                  const [filter] = filters;
                  dispatch({ type: 'addFilter', value: filter });
                  const newDisplay =
                    'field' in filter
                      ? [...new Set([...display, filter.field])]
                      : display;
                  dispatch({ type: 'setDisplay', value: newDisplay });
                  close();
                  if ('field' in filter) {
                    void recordEvent({
                      action: `Metric Added`,
                      target: 'Insights Metric',
                      metadata: {
                        metric: filter.field,
                      },
                    });
                  }
                }}
                defaultValues={defaultValue ? [defaultValue] : undefined}
              />
            </div>
          ) : view.mode === 'search' ? (
            <InsightsFilterMetricMenuContentSearchResults
              platform={platform}
              query={view.query}
              customEvents={customEvents}
              customConversions={customConversions}
              onSelect={(selectedMetric) =>
                setView({ mode: 'edit', selectedMetric })
              }
            />
          ) : (
            <AnimatePresence initial={false} mode="popLayout">
              {view.mode === 'initial' && (
                <motion.div
                  key="initial"
                  className="flex min-h-0 flex-auto flex-col"
                  transition={{ type: 'tween' }}
                  initial={{ x: -20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -20, opacity: 0 }}
                >
                  <AriaMenu.List
                    className="flex flex-1 flex-col overflow-auto"
                    onAction={(key) => {
                      if (typeof key !== 'string') return;
                      switch (key) {
                        case 'performance-metrics':
                          setView({ mode: 'performance', viewAll: false });
                          return;
                        case 'tags':
                          setIsTagModalOpen(true);
                          return;
                        default:
                          setView({ mode: 'edit', selectedMetric: key });
                          return;
                      }
                    }}
                  >
                    <Section className="p-2 pt-0">
                      {getBaseFiltersForPlatform(platform).map((x) => (
                        <AriaMenu.Item key={x} id={x}>
                          {getMetricLabelAsText(platform, x, null, null)}
                        </AriaMenu.Item>
                      ))}
                    </Section>
                    <Separator className="h-px shrink-0 bg-purple-200" />
                    <Section className="p-2">
                      <AriaMenu.Item
                        id="performance-metrics"
                        contentClassName="flex flex-row justify-between items-center flex-1"
                      >
                        <span>Performance Metrics</span>
                        <Icon className="size-4">
                          <ChevronRight />
                        </Icon>
                      </AriaMenu.Item>
                    </Section>
                    <Separator className="h-px shrink-0 bg-purple-200" />
                    <Section className="p-2 pb-0">
                      <AriaMenu.Item
                        className="mb-2"
                        contentClassName="flex flex-row justify-between items-center flex-1"
                        id="tags"
                      >
                        Tags
                      </AriaMenu.Item>
                    </Section>
                  </AriaMenu.List>
                </motion.div>
              )}

              {view.mode === 'performance' && (
                <motion.div
                  key="performance"
                  className="flex min-h-0 flex-1"
                  transition={{ type: 'tween' }}
                  initial={{ x: 20, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: 20, opacity: 0 }}
                >
                  <InsightsFilterMetricMenuContentPerformanceMetrics
                    platform={platform}
                    onBack={() => setView({ mode: 'initial' })}
                    onSelect={(selectedMetric) =>
                      setView({ mode: 'edit', selectedMetric })
                    }
                    customEvents={customEvents}
                    customConversions={customConversions}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          )}
        </div>
      </div>
      {isTagModalOpen && (
        <InsightsTagSelectionModal
          initialTagsSelected={selectedTags}
          onClose={() => {
            setIsTagModalOpen(false);
          }}
          onApply={({ selection }) => {
            const idx = filter.findIndex((x) => x.field === 'tag');

            if (selection.length === 0 && idx !== -1) {
              dispatch({
                type: 'setFilter',
                value: [...filter.slice(0, idx), ...filter.slice(idx + 1)],
              });
              return;
            }

            const tagFilter: InsightsFilter = {
              field: 'tag',
              operation: 'in',
              values: selection,
            };

            if (idx === -1) {
              dispatch({
                type: 'setFilter',
                value: [...filter, tagFilter],
              });
            } else {
              const newFilters: typeof filter = [
                ...filter.slice(0, idx),
                tagFilter,
                ...filter.slice(idx + 1),
              ];
              dispatch({ type: 'setFilter', value: newFilters });
            }
          }}
        />
      )}
    </>
  );
};

const InsightsFilterMetricMenuContentPerformanceMetrics: React.FC<
  Props & {
    onBack: () => void;
    onSelect: (metric: string) => void;
    platform: 'tiktok' | 'facebook';
  }
> = ({ customEvents, customConversions, onBack, onSelect, platform }) => {
  const [viewAll, setViewAll] = useState(false);

  const items = useInsightsMetricsList(
    viewAll
      ? {
          metrics: getAllFiltersForPlatform(platform),
          platform,
          customEvents,
          customConversions,
          preserveOrder: true,
          shouldInjectCustomEventsAndConversions: true,
        }
      : {
          metrics: getSuggestedFiltersForPlatform(platform),
          platform,
          preserveOrder: true,
          customEvents: null,
          customConversions: null,
          shouldInjectCustomEventsAndConversions: true,
        }
  );

  return (
    <div className="flex flex-1 flex-col">
      <AriaMenu.List
        className="flex min-h-0 flex-1"
        onAction={(key) => {
          if (typeof key !== 'string') return;
          onSelect(key);
        }}
      >
        <Section
          className={cn(
            'flex flex-1 flex-col overflow-auto p-2',
            viewAll ? 'pb-2' : 'pb-0'
          )}
        >
          <Header className="mb-1.5 flex flex-row items-center gap-2 px-3 text-xs font-semibold text-purple-800/50">
            <RACButton
              onPress={onBack}
              className="size-4 outline-none focus:outline-none"
            >
              <Icon className="size-4">
                <ChevronLeft />
              </Icon>
            </RACButton>
            <span>Performance Metrics</span>
          </Header>
          <Collection items={items}>
            {(x) => (
              <AriaMenu.Item className="shrink-0" id={x.metric}>
                {x.label}
              </AriaMenu.Item>
            )}
          </Collection>
        </Section>
      </AriaMenu.List>
      {!viewAll && (
        <div className="shrink-0 px-2.5 py-2">
          <AriaButton
            size="small"
            className="w-full"
            variant="white"
            onPress={() => setViewAll(true)}
          >
            Show all metrics
          </AriaButton>
        </div>
      )}
    </div>
  );
};

const InsightsFilterMetricMenuContentSearchResults: React.FC<
  Props & {
    platform: 'tiktok' | 'facebook';
    onSelect: (metric: string) => void;
    query: string;
  }
> = ({ customEvents, customConversions, onSelect, query, platform }) => {
  const [debouncedQuery] = useDebounce(query, 150, {
    leading: true,
  });

  const searchableItems = useInsightsMetricsList({
    metrics: getAllFiltersForPlatform(platform),
    platform,
    customEvents,
    customConversions,
    shouldInjectCustomEventsAndConversions: true,
  });

  const fuse = useMetricFuse(searchableItems);

  const results = useMemo(
    () => fuse.search(debouncedQuery, { limit: 50 }),
    [fuse, debouncedQuery]
  );

  return (
    <div className="flex min-h-0 flex-1 flex-col">
      <AriaMenu.List
        className="flex min-h-0 flex-1"
        onAction={(key) => {
          if (typeof key !== 'string') return;
          onSelect(key);
        }}
      >
        <InsightsFilterMetricMenuContentSearchResultsList results={results} />
      </AriaMenu.List>

      {!results?.length && (
        <p className="p-2 pt-0 text-xs text-purple-800/50">
          No metrics match your search query
        </p>
      )}
    </div>
  );
};

const InsightsFilterMetricMenuContentSearchResultsList: React.FC<{
  results: FuseResult<
    ArrayElement<ReturnType<typeof useInsightsMetricsList>>
  >[];
}> = ({ results }) => {
  return (
    <Section className="flex flex-1 flex-col overflow-auto p-2 pt-0">
      <Collection items={results}>
        {(x) => (
          <AriaMenu.Item className="shrink-0" id={x.item.metric}>
            {x.item.label}
          </AriaMenu.Item>
        )}
      </Collection>
    </Section>
  );
};

function defaultValueForInsightsFilterTarget(
  platform: 'facebook' | 'tiktok',
  metric: string
): InsightsFilter | undefined {
  const enumerable = getIsMetricEnumerable(platform, metric);
  if (enumerable) {
    return {
      field: metric,
      operation: 'in',
      values: [],
    };
  }

  const format = getMetricFormat(platform, metric);

  switch (format) {
    case 'currency':
    case 'numeric':
    case 'percentage':
      return {
        field: metric,
        operation: '=',
        value: undefined as unknown as number,
      };
    case 'text':
      return {
        field: metric,
        operation: 'contains',
        value: '',
      };
    case 'date':
      return {
        field: metric,
        operation: '=',
        value: undefined as unknown as Date,
      };
    default: {
      format satisfies never;
    }
  }
}
