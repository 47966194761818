import {
  NumberField as AriaNumberField,
  type NumberFieldProps as AriaNumberFieldProps,
} from 'react-aria-components';

import { cn } from '../lib/cn';

export const NumberField = (props: AriaNumberFieldProps) => {
  return (
    <AriaNumberField
      {...props}
      className={cn('flex flex-col gap-1.5', props.className)}
    />
  );
};
