import { captureException } from '@sentry/react';
import { useTypesafeSearchParams } from '../../../utils/useTypesafeSearchParams';

type YouTubeSearchParams = {
  code: string[];
  state: string[];
};

export const defaultYouTubeSearchParams: YouTubeSearchParams = {
  code: [],
  state: [],
};

export function useYouTubeSearchParams() {
  const search = useTypesafeSearchParams<YouTubeSearchParams>(
    defaultYouTubeSearchParams
  );

  const getCode = () => {
    const code = search.get('code')?.[0];
    if (code == null) {
      captureException(new Error('No YouTube code in URL'), (scope) => {
        scope.setTransactionName('youTube->oauthExchange');
        return scope;
      });
    }
    return code;
  };

  const getState = () => search.get('state')?.[0];
  const deleteCode = () => search.deleteByKey('code');
  const deleteState = () => search.deleteByKey('state');

  return {
    getCode,
    getState,
    deleteCode,
    deleteState,
  };
}
