import { useAtomValue, useSetAtom } from 'jotai';
import { Button } from 'react-aria-components';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import { Spinner } from '@magicbrief/ui/src/components/spinner';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { useInsightsStoreDispatch } from 'src/pages/Insights/util/useInsightsPersistentState';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import SlashCircle01 from 'src/assets/svgicons/solid/slash-circle-01.svg';
import checkerboard from 'src/assets/imgs/checker.jpeg';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { insightsAdPreviewAtom } from 'src/pages/Insights/Insights.atoms';
import type { InsightsCheckboxStyles } from 'src/pages/Insights/util/constants';
import type { TickRendererProps } from '@visx/axis';
import type {
  InsightsAdGroupWithInsights,
  InsightsAdWithInsights,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

interface InsightsChartTickProps {
  id: string;
  sortedData: Array<InsightsAdWithInsights | InsightsAdGroupWithInsights>;
  ad: InsightsAdWithInsights | InsightsAdGroupWithInsights;
  styles: InsightsCheckboxStyles;
  tickRendererProps: TickRendererProps;
  adPreviewPortalId?: string;
}

export const InsightsChartTick = ({
  id,
  sortedData,
  ad,
  styles,
  tickRendererProps,
  adPreviewPortalId,
}: InsightsChartTickProps) => {
  const { recordEvent: newUserEvent } = useNewAnalyticsEvent();
  const dispatch = useInsightsStoreDispatch();

  const hasInsightsAdPreview = useAtomValue(insightsAdPreviewAtom);
  const setInsightsAdPreview = useSetAtom(insightsAdPreviewAtom);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { x, y, formattedValue, ...rest } = tickRendererProps;

  if (!ad) {
    return null;
  }

  const label = ad.name;
  const asset =
    ad.type === 'ad' ? ad.creative?.assets?.[0] : (ad.assets?.[0] ?? null);

  const imageUrl =
    asset?.cachedThumbnailUrl ??
    (asset?.mediaType === 'image'
      ? (asset.cachedUrl ?? asset.url)
      : undefined) ??
    asset?.thumbnailUrl ??
    undefined;

  const assetState =
    ad.type === 'group'
      ? ad.assets?.[0]?.assetState
      : ad.creative?.assets?.[0]?.assetState;

  return (
    <g transform={`translate(${x}, ${y})`} className="group">
      <g
        onClick={async () => {
          const assets =
            ad.type === 'group' ? ad.assets : (ad.creative?.assets ?? []);
          const creativeType =
            ad.type === 'group' ? 'unknown' : ad.creative?.creativeType;
          const uuid = ad.type === 'group' ? ad.ads?.[0]?.uuid : ad.uuid;

          void newUserEvent({
            target: 'Insights Preview',
            action: 'Opened',
            metadata: {
              object_url: `${window.location.href}?libraryUuid=${uuid}`,
            },
          });

          if (hasInsightsAdPreview) {
            /**
             * If the user is already previewing another ad, this ensures the newly selected ad
             * is rendered as the video element remains mounted in memory even if the underlying ad is changed.
             *
             * The recommendation from the HTML Living Standard is to release the resource by
             * resetting the media element's src attribute.
             *
             * @link https://html.spec.whatwg.org/multipage/media.html#best-practices-for-authors-using-media-elements
             */
            const videoPreviewEl = document.getElementById(
              'insights-video-preview'
            ) as HTMLVideoElement;

            if (videoPreviewEl != null) {
              videoPreviewEl?.pause();
              videoPreviewEl?.removeAttribute('src');
              videoPreviewEl?.load();
            }

            setInsightsAdPreview({
              assets,
              creativeType,
              uuid,
              portalId: adPreviewPortalId,
            });
          } else {
            setInsightsAdPreview({
              assets,
              creativeType,
              uuid,
              portalId: adPreviewPortalId,
            });
          }
        }}
      >
        <defs>
          <mask id={`roundedMask-${id}`}>
            {/* The rectangle that defines the rounded corners for the mask */}
            <rect
              x={-72 / 2}
              width={72}
              height={72}
              rx="8"
              ry="8"
              fill="white"
            />
          </mask>

          <pattern
            id={`checkerboard-${id}`}
            patternUnits="userSpaceOnUse"
            width={10}
            height={10}
          >
            <image href={checkerboard} width={10} height={10} />
          </pattern>
        </defs>

        <ChartTickThumbnail
          id={id}
          assetState={assetState ?? 'completed'}
          imageUrl={imageUrl}
          styles={styles}
        />

        {/* Create the border using a <rect> element */}
        <rect
          x={-72 / 2}
          width={72}
          height={72}
          rx="8"
          ry="8"
          fill="none"
          className={styles?.stroke}
          strokeWidth="2"
        />
        <text
          {...rest}
          width={135}
          y={72 + 18}
          fontSize="12px"
          fontFamily="Inter"
          fill="#101828"
          className="hidden md:block"
          fontStyle={!label ? 'italic' : undefined}
          opacity={!label ? 0.8 : 1}
        >
          {label && label.length > 17
            ? `${label.slice(0, 10)}...`
            : label || 'None'}
        </text>
      </g>
      <foreignObject width="16" height="16" y={-6} x={28}>
        <button
          className="rounded-full border border-solid border-purple-300 bg-white opacity-0 transition-all duration-300 group-hover:border-purple-300 group-hover:opacity-100"
          onClick={() => {
            const idx = sortedData.findIndex(
              (x) =>
                (x.type === 'ad' ? x.uuid : x.group) ===
                (ad.type === 'ad' ? ad.uuid : ad.group)
            );
            if (idx !== -1) {
              dispatch({
                type: 'setSelected',
                value: [
                  ...sortedData
                    .slice(0, idx)
                    .map((x) => (x.type === 'ad' ? x.uuid : x.group)),
                  ...sortedData
                    .slice(idx + 1)
                    .map((x) => (x.type === 'ad' ? x.uuid : x.group)),
                ],
              });
            }
          }}
        >
          <XClose className="size-3 text-primary" />
        </button>
      </foreignObject>
    </g>
  );
};

interface ChartTickThumbnailProps {
  id: string;
  assetState: string;
  imageUrl?: string;
  styles: InsightsCheckboxStyles;
}

const ChartTickThumbnail = ({
  id,
  assetState,
  imageUrl,
  styles,
}: ChartTickThumbnailProps) => {
  switch (assetState) {
    case 'initial':
    case 'processing': {
      return (
        <foreignObject
          width={72}
          height={72}
          x={-72 / 2}
          className="bg-gray-50"
        >
          <TooltipTrigger delay={200} closeDelay={0}>
            <Button className="flex size-full items-center justify-center">
              <Spinner className="size-8 text-gray-700/50" />
            </Button>
            <Tooltip>
              <div className="flex flex-col">
                <span className="text-xs">Syncing ad content</span>
                <span className="text-xs text-white/75">
                  Please check back shortly
                </span>
              </div>
            </Tooltip>
          </TooltipTrigger>
        </foreignObject>
      );
    }

    case 'recoverable_error':
    case 'unrecoverable_error':
    case 'not_found': {
      const getLabel = () => {
        switch (assetState) {
          case 'recoverable_error':
            return 'We had issues loading this media, please attempt another sync';
          case 'unrecoverable_error':
          case 'not_found':
            return 'This media is no longer available on facebook';

          default: {
            const _exhaustiveCheck: never = assetState;
            throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
          }
        }
      };

      return (
        <foreignObject
          width={72}
          height={72}
          x={-72 / 2}
          className="bg-gray-50"
        >
          <TooltipTrigger delay={200} closeDelay={0}>
            <Button className="flex size-full items-center justify-center">
              <SlashCircle01 className="size-8 text-gray-700/50" />
            </Button>
            <Tooltip>
              <div className="flex flex-col">
                <span className="text-xs">Preview not available</span>
                <span className="text-xs text-white/75">{getLabel()}</span>
              </div>
            </Tooltip>
          </TooltipTrigger>
        </foreignObject>
      );
    }

    case 'page_access_error': {
      return (
        <foreignObject
          width={72}
          height={72}
          x={-72 / 2}
          className="bg-gray-50"
        >
          <TooltipTrigger delay={200} closeDelay={0}>
            <Button className="flex size-full items-center justify-center">
              <AlertTriangle className="size-8 text-gray-700/50" />
            </Button>
            <Tooltip>
              <div className="flex flex-col">
                <span className="text-xs">
                  MagicBrief needs permission to view this asset
                </span>
                <span className="text-xs text-white/75">
                  Please connect the Facebook page or Instagram account running
                  this ad
                </span>
              </div>
            </Tooltip>
          </TooltipTrigger>
        </foreignObject>
      );
    }

    case 'ready':
    case 'completed': {
      if (!imageUrl) {
        return (
          <rect
            width={72}
            height={72}
            x={-72 / 2}
            fill={`url(#checkerboard-${id})`}
            mask={`url(#roundedMask-${id})`}
          />
        );
      }

      return (
        <>
          <image
            mask={`url(#roundedMask-${id})`}
            className="cursor-zoom-in transition-all hover:opacity-80"
            width={72}
            height={72}
            x={-72 / 2}
            preserveAspectRatio="xMidYMid slice"
            href={imageUrl}
          />
          <rect
            x={-72 / 2}
            width={72}
            height={72}
            rx="8"
            ry="8"
            fill="none"
            className={styles?.stroke}
            strokeWidth="2"
          />
        </>
      );
    }

    default: {
      const _exhaustiveCheck = assetState as never;
      throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
    }
  }
};
