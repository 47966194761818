import { LoaderFunctionArgs } from 'react-router-dom';
import { getQueryKey, TRPCClientError } from '@trpc/react-query';
import { toast } from 'react-toastify';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import { GetAdAccountResponse } from 'src/types/insights';
import queryClient from 'src/lib/queryClient';
import { auth } from 'src/lib/firebase';
import { localStorage } from 'src/utils/storage';
import { INSIGHTS_PAGE_TAKE_LIMIT } from '../../util/constants';
import {
  createInsightsDefaultPersistentState,
  createInsightsStandardStorage,
} from '../../util/useInsightsPersistentState';
import { getInsightsSearchParams } from '../../util/useInsightsSearchParams';

export type InsightsAccountRouteLoaderData = Awaited<
  ReturnType<typeof insightsAccountRouteLoader>
>;

export async function insightsAccountRouteLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  const accountUuid = params.accountUuid ?? '';

  await auth.authStateReady();

  let account: GetAdAccountResponse | undefined;
  try {
    account = await queryClient.ensureQueryData<GetAdAccountResponse>(
      getQueryKey(
        trpc.insightsAccounts.getAdAccount,
        { uuid: accountUuid },
        'query'
      ),
      async () => {
        const data = await trpcProxyClient.insightsAccounts.getAdAccount.query({
          uuid: accountUuid,
        });

        return data;
      }
    );
  } catch (error) {
    // Catch workspace access errors early
    if (
      error instanceof TRPCClientError &&
      error != null &&
      'cause' in error.data &&
      'action' in error.data.cause
    ) {
      return { error };
    }

    if (
      error instanceof TRPCClientError &&
      error.data?.code === 'NOT_FOUND' &&
      localStorage.getItem('insightsLastViewedAccount')
    ) {
      localStorage.removeItem('insightsLastViewedAccount');
      window.location.href = '/insights/accounts';
      toast.error('An unexpected error occurred while getting the ad account');
      return null;
    }
  }

  if (!account) return null;

  const state = createInsightsStandardStorage(account.platform).getItem(
    `insights_account_${accountUuid}_overview`,
    createInsightsDefaultPersistentState(account.platform)
  );

  const queryParams = getInsightsSearchParams(
    new URL(request.url).searchParams
  );

  const forTimePeriod =
    queryParams.timePeriod === 'custom'
      ? {
          datePreset: 'custom' as const,
          since: queryParams.since ?? '',
          until: queryParams.until ?? '',
        }
      : {
          datePreset: queryParams.timePeriod,
        };

  const getAdsQuery = {
    accountUuid: params.accountUuid ?? '',
    group: state.group ?? undefined,
    attributionWindow: queryParams.attributionWindow ?? undefined,
    forTimePeriod,
    filter: state.filter,
    sort: state.sort,
    ungroup: undefined,
    count: INSIGHTS_PAGE_TAKE_LIMIT,
    cursor: undefined,
  } as const;

  const adsQueryKey = getQueryKey(
    trpc.insightsAds.getManyAds,
    getAdsQuery,
    'infinite'
  );

  const statisticsQuery = {
    accountUuid: params.accountUuid ?? '',
    group: state.group ?? undefined,
    attributionWindow: queryParams.attributionWindow ?? undefined,
    forTimePeriod,
    filter: state.filter,
    ungroup: undefined,
  } as const;

  const statisticsQueryKey = getQueryKey(
    trpc.insightsAds.getStatisticsForAds,
    statisticsQuery,
    'query'
  );

  void queryClient.prefetchInfiniteQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: adsQueryKey,
    queryFn: async () => {
      const ads = await trpcProxyClient.insightsAds.getManyAds.query(
        getAdsQuery,
        {
          context: { skipBatch: true },
        }
      );

      for (const ad of ads) {
        if (ad.type === 'ad') {
          queryClient.setQueryData(
            getQueryKey(
              trpc.insightsAds.getAd,
              {
                attributionWindow: queryParams.attributionWindow,
                forTimePeriod,
                uuid: ad.uuid,
              },
              'query'
            ),
            ad
          );
        } else if (getAdsQuery.group) {
          queryClient.setQueryData(
            getQueryKey(
              trpc.insightsAds.getAdGroup,
              {
                attributionWindow: queryParams.attributionWindow,
                forTimePeriod,
                name: ad.group,
                group: getAdsQuery.group,
                accountUuid: getAdsQuery.accountUuid,
              },
              'query'
            ),
            ad
          );
        }
      }
      return ads;
    },
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: statisticsQueryKey,
    queryFn: async () =>
      trpcProxyClient.insightsAds.getStatisticsForAds.query(statisticsQuery, {
        context: { skipBatch: true },
      }),
  });

  if (Array.isArray(state.selected)) {
    if (getAdsQuery.group) {
      void queryClient.prefetchQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: getQueryKey(
          trpc.insightsAds.getManyAdGroups,
          {
            forTimePeriod,
            attributionWindow: queryParams.attributionWindow,
            names: state.selected,
            group: getAdsQuery.group,
            accountUuid: getAdsQuery.accountUuid,
          },
          'query'
        ),
        queryFn: async () => {
          const groups =
            await trpcProxyClient.insightsAds.getManyAdGroups.query({
              forTimePeriod,
              attributionWindow: queryParams.attributionWindow,
              names: state.selected as Array<string>,
              group: getAdsQuery.group ?? 'adName',
              accountUuid: getAdsQuery.accountUuid,
            });

          for (const group of groups) {
            queryClient.setQueryData(
              getQueryKey(
                trpc.insightsAds.getAdGroup,
                {
                  attributionWindow: queryParams.attributionWindow,
                  forTimePeriod,
                  name: group.group,
                  group: getAdsQuery.group,
                  accountUuid: getAdsQuery.accountUuid,
                },
                'query'
              ),
              group
            );
          }

          return groups;
        },
      });
    } else {
      for (const selected of state.selected) {
        const adQueryParams = {
          forTimePeriod,
          attributionWindow: queryParams.attributionWindow,
          uuid: selected!,
        } as const;
        void queryClient.prefetchQuery({
          // eslint-disable-next-line @tanstack/query/exhaustive-deps
          queryKey: getQueryKey(trpc.insightsAds.getAd, adQueryParams, 'query'),
          queryFn: async () =>
            trpcProxyClient.insightsAds.getAd.query(adQueryParams),
        });
      }
    }
  }

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insightsAccounts.getAdAccount,
      { uuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insightsAccounts.getAdAccount.query(
        {
          uuid: accountUuid,
        },
        { context: { skipBatch: true } }
      ),
  });

  void queryClient.prefetchQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: getQueryKey(
      trpc.insightsTags.getTags,
      { insightsAdAccountFacebookUuid: accountUuid },
      'query'
    ),
    queryFn: async () =>
      trpcProxyClient.insightsTags.getTags.query(
        { insightsAdAccountFacebookUuid: accountUuid },
        {
          context: { skipBatch: true },
        }
      ),
  });

  return account;
}
