import { cn } from '../lib/cn';

interface Props {
  className?: string;
}

export const ChevronDown = ({ className }: Props) => {
  return (
    <svg
      className={cn('size-6', className)}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 9L12 15L18 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
