import { LoaderFunctionArgs, redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from 'src/lib/firebase';
import { trpcProxyClient } from 'src/lib/trpc';

export async function googleYoutubeOAuthLoader({
  request,
}: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const codeParam = url.searchParams.get('code');

  if (codeParam == null) return null;

  await auth.authStateReady();

  try {
    const data =
      await trpcProxyClient.youtube.getAvailableAccountsForCode.mutate({
        code: codeParam,
      });

    const authState = {
      status: 'accountSelect',
      customers: data.companies,
      youtubeIntegrationID: data.youtubeID,
    };

    return authState;
  } catch (error) {
    toast.error('Failed to connect accounts. Please', {
      className: 'toast-danger',
    });
    redirect('/settings/integrations');
    return null;
  }
}
