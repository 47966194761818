import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { User } from 'firebase/auth';
import { RouterProvider } from 'react-aria-components';
import { auth } from 'src/lib/firebase';
import { AppWithNavLoader } from 'src/components/SuspenseLoaders/SuspenseLoaders';
import { getFeatureFlagValue } from 'src/utils/getFeatureFlagValue';
import { FirebaseContext, FirebaseDispatchContext } from './context';

const FirebaseOutlet: React.FunctionComponent = (): JSX.Element => {
  const [firebaseUser, setFirebaseUser] = useState<User | null | undefined>(
    undefined
  );
  const navigate = useNavigate();
  const refreshUser = useCallback(async () => {
    await auth.currentUser?.reload();
    const currentUser = auth.currentUser;
    setFirebaseUser(currentUser ? { ...currentUser } : currentUser);
    return currentUser;
  }, []);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (user) => {
      if (user) {
        Sentry.setUser({
          id: user.uid,
          email: user.email ?? undefined,
          username: user.displayName ?? undefined,
        });

        // Boot Pylon
        if (user.email && user.displayName) {
          window.pylon = {
            chat_settings: {
              app_id: getFeatureFlagValue('PYLON_APP_ID'),
              email: user.email,
              name: user.displayName,
            },
          };
          window.Pylon('showChatBubble');
        }
      }
      setFirebaseUser(user ? { ...user } : user);
    });
    return unsub;
  }, []);

  return (
    <RouterProvider navigate={navigate}>
      <FirebaseContext.Provider value={firebaseUser ?? null}>
        <FirebaseDispatchContext.Provider value={{ refreshUser }}>
          {firebaseUser === undefined ? <AppWithNavLoader /> : <Outlet />}
        </FirebaseDispatchContext.Provider>
      </FirebaseContext.Provider>
    </RouterProvider>
  );
};

export default FirebaseOutlet;
