import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import '../src/styles/reset.css';
import '../src/styles/index.css';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import '@magicbrief/prisma/src/jsonTypes';
import { MagicBriefErrorBoundary } from './lib/MagicBriefErrorBoundary';
import SEO from './components/SEO/SEO';
import { trpc, trpcReactClient } from './lib/trpc';
import queryClient from './lib/queryClient';
import TypesafeI18nContext from './contexts/TypesafeI18nContext';
import { appConfigs } from './configs';
import ToastWrapper from './components/Utils/ToastWrapper';
import { initSentry } from './lib/sentry';
import { initAnalytics } from './lib/segment';
import { analytics } from './lib/gtm';
import { setAttributionData } from './lib/attribution';

const QUERY_DEVTOOLS_ENABLED = appConfigs.env === 'development' && false;

void setAttributionData();
void initAnalytics();
analytics();
initSentry();

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <StrictMode>
    <HelmetProvider>
      <SEO
        title="MagicBrief"
        name="MagicBrief"
        description="Create storyboard briefs in minutes. Upload your assets or pull together references to make the process of building performance assets magical."
      />
      <MagicBriefErrorBoundary>
        <trpc.Provider client={trpcReactClient} queryClient={queryClient}>
          <QueryClientProvider client={queryClient}>
            <TypesafeI18nContext>
              <ToastWrapper />
              <App />
              {QUERY_DEVTOOLS_ENABLED && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </TypesafeI18nContext>
          </QueryClientProvider>
        </trpc.Provider>
      </MagicBriefErrorBoundary>
    </HelmetProvider>
  </StrictMode>
);
