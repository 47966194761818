import { getQueryKey } from '@trpc/react-query';
import { redirect, type LoaderFunctionArgs } from 'react-router-dom';
import { toast } from 'react-toastify';
import queryClient from 'src/lib/queryClient';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import { auth } from 'src/lib/firebase';
import type { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';

export async function sharedInsightsAdRouteLoader({
  params,
  request,
}: LoaderFunctionArgs) {
  if (!params.insightsOrganisationAdFacebookUuid) {
    redirect('/insights');
  }

  try {
    const url = new URL(request.url);

    const datePreset = url.searchParams.get('timePeriod') ?? 'last_14d';
    const since = url.searchParams.get('since') ?? null;
    const until = url.searchParams.get('until') ?? null;
    const attributionWindow =
      url.searchParams.get('attributionWindow') ?? 'default';

    const forTimePeriod = (
      datePreset === 'custom'
        ? {
            datePreset: 'custom',
            since,
            until,
          }
        : { datePreset }
    ) as ForTimePeriodInput;

    await auth.authStateReady();

    const ad = await queryClient.ensureQueryData(
      getQueryKey(
        trpc.insightsAds.getAdByOrganisationAdUuid,
        {
          uuid: params.insightsOrganisationAdFacebookUuid ?? '',
          forTimePeriod,
          attributionWindow: attributionWindow ?? undefined,
        },
        'query'
      ),
      async () => {
        const data =
          await trpcProxyClient.insightsAds.getAdByOrganisationAdUuid.query({
            uuid: params.insightsOrganisationAdFacebookUuid ?? '',
            forTimePeriod,
            attributionWindow: attributionWindow ?? undefined,
          });

        return data;
      }
    );

    if (ad.role === 'owner') {
      const url = new URL(
        `${window.location.origin}/insights/accounts/${ad.accountUuid}`
      );

      url.searchParams.set('insightsUuid', ad.uuid);

      const path = url.toString().replace(window.location.origin, '');

      redirect(path);
    }

    return ad;
  } catch (error) {
    toast.error(
      error instanceof Error
        ? error.message
        : 'Error occured while fetching shared ad',
      {
        className: 'toast-danger',
      }
    );
    redirect('/insights');
  }

  return null;
}
