import {
  type DeploymentEnvironment,
  getFeatureFlagValue,
} from '@magicbrief/common';
import { getQueryKey, TRPCClientError } from '@trpc/react-query';
import { LoaderFunctionArgs } from 'react-router-dom';
import { toast } from 'react-toastify';
import { auth } from 'src/lib/firebase';
import queryClient from 'src/lib/queryClient';
import { trpc, trpcProxyClient } from 'src/lib/trpc';
import { GetAdAccountResponse } from 'src/types/insights';

export async function insightsDashboardRouteLoader({
  params,
}: LoaderFunctionArgs) {
  const { accountUuid = '' } = params;

  await auth.authStateReady();

  let account: GetAdAccountResponse | undefined;
  try {
    account = await queryClient.ensureQueryData<GetAdAccountResponse>(
      getQueryKey(
        trpc.insightsAccounts.getAdAccount,
        { uuid: accountUuid },
        'query'
      ),
      async () => {
        const data = await trpcProxyClient.insightsAccounts.getAdAccount.query({
          uuid: accountUuid,
        });

        return data;
      }
    );
  } catch (error) {
    if (
      error instanceof TRPCClientError &&
      error.data?.code === 'NOT_FOUND' &&
      localStorage.getItem('insightsLastViewedAccount')
    ) {
      localStorage.removeItem('insightsLastViewedAccount');
      window.location.href = '/insights/accounts';
    }
    toast.error('An unexpected error occurred while getting the ad account');
    return null;
  }

  const isDashboardEnabled =
    getFeatureFlagValue(
      'INSIGHTS_DASHBOARD_ENABLED',
      import.meta.env.VITE_DEPLOYMENT as DeploymentEnvironment
    ) && account.platform === 'facebook';

  if (isDashboardEnabled) {
    void queryClient.prefetchQuery({
      queryKey: getQueryKey(
        trpc.insightsDashboard.getPerformanceForInsightsAdAccount,
        { adAccountUuid: accountUuid },
        'query'
      ),
      queryFn: async () =>
        trpcProxyClient.insightsDashboard.getPerformanceForInsightsAdAccount.query(
          {
            adAccountUuid: accountUuid,
            timePeriod: null,
          },
          {
            context: { skipBatch: true },
          }
        ),
    });

    void queryClient.prefetchQuery({
      queryKey: getQueryKey(
        trpc.insightsDashboard.getWinnersForInsightsAdAccount,
        { adAccountUuid: accountUuid },
        'query'
      ),
      queryFn: async () =>
        trpcProxyClient.insightsDashboard.getWinnersForInsightsAdAccount.query(
          {
            adAccountUuid: accountUuid,
          },
          {
            context: { skipBatch: true },
          }
        ),
    });

    void queryClient.prefetchQuery({
      queryKey: getQueryKey(
        trpc.insightsDashboard.getRecommendationsForInsightsAdAccount,
        { adAccountUuid: accountUuid },
        'query'
      ),
      queryFn: async () =>
        trpcProxyClient.insightsDashboard.getRecommendationsForInsightsAdAccount.query(
          {
            adAccountUuid: accountUuid,
          },
          {
            context: { skipBatch: true },
          }
        ),
    });
  }

  return account;
}
