import { useState } from 'react';
import {
  InsightsAdWithInsights,
  InsightsAdGroupWithInsights,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { useMatch, useParams } from 'react-router-dom';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { getMetricFormat } from '@magicbrief/common';
import { Button } from '@magicbrief/ui/src/components/button';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import { useInsightsPlatform } from 'src/pages/Insights/util/useInsightsPersistentState';
import { getMetricLabelAsText } from 'src/pages/Insights/util/useParseMetric';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import Plus from 'src/assets/svgicons/line/plus.svg';
import { GetFacebookAdAccountResponse } from 'src/types/insights';
import { InsightsGraphMetricItem } from '../../InsightsFilter/InsightsFilterGraphMetrics';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../../const';
import { InsightsComparisonBarChart } from '../../InsightsComparisonBarChart/InsightsComparisonBarChart';
import { useInsightsAdBreakdown } from '../useInsightsAdBreakdown';
import { AdBreakdownMetricModal } from './AdBreakdownMetricModal';

interface AdBreakdownTableProps {
  ads: (InsightsAdWithInsights | InsightsAdGroupWithInsights)[];
  currency: GetFacebookAdAccountResponse['currency'];
  isLoading: boolean;
}

export const AdBreakdownChart = ({
  ads,
  currency,
  isLoading,
}: AdBreakdownTableProps) => {
  const { accountUuid } = useParams();
  const platform = useInsightsPlatform();
  const { selectedKeys, chartMetrics, setChartMetrics } =
    useInsightsAdBreakdown();
  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({ accountUuid });

  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  const [isOpen, setIsOpen] = useState(false);

  let coloredIndex = 0;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over) {
      const oldIndex = chartMetrics.findIndex((item) => item === active.id);
      const newIndex = chartMetrics.findIndex((item) => item === over.id);
      const newSortOrder = arrayMove(chartMetrics, oldIndex, newIndex);
      setChartMetrics(newSortOrder);
    }
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  return (
    <div>
      <div className="flex flex-row flex-wrap gap-2.5 rounded-t-xl border border-solid border-purple-200 bg-purple-50 p-3">
        <DndContext
          collisionDetection={closestCenter}
          modifiers={[restrictToHorizontalAxis]}
          onDragEnd={handleDragEnd}
          sensors={sensors}
        >
          <SortableContext
            items={chartMetrics}
            strategy={horizontalListSortingStrategy}
          >
            {chartMetrics?.map((sortKey, index) => {
              const label = getMetricLabelAsText(
                platform,
                sortKey,
                accountCustomEvents,
                accountCustomConversions
              );
              const format = getMetricFormat(platform, sortKey);

              if (label == null) {
                return null;
              }

              const rankedColor =
                coloredIndex < INSIGHTS_TABLE_SELECTION_MAX &&
                (format === 'currency' ||
                  format === 'numeric' ||
                  format === 'percentage')
                  ? COLOR_RANK_SCALE[coloredIndex]
                  : undefined;

              if (rankedColor) coloredIndex++;

              return (
                <InsightsGraphMetricItem
                  id={sortKey}
                  key={sortKey}
                  desc={true}
                  position={index + 1}
                  color={rankedColor}
                  customConversions={accountCustomConversions ?? []}
                  customEvents={accountCustomEvents ?? []}
                  onRemove={() => {
                    setChartMetrics(
                      chartMetrics.filter((item) => item !== sortKey)
                    );
                  }}
                />
              );
            })}
          </SortableContext>
        </DndContext>
        {!isSharedReport && !isSharedComparisonReport && (
          <>
            <Button
              variant="secondary"
              className="h-8 gap-0.5 rounded-md p-1.5 pr-2 text-xs"
              onPress={() => setIsOpen(true)}
              isDisabled={chartMetrics.length >= 5}
            >
              <Plus className="size-4" />
              Add Metric
            </Button>

            <AdBreakdownMetricModal
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isGraphMetrics
            />
          </>
        )}
      </div>

      <InsightsComparisonBarChart
        data={
          ads.filter((ad) =>
            selectedKeys?.includes(ad.type === 'ad' ? ad.uuid : ad.group)
          ) as (InsightsAdWithInsights | InsightsAdGroupWithInsights)[]
        }
        currency={currency}
        customEvents={accountCustomEvents}
        customConversions={accountCustomConversions}
        isFetching={isLoading}
        keys={chartMetrics}
        selectionOrder={selectedKeys?.filter(
          (x): x is Exclude<typeof x, null> => !!x
        )}
        adPreviewPortalId="insights-ad-breakdown"
      />
    </div>
  );
};
