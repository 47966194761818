import { FC } from 'react';
import { uuidv4 } from '@magicbrief/common';
import { Button } from 'react-aria-components';
import { useMatch } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { trpc } from 'src/lib/trpc';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Hash01 from 'src/assets/svgicons/line/hash-01.svg';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import { Icon } from 'src/components/Icon';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { DialogPopover } from '../../../../components/DialogPopover/DialogPopover';
import {
  useInsightsDisplay,
  useInsightsFilter,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import {
  getMetricFilterValuesAsText,
  useParseMetric,
} from '../../util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { InsightsFilterForm } from './components/InsightsFilterMetricItemEdit';

type Props = {
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
  className?: string;
};

const InsightsFilterMetricList: FC<Props> = ({
  customEvents,
  customConversions,
  className,
}) => {
  const { accountUuid } = useParams();
  const { LL } = useI18nContext();
  const platform = useInsightsPlatform();
  const display = useInsightsDisplay();
  const metrics = useInsightsFilter();
  const dispatch = useInsightsStoreDispatch();
  const { getMetricLabelAsText } = useParseMetric();
  const { recordEvent } = useNewAnalyticsEvent();

  const tags = trpc.insightsTags.getTags.useQuery({
    insightsAdAccountFacebookUuid: accountUuid ?? '',
  });
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');

  if (metrics != null && metrics.length === 0) {
    return null;
  }

  return (
    <div className={cn('flex flex-col gap-2.5', className)}>
      {metrics.length > 0 && (
        <div className="flex flex-row flex-wrap justify-start gap-2.5">
          {metrics.map((filter, index) => {
            if (!('field' in filter)) {
              return null;
            }

            const key = uuidv4();

            if (filter.field === 'tag') {
              if (filter.operation === 'in') {
                return (
                  <>
                    {filter.values.map((x, i) => {
                      const tag = tags.data?.find((y) => y.uuid === x);
                      return (
                        <InsightsFilterMetricListItem
                          style={
                            !tag
                              ? {
                                  filter:
                                    'grayscale(100%) brightness(100%) sepia(100%) hue-rotate(290deg) saturate(300%)',
                                }
                              : undefined
                          }
                          key={x}
                          onDelete={() => {
                            const newTagValues = [
                              ...filter.values.slice(0, i),
                              ...filter.values.slice(i + 1),
                            ];

                            if (newTagValues.length > 0) {
                              const newFilters: typeof metrics = [
                                ...metrics.slice(0, index),
                                {
                                  field: 'tag',
                                  operation: 'in',
                                  values: newTagValues,
                                },
                                ...metrics.slice(index + 1),
                              ];
                              dispatch({
                                type: 'setFilter',
                                value: newFilters,
                              });
                            } else {
                              dispatch({
                                type: 'setFilter',
                                value: [
                                  ...metrics.slice(0, index),
                                  ...metrics.slice(index + 1),
                                ],
                              });
                            }
                          }}
                        >
                          <span className="flex flex-row items-center gap-1 overflow-hidden">
                            <Icon className="size-4 text-purple-600">
                              {tag ? <Hash01 /> : <AlertTriangle />}
                            </Icon>
                            <span className="overflow-hidden text-ellipsis text-nowrap">
                              {tag?.label ?? 'Unknown/Deleted Tag'}
                            </span>
                          </span>
                        </InsightsFilterMetricListItem>
                      );
                    })}
                  </>
                );
              }
            }

            const label = getMetricLabelAsText(
              platform,
              filter.field,
              customEvents,
              customConversions
            );

            if (label == null) {
              return null;
            }

            return (
              <InsightsFilterMetricListItem
                key={key}
                onDelete={() => {
                  dispatch({
                    type: 'removeFilter',
                    value: filter,
                  });

                  void recordEvent({
                    action: `Metric Removed`,
                    target: 'Insights Metric',
                    metadata: {
                      metric: filter.field,
                    },
                  });
                }}
              >
                <DialogPopover
                  isDisabled={!!isSharedReport}
                  shouldCloseOnInteractOutside={(el) => {
                    // Temporary workaround to stop metric value combobox interactions from closing the dialog
                    return !el.closest(
                      '#metric-edit-multi-option-select-options'
                    );
                  }}
                  className="max-h-72"
                  placement="bottom end"
                >
                  <Button className="flex flex-row items-center gap-2">
                    <span className="flex flex-col justify-start">
                      <span className="flex flex-row items-center gap-1">
                        <span className="text-primary/50">
                          <InsightsMetricLabelTooltip
                            platform={platform}
                            metric={filter.field}
                            customConversions={customConversions}
                            customEvents={customEvents}
                          />
                        </span>
                        <span className="text-primary/50">
                          {LL.insights.metrics.operationSymbols[
                            filter.operation
                          ]()}
                        </span>
                        <span>
                          {getMetricFilterValuesAsText(platform, filter)}
                        </span>
                      </span>
                    </span>
                  </Button>
                  {({ close }) => {
                    if (isSharedReport) return null;

                    return (
                      <div className="p-2">
                        <InsightsFilterForm
                          platform={platform}
                          customEvents={customEvents}
                          customConversions={customConversions}
                          onSaveComplete={(newFilters) => {
                            const newFilter = newFilters[0];
                            const filters = [
                              ...metrics.slice(0, index),
                              newFilter,
                              ...metrics.slice(index + 1),
                            ];
                            const newDisplay =
                              'field' in newFilter
                                ? [...new Set([...display, newFilter.field])]
                                : display;
                            dispatch({ type: 'setFilter', value: filters });
                            dispatch({ type: 'setDisplay', value: newDisplay });
                            close();

                            void recordEvent({
                              action: `Metric Edit Opened`,
                              target: 'Insights Metric',
                              metadata: filter,
                            });
                          }}
                          defaultValues={[filter]}
                        />
                      </div>
                    );
                  }}
                </DialogPopover>
              </InsightsFilterMetricListItem>
            );
          })}
        </div>
      )}
    </div>
  );
};

const InsightsFilterMetricListItem: React.FC<
  React.HTMLAttributes<HTMLDivElement> & { onDelete: () => void }
> = ({ children, onDelete, className, ...divProps }) => {
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');

  return (
    <div
      {...divProps}
      className={cn(
        /* Base */
        'flex w-max shrink-0 flex-row items-center gap-1 rounded-md border border-solid border-purple-100 bg-white px-2.5 py-1.5 outline-none transition-colors',
        /* Typography */
        'text-xs font-medium text-primary',
        /* Hover */
        'hover:border-purple-200 hover:bg-purple-50',
        className
      )}
    >
      {children}
      {isSharedReport ? null : (
        <Button
          className={cn(
            /* Base */
            'size-4 rounded text-purple-900/60 outline-none',
            /* Hover */
            'hover:bg-purple-100'
          )}
          onPress={onDelete}
        >
          <XClose className="size-4 text-primary/50" />
        </Button>
      )}
    </div>
  );
};

export default InsightsFilterMetricList;
