import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Heading, Modal, ModalOverlay } from 'react-aria-components';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Button } from '@magicbrief/ui/src/components/button';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { INSIGHTS_REPORT_MAX_DESCRIPTION_LENGTH } from '@magicbrief/common';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import Input from 'src/components/Input';
import TextArea from 'src/components/TextArea';
import { trpc } from 'src/lib/trpc';
import { useI18nContext } from 'src/i18n/i18n-react';
import Alert from 'src/components/AlertV2/Alert';

const schema = z.object({
  title: z.string(),
  description: z.string().nullable(),
});

type FormSchema = z.infer<typeof schema>;

interface Props {
  reportUuid: string;
  reportName: string;
  reportDescription: string | null;
  reportType: 'report' | 'comparison-report';
  show: boolean;
  onClose: () => void;
}

export const EditReportModal: React.FC<Props> = ({
  show,
  onClose,
  reportUuid,
  reportName,
  reportDescription,
  reportType,
}) => {
  const { LL } = useI18nContext();
  const trpcUtils = trpc.useUtils();

  const inputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>();

  const { register, handleSubmit, reset, watch } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: reportName,
      description: reportDescription,
    },
  });

  const descriptionLength = watch('description')?.length ?? 0;

  const { ref, ...rest } = register('title');

  const editInsightsReport =
    trpc.insightsReports.editInsightsReport.useMutation({
      onSuccess: () => {
        void trpcUtils.insightsReports.getManyInsightsReports.invalidate();
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        void trpcUtils.insightsReports.getInsightsReport.invalidate({
          uuid: reportUuid,
        });

        onClose();
        toast.success(LL.insights.reports.renameModal.success(), {
          className: 'toast-success',
        });
      },
      onError: (error) => {
        captureException(error);
        setError(error.message);
      },
    });

  const editInsightsComparisonReport =
    trpc.insightsComparisonReports.update.useMutation({
      onSuccess: () => {
        void trpcUtils.insightsReports.getManyInsightsReportsAndComparisonReports.invalidate();
        void trpcUtils.insightsComparisonReports.getOne.invalidate({
          uuid: reportUuid,
        });
        void trpcUtils.insightsComparisonReports.getAll.invalidate();
        onClose();
        toast.success(LL.insights.reports.renameModal.success(), {
          className: 'toast-success',
        });
      },
      onError: (error) => {
        captureException(error);
        setError(error.message);
      },
    });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    switch (reportType) {
      case 'report':
        editInsightsReport.mutate({
          uuid: reportUuid,
          name: data.title,
          description: data.description,
        });
        break;
      case 'comparison-report':
        editInsightsComparisonReport.mutate({
          uuid: reportUuid,
          name: data.title,
          description: data.description,
        });
        break;
      default: {
        const _exhaustiveCheck = reportType satisfies never;
        throw new Error(`Unhandled report type: ${_exhaustiveCheck}`);
      }
    }
  };

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      if (!show) {
        reset();
        setError(null);
      }
    };
  }, [show, reset]);

  return (
    <ModalOverlay
      isOpen={show}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="max-h-[50%] w-[500px] rounded-xl bg-white p-6 shadow outline-none focus-visible:outline-none"
          aria-label="edit report modal"
        >
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="flex items-center gap-2">
              <Heading className="grow text-2xl font-semibold text-primary">
                {`Edit '${reportName}'`}
              </Heading>
              <Button
                variant="text"
                size="small"
                className="size-10 p-2"
                slot="close"
              >
                <XClose className="size-5" />
              </Button>
            </div>
            <Input
              {...rest}
              ref={(e) => {
                ref(e); // Register the ref with React Hook Form
                inputRef.current = e; // Store ref to use for focusing
              }}
              label="Title"
              placeholder="Enter title"
            />
            <TextArea
              {...register('description')}
              className="max-h-96"
              label="Description"
              placeholder="Enter description (optional)"
              maxLength={INSIGHTS_REPORT_MAX_DESCRIPTION_LENGTH}
              count={`${descriptionLength}/${INSIGHTS_REPORT_MAX_DESCRIPTION_LENGTH}`}
            />
            {error ? <Alert variant="danger" title={error} /> : null}
            <div className="flex items-center justify-end gap-2">
              <Button
                variant="secondary"
                type="button"
                slot="close"
                isDisabled={
                  editInsightsReport.isLoading ||
                  editInsightsComparisonReport.isLoading
                }
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={
                  editInsightsReport.isLoading ||
                  editInsightsComparisonReport.isLoading
                }
              >
                Save
              </Button>
            </div>
          </form>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
