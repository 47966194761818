import { useCallback, useRef } from 'react';
import { uuidv4 } from '@magicbrief/common';
import { SquareLoader } from './SquareLoaders';

/**
 * Hook for producing an array of Square Loader elements.
 * Generally, you'll only want to use this when you need an array as
 * opposed to a fragment.
 *
 * The only use case I can see for this is in a masonry grid that
 * manipulates the order of the children.
 */
export function useSquareLoaders(options: {
  amount: number;
  className: string;
}) {
  const keys = useRef(
    Array.from({ length: options.amount }).map((_, i) => ({
      key: uuidv4(),
      delayStart: (i + 1) * 0.05,
      delayRepeat: (i + 1) * 0.1,
    }))
  );
  const getSquareLoaders = useCallback(() => {
    return keys.current.map((x) => (
      <SquareLoader
        key={x.key}
        delayRepeat={x.delayRepeat}
        delayStart={x.delayStart}
        className={options.className}
      />
    ));
  }, [options.className]);

  return getSquareLoaders;
}
