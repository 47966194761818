import { useEffect, useRef, useState } from 'react';
import { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { Key, Text } from 'react-aria-components';
import { useMatch, useParams } from 'react-router-dom';
import {
  getAllMetricsAndAttributesForPlatform,
  INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS,
  InsightsFacebookGroup,
  InsightsFacebookWizardMetric,
} from '@magicbrief/common';
import { Button } from '@magicbrief/ui/src/components/button';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from '@magicbrief/ui/src/components/popover';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { trpc } from 'src/lib/trpc';
import { BreakdownAgeGenderChart } from 'src/pages/Insights/InsightsDetailOutlet/components/InsightsDetailSidebar/BreakdownAgeGenderChart';
import { BreakdownPlacementChart } from 'src/pages/Insights/InsightsDetailOutlet/components/InsightsDetailSidebar/BreakdownPlacementChart';
import { useInsightsAdAccount } from 'src/pages/Insights/util/useInsightsQueries';
import { useInsightsSearchParams } from 'src/pages/Insights/util/useInsightsSearchParams';
import {
  getMetricLabelAsText,
  getMetricValue,
  parseValueForMetric,
} from 'src/pages/Insights/util/useParseMetric';
import {
  Select,
  SelectItem,
  SelectListBox,
  SelectPopover,
} from 'src/components/AriaSelect/Select';
import SearchMd from 'src/assets/svgicons/line/search-md.svg';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import Input from 'src/components/Input';
import { useMetricSearch } from 'src/pages/Insights/util/useMetricSearch';
import {
  useInsightsFilter,
  useInsightsSort,
} from 'src/pages/Insights/util/useInsightsPersistentState';
import InfoCircle from 'src/assets/svgicons/line/info-circle.svg';
import { InsightsMetricLabelTooltip } from '../../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import {
  CLICKS_CATEGORY_METRICS,
  ENGAGEMENT_CATEGORY_METRICS,
  PERFORMANCE_CATEGORY_METRICS,
  VIDEO_CATEGORY_METRICS,
  WIZARD_SCORE_CATEGORY_METRICS,
} from '../../const';
import { INSIGHTS_GROUP_BREAKDOWNS_AD_THRESHOLD } from '../constants';

export const InsightsGroupSidebar = ({
  adGroup,
  group,
  currency,
}: {
  adGroup: InsightsAdGroupWithInsights;
  group: InsightsFacebookGroup | null;
  currency: string;
}) => {
  const isCompare = useMatch('/insights/accounts/:accountUuid/compare');
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  return (
    <div
      className={cn(
        'w-[446px] shrink-0 overflow-y-auto border-l border-solid border-l-purple-200 bg-purple-50',
        isCompare || isSharedReport || isSharedComparisonReport
          ? 'divide-y divide-solid divide-purple-200'
          : ''
      )}
    >
      <GroupPerformanceSummary
        metrics={adGroup.metrics}
        platform={adGroup.platform as 'facebook' | 'tiktok'}
        currency={currency}
      />
      {isCompare || isSharedReport || isSharedComparisonReport ? null : (
        <GroupBreakdowns adGroup={adGroup} group={group} currency={currency} />
      )}
      <GroupMetrics
        adGroup={adGroup}
        platform={adGroup.platform as 'facebook' | 'tiktok'}
        currency={currency}
      />
    </div>
  );
};

const GroupPerformanceSummary = ({
  metrics,
  platform,
  currency,
}: {
  metrics: InsightsAdGroupWithInsights['metrics'];
  platform: 'facebook' | 'tiktok';
  currency: string;
}) => {
  return (
    <div className="flex flex-col gap-3 px-4 py-6">
      <Text className="text-sm font-semibold text-primary">
        Ad Group Performance Summary
      </Text>

      <div className="rounded-lg border border-solid border-purple-200 bg-white p-3">
        <div className="flex flex-col border-b border-solid border-b-purple-200 pb-4">
          <Text className="text-xs font-medium text-primary/50">
            Total Spend
          </Text>

          <Text className="text-2xl font-semibold text-primary">
            {!!metrics.spend && !!currency
              ? parseValueForMetric(
                  platform,
                  'spend',
                  metrics.spend as number,
                  currency
                )
              : '-'}
          </Text>
        </div>

        <div className="grid grid-cols-2 gap-3 pt-4">
          <div className="flex flex-col border-r border-solid border-r-purple-200">
            <Text className="text-xs font-medium text-primary/50">
              Impressions
            </Text>
            <Text className="text-2xl font-semibold text-primary">
              {!!metrics.impressions && !!currency
                ? parseValueForMetric(
                    platform,
                    'impressions',
                    metrics.impressions as number,
                    currency
                  )
                : '-'}
            </Text>
          </div>

          <div className="flex flex-col">
            <Text className="text-xs font-medium text-primary/50">Clicks</Text>
            <Text className="text-2xl font-semibold text-primary">
              {!!metrics.clicks && !!currency
                ? parseValueForMetric(
                    platform,
                    'clicks',
                    metrics.clicks as number,
                    currency
                  )
                : '-'}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

const GroupBreakdowns = ({
  adGroup,
  group,
  currency,
}: {
  adGroup: InsightsAdGroupWithInsights;
  group: InsightsFacebookGroup | null;
  currency: string;
}) => {
  const { accountUuid } = useParams();
  const sort = useInsightsSort();
  const filter = useInsightsFilter();
  const { forTimePeriod, attributionWindow } =
    useInsightsSearchParams().getParsedValues();

  const isBelowAdLimit =
    adGroup.ads.length <= INSIGHTS_GROUP_BREAKDOWNS_AD_THRESHOLD;
  const isSharedReport = useMatch('/insights/reports/share/:reportUuid');
  const isSharedComparisonReport = useMatch(
    '/insights/comparison-reports/share/:reportUuid'
  );

  const breakdowns = trpc.insightsAds.getBreakdownsForManyAds.useQuery(
    {
      groupName: adGroup.group ?? '',
      accountUuid: accountUuid ?? '',
      group: group ?? undefined,
      sort,
      filter,
      forTimePeriod,
      attributionWindow: attributionWindow ?? 'default',
    },
    {
      enabled:
        isBelowAdLimit &&
        !isSharedReport &&
        !isSharedComparisonReport &&
        !!adGroup &&
        !!adGroup.group &&
        !!accountUuid &&
        !!group &&
        !!forTimePeriod,
    }
  );

  if (isSharedReport || isSharedComparisonReport) {
    return null;
  }

  if (!isBelowAdLimit) {
    return (
      <div className="-mt-3 flex items-center gap-2 border-b border-solid border-b-purple-200 px-4 pb-6">
        <InfoCircle className="size-4 text-primary/50" />
        <span className="text-xs font-medium text-primary/50">
          Display limit: we only display groups containing{' '}
          {INSIGHTS_GROUP_BREAKDOWNS_AD_THRESHOLD} or fewer ads.
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 border-y border-solid border-y-purple-200 px-4 pb-6 pt-3">
      <div>
        <Text className="text-sm font-medium text-primary">Impressions</Text>
        <BreakdownAgeGenderChart
          breakdowns={breakdowns?.data ?? []}
          isFetchingBreakdowns={breakdowns.isFetching}
          isBreakdownsError={breakdowns.isError}
        />
      </div>
      <div>
        <Text className="text-sm font-medium text-primary">
          Placements Breakdown
        </Text>
        {currency ? (
          <BreakdownPlacementChart
            breakdowns={breakdowns?.data ?? []}
            isFetchingBreakdowns={breakdowns.isFetching}
            isBreakdownsError={breakdowns.isError}
            currency={currency}
            platform={adGroup.platform as 'facebook' | 'tiktok'}
          />
        ) : null}
      </div>
    </div>
  );
};

const GroupMetrics = ({
  adGroup,
  platform,
  currency,
}: {
  adGroup: InsightsAdGroupWithInsights;
  platform: 'facebook' | 'tiktok';
  currency: string;
}) => {
  const { accountUuid } = useParams();
  const {
    accountCustomEvents: customEvents = [],
    accountCustomConversions: customConversions = [],
  } = useInsightsAdAccount({ accountUuid });

  const inputRef = useRef<HTMLInputElement>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [metricGroup, setMetricGroup] = useState<Key>('Performance Metrics');

  const filteredMetrics = INSIGHTS_FACEBOOK_TIME_SERIES_ALL_METRICS.filter(
    (m) => {
      if (m === 'tag') return false;

      switch (metricGroup) {
        case 'Performance Metrics':
          return PERFORMANCE_CATEGORY_METRICS.includes(m);
        case 'Wizard Scores':
          return WIZARD_SCORE_CATEGORY_METRICS.includes(
            m as InsightsFacebookWizardMetric
          );
        case 'Click Metrics':
          return CLICKS_CATEGORY_METRICS.includes(m);
        case 'Video Metrics':
          return VIDEO_CATEGORY_METRICS.includes(m);
        case 'Engagement Metrics':
          return ENGAGEMENT_CATEGORY_METRICS.includes(m);
        default:
          return true;
      }
    }
  );

  const {
    results: searchMetrics,
    onChange,
    query,
  } = useMetricSearch({
    platform,
    builtinMetrics: getAllMetricsAndAttributesForPlatform(platform),
    customEvents,
    customConversions,
  });

  const displayMetrics = query ? searchMetrics : filteredMetrics;

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  if (!currency) return null;

  return (
    <div className="flex flex-col gap-6 px-4 py-6">
      <div className="flex w-full items-center">
        <PopoverTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
          <Button
            variant="secondary"
            size="small"
            className="border-r-none rounded-r-none p-2"
          >
            <SearchMd className="size-4" />
          </Button>
          <Popover placement="bottom start">
            <PopoverDialog className="p-2">
              <Input
                ref={inputRef}
                name="search"
                value={query}
                onChange={(e) => onChange(e.target.value)}
                placeholder="Search metrics"
              />
            </PopoverDialog>
          </Popover>
        </PopoverTrigger>
        <Select
          className="w-full"
          defaultSelectedKey={metricGroup}
          onSelectionChange={(key) => {
            if (query) {
              onChange('');
            }
            setMetricGroup(key);
          }}
        >
          <Button
            variant="secondary"
            size="small"
            className="w-full justify-between rounded-l-none border-l-0"
          >
            {query ? `Results for "${query}"` : metricGroup}
            <ChevronDown className="size-4" />
          </Button>
          <SelectPopover>
            <SelectListBox aria-label="metrics group list">
              <SelectItem id="Performance Metrics">
                Performance Metrics
              </SelectItem>
              <SelectItem id="Wizard Scores">Wizard Scores</SelectItem>
              <SelectItem id="Click Metrics">Click Metrics</SelectItem>
              <SelectItem id="Video Metrics">Video Metrics</SelectItem>
              <SelectItem id="Engagement Metrics">
                Engagement Metrics
              </SelectItem>
            </SelectListBox>
          </SelectPopover>
        </Select>
      </div>

      {displayMetrics.length === 0 ? (
        <>
          <span className="flex items-center justify-center text-sm font-normal italic text-primary">
            No metrics found
          </span>
        </>
      ) : null}

      <ul className="flex flex-col gap-4">
        {displayMetrics.map((metric) => {
          const label = getMetricLabelAsText(
            platform,
            metric,
            customEvents,
            customConversions
          );

          const value = getMetricValue(
            platform,
            metric,
            adGroup.metrics,
            currency
          );

          if (value == null || label == null) {
            return null;
          }

          return (
            <li
              key={label}
              className="flex items-center justify-between gap-6 text-xs font-medium text-primary"
            >
              <InsightsMetricLabelTooltip
                platform={platform}
                metric={metric}
                customConversions={customConversions}
                customEvents={customEvents}
              />
              <div className="max-w-[50%] truncate rounded-[4px] bg-gray-200 px-1.5 py-1 text-center">
                <span className="truncate text-sm font-semibold text-black">
                  {value}
                </span>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
