import {
  TextField as AriaTextField,
  TextFieldProps as AriaTextFieldProps,
} from 'react-aria-components';
import { cn } from '../lib/cn';

export function TextField(props: AriaTextFieldProps) {
  return (
    <AriaTextField
      {...props}
      className={cn('flex flex-col gap-1.5', props.className)}
    />
  );
}
