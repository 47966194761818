import { useState } from 'react';
import { toast } from 'react-toastify';
import { captureException } from '@sentry/react';
import axios from 'axios';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpcProxyClient } from 'src/lib/trpc';

const DEFAULT_REQUIRED_DIMENSIONS = {
  width: 520,
  height: 800,
};

type Params = {
  requiredDimensions?: {
    width: number;
    height: number;
  };
};

export function useUploadThumbnail({
  requiredDimensions = DEFAULT_REQUIRED_DIMENSIONS,
}: Params = {}) {
  const [thumbnailUrl, setThumbnailUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const { LL } = useI18nContext();

  async function handleUploadThumbnail(file: File) {
    setIsUploading(true);
    try {
      const fileExt = file.name.split('.').pop();
      if (!fileExt) {
        throw new Error('File is missing an extension');
      }

      // Get the signed URL from the API
      const uploadResponse =
        await trpcProxyClient.storage.generatePubliclyAvailableAssetURL.mutate({
          fileName: file.name,
          fileExt,
          storageName: 'ad_packs', // which directory should it exist in
          mimeType: file.type,
          metadata: {
            height: requiredDimensions.height,
            width: requiredDimensions.width,
            size: file.size,
          },
        });

      // Upload the file to the signed URL
      await axios.put(uploadResponse.url, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      // Set the clean URL for viewing
      setThumbnailUrl(uploadResponse.cdnUrl);
      return uploadResponse.cdnUrl;
    } catch (e) {
      console.error(e);
      toast.error(LL.packs.create.thumbnailUploadFailed());
      captureException(e, (scope) => {
        scope.setTransactionName('useUploadThumbnail->handleUploadThumbnail');
        return scope;
      });
      throw e;
    } finally {
      setIsUploading(false);
    }
  }

  async function validateImageDimensions(file: File): Promise<boolean> {
    return new Promise((resolve) => {
      const objectURL = URL.createObjectURL(file);
      const image = new Image();

      image.onload = () => {
        URL.revokeObjectURL(objectURL);
        if (
          requiredDimensions.width !== image.width ||
          requiredDimensions.height !== image.height
        ) {
          toast.error(
            `Thumbnail must be ${requiredDimensions.width} x ${requiredDimensions.height}px`
          );
          resolve(false);
        }
        resolve(true);
      };

      image.onerror = () => {
        URL.revokeObjectURL(objectURL);
        toast.error(LL.packs.create.thumbnailUploadFailed());
        resolve(false);
      };

      image.src = objectURL;
    });
  }

  async function onImageChange(event: React.ChangeEvent<HTMLInputElement>) {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    const file = files[0];
    const isValid = await validateImageDimensions(file);

    if (isValid) {
      await handleUploadThumbnail(file);
    }
  }

  return {
    onImageChange,
    thumbnailUrl,
    setThumbnailUrl,
    isUploading,
  };
}
