import * as React from 'react';
import {
  Tooltip as AriaTooltip,
  TooltipTrigger as AriaTooltipTrigger,
  composeRenderProps,
  OverlayArrow as AriaOverlayArrow,
  type TooltipProps as AriaTooltipProps,
} from 'react-aria-components';

import { cn } from '../lib/cn';

const TooltipTrigger = AriaTooltipTrigger;

interface TooltipProps extends Omit<AriaTooltipProps, 'children'> {
  children: React.ReactNode;
}

const Tooltip = ({
  className,
  offset = 4,
  placement,
  children,
  ...props
}: TooltipProps) => (
  <AriaTooltip
    offset={offset}
    placement={placement}
    className={composeRenderProps(className, (className) =>
      cn(
        'z-50 rounded-md bg-black px-2 py-1.5 text-xs text-white outline-none',
        /* Entering */
        'data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95',
        /* Exiting */
        'data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95',
        /* Placement */
        'data-[placement=bottom]:slide-in-from-top-2 data-[placement=left]:slide-in-from-right-2 data-[placement=right]:slide-in-from-left-2 data-[placement=top]:slide-in-from-bottom-2',
        className
      )
    )}
    {...props}
  >
    <AriaOverlayArrow>
      <svg
        className={cn(
          'size-2',
          placement === 'top' && 'rotate-none',
          placement === 'bottom' && 'rotate-180',
          placement === 'right' && 'rotate-90',
          placement === 'left' && 'rotate-[270deg]'
        )}
        viewBox="0 0 8 8"
      >
        <path d="M0 0 L4 4 L8 0" />
      </svg>
    </AriaOverlayArrow>
    {children}
  </AriaTooltip>
);

export { Tooltip, TooltipTrigger };
