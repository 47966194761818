import { FC } from 'react';
import { InsightsFacebookSelectableDatePreset } from '@magicbrief/common';
import Calendar from 'src/assets/svgicons/duocolor/calendar.svg';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { AriaButton } from 'src/components/Button/Button';
import { Icon } from 'src/components/Icon';
import useFeatureFlag from 'src/utils/useFeatureFlag';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useI18nContext } from '../../../../i18n/i18n-react';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';
import InsightsFilterTimePeriodMenuV2 from './InsightsFilterTimePeriodMenuV2';

/** @deprecated use InsightsFilterTimePeriodMenuV2 */
const InsightsFilterTimePeriodMenu: FC = () => {
  const { LL } = useI18nContext();
  const { getParsedValues, params } = useInsightsSearchParams();
  const isNewTimePeriodMenuEnabled = useFeatureFlag(
    'INSIGHTS_TIME_PERIOD_FILTER_V2_ENABLED'
  );
  const { recordEvent } = useNewAnalyticsEvent();

  if (isNewTimePeriodMenuEnabled) {
    return <InsightsFilterTimePeriodMenuV2 />;
  }

  const { timePeriod } = getParsedValues();
  const datePresets = Object.keys(LL.insights.timePeriod).filter(
    (preset) => preset !== 'custom'
  );

  return (
    <>
      <AriaMenu
        onOpenChange={(open) => {
          if (open) {
            void recordEvent({
              action: 'Time Period Menu Opened',
              target: 'Insights Time Period',
            });
          }
        }}
      >
        <AriaButton
          data-intercom-target="insight_date"
          variant="white"
          icon={
            <Icon>
              <Calendar />
            </Icon>
          }
        >
          <span className="block lg:hidden">
            {LL.insights.timePeriodTruncated[
              timePeriod.toString() as keyof typeof LL.insights.timePeriod
            ]()}
          </span>
          <span className="hidden lg:block">
            {LL.insights.timePeriod[
              timePeriod.toString() as keyof typeof LL.insights.timePeriod
            ]()}
          </span>
        </AriaButton>
        <AriaMenu.List
          selectionMode="single"
          selectedKeys={[timePeriod]}
          onAction={(key) => {
            params.set(
              'timePeriod',
              key as InsightsFacebookSelectableDatePreset
            );
            void recordEvent({
              action: 'Time Period Changed',
              target: 'Insights Time Period',
              metadata: {
                timePeriod: key,
              },
            });
          }}
        >
          {datePresets.map((timePeriod) => (
            <AriaMenu.Item id={timePeriod} key={timePeriod}>
              {LL.insights.timePeriod[
                timePeriod as keyof typeof LL.insights.timePeriod
              ]()}
            </AriaMenu.Item>
          ))}
        </AriaMenu.List>
      </AriaMenu>
    </>
  );
};

export default InsightsFilterTimePeriodMenu;
