import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import {
  convertDatePresetToDateRange,
  INSIGHTS_FACEBOOK_SELECTABLE_DATE_PRESETS,
  INSIGHTS_TIKTOK_SELECTABLE_DATE_PRESETS,
  InsightsFacebookSelectableDatePreset,
} from '@magicbrief/common';
import { ForTimePeriodInput } from '@magicbrief/server/src/insights/types';
import {
  DateValue,
  HeadingContext,
  HeadingProps,
  ListBox,
  useContextProps,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '@magicbrief/ui/src/components/button';
import { ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import { Placement } from 'react-aria';
import Calendar from 'src/assets/svgicons/duocolor/calendar.svg';
import {
  CalendarCell,
  CalendarGrid,
  CalendarGridBody,
  CalendarGridHeader,
  CalendarHeaderCell,
  RangeCalendar,
} from 'src/components/Calendar/AriaCalendar';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from 'src/components/Popover/AriaPopover';
import { useI18nContext } from 'src/i18n/i18n-react';
import dayjs from 'src/lib/dayjs';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import Check from 'src/assets/svgicons/line/check.svg';
import ChevronLeft from 'src/assets/svgicons/line/chevron-left.svg';
import ChevronRight from 'src/assets/svgicons/line/chevron-right.svg';
import { getTimePeriodLabel } from '../../util/getTimePeriodLabel';
import {
  useInsightsAdAccount,
  useRefetchData,
} from '../../util/useInsightsQueries';
import { useInsightsSearchParams } from '../../util/useInsightsSearchParams';

const facebookDatePresets: (InsightsFacebookSelectableDatePreset | 'custom')[] =
  [
    ...(Object.keys(
      INSIGHTS_FACEBOOK_SELECTABLE_DATE_PRESETS
    ) as InsightsFacebookSelectableDatePreset[]),
    'custom' as const,
  ];

const tiktokDatePresets = [
  ...(Object.keys(
    INSIGHTS_TIKTOK_SELECTABLE_DATE_PRESETS
  ) as InsightsFacebookSelectableDatePreset[]),
  'custom' as const,
];

function getDatePresetsForPlatform(platform: 'facebook' | 'tiktok') {
  switch (platform) {
    case 'facebook':
      return facebookDatePresets;
    case 'tiktok':
      return tiktokDatePresets;
  }
}

/** @todo remove the casts to DateValue here, weird type issues due to private field on CalendarDate type */
export function InsightsFilterTimePeriodMenuV2({
  placement,
}: {
  placement?: Placement;
}) {
  const { LL } = useI18nContext();
  const { getParsedValues, params } = useInsightsSearchParams();
  const { since, until, timePeriod, forTimePeriod } = getParsedValues();
  const { refetch, isLoading } = useRefetchData();
  const { accountUuid, reportUuid } = useParams();
  const { recordEvent } = useNewAnalyticsEvent();

  const { adAccount } = useInsightsAdAccount({
    accountUuid,
  });

  const [currentDatePreset, setCurrentDatePreset] = useState<
    InsightsFacebookSelectableDatePreset | 'custom'
  >(timePeriod);

  const [start, setStart] = useState<DateValue | null>(
    timePeriod !== 'custom'
      ? (parseDate(
          convertDatePresetToDateRange(timePeriod).since.format('YYYY-MM-DD')
        ) as unknown as DateValue)
      : since
        ? (parseDate(since) as unknown as DateValue)
        : null
  );
  const [end, setEnd] = useState<DateValue | null>(
    timePeriod !== 'custom'
      ? (parseDate(
          convertDatePresetToDateRange(timePeriod).until.format('YYYY-MM-DD')
        ) as unknown as DateValue)
      : until
        ? (parseDate(until) as unknown as DateValue)
        : null
  );
  const [focusedValue, setFocusedValue] = useState<DateValue>();

  const isValid = useMemo(
    () => currentDatePreset != null && start != null && end != null,
    [currentDatePreset, start, end]
  );

  const [open, setOpen] = useState(false);

  const datePresets = adAccount.data
    ? getDatePresetsForPlatform(adAccount.data?.platform)
    : [];

  // This is to syncronise the Calendar UI with the date preset for Reports or from the default date preset
  useEffect(() => {
    if (reportUuid || timePeriod) {
      setCurrentDatePreset(timePeriod);
      setStart(
        timePeriod !== 'custom'
          ? (parseDate(
              convertDatePresetToDateRange(timePeriod).since.format(
                'YYYY-MM-DD'
              )
            ) as unknown as DateValue)
          : since
            ? (parseDate(since) as unknown as DateValue)
            : null
      );
      setEnd(
        timePeriod !== 'custom'
          ? (parseDate(
              convertDatePresetToDateRange(timePeriod).until.format(
                'YYYY-MM-DD'
              )
            ) as unknown as DateValue)
          : until
            ? (parseDate(until) as unknown as DateValue)
            : null
      );
    }
  }, [reportUuid, since, timePeriod, until]);

  return (
    <>
      <PopoverTrigger>
        <Button variant="secondary" size="small" onPress={() => setOpen(true)}>
          <Calendar className="size-4 text-primary" />
          <span className="hidden sm:block sm:whitespace-nowrap">
            {getTimePeriodLabel(forTimePeriod, LL)}
          </span>
        </Button>

        <Popover
          offset={8}
          isOpen={open}
          onOpenChange={setOpen}
          placement={placement}
        >
          <PopoverDialog className="flex max-h-[470px] p-0">
            <div className="grow overflow-auto border-r border-solid border-r-gray-200 p-2">
              <ListBox className="min-w-44" aria-label="time period options">
                {datePresets.map((datePreset) => {
                  const isSelected = datePreset === currentDatePreset;

                  return (
                    <ListBoxItem
                      key={datePreset}
                      className="flex items-center justify-between gap-2 px-2 py-1.5 font-normal"
                      textValue={LL.insights.timePeriod[
                        datePreset as keyof typeof LL.insights.timePeriod
                      ]()}
                      onAction={() => {
                        setCurrentDatePreset(datePreset);
                        if (datePreset === 'custom') {
                          setStart(
                            since
                              ? (parseDate(since) as unknown as DateValue)
                              : null
                          );
                          setEnd(
                            until
                              ? (parseDate(until) as unknown as DateValue)
                              : null
                          );
                          setFocusedValue(
                            until
                              ? (parseDate(until) as unknown as DateValue)
                              : undefined
                          );
                        } else {
                          const { since, until } =
                            convertDatePresetToDateRange(datePreset);

                          setStart(
                            parseDate(
                              since.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                          setEnd(
                            parseDate(
                              until.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                          setFocusedValue(
                            parseDate(
                              until.format('YYYY-MM-DD')
                            ) as unknown as DateValue
                          );
                        }
                        void recordEvent({
                          action: 'Time Period Changed',
                          target: 'Insights Time Period',
                          metadata: {
                            since: start?.toString(),
                            until: end?.toString(),
                            currentDatePreset,
                          },
                        });
                      }}
                    >
                      {LL.insights.timePeriod[
                        datePreset as keyof typeof LL.insights.timePeriod
                      ]()}

                      {isSelected ? <Check className="size-[18px]" /> : null}
                    </ListBoxItem>
                  );
                })}
              </ListBox>
            </div>
            <div className="flex flex-col justify-between">
              <div className="grow p-4">
                <RangeCalendar
                  className="w-fit"
                  value={start && end && { start, end }}
                  defaultValue={start && end && { start, end }}
                  focusedValue={focusedValue}
                  visibleDuration={{ months: 2 }}
                  pageBehavior="single"
                  maxValue={today(getLocalTimeZone())}
                  onFocusChange={setFocusedValue}
                  onChange={(value) => {
                    /** @todo when the month changes out of view, switch to that month (is this a bug in RAC?) */
                    /** @todo look up Facebook docs for actual earliest date here and make sure earlier since is set to that */
                    /** @todo do this check in the API layer also https://linear.app/magicbrief/issue/ENG-1655 */

                    const todayMs = dayjs().endOf('day').valueOf();
                    const isValidEndDate =
                      value?.end &&
                      dayjs(value.end.toString()).valueOf() < todayMs;

                    if (!isValidEndDate) {
                      setStart(start);
                      setEnd(end);
                      toast.error('You cannot select a date in the future');
                      return;
                    }

                    const twoYearsAgoMs = dayjs()
                      .subtract(2, 'years')
                      .startOf('day')
                      .valueOf();

                    const isValidStartDate =
                      value.start &&
                      dayjs(value.start.toString()).valueOf() > twoYearsAgoMs;

                    if (!isValidStartDate) {
                      setStart(start);
                      setEnd(end);
                      toast.error(
                        'You cannot select a date that far in the past'
                      );
                      return;
                    }

                    setCurrentDatePreset('custom');
                    setStart(value.start);
                    setEnd(value.end);
                  }}
                >
                  <InsightsRangeCalendarHeading />
                  <div className="flex gap-4">
                    <CalendarGrid>
                      <CalendarGridHeader>
                        {(day) => (
                          <CalendarHeaderCell>{day}</CalendarHeaderCell>
                        )}
                      </CalendarGridHeader>
                      <CalendarGridBody>
                        {(date) => <CalendarCell date={date} />}
                      </CalendarGridBody>
                    </CalendarGrid>

                    <CalendarGrid offset={{ months: 1 }}>
                      <CalendarGridHeader>
                        {(day) => (
                          <CalendarHeaderCell>{day}</CalendarHeaderCell>
                        )}
                      </CalendarGridHeader>
                      <CalendarGridBody>
                        {(date) => <CalendarCell date={date} />}
                      </CalendarGridBody>
                    </CalendarGrid>
                  </div>
                </RangeCalendar>
              </div>
              <div className="flex items-center justify-end gap-2 border-t border-solid border-t-gray-200 p-3">
                <Button
                  variant="secondary"
                  size="small"
                  onPress={() => setOpen(false)}
                  isDisabled={isLoading}
                >
                  {LL.cancel()}
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  loading={isLoading}
                  isDisabled={!isValid}
                  onPress={async () => {
                    if (currentDatePreset === 'custom') {
                      const now = dayjs();
                      const updatedUntil = end ? dayjs(end.toString()) : now;
                      const updatedSince = start
                        ? dayjs(start.toString())
                        : updatedUntil.subtract(1, 'week');

                      params.setMany({
                        timePeriod: currentDatePreset,
                        since: updatedSince.format('YYYY-MM-DD'),
                        until: updatedUntil.format('YYYY-MM-DD'),
                      });

                      const forTimePeriod: ForTimePeriodInput = {
                        datePreset: 'custom',
                        since: updatedSince.format('YYYY-MM-DD'),
                        until: updatedUntil.format('YYYY-MM-DD'),
                      };

                      if (adAccount?.data?.uuid) {
                        refetch({
                          adAccountUuid: adAccount?.data?.uuid,
                          forTimePeriod: forTimePeriod,
                        });
                      }
                    } else {
                      const forTimePeriod: ForTimePeriodInput = {
                        datePreset: currentDatePreset,
                      };
                      if (adAccount?.data?.uuid) {
                        refetch({
                          adAccountUuid: adAccount?.data?.uuid,
                          forTimePeriod: forTimePeriod,
                        });
                      }

                      params.setMany({
                        timePeriod: currentDatePreset,
                        since: undefined,
                        until: undefined,
                      });
                    }

                    setOpen(false);
                  }}
                >
                  {LL.save()}
                </Button>
              </div>
            </div>
          </PopoverDialog>
        </Popover>
      </PopoverTrigger>
    </>
  );
}

export default InsightsFilterTimePeriodMenuV2;

const InsightsRangeCalendarHeading = forwardRef(
  (props: HeadingProps, ref: React.ForwardedRef<HTMLHeadingElement>) => {
    [props, ref] = useContextProps(props, ref, HeadingContext);

    if (typeof props.children !== 'string') return null;

    const [leftMonth, rightMonth] = parseDateRange(props.children);

    return (
      <header
        ref={ref}
        className="flex w-full items-center gap-4 py-3"
        {...props}
      >
        <div className="relative flex w-full items-center">
          <Button
            slot="previous"
            className="absolute p-2 hover:bg-purple-100"
            variant="text"
            size="small"
          >
            <ChevronLeft className="size-5 text-primary/50" />
          </Button>
          <span className="grow text-center text-base font-semibold text-primary">
            {leftMonth}
          </span>
        </div>
        <div className="relative flex w-full items-center">
          <span className="grow text-center text-base font-semibold text-primary">
            {rightMonth}
          </span>
          <Button
            slot="next"
            className="absolute right-0 p-2 hover:bg-purple-100"
            variant="text"
            size="small"
          >
            <ChevronRight className="size-5 text-primary/50" />
          </Button>
        </div>
      </header>
    );
  }
);
InsightsRangeCalendarHeading.displayName = 'InsightsRangeCalendarHeading';

function parseDateRange(dateString: string) {
  // Remove any spaces around dashes and normalize the dash
  const normalized = dateString.replace(/\s*[–-]\s*/g, '-');
  const [start, end] = normalized.split('-');

  const startDate = extractMonthYear(start);
  const endDate = extractMonthYear(end);

  // If start doesn't have year, use end year
  if (!startDate.year) {
    startDate.year = endDate.year;
  }

  return [
    `${startDate.month} ${startDate.year}`,
    `${endDate.month} ${endDate.year}`,
  ];
}

// Helper function to extract month and year from a part
function extractMonthYear(part: string) {
  const parts = part.trim().split(' ');
  return {
    month: parts[0],
    year: parts[1] || null,
  };
}
