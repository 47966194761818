import { useEffect, useRef, useState } from 'react';
import { convertDatePresetToDateRange } from '@magicbrief/common';
import { Heading, Text } from 'react-aria-components';
import { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Button } from '@magicbrief/ui/src/components/button';
import { useI18nContext } from 'src/i18n/i18n-react';
import { useInsightsGroup } from 'src/pages/Insights/util/useInsightsPersistentState';
import { useInsightsSearchParams } from 'src/pages/Insights/util/useInsightsSearchParams';
import LayersThree01 from 'src/assets/svgicons/line/layers-three-01.svg';
import Calendar from 'src/assets/svgicons/line/calendar.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import ChevronDown from 'src/assets/svgicons/solid/chevron-down.svg';
import { formatCustomDateLabel } from '../../InsightsFilter/InsightsFilterTimePeriodMenuV2.utils';

interface Props {
  adGroup: InsightsAdGroupWithInsights;
  className?: string;
}

export const AdBreakdownHeader = ({ adGroup, className }: Props) => {
  const { LL } = useI18nContext();
  const group = useInsightsGroup();
  const { forTimePeriod, since, until } =
    useInsightsSearchParams().getParsedValues();

  const sinceAndUntilDates =
    forTimePeriod.datePreset !== 'custom'
      ? convertDatePresetToDateRange(forTimePeriod.datePreset)
      : { since, until };
  const datePreset =
    LL.insights.timePeriod[
      forTimePeriod.datePreset as keyof typeof LL.insights.timePeriod
    ]();
  const timePeriodLabel =
    sinceAndUntilDates.since && sinceAndUntilDates.until
      ? formatCustomDateLabel({
          since: sinceAndUntilDates.since,
          until: sinceAndUntilDates.until,
        })
      : '';

  const { elementRef: textRef, isOverflowing } =
    useLineClampOverflow<HTMLHeadingElement>(2);
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={cn('flex items-start justify-between gap-4', className)}>
      <div className="flex flex-col gap-3">
        <div className="relative">
          <Heading
            ref={textRef}
            className={cn(
              'text-2xl font-semibold text-primary',
              isExpanded ? 'line-clamp-none' : 'line-clamp-2'
            )}
          >
            {adGroup.name ?? 'None'}
          </Heading>
          {isOverflowing && !isExpanded && (
            <div className="absolute inset-x-0 bottom-0 h-8 bg-gradient-to-t from-white to-transparent"></div>
          )}
        </div>

        {isOverflowing && (
          <Button
            className="-mx-1.5 h-fit rounded-md px-1.5 py-1 text-xs"
            variant="text"
            size="small"
            onPress={() => setIsExpanded((prev) => !prev)}
          >
            Read {isExpanded ? 'less' : 'more'}
            <ChevronDown
              className={cn('size-3.5', isExpanded ? 'rotate-180' : '')}
            />
          </Button>
        )}

        <div className="flex items-center">
          {/* Group By subheader */}
          {group && group !== 'true' ? (
            <>
              <LayersThree01 className="mr-1.5 size-[14px] text-primary/50" />
              <Text className="mr-4 text-sm font-medium text-primary/50">
                Group by{' '}
                <Text className="text-primary">
                  {LL.insights.groups[group]()}
                </Text>
              </Text>
            </>
          ) : null}

          {/* Date subheader */}
          <>
            <Calendar className="mr-1.5 size-[14px] text-primary/50" />
            <Text className="text-sm font-medium text-primary/50">
              {datePreset}{' '}
              <Text className="text-primary">{timePeriodLabel}</Text>
            </Text>
          </>
        </div>
      </div>

      {/* Close Button */}
      <Button variant="text" size="small" className="size-10 p-2" slot="close">
        <XClose className="size-5" />
      </Button>
    </div>
  );
};

/**
 * Custom hook to detect if text is overflowing a line clamp
 * @param {number} maxLines - Maximum number of lines (default: 2)
 * @return {[React.RefObject, boolean]} - Tuple containing the ref and overflow state
 */
function useLineClampOverflow<T extends HTMLElement>(maxLines = 2) {
  const elementRef = useRef<T>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      const element = elementRef.current;
      if (!element) return;

      const style = window.getComputedStyle(element);

      let lineHeight;
      if (style.lineHeight === 'normal') {
        // Estimate line height based on font size
        const fontSize = parseFloat(style.fontSize);
        lineHeight = fontSize * 1.2;
      } else {
        lineHeight = parseFloat(style.lineHeight);
      }

      const maxHeight = lineHeight * maxLines;
      setIsOverflowing(element.scrollHeight > maxHeight);
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);

    const observer = new MutationObserver(checkOverflow);
    if (elementRef.current) {
      observer.observe(elementRef.current, {
        childList: true,
        characterData: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener('resize', checkOverflow);
      observer.disconnect();
    };
  }, [maxLines]);

  return { elementRef, isOverflowing };
}
