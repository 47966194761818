import { InsightsFacebookCreativeType } from '@magicbrief/common';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { useI18nContext } from 'src/i18n/i18n-react';

interface CreativeTypeChipProps extends React.ComponentPropsWithoutRef<'div'> {
  creativeType: InsightsFacebookCreativeType;
}

export const CreativeTypeChip = ({
  creativeType,
  className,
  ...props
}: CreativeTypeChipProps) => {
  const { LL } = useI18nContext();

  return (
    <div
      className={cn(
        'flex items-center rounded bg-[rgb(17,9,43)] bg-opacity-50 px-2 py-1 text-xs font-medium text-white',
        className
      )}
      {...props}
    >
      {LL.insights.creativeType[creativeType]()}
    </div>
  );
};
