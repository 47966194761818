import { cva, type VariantProps } from 'class-variance-authority';
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
} from 'react-aria-components';
import { Spinner } from './spinner';
import { cn } from '../lib/cn';
import { forwardRef } from 'react';

const buttonVariants = cva(
  [
    /* Base */
    'inline-flex w-fit items-center justify-center gap-2 whitespace-nowrap rounded-lg shadow-sm outline-none ring-purple-500 transition-colors duration-300 ease-out [&_svg]:size-4',
    /* Typography */
    'text-sm font-semibold',
    /* Focus */
    'focus:ring-1',
    /* Disabled */
    'disabled:pointer-events-none disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        primary: 'bg-primary text-white hover:bg-purple-900',
        secondary:
          'border border-purple-200 bg-white text-primary hover:bg-purple-50',
        text: 'text-primary shadow-none hover:bg-purple-50',
        destructive:
          'border border-red-200 bg-red-100 text-red-700 hover:bg-red-200',
      },
      size: {
        small: 'h-9 px-3 py-2',
        medium: 'h-10 px-3.5 py-2.5',
        large: 'h-12 px-4 py-3',
      },
    },
  }
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {
  variant?: 'primary' | 'secondary' | 'text' | 'destructive';
  size?: 'small' | 'medium' | 'large';
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = 'primary',
      size = 'medium',
      loading = false,
      isDisabled,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <AriaButton
        ref={ref}
        className={cn(buttonVariants({ variant, size }), className)}
        isDisabled={isDisabled || loading}
        {...props}
      >
        {(renderProps) => (
          <>
            {loading ? <Spinner className="size-4" /> : null}
            {typeof children === 'function' ? children(renderProps) : children}
          </>
        )}
      </AriaButton>
    );
  }
);
Button.displayName = 'Button';

// eslint-disable-next-line react-refresh/only-export-components
export { Button, buttonVariants };
export type { ButtonProps };
