import { InsightsSort } from './useInsightsPersistentState';
import { getValueForMetric } from './useParseMetric';
import type {
  InsightsAdWithInsights,
  InsightsAdGroupWithInsights,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';

export function sortInsightsGroupsClientSide<
  T extends InsightsAdGroupWithInsights | InsightsAdWithInsights,
>(items: Array<T>, sortOrder: InsightsSort[] | undefined): Array<T> {
  if (!sortOrder) return items;
  return items.sort((a, b) => {
    for (const sort of sortOrder) {
      switch (sort.id) {
        case 'name':
        case 'adName':
        case 'group': {
          if (a.name === b.name) return 0;
          if (a.name == null) return 1;
          if (b.name == null) return -1;
          const comparison = a.name.localeCompare(b.name);
          return sort.desc ? -comparison : comparison;
        }
        default: {
          const valueA = getValueForMetric(sort.id, a.metrics);
          const valueB = getValueForMetric(sort.id, b.metrics);
          if (Array.isArray(valueA) || Array.isArray(valueB)) return 0;
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return sort.desc
              ? valueB.localeCompare(valueA)
              : valueA.localeCompare(valueB);
          }
          if (valueA instanceof Date && valueB instanceof Date) {
            return sort.desc
              ? valueB.getTime() - valueA.getTime()
              : valueA.getTime() - valueB.getTime();
          }
          if (valueA === valueB) return 0;
          if (valueA == null) return 1;
          if (valueB == null) return -1;
          const comparison = (valueA as number) - (valueB as number);
          return sort.desc ? -comparison : comparison;
        }
      }
    }
    return 0;
  });
}
