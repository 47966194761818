import React from 'react';
import {
  Dialog,
  DialogProps,
  DialogTrigger,
  PopoverProps,
} from 'react-aria-components';
import classNames from 'classnames';
import { AriaPopover } from '../AriaPopover/AriaPopover';
import { usePopoverState } from '../AriaPopover/usePopoverState';

type Props = Omit<
  PopoverProps,
  | 'children'
  | 'isExiting'
  | 'onAnimationComplete'
  | 'className'
  | 'trigger'
  | 'style'
> & {
  isDisabled?: boolean;
  className?: string;
  children: [React.ReactElement, DialogProps['children']];
  style?: React.CSSProperties;
};

export const DialogPopover: React.FC<Props> = ({
  children,
  className,
  isDisabled,
  ...props
}) => {
  const [popoverState, setPopoverState] = usePopoverState();

  return (
    <DialogTrigger
      onOpenChange={(isOpen) => {
        if (!isDisabled) {
          setPopoverState(isOpen ? 'animate' : 'initial');
        }
        props.onOpenChange?.(isOpen);
      }}
    >
      {children[0]}
      <AriaPopover
        {...props}
        state={popoverState}
        onStateChange={setPopoverState}
        className="rounded-md bg-white shadow-md ring-1 ring-purple-300 focus:outline-none focus-visible:outline-none"
      >
        <Dialog
          className={classNames(
            'focus:outline-none focus-visible:outline-none',
            className
          )}
        >
          {children[1]}
        </Dialog>
      </AriaPopover>
    </DialogTrigger>
  );
};
