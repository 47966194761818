import { useLayoutEffect, useRef, useState } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import {
  Dialog,
  Heading,
  Label,
  Modal,
  ModalOverlay,
  TextField,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import Input from 'src/components/Input';
import { trpc } from 'src/lib/trpc';
import {
  useInsightsDisplay,
  useInsightsGridMetrics,
  useInsightsLayout,
} from 'src/pages/Insights/util/useInsightsPersistentState';
import { presetAtom } from '../../InsightsTable/TableMetricPresets.atoms';

interface InsightsCreatePresetModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InsightsCreatePresetModal = ({
  isOpen,
  setIsOpen,
}: InsightsCreatePresetModalProps) => {
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();
  const layout = useInsightsLayout();
  const tableMetrics = useInsightsDisplay();
  const gridMetrics = useInsightsGridMetrics();

  const setPreset = useSetAtom(presetAtom);

  const inputRef = useRef<HTMLInputElement>(null);
  const [presetName, setPresetName] = useState('');

  const metrics = layout === 'full-grid' ? gridMetrics : tableMetrics;

  const createPreset = trpc.insights.createInsightsMetricPreset.useMutation({
    onSuccess: (response) => {
      void trpcUtils.insights.getInsightsMetricPresets.invalidate();
      setIsOpen(false);
      setPreset(response.uuid);
      toast.success('Preset successfully created');
    },
    onError: (e) => {
      toast.error(e.message ?? 'Error creating preset');
    },
  });

  useLayoutEffect(() => {
    inputRef.current?.focus();
  }, [isOpen]);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative flex w-[480px] flex-col gap-6 overflow-y-auto rounded-2xl border border-purple-200 bg-white p-6 shadow focus-visible:outline-none"
          aria-label="create table metrics preset modal"
        >
          <Heading className="text-xl font-semibold text-primary">
            Save as New Preset
          </Heading>
          <TextField className="flex flex-col gap-1">
            <Label className="text-sm font-semibold text-primary">
              Preset Name
            </Label>
            <Input
              ref={inputRef}
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              name="preset"
              placeholder="e.g. Performance Metrics"
            />
          </TextField>

          <div className="flex items-center justify-end gap-2.5">
            <Button variant="secondary" size="small" slot="close">
              Cancel
            </Button>
            <Button
              size="small"
              onPress={() => {
                createPreset.mutate({
                  name: presetName,
                  metrics,
                  insightsAdAccountUuid: accountUuid ?? '',
                });
              }}
              loading={createPreset.isLoading}
            >
              Create Preset
            </Button>
          </div>
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
};
