import { useRef, useState } from 'react';
import { InsightsServiceTagTaxonomyTag } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { AnimatePresence } from 'motion/react';
import {
  Checkbox,
  Dialog,
  Heading,
  Input,
  SearchField,
  Button as AriaButton,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { AriaMenu } from 'src/components/AriaMenu/AriaMenu';
import { MotionModal } from 'src/components/AriaModal/components/MotionModal';
import { MotionModalOverlay } from 'src/components/AriaModal/components/MotionModalOverlay';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import SearchSm from 'src/assets/svgicons/duotone/search-sm.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import DotsVertical from 'src/assets/svgicons/line/dots-vertical.svg';
import Edit05 from 'src/assets/svgicons/line/edit-05.svg';
import Trash01 from 'src/assets/svgicons/line/trash-01.svg';
import { CreateOrEditTagModal } from './CreateOrEditTagModal/CreateOrEditTagModal';
import { DeleteTagModal } from './DeleteTagModal';

function tagSearch(
  query: string,
  tags: InsightsServiceTagTaxonomyTag[],
  categories: Partial<Record<string, { label: string }>>
): Array<InsightsServiceTagTaxonomyTag> {
  if (!query) return tags;
  return tags.filter((x) => {
    if (x.label.toLowerCase().includes(query)) return true;
    if (!x.categoryUuid) return false;
    const category = categories[x.categoryUuid];
    if (category?.label.toLowerCase().includes(query)) return true;
    return false;
  });
}

export const InsightsTagSelectionModal: React.FC<{
  onApply: (result: {
    added: string[];
    removed: string[];
    selection: string[];
  }) => void;
  onClose: () => void;
  initialTagsSelected: string[] | null;
}> = ({ onClose, initialTagsSelected, onApply }) => {
  const { accountUuid } = useParams();

  const { LL } = useI18nContext();
  const [isInternalOpen, setIsInternalOpen] = useState(true);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<0 | 1 | string>(0);
  const [selectedTags, setSelectedTags] = useState(initialTagsSelected ?? []);

  const [isCreateTagModalOpen, setIsCreateTagModalOpen] = useState<
    { type: 'create' } | { type: 'edit'; uuid: string } | null
  >(null);

  const [deleteTagUuid, setDeleteTagUuid] = useState<string | null>(null);

  const tagTaxonomy = trpc.insightsTags.getTagTaxonomyForAccount.useQuery(
    {
      accountUuid: accountUuid ?? '',
    },
    {
      enabled: !!accountUuid,
    }
  );

  const recentlyUsedTags = trpc.insights.getRecentlyUsedInsightsTags.useQuery(
    { adAccountUuid: accountUuid ?? '' },
    { enabled: !!accountUuid }
  );

  const allTags = tagTaxonomy.data ? Object.values(tagTaxonomy.data.tags) : [];

  const categoryTagMap = allTags.reduce<{
    categorised: Record<
      string,
      {
        label: string;
        uuid: string;
        tags: Array<InsightsServiceTagTaxonomyTag>;
      }
    >;
    uncategorised: Array<InsightsServiceTagTaxonomyTag>;
    allNonRecent: Array<InsightsServiceTagTaxonomyTag>;
    count: number;
  }>(
    (acc, curr) => {
      if (curr.categoryUuid) {
        const category = tagTaxonomy.data?.categories[curr.categoryUuid];
        if (!category) return acc;
        acc.categorised[curr.categoryUuid] = {
          label: category.label,
          uuid: category.uuid,
          tags: [...(acc.categorised[curr.categoryUuid]?.tags ?? []), curr],
        };
      } else {
        acc.uncategorised.push(curr);
      }
      if (!recentlyUsedTags?.data?.find((x) => x.uuid === curr.uuid)) {
        acc.allNonRecent.push(curr);
      }
      acc.count += 1;
      return acc;
    },
    { categorised: {}, uncategorised: [], allNonRecent: [], count: 0 }
  );

  const searchTags = tagSearch(
    search,
    selectedCategory === 0
      ? allTags
      : selectedCategory === 1
        ? categoryTagMap.uncategorised
        : (categoryTagMap.categorised[selectedCategory]?.tags ?? []),
    tagTaxonomy.data?.categories ?? {}
  );

  return (
    <AnimatePresence onExitComplete={onClose}>
      {isInternalOpen && (
        <MotionModalOverlay
          isOpen
          onOpenChange={(s) => {
            setIsInternalOpen(s);
          }}
          className="overflow-visible"
        >
          <MotionModal className="flex h-3/4 w-1/2">
            <Dialog className="flex size-full flex-col overflow-hidden rounded-lg bg-white shadow outline-none">
              <SearchField
                className="relative"
                aria-label="display metric search"
              >
                <Icon className="absolute left-3 top-1/2 size-5 -translate-y-1/2 text-primary">
                  <SearchSm />
                </Icon>
                <Input
                  ref={searchRef}
                  type="text"
                  className="text-md w-full border-b border-solid border-purple-200 bg-white py-3 pl-10 pr-3 font-medium text-primary focus:outline-none"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder={
                    selectedCategory === 0
                      ? LL.searchPlaceholder()
                      : LL.searchNamedPlaceholder(
                          selectedCategory === 1
                            ? 'Uncategorized'
                            : (tagTaxonomy.data?.categories[selectedCategory]
                                ?.label ?? 'Unknown Category')
                        )
                  }
                />
              </SearchField>

              <div className="grid flex-1 grid-cols-10 overflow-hidden">
                <div className="relative col-span-3 overflow-auto border-b border-r border-solid border-purple-200 p-2.5">
                  <AriaMenu.List
                    selectionMode="single"
                    selectedKeys={[selectedCategory]}
                    onAction={(key) => {
                      setSelectedCategory(key as typeof selectedCategory);
                    }}
                  >
                    <AriaMenu.Item
                      className="text-xs selected:bg-purple-100"
                      contentClassName="flex flex-1 justify-between gap-4 min-w-0"
                      id={0}
                      key={0}
                      showCheckbox={false}
                    >
                      <span className="truncate">All tags</span>
                      <span className="text-text-secondary">
                        {categoryTagMap.count}
                      </span>
                    </AriaMenu.Item>
                    {Object.values(categoryTagMap.categorised).map(
                      (category) => (
                        <AriaMenu.Item
                          className="text-xs selected:bg-purple-100"
                          contentClassName="flex flex-1 justify-between gap-4 min-w-0"
                          showCheckbox={false}
                          id={category.uuid}
                          key={category.uuid}
                        >
                          <span className="truncate">{category.label}</span>
                          <span className="text-text-secondary">
                            {category.tags.length}
                          </span>
                        </AriaMenu.Item>
                      )
                    )}
                    <AriaMenu.Item
                      className="text-xs selected:bg-purple-100"
                      contentClassName="flex flex-1 justify-between gap-4 min-w-0"
                      showCheckbox={false}
                      id={1}
                      key={1}
                    >
                      <span className="truncate">Uncategorized</span>
                      <span className="text-text-secondary">
                        {categoryTagMap.uncategorised.length}
                      </span>
                    </AriaMenu.Item>
                  </AriaMenu.List>
                </div>
                <div className="col-span-7 space-y-2 overflow-auto border-b border-solid border-purple-200 p-2.5">
                  {!!selectedTags.length && (
                    <div className="space-y-2 border-b border-solid border-purple-200 pb-2">
                      <div className="space-y-2">
                        <Heading className="text-xs text-text-secondary">
                          Selected ({selectedTags.length})
                        </Heading>
                        <div>
                          {selectedTags.map((selected) =>
                            tagTaxonomy.data?.tags[selected]?.label ? (
                              <InsightsTagModalCheckbox
                                key={selected}
                                isSelected
                                onEdit={() => {
                                  setIsCreateTagModalOpen({
                                    type: 'edit',
                                    uuid: selected,
                                  });
                                }}
                                onDelete={() => {
                                  setDeleteTagUuid(selected);
                                }}
                                onChange={(isSelected) => {
                                  setSelectedTags((s) => {
                                    if (isSelected) {
                                      return [...s, selected];
                                    }
                                    return s.filter((x) => x !== selected);
                                  });
                                }}
                                label={
                                  tagTaxonomy.data?.tags[selected]?.label ??
                                  'Unknown'
                                }
                                numAds={
                                  tagTaxonomy.data?.tags[selected]?.numAds ??
                                  null
                                }
                              />
                            ) : null
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {search ? (
                    <div className="space-y-2">
                      <Heading className="text-xs text-text-secondary">
                        Results for &ldquo;{search}&rdquo;
                        {selectedCategory === 1
                          ? ' in Uncategorized'
                          : typeof selectedCategory === 'string'
                            ? ` in ${tagTaxonomy.data?.categories[selectedCategory]?.label ?? 'Unknown Category'}`
                            : null}{' '}
                        ({searchTags.length})
                      </Heading>
                      <div>
                        {searchTags.map((tag) => (
                          <InsightsTagModalCheckbox
                            key={tag.uuid}
                            isSelected={selectedTags.includes(tag.uuid)}
                            onEdit={() => {
                              setIsCreateTagModalOpen({
                                type: 'edit',
                                uuid: tag.uuid,
                              });
                            }}
                            onDelete={() => {
                              setDeleteTagUuid(tag.uuid);
                            }}
                            onChange={(isSelected) => {
                              setSelectedTags((s) => {
                                if (isSelected) {
                                  return [...s, tag.uuid];
                                }
                                return s.filter((x) => x !== tag.uuid);
                              });
                            }}
                            label={
                              tagTaxonomy.data?.tags[tag.uuid]?.label ??
                              'Unknown'
                            }
                            numAds={
                              tagTaxonomy.data?.tags[tag.uuid]?.numAds ?? null
                            }
                          />
                        ))}
                        {searchTags.length === 0 && (
                          <div className="text-xs text-text-secondary">
                            No tags or categories match the query &ldquo;
                            {search}&rdquo;
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      {selectedCategory === 0 ? (
                        <>
                          <div className="space-y-2 border-b border-solid border-purple-200 pb-2">
                            {!!recentlyUsedTags.data?.length && (
                              <div className="space-y-2">
                                <Heading className="text-xs text-text-secondary">
                                  Recent ({recentlyUsedTags.data.length})
                                </Heading>
                                <div className="">
                                  {recentlyUsedTags.data.map((tag) => (
                                    <InsightsTagModalCheckbox
                                      key={tag.uuid}
                                      isSelected={selectedTags.includes(
                                        tag.uuid
                                      )}
                                      onEdit={() => {
                                        setIsCreateTagModalOpen({
                                          type: 'edit',
                                          uuid: tag.uuid,
                                        });
                                      }}
                                      onDelete={() => {
                                        setDeleteTagUuid(tag.uuid);
                                      }}
                                      onChange={(isSelected) => {
                                        setSelectedTags((s) => {
                                          if (isSelected) {
                                            return [...s, tag.uuid];
                                          }
                                          return s.filter(
                                            (x) => x !== tag.uuid
                                          );
                                        });
                                      }}
                                      label={
                                        tagTaxonomy.data?.tags[tag.uuid]
                                          ?.label ?? 'Unknown'
                                      }
                                      numAds={
                                        tagTaxonomy.data?.tags[tag.uuid]
                                          ?.numAds ?? null
                                      }
                                    />
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>

                          {!!categoryTagMap.allNonRecent.length && (
                            <div className="space-y-2">
                              <Heading className="text-xs text-text-secondary">
                                Others ({categoryTagMap.allNonRecent.length})
                              </Heading>
                              <div className="">
                                {categoryTagMap.allNonRecent.map((tag) => (
                                  <InsightsTagModalCheckbox
                                    key={tag.uuid}
                                    isSelected={selectedTags.includes(tag.uuid)}
                                    onEdit={() => {
                                      setIsCreateTagModalOpen({
                                        type: 'edit',
                                        uuid: tag.uuid,
                                      });
                                    }}
                                    onDelete={() => {
                                      setDeleteTagUuid(tag.uuid);
                                    }}
                                    onChange={(isSelected) => {
                                      setSelectedTags((s) => {
                                        if (isSelected) {
                                          return [...s, tag.uuid];
                                        }
                                        return s.filter((x) => x !== tag.uuid);
                                      });
                                    }}
                                    label={
                                      tagTaxonomy.data?.tags[tag.uuid]?.label ??
                                      'Unknown'
                                    }
                                    numAds={
                                      tagTaxonomy.data?.tags[tag.uuid]
                                        ?.numAds ?? null
                                    }
                                  />
                                ))}
                              </div>
                            </div>
                          )}
                        </>
                      ) : selectedCategory === 1 ? (
                        <div className="">
                          {categoryTagMap.uncategorised.map((tag) => (
                            <InsightsTagModalCheckbox
                              key={tag.uuid}
                              isSelected={selectedTags.includes(tag.uuid)}
                              onEdit={() => {
                                setIsCreateTagModalOpen({
                                  type: 'edit',
                                  uuid: tag.uuid,
                                });
                              }}
                              onDelete={() => {
                                setDeleteTagUuid(tag.uuid);
                              }}
                              onChange={(isSelected) => {
                                setSelectedTags((s) => {
                                  if (isSelected) {
                                    return [...s, tag.uuid];
                                  }
                                  return s.filter((x) => x !== tag.uuid);
                                });
                              }}
                              label={
                                tagTaxonomy.data?.tags[tag.uuid]?.label ??
                                'Unknown'
                              }
                              numAds={
                                tagTaxonomy.data?.tags[tag.uuid]?.numAds ?? null
                              }
                            />
                          ))}
                        </div>
                      ) : (
                        <div>
                          {categoryTagMap.categorised[
                            selectedCategory
                          ].tags.map((tag) => (
                            <InsightsTagModalCheckbox
                              key={tag.uuid}
                              isSelected={selectedTags.includes(tag.uuid)}
                              onEdit={() => {
                                setIsCreateTagModalOpen({
                                  type: 'edit',
                                  uuid: tag.uuid,
                                });
                              }}
                              onDelete={() => {
                                setDeleteTagUuid(tag.uuid);
                              }}
                              onChange={(isSelected) => {
                                setSelectedTags((s) => {
                                  if (isSelected) {
                                    return [...s, tag.uuid];
                                  }
                                  return s.filter((x) => x !== tag.uuid);
                                });
                              }}
                              label={
                                tagTaxonomy.data?.tags[tag.uuid]?.label ??
                                'Unknown'
                              }
                              numAds={
                                tagTaxonomy.data?.tags[tag.uuid]?.numAds ?? null
                              }
                            />
                          ))}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className="flex justify-between gap-2.5 px-2.5 py-3">
                {isCreateTagModalOpen && (
                  <CreateOrEditTagModal
                    accountUuid={accountUuid ?? ''}
                    adUuids={[]}
                    editTagUuid={
                      isCreateTagModalOpen.type === 'edit'
                        ? isCreateTagModalOpen.uuid
                        : undefined
                    }
                    onClose={() => setIsCreateTagModalOpen(null)}
                  />
                )}

                {!!deleteTagUuid && (
                  <DeleteTagModal
                    accountUuid={accountUuid ?? ''}
                    tagUuid={deleteTagUuid}
                    onClose={() => setDeleteTagUuid(null)}
                  />
                )}

                <Button
                  size="small"
                  variant="secondary"
                  onPress={() => setIsCreateTagModalOpen({ type: 'create' })}
                >
                  New Tag
                </Button>
                <div className="flex gap-2.5">
                  <Button
                    size="small"
                    variant="secondary"
                    onPress={() => setIsInternalOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    variant="primary"
                    onPress={() => {
                      onApply({
                        added:
                          selectedTags?.filter(
                            (x) => !initialTagsSelected?.find((y) => y === x)
                          ) ?? [],
                        removed:
                          initialTagsSelected?.filter(
                            (x) => !selectedTags?.find((y) => y === x)
                          ) ?? [],
                        selection: selectedTags,
                      });
                      setIsInternalOpen(false);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </Dialog>
          </MotionModal>
        </MotionModalOverlay>
      )}
    </AnimatePresence>
  );
};

const InsightsTagModalCheckbox: React.FC<{
  label: string;
  numAds: number | null;
  isSelected: boolean;
  onChange: (selected: boolean) => void;
  onEdit: () => void;
  onDelete: () => void;
}> = ({ label, numAds, isSelected, onChange, onEdit, onDelete }) => {
  return (
    <div className="flex flex-row items-center gap-2.5">
      <Checkbox
        isSelected={isSelected}
        onChange={onChange}
        className="group/checkbox flex grow cursor-pointer items-center gap-2.5 px-2.5 py-2 disabled:cursor-not-allowed disabled:opacity-50"
      >
        <div
          className={cn(
            /** Base */
            'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
            /** Selected */
            'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
          )}
        >
          <Icon className="hidden size-4 text-white group-selected/checkbox:block">
            <Check />
          </Icon>
        </div>
        <span className="grow truncate text-xs text-primary">{label}</span>
        <div className="truncate text-xs text-text-secondary">{numAds}</div>
      </Checkbox>

      <AriaMenu>
        <AriaButton>
          <Icon className="size-3 shrink-0 text-text-secondary">
            <DotsVertical />
          </Icon>
        </AriaButton>
        <AriaMenu.List
          onAction={(key) => {
            if (key === 'edit') {
              onEdit();
            } else {
              onDelete();
            }
          }}
        >
          <AriaMenu.Item
            id="edit"
            icon={
              <Icon>
                <Edit05 />
              </Icon>
            }
          >
            Edit Tag
          </AriaMenu.Item>
          <AriaMenu.Item
            danger
            id="delete"
            icon={
              <Icon>
                <Trash01 />
              </Icon>
            }
          >
            Delete Tag from All Ads
          </AriaMenu.Item>
        </AriaMenu.List>
      </AriaMenu>
    </div>
  );
};
