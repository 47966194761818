import {
  Button as AriaButton,
  ButtonProps as AriaButtonProps,
  ListBox as AriaListBox,
  ListBoxProps as AriaListBoxProps,
  PopoverProps as AriaPopoverProps,
  Select as AriaSelect,
  SelectValue as AriaSelectValue,
  SelectValueProps as AriaSelectValueProps,
  composeRenderProps,
} from 'react-aria-components';
import { ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import { cn } from '@magicbrief/ui/src/lib/cn';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import { Popover } from '../Popover/AriaPopover';
import { Icon } from '../Icon';

const Select = AriaSelect;

const SelectItem = ListBoxItem;

const SelectValue = <T extends object>({
  className,
  ...props
}: AriaSelectValueProps<T>) => (
  <AriaSelectValue
    className={composeRenderProps(className, (className) =>
      cn(
        'line-clamp-1 text-primary data-[placeholder]:text-[#917DD2]',
        /* Description */
        '[&>[slot=description]]:hidden',
        className
      )
    )}
    {...props}
  />
);

const SelectTrigger = ({ className, children, ...props }: AriaButtonProps) => (
  <AriaButton
    className={composeRenderProps(className, (className) =>
      cn(
        'flex h-10 w-fit items-center justify-between gap-3 rounded-md border border-purple-200 bg-white px-3 py-2 text-sm shadow-sm ring-offset-white',
        /* Disabled */
        'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50',
        /* Focused */
        'data-[focus-visible]:outline-none data-[focus-visible]:ring-2 data-[focus-visible]:ring-purple-300 data-[focus-visible]:ring-offset-2',
        /* Resets */
        'focus-visible:outline-none',
        className
      )
    )}
    {...props}
  >
    {composeRenderProps(children, (children) => (
      <>
        {children}
        <Icon className="size-5 text-primary opacity-50" aria-hidden="true">
          <ChevronDown />
        </Icon>
      </>
    ))}
  </AriaButton>
);

const SelectPopover = ({ className, ...props }: AriaPopoverProps) => (
  <Popover
    className={composeRenderProps(className, (className) =>
      cn('w-[--trigger-width]', className)
    )}
    {...props}
  />
);

const SelectListBox = <T extends object>({
  className,
  ...props
}: AriaListBoxProps<T>) => (
  <AriaListBox
    className={composeRenderProps(className, (className) =>
      cn(
        'max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
        className
      )
    )}
    {...props}
  />
);

export {
  Select,
  SelectValue,
  SelectTrigger,
  SelectItem,
  SelectPopover,
  SelectListBox,
};
