import { useMatch } from 'react-router-dom';
import {
  HorizontalTabbedRadio,
  HorizontalTabbedRadioGroup,
} from 'src/components/HorizontalTabbedRadio/HorizontalTabbedRadio';
import BarChart10 from 'src/assets/svgicons/line/bar-chart-10.svg';
import Grid01 from 'src/assets/svgicons/duocolor/grid-01.svg';
import Copy03 from 'src/assets/svgicons/line/copy-03.svg';
import {
  InsightsLayout,
  useInsightsLayout,
  useInsightsStoreDispatch,
} from '../util/useInsightsPersistentState';

export const InsightsViewRadioGroup: React.FC = () => {
  const dispatch = useInsightsStoreDispatch();
  const layout = useInsightsLayout();

  const isAnalysis = useMatch('/insights/accounts/:accountUuid/analysis');

  if (isAnalysis) {
    return (
      <HorizontalTabbedRadioGroup
        data-intercom-target="insight_viewMode"
        groupAriaLabel="View"
        value={layout}
        onChange={(value) => {
          const layout = value as InsightsLayout;
          dispatch({
            type: 'setLayout',
            value: layout,
          });
        }}
      >
        <HorizontalTabbedRadio aria-label="Table" value="table">
          <BarChart10 className="size-4" />
        </HorizontalTabbedRadio>
        <HorizontalTabbedRadio aria-label="Grid" value="grid">
          <Grid01 className="size-4" />
        </HorizontalTabbedRadio>
      </HorizontalTabbedRadioGroup>
    );
  }

  return (
    <HorizontalTabbedRadioGroup
      data-intercom-target="insight_viewMode"
      groupAriaLabel="View"
      value={layout}
      onChange={(value) => {
        const layout = value as InsightsLayout;
        dispatch({
          type: 'setLayout',
          value: layout,
        });
      }}
    >
      <HorizontalTabbedRadio aria-label="Table" value="table">
        <BarChart10 className="size-4" />
      </HorizontalTabbedRadio>
      <HorizontalTabbedRadio aria-label="Grid" value="grid">
        <Copy03 className="size-4" />
      </HorizontalTabbedRadio>
      <HorizontalTabbedRadio aria-label="Full Grid" value="full-grid">
        <Grid01 className="size-4" />
      </HorizontalTabbedRadio>
    </HorizontalTabbedRadioGroup>
  );
};
