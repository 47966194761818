import { PropsWithChildren, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';
import { InsightsSyncSummary } from '@magicbrief/server/src/insights/tik-tok/queues/tik-tok-ad-account-ingestor/tik-tok-ad-account-ingestor.queue';
import { trpc } from 'src/lib/trpc';

export const InsightsJobWrapper = ({ children }: PropsWithChildren) => {
  const trpcUtils = trpc.useUtils();
  const { accountUuid } = useParams();

  const prevAccountUuid = useRef<string | null>(null);

  const prevJobData = useRef<
    Partial<{ [accountUuid: string]: InsightsSyncSummary[] }>
  >({});

  const jobs = trpc.insightsAccounts.getAdAccountSyncStatus.useQuery(
    { adAccountUuid: accountUuid ?? '' },
    {
      enabled: !!accountUuid,

      refetchInterval(data) {
        if (!Array.isArray(data)) return false;
        if (data.find((x) => x.status === 'processing')) {
          return 5 * 1000; // 5 sec
        }

        return false;
      },
    }
  );

  useEffect(() => {
    if (accountUuid) {
      // User changed account, invalidate last selected account's sync status cache
      // so that if we switch back to that account we refetch the sync status
      if (accountUuid !== prevAccountUuid.current) {
        void trpcUtils.insightsAccounts.getAdAccountSyncStatus.invalidate({
          adAccountUuid: prevAccountUuid.current ?? '',
        });
      }
      prevAccountUuid.current = accountUuid;
    }

    // User navigated away from insights, invalidate all sync status caches
    return () => {
      void trpcUtils.insightsAccounts.getAdAccountSyncStatus.invalidate();
    };
  }, [trpcUtils, accountUuid]);

  useEffect(() => {
    if (jobs.data && accountUuid) {
      const oldData = prevJobData.current[accountUuid];
      if (oldData) {
        // Compare *all* old jobs to new jobs data so we can ensure that
        // we invalidate queries whose syncs complete even when they aren't
        // actively being viewed
        const differingJobs = jobs.data.filter((currentJob) => {
          const oldEquivalent = oldData.find((oldJob) => {
            if (
              oldJob.forTimePeriod.datePreset === 'custom' &&
              currentJob.forTimePeriod.datePreset === 'custom'
            ) {
              return isEqual(currentJob.forTimePeriod, oldJob.forTimePeriod);
            }
            return (
              currentJob.forTimePeriod.datePreset ===
              oldJob.forTimePeriod.datePreset
            );
          });

          // Check if job appeared and completed sync before we even saw it last
          if (!oldEquivalent && currentJob.status === 'completed') {
            return true;
          }

          // Otherwise if we found an old matching job, check if there's been a change
          if (oldEquivalent) {
            if (
              currentJob.asOfTime.getTime() >
                oldEquivalent.asOfTime.getTime() &&
              currentJob.status === 'completed'
            ) {
              return true;
            }
          }

          return false;
        });

        for (const differingJob of differingJobs) {
          void trpcUtils.insightsAds.getManyAds.invalidate({
            accountUuid,
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insightsAds.getManyAdGroups.invalidate({
            accountUuid,
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insightsAds.getAdGroup.invalidate({
            accountUuid,
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insightsAds.getStatisticsForAds.invalidate({
            accountUuid,
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insights.getSprintCounts.invalidate({
            uuid: accountUuid,
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insightsAds.getManyAdsGroupedByAssetWithTags.invalidate(
            {
              accountUuid,
            }
          );
          void trpcUtils.insights.getCustomEventsAndConversionsForInsightsAdAccount.invalidate(
            {
              insightsAdAccountFacebookUuid: accountUuid,
            }
          );

          // These queries do not rely on account, so we have to invalidate all of them
          void trpcUtils.insightsAds.getAd.invalidate({
            forTimePeriod: differingJob.forTimePeriod,
          });
          void trpcUtils.insightsAds.getAdByOrganisationAdUuid.invalidate({
            forTimePeriod: differingJob.forTimePeriod,
          });
        }
      }

      prevJobData.current[accountUuid] = jobs.data;
    }
  }, [jobs.data, accountUuid, trpcUtils]);

  return children;
};
