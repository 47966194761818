import { useContext } from 'react';
import { InsightsAdBreakdownContext } from './InsightsAdBreakdownPanel.context';

export const useInsightsAdBreakdown = () => {
  const context = useContext(InsightsAdBreakdownContext);
  if (!context) {
    throw new Error(
      'useInsightsAdBreakdown must be used within a InsightsAdBreakdownProvider'
    );
  }
  return context;
};
