import { Dayjs } from 'dayjs';
import dayjs from 'src/lib/dayjs';

export function formatCustomDateLabel({
  since,
  until,
}: {
  since: string | Dayjs;
  until: string | Dayjs;
}) {
  const startDate = dayjs(since);
  const endDate = dayjs(until);

  // If user selects the same date for both start and end, just display a single date
  if (startDate.isSame(endDate, 'day')) {
    return startDate.format('MMM D, YYYY');
  }

  // For date ranges within the same year, just display the year once at the end of the custom label
  if (startDate.year() === endDate.year()) {
    return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
  }

  return `${startDate.format('MMM D, YYYY')} - ${endDate.format(
    'MMM D, YYYY'
  )}`;
}
