import { TOUR_LINK } from '@magicbrief/common';
import { Text } from 'react-aria-components';
import { Link, useParams, type LinkProps } from 'react-router-dom';
import Edit04 from 'src/assets/svgicons/line/edit-04.svg';
import Plus from 'src/assets/svgicons/line/plus.svg';
import Bookmark from 'src/assets/svgicons/line/bookmark.svg';
import VideoRecorder from 'src/assets/svgicons/line/video-recorder.svg';
import { Icon } from 'src/components/Icon';

interface QuickActionCardProps extends LinkProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  external?: boolean;
}

export const DashboardQuickActions = () => {
  const { accountUuid } = useParams();

  const actions: QuickActionCardProps[] = [
    {
      icon: <Plus />,
      to: `/insights/accounts/${accountUuid}/overview`,
      title: 'Uncover insights',
      description: 'Create a new report',
    },
    {
      icon: <Edit04 />,
      to: `/insights/accounts/${accountUuid}/compare`,
      title: 'Compare ad traits',
      description: 'Build a comparison report',
    },
    {
      icon: <Bookmark />,
      to: `/insights/accounts/${accountUuid}/tagging`,
      title: 'Creative tagging',
      description: 'Manage and organise',
    },
    {
      icon: <VideoRecorder />,
      to: TOUR_LINK,
      title: 'Book a call',
      description: 'Get personalised assistance',
      external: true,
    },
  ];

  return (
    <div className="mb-14 grid grid-cols-1 gap-4 md:grid-cols-4">
      {actions.map((x) => (
        <QuickActionCard
          to={x.to}
          key={x.title}
          icon={x.icon}
          title={x.title}
          description={x.description}
        />
      ))}
    </div>
  );
};

const QuickActionCard = ({
  icon,
  title,
  description,
  external,
  to,
  ...props
}: LinkProps & QuickActionCardProps) => {
  return (
    <Link
      to={to}
      className="group flex gap-3 rounded-xl border border-solid border-purple-200 bg-white p-4 transition-colors hover:border-purple-300 hover:shadow-sm"
      {...(external ? { target: '_blank', rel: 'noopener noreferrer' } : {})}
      {...props}
    >
      <div className="flex size-10 items-center justify-center rounded-md bg-purple-100 p-2.5">
        <Icon className="size-5 shrink-0 text-primary">{icon}</Icon>
      </div>
      <div className="flex flex-col">
        <Text className="text-sm font-semibold text-primary">{title}</Text>
        <Text className="text-sm font-medium text-[#917DD2]">
          {description}
        </Text>
      </div>
    </Link>
  );
};
