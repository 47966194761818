import { PropsWithChildren } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { captureException } from '@sentry/react';
import {
  Dialog,
  Heading,
  Modal,
  ModalOverlay,
  Text,
} from 'react-aria-components';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SwitchHorizontal02 from 'src/assets/svgicons/line/switch-horizontal-02.svg';
import { useI18nContext } from 'src/i18n/i18n-react';
import { trpc } from 'src/lib/trpc';
import Lock01 from 'src/assets/svgicons/line/lock-01.svg';
import { InsightsAccountRouteLoaderData } from 'src/pages/Insights/routes/InsightsAccount/route-loader';
import { getLastViewedAccountMap } from 'src/pages/Insights/util/getLastViewedAccount';
import { useUserAndOrganisation } from './useUserAndOrganisation';

export const InsightsAccountValidator = (props: PropsWithChildren) => {
  const { LL } = useI18nContext();
  const account = useLoaderData() as InsightsAccountRouteLoaderData;
  const navigate = useNavigate();
  const trpcUtils = trpc.useUtils();
  const user = useUserAndOrganisation();

  const error = account && 'error' in account ? account?.error : null;
  const errorAction = error?.data?.cause?.action;

  const { data: adAccounts } = trpc.insightsAccounts.getManyAdAccounts.useQuery(
    undefined,
    { enabled: !!user.data }
  );

  const cachedUuid = () => {
    if (user.data == null || adAccounts == null) {
      return;
    }

    const lastViewedAccountMap = getLastViewedAccountMap();
    const lastViewedAccountForOrg =
      lastViewedAccountMap[user.data.organisation.uuid];
    return lastViewedAccountForOrg;
  };

  const currentAdAccountUuid = cachedUuid() ?? adAccounts?.[0].uuid;

  const updateUserCurrentOrganisation =
    trpc.user.updateUserCurrentOrganisation.useMutation({
      onSuccess: () => {
        void trpcUtils.directories.getDirectoryStructureForOrganisation.invalidate();
        toast.success(LL.organisations.switcher.success(), {
          className: 'toast-success',
        });
        navigate(0); // Acts like a browser refresh when passing 0
      },
      onError: (e, opts) => {
        captureException(e, { contexts: { opts } });
        toast.error(LL.organisations.switcher.error());
      },
    });

  if (!account || !('error' in account) || !error) return props.children;

  switch (errorAction) {
    case 'ACCESS_DENIED': {
      return (
        <ModalOverlay
          isOpen={true}
          isDismissable={true}
          className={cn([
            /* Base */
            'fixed inset-0 z-[9999] bg-gray-900/60 backdrop-blur',
            /* Entering */
            'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
            /* Exiting */
            'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
          ])}
        >
          <Modal
            className={cn([
              /* Base */
              'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
              /* Entering */
              'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
              /* Exiting */
              'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
            ])}
          >
            <Dialog
              className="flex max-h-[50%] w-[540px] flex-col items-center justify-center gap-8 rounded-xl border border-solid border-purple-300 bg-white p-14 shadow outline-none focus-visible:outline-none"
              aria-label="switch workspace modal"
            >
              <div className="flex size-14 items-center justify-center rounded-xl bg-purple-100">
                <Lock01 className="size-8 text-primary" />
              </div>
              <div className="flex flex-col gap-3.5">
                <Heading className="text-center text-3xl font-semibold text-primary">
                  Access Denied
                </Heading>
                <Text className="text-center text-base font-medium text-primary/50">
                  Please contact the workspace administrator for access
                </Text>
              </div>

              <Button
                className="w-full"
                onPress={() =>
                  navigate(`/insights/accounts/${currentAdAccountUuid}`)
                }
              >
                Return to Workspace
              </Button>
            </Dialog>
          </Modal>
        </ModalOverlay>
      );
    }

    case 'SWITCH_WORKSPACE': {
      const organisationName = error.data.cause.organisationName;
      const organisationId = error.data.cause.organisationId;

      return (
        <ModalOverlay
          isOpen={true}
          isDismissable={true}
          className={cn([
            /* Base */
            'fixed inset-0 z-[9999] bg-gray-900/60 backdrop-blur',
            /* Entering */
            'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
            /* Exiting */
            'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
          ])}
        >
          <Modal
            className={cn([
              /* Base */
              'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
              /* Entering */
              'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
              /* Exiting */
              'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
            ])}
          >
            <Dialog
              className="flex max-h-[50%] w-[540px] flex-col items-center justify-center gap-8 rounded-xl border border-solid border-purple-300 bg-white p-14 shadow outline-none focus-visible:outline-none"
              aria-label="switch workspace modal"
            >
              <div className="flex size-14 items-center justify-center rounded-xl bg-purple-100">
                <SwitchHorizontal02 className="size-8 text-primary" />
              </div>
              <div className="flex flex-col gap-3.5">
                <Heading className="text-center text-3xl font-semibold text-primary">
                  Resource Available in Different Workspace
                </Heading>
                <Text className="text-center text-base font-medium text-primary/50">
                  This resource is located in{' '}
                  <Text className="text-primary">{organisationName}</Text>
                </Text>
              </div>

              <div className="flex w-full flex-col gap-2.5">
                <Button
                  className="w-full"
                  onPress={() => {
                    if (organisationId) {
                      updateUserCurrentOrganisation.mutate({ organisationId });
                    }
                  }}
                  loading={updateUserCurrentOrganisation.isLoading}
                >
                  Switch Workspace
                </Button>
                <Button
                  className="w-full"
                  variant="secondary"
                  type="button"
                  onPress={() => {
                    navigate(`/insights/accounts/${currentAdAccountUuid}`);
                  }}
                >
                  Stay in Current Workspace
                </Button>
              </div>
            </Dialog>
          </Modal>
        </ModalOverlay>
      );
    }

    default:
      return props.children;
  }
};
