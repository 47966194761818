import { Button, type ButtonProps } from './button';
import { Input } from './field';
import { cn } from '../lib/cn';
import { Popover } from './popover';
import { ListBox, ListBoxItem } from './list-box';
import {
  ListBoxItemProps,
  ListBoxProps,
  PopoverProps,
  ComboBox as AriaComboBox,
  ComboBoxProps,
  InputProps,
  Virtualizer,
  ListLayout,
} from 'react-aria-components';
import { ChevronSelectorVertical } from '../icons/chevron-selector-vertical';

export const ComboBox = <T extends object>({
  className,
  ...props
}: ComboBoxProps<T>) => {
  return <AriaComboBox className={cn('group w-full', className)} {...props} />;
};

export const ComboBoxInput = (props: InputProps) => {
  return <Input {...props} className={cn('text-ellipsis', props.className)} />;
};

export interface ComboBoxContentProps<T>
  extends Omit<PopoverProps, 'children' | 'style' | 'className'>,
    Omit<ListBoxProps<T>, 'style'> {
  popoverClassName?: string;
}

export const ComboBoxContent = <T extends object>({
  className,
  popoverClassName,
  ...props
}: ComboBoxContentProps<T>) => {
  return (
    <Popover className={popoverClassName} {...props}>
      <Virtualizer
        layout={ListLayout}
        layoutOptions={{
          rowHeight: 36,
          padding: 4,
          gap: 0,
        }}
      >
        <ListBox {...props} className={cn('p-0', className)} />
      </Virtualizer>
    </Popover>
  );
};

export const ComboBoxItem = (props: ListBoxItemProps) => {
  return <ListBoxItem {...props} />;
};

export const ComboBoxButton = (props: ButtonProps) => {
  return (
    <Button
      {...props}
      variant="text"
      size="small"
      className={cn('h-fit p-1.5', props.className)}
    >
      <ChevronSelectorVertical className="size-4 text-primary/50" />
    </Button>
  );
};
