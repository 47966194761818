import React, { useState } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Icon } from 'src/components/Icon';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import AriaModal from 'src/components/AriaModal/AriaModal';
import { AriaButton } from 'src/components/Button';
import { trpc } from 'src/lib/trpc';

interface DeleteTagModalProps {
  categoryUuid: string;
  accountUuid: string;
  onClose: () => void;
}

export const DeleteTagCategoryModal: React.FC<DeleteTagModalProps> = ({
  categoryUuid,
  accountUuid,
  onClose,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const tagTaxonomy = trpc.insightsTags.getTagTaxonomyForAccount.useQuery({
    accountUuid,
  });

  const trpcUtils = trpc.useUtils();

  const deleteCategory = trpc.insightsTags.deleteCategory.useMutation({
    onSuccess: async () => {
      await trpcUtils.insightsTags.getTags.invalidate();
      await trpcUtils.insightsTags.getTagsForAd.invalidate();
      await trpcUtils.insights.getRecentlyUsedInsightsTags.invalidate();
      await trpcUtils.insightsAds.getManyAdsGroupedByAssetWithTags.invalidate();
      await trpcUtils.insightsTags.getTagTaxonomyForAccount.invalidate();
      setIsOpen(false);
    },
    onError: (e, opts) => {
      toast.error(e instanceof Error ? e.message : 'Unknown error');
      Sentry.captureException(e, { contexts: { opts } });
    },
  });

  const label = tagTaxonomy.data?.categories[categoryUuid]?.label;
  const { numAds, numTags } = Object.values(
    tagTaxonomy.data?.tags ?? {}
  ).reduce<{ numAds: number; numTags: number }>(
    (acc, curr) => {
      if (curr.categoryUuid === categoryUuid) {
        acc.numTags += 1;
        acc.numAds += curr.numAds;
      }
      return acc;
    },
    { numAds: 0, numTags: 0 }
  );

  return (
    <AriaModal
      footer={
        <div className="flex flex-row justify-end gap-2.5">
          <AriaButton variant="white" onPress={() => setIsOpen(false)}>
            Cancel
          </AriaButton>
          <AriaButton
            loading={deleteCategory.isLoading}
            colour="danger"
            variant="secondary"
            onPress={() => {
              deleteCategory.mutate({
                accountUuid,
                category: { uuid: categoryUuid },
              });
            }}
          >
            Permanently Delete Category
          </AriaButton>
        </div>
      }
      show={isOpen}
      onClose={() => setIsOpen(false)}
      afterClose={onClose}
      title={`Delete '${label}' Category`}
    >
      <div className="space-y-4">
        <div className="text-sm text-text-secondary">
          Are you sure you want to delete this category?
        </div>
        <div className="flex flex-row gap-3 rounded-lg bg-red-50 pb-3 pl-3 pr-6 pt-2.5 text-xs text-red-700">
          <Icon className="size-4 shrink-0">
            <AlertTriangle />
          </Icon>
          <div>
            {`This category will be permanently deleted.${numTags ? ` The ${numTags} tag${numTags > 1 ? 's' : ''} in this category will become uncategorized. This will affect ${numAds} ad${numAds !== 1 ? 's' : ''} to which these tags are applied.` : ''}`}
          </div>
        </div>
      </div>
    </AriaModal>
  );
};
