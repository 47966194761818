import React, {
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  getAllMetricsAndAttributesForPlatform,
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS,
  InsightsFacebookTimeSeriesMetricName,
  InsightsFacebookWizardMetric,
} from '@magicbrief/common';
import { clsx } from 'clsx';
import {
  Button as RACButton,
  Checkbox,
  Dialog,
  DialogTrigger,
  Input as AriaInput,
  Modal,
  ModalOverlay,
  SearchField,
  Text,
  Key,
  ListBox,
} from 'react-aria-components';
import { useParams } from 'react-router-dom';
import { Button } from '@magicbrief/ui/src/components/button';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { Popover, PopoverTrigger } from '@magicbrief/ui/src/components/popover';
import { ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import { useAtom } from 'jotai';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import Eye from 'src/assets/svgicons/duotone/eye.svg';
import SearchSm from 'src/assets/svgicons/duotone/search-sm.svg';
import Check from 'src/assets/svgicons/line/check.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { Icon } from 'src/components/Icon';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { trpc } from 'src/lib/trpc';
import ChevronDown from 'src/assets/svgicons/line/chevron-down.svg';
import Edit05 from 'src/assets/svgicons/line/edit-05.svg';
import {
  useInsightsDisplay,
  useInsightsGraphMetrics,
  useInsightsGridMetrics,
  useInsightsLayout,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import {
  useInsightsAdAccount,
  useInsightsAds,
} from '../../util/useInsightsQueries';
import { useMetricSearch } from '../../util/useMetricSearch';
import { parseValueForMetric, useParseMetric } from '../../util/useParseMetric';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import { presetAtom } from '../InsightsTable/TableMetricPresets.atoms';
import {
  AD_DETAILS_CATEGORY_METRICS,
  CLICKS_CATEGORY_METRICS,
  ENGAGEMENT_CATEGORY_METRICS,
  PERFORMANCE_CATEGORY_METRICS,
  VIDEO_CATEGORY_METRICS,
  WIZARD_SCORE_CATEGORY_METRICS,
} from '../const';
import { useInsightsJob } from '../../util/useInsightsJob';
import { InsightsRenamePresetModal } from './components/InsightsRenamePresetModal';
import { InsightsDeletePresetModal } from './components/InsightsDeletePresetModal';
import { InsightsCreatePresetModal } from './components/InsightsCreatePresetModal';

export const InsightsFilterDisplayMenuV2: React.FC<{
  platform: 'facebook' | 'tiktok';
}> = ({ platform }) => {
  const { LL } = useI18nContext();

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <DialogTrigger>
        <Button
          variant="secondary"
          size="small"
          onPress={() => setIsOpen(true)}
        >
          <Eye className="size-4" />
          {LL.insights.card.tabs.metrics()}
        </Button>

        <InsightsDisplayMetricModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          platform={platform}
        />
      </DialogTrigger>
    </>
  );
};

interface InsightsDisplayMetricModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  platform: 'facebook' | 'tiktok';
  isGraphMetrics?: boolean;
}

export function InsightsDisplayMetricModal({
  isOpen,
  setIsOpen,
  platform,
  isGraphMetrics = false,
}: InsightsDisplayMetricModalProps) {
  const { accountUuid } = useParams();
  const {
    accountCustomEvents: customEvents,
    accountCustomConversions: customConversions,
  } = useInsightsAdAccount({ accountUuid });

  const searchRef = useRef<HTMLInputElement>(null);
  const [preset, setPreset] = useAtom(presetAtom);

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <ModalOverlay
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
    >
      <Modal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] w-[970px] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
      >
        <Dialog
          className="relative grid h-[800px] rounded-2xl border border-purple-200 bg-white shadow focus-visible:outline-none lg:h-auto"
          aria-label="billing plans modal"
        >
          {({ close }) => (
            <>
              <DisplayFilterModalHeader
                isGraphMetrics={isGraphMetrics}
                preset={preset}
                setPreset={setPreset}
              />
              <div className="flex h-[500px]">
                <SelectedDisplayFilters
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                  isGraphMetrics={isGraphMetrics}
                />
                <DisplayFiltersSelection
                  platform={platform}
                  customEvents={customEvents ?? []}
                  customConversions={customConversions ?? []}
                  isGraphMetrics={isGraphMetrics}
                />
              </div>
              <DisplayFilterModalFooter
                close={close}
                preset={preset}
                isGraphMetrics={isGraphMetrics}
              />
            </>
          )}
        </Dialog>
      </Modal>
    </ModalOverlay>
  );
}

export type InsightsPreset = {
  name: string;
  uuid: string;
  metrics: string[];
};

function DisplayFilterModalHeader({
  isGraphMetrics,
  preset,
  setPreset,
}: {
  isGraphMetrics: boolean;
  preset: Key;
  setPreset: React.Dispatch<React.SetStateAction<Key>>;
}) {
  const { accountUuid } = useParams();
  const dispatch = useInsightsStoreDispatch();
  const layout = useInsightsLayout();

  const [isOpen, setIsOpen] = useState(false);
  const [editPreset, setEditPreset] = useState<InsightsPreset | null>(null);
  const [deletePreset, setDeletePreset] = useState<InsightsPreset | null>(null);

  const { data: presets = [] } =
    trpc.insights.getInsightsMetricPresets.useQuery({
      insightsAdAccountUuid: accountUuid ?? '',
    });

  if (isGraphMetrics) {
    return (
      <div className="border-b border-solid border-b-purple-300 px-6 py-4">
        <h2 className="text-xl font-semibold text-primary">
          Customise Visible Metrics
        </h2>
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center gap-3 border-b border-solid border-b-purple-300 px-6 py-4">
        <Text className="text-sm font-semibold text-primary/50">
          Column view:
        </Text>

        {presets.length === 0 ? (
          <TooltipTrigger delay={200} closeDelay={0}>
            <RACButton className="flex h-10 min-w-[100px] cursor-not-allowed items-center gap-6 rounded-md border border-solid border-purple-200 px-3 py-2.5 text-sm font-medium text-primary opacity-50">
              <span className="grow">
                {presets?.find((p) => p.uuid === preset)?.name ?? 'Custom'}
              </span>
              <ChevronDown className="size-4" />
            </RACButton>
            <Tooltip offset={8}>Create a custom preset view</Tooltip>
          </TooltipTrigger>
        ) : (
          <PopoverTrigger isOpen={isOpen} onOpenChange={setIsOpen}>
            <RACButton
              className={cn(
                'flex h-10 min-w-[100px] items-center gap-6 rounded-md border border-solid border-purple-200 px-3 py-2.5 text-sm font-medium text-primary',
                'disabled:cursor-not-allowed disabled:opacity-50'
              )}
              isDisabled={presets.length === 0}
            >
              <span className="grow">
                {presets?.find((p) => p.uuid === preset)?.name ?? 'Custom'}
              </span>
              <ChevronDown className="size-4" />
            </RACButton>

            <Popover
              placement="bottom start"
              className="max-h-[300px] w-fit min-w-[272px] overflow-y-auto"
            >
              <ListBox
                className="p-2"
                onAction={(presetKey) => {
                  setPreset(presetKey);
                  const preset = presets?.find((p) => p.uuid === presetKey);
                  switch (layout) {
                    case 'table':
                    case 'grid':
                      dispatch({
                        type: 'setDisplay',
                        value: preset?.metrics ?? [],
                      });
                      break;
                    case 'full-grid':
                      dispatch({
                        type: 'setGridMetrics',
                        value: preset?.metrics ?? [],
                      });
                      break;
                    default:
                      break;
                  }

                  setIsOpen(false);
                }}
              >
                {presets?.map((preset) => (
                  <ListBoxItem
                    key={preset.uuid}
                    id={preset.uuid}
                    className="flex items-center gap-1"
                  >
                    <span className="shrink-0 grow">{preset.name}</span>
                    <RACButton
                      className="rounded-md p-1 hover:bg-purple-50"
                      onPress={() => {
                        setEditPreset(preset);
                        setIsOpen(false);
                      }}
                    >
                      <Edit05 className="size-4" />
                    </RACButton>
                    <RACButton
                      className="rounded-md p-1 hover:bg-red-50 hover:text-red-600"
                      onPress={() => {
                        setDeletePreset(preset);
                        setIsOpen(false);
                      }}
                    >
                      <XClose className="size-4" />
                    </RACButton>
                  </ListBoxItem>
                ))}
              </ListBox>
            </Popover>
          </PopoverTrigger>
        )}
      </div>
      {editPreset ? (
        <InsightsRenamePresetModal
          editPreset={editPreset}
          setEditPreset={setEditPreset}
        />
      ) : null}
      {deletePreset ? (
        <InsightsDeletePresetModal
          deletePreset={deletePreset}
          setDeletePreset={setDeletePreset}
        />
      ) : null}
    </>
  );
}

function DisplayFilterModalFooter({
  close,
  preset,
  isGraphMetrics,
}: {
  close: () => void;
  preset: Key;
  isGraphMetrics: boolean;
}) {
  const { accountUuid } = useParams();
  const trpcUtils = trpc.useUtils();
  const layout = useInsightsLayout();
  const tableMetrics = useInsightsDisplay();
  const gridMetrics = useInsightsGridMetrics();

  const [isCreatePresetOpen, setIsCreatePresetOpen] = useState(false);

  const metrics = layout === 'full-grid' ? gridMetrics : tableMetrics;

  const { data: presets } = trpc.insights.getInsightsMetricPresets.useQuery({
    insightsAdAccountUuid: accountUuid ?? '',
  });

  const hasPresetChanged =
    preset !== 'custom'
      ? !isEqual(presets?.find((p) => p.uuid === preset)?.metrics, metrics)
      : false;

  const updatePreset = trpc.insights.updateInsightsMetricPreset.useMutation({
    onSuccess: () => {
      void trpcUtils.insights.getInsightsMetricPresets.invalidate();
      toast.success('Preset successfully updated');
    },
    onError: () => {
      toast.error('Error updating preset');
    },
  });

  return (
    <>
      <div
        className={cn(
          'flex items-center gap-2 rounded-b-[inherit] border-t border-solid border-t-purple-300 bg-white px-6 py-4',
          isGraphMetrics ? 'justify-end' : 'justify-between'
        )}
      >
        {isGraphMetrics ? null : (
          <div className="space-x-2">
            <Button
              variant="secondary"
              size="small"
              onPress={() => {
                updatePreset.mutate({
                  insightsAdAccountUuid: accountUuid ?? '',
                  presetUuid: preset as string,
                  metrics,
                });
              }}
              loading={updatePreset.isLoading}
              isDisabled={preset === 'custom' || !hasPresetChanged}
            >
              Update Preset
            </Button>
            <DialogTrigger>
              <Button
                variant="secondary"
                size="small"
                onPress={() => setIsCreatePresetOpen(true)}
              >
                Save as New Preset
              </Button>

              <InsightsCreatePresetModal
                isOpen={isCreatePresetOpen}
                setIsOpen={setIsCreatePresetOpen}
              />
            </DialogTrigger>
          </div>
        )}

        <div className="space-x-2">
          <MagicBriefButton
            className="w-fit"
            variant="text"
            size="small"
            onPress={close}
          >
            Cancel
          </MagicBriefButton>
          <MagicBriefButton
            className="w-fit"
            variant="primary"
            size="small"
            onPress={close}
          >
            Update Metrics
          </MagicBriefButton>
        </div>
      </div>
    </>
  );
}

const SelectedDisplayFilters: React.FC<
  CustomEventsAndConversionsProps & { isGraphMetrics: boolean }
> = ({ customEvents, customConversions, isGraphMetrics }) => {
  const dispatch = useInsightsStoreDispatch();
  const tableMetrics = useInsightsDisplay();
  const graphMetrics = useInsightsGraphMetrics();
  const gridMetrics = useInsightsGridMetrics();
  const layout = useInsightsLayout();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const display =
    layout === 'full-grid'
      ? gridMetrics
      : isGraphMetrics
        ? graphMetrics
        : tableMetrics;

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);

      switch (true) {
        case layout === 'full-grid':
          dispatch({ type: 'setGridMetrics', value: newDisplayOrder });
          return;
        case isGraphMetrics:
          dispatch({ type: 'setGraphMetrics', value: newDisplayOrder });
          return;
        default:
          dispatch({ type: 'setDisplay', value: newDisplayOrder });
          return;
      }
    }
  };

  if (display.length === 0) {
    return (
      <div className="flex w-[300px] min-w-[300px] flex-col gap-4 border-r border-solid border-r-purple-200 px-6 pt-3">
        <span className="text-lg font-semibold text-primary">Selected</span>
        <span className="text-md font-medium text-primary opacity-50">
          No metrics selected.
        </span>
      </div>
    );
  }

  return (
    <div className="flex h-[500px] w-[300px] min-w-[300px] flex-col gap-2 overflow-y-auto overflow-x-hidden border-r border-solid border-r-purple-200 px-6 py-3">
      <span className="text-lg font-semibold text-primary">Selected</span>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <SortableContext items={display} strategy={verticalListSortingStrategy}>
          {display.map((id) => (
            <DraggableDisplayFilter
              key={id}
              id={id}
              customEvents={customEvents}
              customConversions={customConversions}
              isGraphMetrics={isGraphMetrics}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface CustomEventsAndConversionsProps {
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}

interface DraggableDisplayFilterProps extends CustomEventsAndConversionsProps {
  id: string;
  isGraphMetrics: boolean;
}

const DraggableDisplayFilter: React.FC<DraggableDisplayFilterProps> = ({
  id,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const layout = useInsightsLayout();
  const platform = useInsightsPlatform();
  const dispatch = useInsightsStoreDispatch();
  const { getMetricLabelAsText } = useParseMetric();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex cursor-grab items-center gap-1.5 rounded-md border border-solid border-purple-200 bg-violet-50 px-2.5 py-1.5"
      style={styles}
      aria-label="selected display metric"
      {...attributes}
      {...listeners}
    >
      <DotsGrid className="size-4 text-purple-300 focus:outline-none" />
      <Text
        slot="label"
        className="grow truncate text-sm font-semibold text-primary"
      >
        {getMetricLabelAsText(platform, id, customEvents, customConversions)}
      </Text>

      <RACButton
        onPress={() => {
          switch (true) {
            case layout === 'full-grid':
              dispatch({ type: 'removeGridMetric', value: id });
              return;
            case isGraphMetrics:
              dispatch({ type: 'removeGraphMetric', value: id });
              return;
            default:
              dispatch({ type: 'toggleDisplay', value: id });
              return;
          }
        }}
        className="focus:outline-none"
      >
        <XClose className="size-[18px] rounded text-primary/50 outline-none hover:bg-purple-200" />
      </RACButton>
    </div>
  );
};

type Category = {
  type: 'list' | 'grid';
  metrics: Array<string>;
};

const DisplayFiltersSelection: React.FC<
  CustomEventsAndConversionsProps & {
    platform: 'facebook' | 'tiktok';
    isGraphMetrics: boolean;
  }
> = ({ customEvents, customConversions, platform, isGraphMetrics }) => {
  const { LL } = useI18nContext();
  const searchRef = useRef<HTMLInputElement>(null);

  const {
    results: items,
    onChange,
    query,
  } = useMetricSearch({
    platform,
    builtinMetrics: getAllMetricsAndAttributesForPlatform(platform),
    customEvents,
    customConversions,
  });

  const mostRelevantSearchMetrics = items.slice(0, 10);
  const categorisedMetrics = (
    items as Array<InsightsFacebookTimeSeriesMetricName>
  ).reduce<Record<string, Category>>((acc, curr) => {
    switch (true) {
      case DEPRECATED_METRICS.some((x) => x === curr):
        return acc;

      case customEvents.some((ce) => curr.includes(ce)): {
        if (acc['Custom Events']) {
          acc['Custom Events']['metrics'].push(curr);
        } else {
          acc['Custom Events'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;
      }

      case customConversions.some((cc) => curr.includes(cc.facebookId)):
        if (acc['Custom Conversions']) {
          acc['Custom Conversions']['metrics'].push(curr);
        } else {
          acc['Custom Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case STANDARD_CONVERSIONS_CATEGORY_METRICS.some((x) => curr.includes(x)):
        if (acc['Standard Conversions']) {
          acc['Standard Conversions']['metrics'].push(curr);
        } else {
          acc['Standard Conversions'] = {
            type: 'grid',
            metrics: [curr],
          };
        }
        break;

      case CLICKS_CATEGORY_METRICS.includes(curr):
        if (acc['Clicks']) {
          acc['Clicks']['metrics'].push(curr);
        } else {
          acc['Clicks'] = { type: 'list', metrics: [curr] };
        }
        break;

      case VIDEO_CATEGORY_METRICS.includes(curr):
        if (acc['Video']) {
          acc['Video']['metrics'].push(curr);
        } else {
          acc['Video'] = { type: 'list', metrics: [curr] };
        }
        break;

      case ENGAGEMENT_CATEGORY_METRICS.includes(curr):
        if (acc['Engagement']) {
          acc['Engagement']['metrics'].push(curr);
        } else {
          acc['Engagement'] = { type: 'list', metrics: [curr] };
        }
        break;

      case WIZARD_SCORE_CATEGORY_METRICS.includes(
        curr as InsightsFacebookWizardMetric
      ):
        if (acc['Wizard Scores']) {
          acc['Wizard Scores']['metrics'].push(curr);
        } else {
          acc['Wizard Scores'] = { type: 'list', metrics: [curr] };
        }
        break;

      case AD_DETAILS_CATEGORY_METRICS.includes(curr):
        if (acc['Ad Details']) {
          acc['Ad Details']['metrics'].push(curr);
        } else {
          acc['Ad Details'] = { type: 'list', metrics: [curr] };
        }
        break;

      case PERFORMANCE_CATEGORY_METRICS.includes(curr):
        if (acc['Performance']) {
          acc['Performance']['metrics'].push(curr);
        } else {
          acc['Performance'] = { type: 'list', metrics: [curr] };
        }
        break;

      default:
        if (acc['Other']) {
          acc['Other']['metrics'].push(curr);
        } else {
          acc['Other'] = {
            type: 'list',
            metrics: [curr],
          };
        }
    }

    return acc;
  }, {});

  useLayoutEffect(() => {
    searchRef.current?.focus();
  }, []);

  return (
    <div className="size-full">
      <div className="flex flex-col gap-4 border-b border-solid border-b-purple-200 px-6 pb-6 pt-3">
        <span className="text-lg font-semibold text-primary">
          Search Metrics
        </span>
        <SearchField className="relative" aria-label="display metric search">
          <Icon className="absolute left-3 top-1/2 size-5 -translate-y-1/2 text-primary">
            <SearchSm />
          </Icon>
          <AriaInput
            ref={searchRef}
            type="text"
            className="text-md w-full rounded-md border border-solid border-purple-200 bg-white py-2.5 pl-10 pr-3 font-medium text-primary shadow-sm focus:outline-none"
            onChange={(e) => onChange(e.target.value)}
            placeholder={LL.insights.metrics.search()}
          />
        </SearchField>
      </div>

      <div className="flex h-[373px] flex-col gap-10 overflow-auto px-6 pt-6">
        {items.length === 0 && (
          <p className="primary text-sm italic text-purple-400">
            {`${LL.insights.metrics.noResults()} for '${query}'`}
          </p>
        )}

        {mostRelevantSearchMetrics.length > 0 && query.length > 0 && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="Most Relevant"
            metrics={mostRelevantSearchMetrics.filter((m) => m !== 'tag') ?? []}
            withCount={false}
            isGraphMetrics={isGraphMetrics}
          />
        )}

        {platform === 'tiktok' && (
          <ListCategoryGroup
            customEvents={customEvents}
            customConversions={customConversions}
            name="All Metrics"
            metrics={getAllMetricsAndAttributesForPlatform(platform)}
            isGraphMetrics={isGraphMetrics}
          />
        )}

        {platform === 'facebook' && (
          <>
            {categorisedMetrics['Performance'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Performance"
                metrics={categorisedMetrics['Performance'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Wizard Scores'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Wizard Scores"
                metrics={categorisedMetrics['Wizard Scores'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Clicks'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Clicks"
                metrics={categorisedMetrics['Clicks'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}

            {categorisedMetrics['Video'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Video"
                metrics={categorisedMetrics['Video'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Engagement'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Engagement"
                metrics={categorisedMetrics['Engagement'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Ad Details'] && (
              <ListCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Ad Details"
                metrics={categorisedMetrics['Ad Details'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Standard Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Standard Conversions"
                metrics={
                  categorisedMetrics['Standard Conversions'].metrics ?? []
                }
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Custom Conversions'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Conversions"
                metrics={categorisedMetrics['Custom Conversions'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
            {categorisedMetrics['Custom Events'] && (
              <GridCategoryGroup
                customEvents={customEvents}
                customConversions={customConversions}
                name="Custom Events"
                metrics={categorisedMetrics['Custom Events'].metrics ?? []}
                isGraphMetrics={isGraphMetrics}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

// Override the alphabetical sort for Wizard scores and orders
// it in the correct sequence as per the conversion funnel
const wizardScoreSortMap = {
  wizardHookScore: 1,
  wizardHoldScore: 2,
  wizardClickScore: 3,
  wizardBuyScore: 4,
};

interface ListCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
  withCount?: boolean;
  isGraphMetrics: boolean;
}

const ListCategoryGroup: React.FC<ListCategoryGroupProps> = ({
  name,
  metrics,
  withCount = true,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const { accountUuid, reportUuid } = useParams();
  const { currency } = useInsightsAdAccount({ accountUuid });
  const dispatch = useInsightsStoreDispatch();
  const layout = useInsightsLayout();
  const tableMetrics = useInsightsDisplay();
  const graphMetrics = useInsightsGraphMetrics();
  const gridMetrics = useInsightsGridMetrics();
  const job = useInsightsJob();
  const platform = useInsightsPlatform();
  const display =
    layout === 'full-grid'
      ? gridMetrics
      : isGraphMetrics
        ? graphMetrics
        : tableMetrics;

  const header = withCount ? `${name} (${metrics.length})` : name;

  const parsedMetrics =
    name === 'Wizard Scores'
      ? metrics.sort(
          (a, b) =>
            wizardScoreSortMap[a as InsightsFacebookWizardMetric] -
            wizardScoreSortMap[b as InsightsFacebookWizardMetric]
        )
      : metrics;

  const { ads } = useInsightsAds(
    accountUuid
      ? { type: 'account', uuid: accountUuid, jobStatus: job?.status }
      : {
          type: 'report',
          uuid: reportUuid,
        }
  );

  const referenceMetrics = ads.data?.pages?.[0]?.[0]?.metrics
    ? ads.data.pages[0][0].metrics
    : {};

  const handleToggleMetric = (metric: string) => {
    switch (true) {
      case layout === 'full-grid':
        dispatch({
          type: gridMetrics.includes(metric)
            ? 'removeGridMetric'
            : 'addGridMetric',
          value: metric,
        });
        return;
      case isGraphMetrics:
        dispatch({
          type: display.includes(metric)
            ? 'removeGraphMetric'
            : 'addGraphMetric',
          value: metric,
        });
        if (!tableMetrics.includes(metric)) {
          // Prevents metric being removed from table if it's already there
          dispatch({ type: 'toggleDisplay', value: metric });
        }
        return;
      default:
        dispatch({ type: 'toggleDisplay', value: metric });
        return;
    }
  };

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{header}</span>
      <div className="space-y-0.5">
        {parsedMetrics.map((metric) => {
          return (
            <Checkbox
              key={metric}
              isSelected={display.includes(metric)}
              onChange={() => handleToggleMetric(metric)}
              className="group/checkbox flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
            >
              <div
                className={clsx(
                  /** Base */
                  'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                  /** Selected */
                  'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100'
                )}
              >
                <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                  <Check />
                </Icon>
              </div>
              <span className="grow truncate text-sm font-medium text-primary">
                <InsightsMetricLabelTooltip
                  platform={platform}
                  metric={metric}
                  customConversions={customConversions}
                  customEvents={customEvents}
                  onPress={() => handleToggleMetric(metric)}
                />
              </span>
              <div className="flex max-w-[250px] items-center justify-center overflow-hidden rounded bg-[#E5DEFF] px-1.5 py-1">
                <span className="truncate text-sm font-medium text-primary">
                  {referenceMetrics[metric] &&
                  currency &&
                  !Array.isArray(referenceMetrics[metric])
                    ? parseValueForMetric(
                        platform,
                        metric,
                        referenceMetrics[metric],
                        currency
                      )
                    : '-'}
                </span>
              </div>
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
};

interface GridCategoryGroupProps extends CustomEventsAndConversionsProps {
  name: string;
  metrics: Array<string>;
  isGraphMetrics: boolean;
}

const GridCategoryGroup: React.FC<GridCategoryGroupProps> = ({
  name,
  metrics,
  customEvents,
  customConversions,
  isGraphMetrics,
}) => {
  const dispatch = useInsightsStoreDispatch();
  const tableMetrics = useInsightsDisplay();
  const graphMetrics = useInsightsGraphMetrics();
  const platform = useInsightsPlatform();

  const display = isGraphMetrics ? graphMetrics : tableMetrics;

  const groupMetrics = metrics.reduce(
    (acc: Record<string, Array<string>>, curr) => {
      const splitIndex = getNthOccurrence(curr, ':', 2);
      const metricCategory = curr.slice(splitIndex + 1);

      if (acc[metricCategory]) {
        acc[metricCategory].push(curr);
      } else {
        acc[metricCategory] = [curr];
      }

      return acc;
    },
    {}
  );

  const customTrigger = ({ children }: PropsWithChildren) => (
    <RACButton className="text-xs font-medium text-primary">
      {children}
    </RACButton>
  );

  return (
    <div className="space-y-2.5">
      <span className="text-md font-semibold text-primary">{name}</span>

      <div className="grid grid-cols-[250px_repeat(3,1fr)]">
        <div />
        {['Total', 'Value', 'Cost'].map((header) => (
          <span
            key={header}
            className="p-2 text-center text-xxs font-semibold text-primary"
          >
            {header}
          </span>
        ))}
      </div>

      {Object.entries(groupMetrics).map(([key]) => {
        return (
          <div
            key={key}
            className="grid grid-cols-[250px_repeat(3,1fr)] items-center justify-center"
          >
            <InsightsMetricLabelTooltip
              metric={`action:total:${key}`}
              customConversions={customConversions}
              customEvents={customEvents}
              customTrigger={customTrigger}
              platform={platform}
            />
            {['total', 'value', 'cost_per'].map((item) => {
              const metric = `action:${item}:${key}`;
              const isDisabled = !metrics.includes(metric);

              return (
                <Checkbox
                  key={item}
                  isSelected={display.includes(metric)}
                  onChange={() => {
                    if (isGraphMetrics) {
                      const graphMetricIndex = graphMetrics.indexOf(metric);
                      dispatch({
                        type:
                          graphMetricIndex === -1
                            ? 'addGraphMetric'
                            : 'removeGraphMetric',
                        value: metric,
                      });
                    }
                    dispatch({ type: 'toggleDisplay', value: metric });
                  }}
                  className="group/checkbox mx-auto flex cursor-pointer items-center gap-2.5 px-3 py-2.5 disabled:cursor-not-allowed disabled:opacity-50"
                  isDisabled={isDisabled}
                >
                  <div
                    className={clsx(
                      /** Base */
                      'flex size-5 items-center justify-center rounded border border-solid border-primary/50 p-0.5 text-white opacity-50 transition-all duration-200 ease-in-out group-indeterminate/checkbox:bg-primary group-indeterminate/checkbox:opacity-100 group-hover/checkbox:opacity-100',
                      /** Selected */
                      'group-selected/checkbox:bg-primary group-selected/checkbox:opacity-100',
                      /** Disabled */
                      'group-disabled/checkbox:bg-purple-100'
                    )}
                  >
                    <Icon className="hidden size-4 text-white group-selected/checkbox:block">
                      <Check />
                    </Icon>
                  </div>
                </Checkbox>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const DEPRECATED_METRICS = ['thumbstop'];

const STANDARD_CONVERSIONS_CATEGORY_METRICS =
  INSIGHTS_FACEBOOK_TIME_SERIES_ACTION_METRICS;

/**
 * Finds the index of the n-th occurence of a substring wtihin a string
 */
function getNthOccurrence(str: string, substring: string, n: number) {
  let count = 0;
  let position = 0;

  while (count < n) {
    position = str.indexOf(substring, position);

    if (position === -1) {
      return -1;
    }

    count++;
    position++;
  }

  return position - 1;
}
