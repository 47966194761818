import React, { PropsWithChildren } from 'react';
import Skeleton01 from 'src/assets/svgicons/solid/skeleton01.svg';
import { cn } from 'src/lib/cn';

type Props = PropsWithChildren & {
  title: string;
  description?: string;
  action?: React.ReactNode;
  media?: React.ReactNode;
  className?: string;
};

const EmptyComponent: React.FunctionComponent<Props> = ({
  title,
  description,
  action,
  media,
  className,
  children,
}) => {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col items-center justify-center overflow-x-auto p-14 text-primary/60',
        className
      )}
    >
      <div className="flex max-w-sm flex-col items-center justify-center gap-6">
        <div>{media || <Skeleton01 className="h-40 w-auto" />}</div>
        <div className="flex flex-col items-center justify-center gap-2 text-center">
          <h1 className="text-xl font-semibold text-primary">{title}</h1>
          {description && (
            <div className="flex flex-col items-center gap-6 text-center text-sm font-medium text-primary/50">
              <p>{description}</p>
            </div>
          )}
        </div>
        {children}
        {action}
      </div>
    </div>
  );
};

export default EmptyComponent;
