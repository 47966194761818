import classNames from 'classnames';

type Props = React.PropsWithChildren<{
  className?: string;
}>;

const Card: React.FunctionComponent<Props> = ({
  className,
  children,
}): JSX.Element => {
  return (
    <div
      className={classNames(
        'rounded-lg border border-solid border-purple-200 bg-white shadow',
        className
      )}
    >
      {children}
    </div>
  );
};

export default Card;
