import { Children, FC, PropsWithChildren, ReactElement, useState } from 'react';
import classNames from 'classnames';
import { motion, Variants } from 'motion/react';
import {
  OverlayArrow,
  Tooltip as AriaTooltip,
  TooltipProps,
  TooltipTrigger,
} from 'react-aria-components';
import { Placement } from 'react-aria';
import TriangleArrowUp from '/src/assets/svgicons/custom/TriangleArrowUp.svg';
import TriangleArrowRight from '/src/assets/svgicons/custom/TriangleArrowRight.svg';
import TriangleArrowDown from '/src/assets/svgicons/custom/TriangleArrowDown.svg';
import TriangleArrowLeft from '/src/assets/svgicons/custom/TriangleArrowLeft.svg';
import { Icon } from '../Icon';

const getArrowDirection = (placement: Placement) => {
  if (
    [
      'bottom',
      'bottom left',
      'bottom right',
      'bottom start',
      'bottom end',
    ].includes(placement)
  ) {
    return 'up';
  }

  if (
    ['top', 'top left', 'top right', 'top start', 'top end'].includes(placement)
  ) {
    return 'down';
  }

  if (
    [
      'left',
      'left top',
      'left bottom',
      'start',
      'start top',
      'start bottom',
    ].includes(placement)
  ) {
    return 'right';
  }

  if (
    [
      'right',
      'right top',
      'right bottom',
      'end',
      'end top',
      'end bottom',
    ].includes(placement)
  ) {
    return 'left';
  }

  throw new Error(`Placement ${placement} not supported`);
};

type TooltipPlacementArrowProps = {
  placement: Placement;
};

const TooltipOverlayArrow: FC<TooltipPlacementArrowProps> = ({ placement }) => {
  const arrowDirection = getArrowDirection(placement);

  return (
    <OverlayArrow>
      <Icon>
        {arrowDirection === 'up' && <TriangleArrowUp />}
        {arrowDirection === 'right' && <TriangleArrowRight />}
        {arrowDirection === 'down' && <TriangleArrowDown />}
        {arrowDirection === 'left' && <TriangleArrowLeft />}
      </Icon>
    </OverlayArrow>
  );
};

type Props = PropsWithChildren<{
  className?: string;
  closeDelay?: number;
  delay?: number;
  isDisabled?: boolean;
  children: [ReactElement, ReactElement];
  placement?: Placement;
  offset?: number;
  crossOffset?: number;
  style?: React.CSSProperties;
}> &
  Omit<TooltipProps, 'className' | 'children' | 'style'>;

const MotionTooltip = motion(AriaTooltip);

const variants = {
  hidden: { opacity: 0, y: 5 },
  visible: { opacity: 1, y: 0 },
} satisfies Variants;

export const Tooltip: FC<Props> = ({
  children,
  className,
  closeDelay,
  delay,
  isDisabled,
  placement = 'top',
  offset = 10,
  crossOffset = 0,
  ...props
}) => {
  const [animation, setAnimation] = useState<
    keyof typeof variants | 'unmounted'
  >('unmounted');

  const childrenArr = Children.toArray(children);

  return (
    <TooltipTrigger
      closeDelay={closeDelay}
      delay={delay}
      isDisabled={isDisabled}
      onOpenChange={(isOpen) => setAnimation(isOpen ? 'visible' : 'hidden')}
    >
      {childrenArr[0]}
      <MotionTooltip
        isExiting={animation === 'hidden'}
        onAnimationComplete={(animation) => {
          setAnimation((a) =>
            animation === 'hidden' && a === 'hidden' ? 'unmounted' : a
          );
        }}
        isEntering={animation === 'visible'}
        animate={animation}
        initial="hidden"
        variants={variants}
        transition={{
          type: 'spring',
          bounce: 0.5,
          duration: 0.25,
        }}
        className={classNames(
          'z-50 rounded-md bg-[#110636] px-3 py-2.5 drop-shadow-lg',
          className
        )}
        placement={placement}
        offset={offset}
        crossOffset={crossOffset}
        {...props}
      >
        <TooltipOverlayArrow placement={placement} />
        <aside className="z-50 flex flex-col items-center whitespace-nowrap text-xs font-normal text-white">
          {childrenArr[1]}
        </aside>
      </MotionTooltip>
    </TooltipTrigger>
  );
};
