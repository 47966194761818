import { FC, useState } from 'react';
import { getMetricFormat } from '@magicbrief/common';
import classNames from 'classnames';
import { Button as RACButton } from 'react-aria-components';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import { Button } from '@magicbrief/ui/src/components/button';
import { useParams } from 'react-router-dom';
import Plus from 'src/assets/svgicons/line/plus.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import { COLOR_RANK_SCALE } from 'src/utils/rankColors';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useParseMetric } from '../../util/useParseMetric';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../const';
import { InsightsMetricLabelTooltip } from '../InsightsMetricLabelTooltip/InsightsMetricLabelTooltip';
import {
  useInsightsGraphMetrics,
  useInsightsGridMetrics,
  useInsightsLayout,
  useInsightsPlatform,
  useInsightsStoreDispatch,
} from '../../util/useInsightsPersistentState';
import { useInsightsAdAccount } from '../../util/useInsightsQueries';
import { InsightsDisplayMetricModal } from './InsightsFilterDisplayMenuV2';

const InsightsFilterGraphMetrics = () => {
  const { accountUuid } = useParams();
  const layout = useInsightsLayout();
  const graphMetrics = useInsightsGraphMetrics();
  const gridMetrics = useInsightsGridMetrics();
  const platform = useInsightsPlatform();

  const {
    accountCustomEvents: customEvents,
    accountCustomConversions: customConversions,
  } = useInsightsAdAccount({ accountUuid });

  const [isOpen, setIsOpen] = useState(false);

  const items = layout === 'full-grid' ? gridMetrics : graphMetrics;

  return (
    <div className="flex flex-row flex-wrap gap-2.5">
      {/* Chart Metrics */}
      <InsightsGraphMetricsDraggableList
        items={items}
        customConversions={customConversions ?? []}
        customEvents={customEvents ?? []}
      />

      {/* Add Metric Button */}
      <Button
        variant="secondary"
        className="h-8 gap-0.5 rounded-md p-1.5 pr-2 text-xs"
        onPress={() => setIsOpen(true)}
      >
        <Plus className="size-4" />
        Add Metric
      </Button>

      <InsightsDisplayMetricModal
        platform={platform}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isGraphMetrics={layout === 'full-grid' ? false : true}
      />
    </div>
  );
};

const InsightsGraphMetricsDraggableList: FC<{
  items: Array<string>;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
}> = ({ items, customEvents, customConversions }) => {
  const layout = useInsightsLayout();
  const { getMetricLabelAsText } = useParseMetric();
  const dispatch = useInsightsStoreDispatch();
  const platform = useInsightsPlatform();
  const { recordEvent } = useNewAnalyticsEvent();

  const isFullGrid = layout === 'full-grid';

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;
    if (active && over) {
      const oldIndex = items.findIndex((item) => item === active.id);
      const newIndex = items.findIndex((item) => item === over.id);
      const newSortOrder = arrayMove(items, oldIndex, newIndex);

      dispatch({
        type: isFullGrid ? 'setGridMetrics' : 'setGraphMetrics',
        value: newSortOrder,
      });

      if (isFullGrid) {
        dispatch({
          type: 'setSort',
          value: [{ id: newSortOrder[0], desc: true }],
        });
      }
    }
  }

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  let coloredIndex = 0;

  if (!items) return null;

  return (
    <DndContext
      collisionDetection={closestCenter}
      modifiers={[restrictToHorizontalAxis]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={horizontalListSortingStrategy}>
        {items?.map((sortKey, index) => {
          const label = getMetricLabelAsText(
            platform,
            sortKey,
            customEvents,
            customConversions
          );
          const format = getMetricFormat(platform, sortKey);

          if (label == null) {
            return null;
          }

          const rankedColor =
            coloredIndex < INSIGHTS_TABLE_SELECTION_MAX &&
            (format === 'currency' ||
              format === 'numeric' ||
              format === 'percentage')
              ? COLOR_RANK_SCALE[coloredIndex]
              : undefined;

          if (rankedColor) coloredIndex++;

          return (
            <InsightsGraphMetricItem
              id={sortKey}
              key={sortKey}
              desc={true}
              position={index + 1}
              color={rankedColor}
              customConversions={customConversions}
              customEvents={customEvents}
              onRemove={() => {
                if (layout === 'full-grid') {
                  dispatch({ type: 'removeGridMetric', value: sortKey });
                } else {
                  dispatch({ type: 'removeGraphMetric', value: sortKey });
                }

                void recordEvent({
                  action: `Metric removed`,
                  target: 'Insights Sort',
                  metadata: {
                    metric: sortKey,
                    desc: true,
                  },
                });
              }}
            />
          );
        })}
      </SortableContext>
    </DndContext>
  );
};

export const InsightsGraphMetricItem: FC<{
  position: number;
  color: string | undefined;
  id: string;
  desc: boolean;
  customEvents: string[];
  customConversions: Array<{ facebookId: string; name: string }>;
  onRemove: () => void;
}> = ({ position, color, id, customConversions, customEvents, onRemove }) => {
  const platform = useInsightsPlatform();
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={styles}
      className={classNames(
        'group flex h-8 w-max shrink-0 cursor-grab flex-row items-center gap-1.5 rounded-md border border-solid border-purple-200 bg-white p-1.5 text-xs font-semibold shadow-sm outline-none',
        'text-purple-800'
      )}
      {...attributes}
      {...listeners}
    >
      <span
        style={{ background: color }}
        className={classNames(
          'flex size-5 cursor-grab items-center justify-center rounded text-xs font-semibold active:cursor-grabbing group-hover:hidden',
          !color
            ? 'border border-solid border-purple-500 text-purple-500'
            : 'text-white'
        )}
      >
        <span>{position}</span>
      </span>
      <span
        className={classNames(
          'hidden size-5 cursor-grab items-center justify-center rounded-full text-xs font-semibold text-purple-300 active:cursor-grabbing group-hover:flex'
        )}
      >
        <DotsGrid />
      </span>
      <div className="pointer-events-none flex flex-col">
        <InsightsMetricLabelTooltip
          platform={platform}
          metric={id}
          customConversions={customConversions}
          customEvents={customEvents}
        />
      </div>
      <RACButton
        className="size-4 rounded-sm text-primary/50 outline-none hover:bg-purple-50"
        onPress={onRemove}
      >
        <XClose className="size-4" />
      </RACButton>
    </div>
  );
};

export default InsightsFilterGraphMetrics;
