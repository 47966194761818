import { cn } from '../lib/cn';

interface Props {
  className?: string;
}

export const ChevronSelectorVertical = ({ className }: Props) => {
  return (
    <svg className={cn('size-6', className)} viewBox="0 0 24 24" fill="none">
      <path
        d="M7 15L12 20L17 15M7 9L12 4L17 9"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
