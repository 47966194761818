import {
  Dialog as RACDialog,
  DialogTrigger as RACDialogTrigger,
  type DialogProps,
} from 'react-aria-components';
import { cn } from '../lib/cn';

const DialogTrigger = RACDialogTrigger;

const Dialog = (props: DialogProps) => {
  return (
    <RACDialog
      {...props}
      className={cn(
        'relative max-h-[inherit] overflow-auto rounded-lg bg-white outline-0 [[data-placement]>&]:p-4',
        props.className
      )}
    />
  );
};

export { DialogTrigger, Dialog };
