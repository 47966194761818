import { InsightsTableColorSchema } from '@magicbrief/common';

export const INSIGHTS_TABLE_NULL_ROW_ID =
  '9e50b465-9006-4e8a-884d-292dc6924120';

export const POSITIVE_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 5 },
  { color: { r: 104, g: 220, b: 143 }, position: 100 },
];
export const NEUTRAL_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 100 },
];

export const NEGATIVE_TABLE_COLORS = [
  { color: { r: 255, g: 255, b: 255 }, position: 0 },
  { color: { r: 255, g: 255, b: 255 }, position: 5 },
  { color: { r: 238, g: 114, b: 115 }, position: 100 },
];

export type ColorSwatchOption = {
  key: InsightsTableColorSchema;
  palette: Array<string>;
};

export const COLOR_SWATCHES: Array<ColorSwatchOption> = [
  { key: 'none', palette: ['bg-white'] },
  {
    key: 'positive-neutral',
    palette: [
      'bg-white',
      'bg-green-100',
      'bg-green-200',
      'bg-green-300',
      'bg-green-400',
    ],
  },
  {
    key: 'neutral-negative',
    palette: [
      'bg-red-400',
      'bg-red-300',
      'bg-red-200',
      'bg-red-100',
      'bg-white',
    ],
  },
  {
    key: 'positive-negative',
    palette: [
      'bg-red-400',
      'bg-red-200',
      'bg-white',
      'bg-green-200',
      'bg-green-400',
    ],
  },
];
