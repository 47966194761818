import { ComponentProps } from 'react';
import { startCase } from 'lodash';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { Spinner } from '@magicbrief/ui/src/components/spinner';
import Facebook from 'src/assets/svgicons/brands/Facebook.svg';
import Instagram from 'src/assets/svgicons/brands/Instagram.svg';
import { getMetricValue } from 'src/pages/Insights/util/useParseMetric';
import type {
  GetBreakdownsForFacebookAdResponse,
  GetBreakdownsForManyFacebookAdsResponse,
} from 'src/types/insights';
import Card from 'src/components/Card';
import Alert from 'src/components/AlertV2/Alert';

const DISPLAYED_PLACEMENT_VALUES = new Set([
  'instagram,instagram_stories',
  'facebook,feed',
  'instagram,feed',
  'facebook,marketplace',
  'instagram,instagram_reels',
]);

interface Props {
  breakdowns?:
    | GetBreakdownsForFacebookAdResponse
    | GetBreakdownsForManyFacebookAdsResponse;
  isFetchingBreakdowns: boolean;
  isBreakdownsError: boolean;
  currency: string;
  platform: 'facebook' | 'tiktok';
}

export function BreakdownPlacementChart({
  breakdowns,
  isFetchingBreakdowns,
  isBreakdownsError,
  currency,
  platform,
}: Props) {
  if (isFetchingBreakdowns) {
    return (
      <Card className="mt-2 flex h-[217px] flex-auto items-center justify-center bg-gray-50 p-3 text-primary shadow-sm">
        <Spinner />
      </Card>
    );
  }

  if (isBreakdownsError) {
    return (
      <Alert
        variant="danger"
        title="Error getting impressions data"
        className="mt-2"
      />
    );
  }

  const placementBreakdowns = breakdowns?.filter(
    (item) =>
      item.breakdownType === 'publisher_platform,platform_position' &&
      item.breakdownValue != null &&
      DISPLAYED_PLACEMENT_VALUES.has(item.breakdownValue)
  );

  const barProps = { barSize: 8, radius: 2 };

  if (!isFetchingBreakdowns && placementBreakdowns?.length === 0) {
    return (
      <Alert
        variant="warning"
        title="Placements data not available for this period "
        className="mt-2"
      />
    );
  }

  return (
    <Card className="mt-2 p-3 shadow-sm">
      <ResponsiveContainer width="100%" height={192}>
        <BarChart
          data={placementBreakdowns}
          barCategoryGap={8}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <XAxis
            height={48}
            className="text-xs [&_.recharts-cartesian-axis-tick_text]:fill-primary"
            dataKey="breakdownValue"
            type="category"
            axisLine={false}
            tickLine={false}
            tickMargin={8}
            includeHidden
            interval={0}
            tick={(props) => {
              const { x, y, payload } = props;
              const breakdownValue = payload.value;

              const getAxisIcon = () => {
                switch (breakdownValue) {
                  case 'facebook,feed':
                  case 'facebook,marketplace':
                    return (
                      <Facebook
                        className="text-[#1877F2]"
                        width={16}
                        height={16}
                        x={x - 8}
                        y={y - 4}
                      />
                    );
                  case 'instagram,instagram_stories':
                  case 'instagram,feed':
                  case 'instagram,instagram_reels':
                    return (
                      <Instagram
                        className="text-[#FE0BA1]"
                        width={16}
                        height={16}
                        x={x - 8}
                        y={y - 4}
                      />
                    );
                  default:
                    return null;
                }
              };

              return (
                <g>
                  {getAxisIcon()}
                  <g transform={`translate(${x + 2},${y + 28})`}>
                    <text style={{ textAnchor: 'middle' }}>
                      {getBreakdownLabel(payload.value)}
                    </text>
                  </g>
                </g>
              );
            }}
          />
          <Tooltip
            cursor={false}
            content={(tooltipProps) => (
              <PlacementsTooltip
                currency={currency}
                platform={platform}
                {...tooltipProps}
              />
            )}
          />
          <Bar dataKey="spend" fill="#6AD09D" {...barProps} />
          <Bar dataKey="impressions" fill="#ED974F" {...barProps} />
          <Bar dataKey="reach" fill="#71A4F4" {...barProps} />
        </BarChart>
      </ResponsiveContainer>
    </Card>
  );
}

interface PlacementsTooltipProps extends ComponentProps<typeof Tooltip> {
  currency: string;
  platform: 'facebook' | 'tiktok';
}

function PlacementsTooltip(props: PlacementsTooltipProps) {
  const { currency, payload, label, platform } = props;

  const breakdownLabel = getBreakdownLabel(label);

  return (
    <div className="flex flex-col overflow-hidden rounded-md border border-solid border-purple-100 bg-white shadow-sm">
      <div className="flex items-center justify-center gap-1.5 bg-primary p-2">
        {getBreakdownIcon(label)}
        <span className="text-xs font-semibold text-white">
          {breakdownLabel}
        </span>
      </div>

      <ul className="ml-0.5 p-2 text-xs">
        {payload?.map((item) => {
          const key = item.dataKey?.toString() ?? '';

          const formattedMetrics = {
            spend: item.payload.spend,
            impressions: item.payload.impressions,
            reach: item.payload.reach,
          };

          const value = getMetricValue(
            platform,
            key,
            formattedMetrics,
            currency
          );

          if (value == null || label == null) {
            return null;
          }

          return (
            <li
              key={item.dataKey}
              className="flex items-center justify-between gap-6"
            >
              <div className="flex items-center gap-1.5">
                <div
                  className="size-2 rounded-sm"
                  style={{ backgroundColor: item.color }}
                />
                <span className="">{startCase(key)}</span>
              </div>
              <span className="text-gray-600">{value} </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

const getBreakdownIcon = (breakdownValue: string) => {
  switch (breakdownValue) {
    case 'facebook,feed':
    case 'facebook,marketplace':
      return <Facebook className="size-4 text-white" />;
    case 'instagram,instagram_stories':
    case 'instagram,feed':
    case 'instagram,instagram_reels':
      return <Instagram className="size-4 text-white" />;
    default:
      return null;
  }
};
const getBreakdownLabel = (breakdownValue: string) => {
  switch (breakdownValue) {
    case 'instagram,instagram_stories':
      return 'Stories';
    case 'facebook,feed':
    case 'instagram,feed':
      return 'Feed';
    case 'facebook,marketplace':
      return 'Marketplace';
    case 'instagram,instagram_reels':
      return 'Reels';
    default:
      return '';
  }
};
