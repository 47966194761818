import { type FC, useState } from 'react';

import { AriaMenu } from '../AriaMenu/AriaMenu';
import { AriaButton } from '../Button/Button';
import { useI18nContext } from '../../i18n/i18n-react';
import { cn } from '../../lib/cn';
import { trpc } from '../../lib/trpc';

import { Alert } from '../Alert';
import AriaModal from '../AriaModal/AriaModal';
import { Badge } from '../Badge';
import { AriaBaseButton } from '../Button/BaseButton';
import Avatar from '../Misc/Avatar';
import { SquareLoaders } from '../SquareLoaders';
import { Tooltip } from '../Tooltip/Tooltip';
import { Customer } from './types';
// Customer interface defined in useYoutubeAuth

// Define our component props
type Props = {
  youtubeAuthState: {
    status: string;
    customers?: Customer[];
    youtubeIntegrationID?: number;
    error?: Error;
  };
  onClose: () => void;
  onSelectAccounts: (selectedCustomers: Customer[]) => void;
};

const YoutubeAccountSelectModal: FC<Props> = ({
  youtubeAuthState,
  onClose,
  onSelectAccounts,
}) => {
  const { LL } = useI18nContext();

  const integrationsQuery =
    trpc.youtube.getOrganisationYoutubeAccounts.useQuery();

  const youtubeIntegrations = integrationsQuery.data?.accounts ?? [];
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);

  const handleConfirm = () => {
    if (
      youtubeAuthState.status !== 'accountSelect' ||
      !youtubeAuthState.customers
    )
      return;

    const selectedCustomers = youtubeAuthState.customers.filter((customer) =>
      selectedCustomerIds.includes(customer.id)
    );

    onSelectAccounts(selectedCustomers);
  };

  return (
    <AriaModal
      show={youtubeAuthState.status !== 'init'}
      onClose={onClose}
      title="Select YouTube Accounts"
      className="!max-w-xl"
      footer={
        youtubeAuthState.status === 'accountSelect' && (
          <div className="flex flex-row justify-between gap-4">
            <AriaButton
              className="shrink-0 grow"
              variant="secondary"
              onPress={onClose}
            >
              {LL.cancel()}
            </AriaButton>
            <AriaButton
              className="shrink-0 grow"
              onPress={handleConfirm}
              isDisabled={selectedCustomerIds.length === 0}
            >
              {LL.confirm()}
            </AriaButton>
          </div>
        )
      }
    >
      {youtubeAuthState.status === 'error' && youtubeAuthState.error && (
        <Alert className="w-full" type="error">
          <p className="text-xs font-normal">
            {LL.errors.genericWithDetail({
              detail: youtubeAuthState.error.message ?? 'Unknown error',
            })}
          </p>
        </Alert>
      )}
      {(youtubeAuthState.status === 'pending' ||
        youtubeAuthState.status === 'codeExchange') && (
        <div className="flex flex-col gap-3">
          <SquareLoaders className="h-10" amount={5} />
        </div>
      )}
      {youtubeAuthState.status === 'accountSelect' &&
        youtubeAuthState.customers && (
          <div className="flex flex-col gap-2">
            <p className="text-xs font-normal text-primary">
              {LL.insights.accounts.accountSelect.description()}
            </p>

            <h2 className="text-lg font-semibold text-primary">
              {LL.insights.accounts.accountSelect.availableTitle()}
              <span className="pl-2 text-xs text-primary/30">
                ({selectedCustomerIds.length}/
                {youtubeAuthState.customers.length})
              </span>
            </h2>

            {youtubeAuthState.customers.length > 0 ? (
              <AriaMenu.List
                items={youtubeAuthState.customers}
                onSelectionChange={(set) => {
                  setSelectedCustomerIds(Array.from(set as Set<string>));
                }}
                selectionMode="multiple"
                selectedKeys={selectedCustomerIds}
                className="flex max-h-96 flex-col justify-between gap-3 overflow-auto"
              >
                {(customer) => {
                  const { id, descriptiveName } = customer;
                  const existingAccount = youtubeIntegrations.find(
                    (integration) =>
                      integration.OrganisationGoogleYoutubeAccount.some(
                        (y) => y.customerID === id
                      )
                  );

                  const exists = existingAccount != null;

                  return (
                    <AriaMenu.Item
                      id={id}
                      key={id}
                      // item box
                      className="font-primary h-auto shrink-0 cursor-pointer rounded-md border border-solid border-purple-300 p-3"
                      // checkbox
                      checkboxClassName={cn(
                        'rounded-md border border-solid border-purple-300 text-white',
                        'group-selected:border-purple-800 group-selected:bg-purple-800',
                        'group-disabled:text-pink group-disabled:cursor-not-allowed group-disabled:border-gray-300 group-disabled:bg-gray-300'
                      )}
                      // text
                      contentClassName="flex w-full flex-row justify-between gap-2"
                      showCheckbox={true}
                    >
                      <div className="flex flex-row items-center gap-2">
                        <Avatar
                          initial={descriptiveName.charAt(0)}
                          className="size-6 shrink-0"
                        />
                        <span className="font-primary text-xs font-semibold">
                          {descriptiveName} - (Google Ads ID: {id})
                        </span>
                      </div>
                      <div className="flex flex-row gap-2">
                        {!exists && (
                          <Tooltip>
                            <AriaBaseButton className="p-0">
                              <Badge color="primary">
                                {LL.insights.accounts.accountSelect.status.notAdded.ctaText()}
                              </Badge>
                            </AriaBaseButton>
                            <span>
                              {LL.insights.accounts.accountSelect.status.notAdded.tooltipText()}
                              <br />
                              {LL.insights.accounts.accountSelect.status.notAdded.tooltipAction()}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </AriaMenu.Item>
                  );
                }}
              </AriaMenu.List>
            ) : (
              <div className="italic text-purple-400">
                {'You have no accounts'}
              </div>
            )}
          </div>
        )}
    </AriaModal>
  );
};

export default YoutubeAccountSelectModal;
