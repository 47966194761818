export const getLastViewedAccountMap = (): Record<string, string> => {
  let parsed;
  try {
    const current = localStorage.getItem('insightsLastViewedAccount');
    if (current) {
      parsed = JSON.parse(current);
    } else {
      parsed = {};
    }

    if (Array.isArray(parsed) || typeof parsed !== 'object') {
      throw new Error('Item is not object');
    }
  } catch (e) {
    parsed = {};
    localStorage.setItem('insightsLastViewedAccount', '{}');
  }

  return parsed;
};
