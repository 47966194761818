import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  PropsWithChildren,
} from 'react';
import {
  InsightsAdWithInsights,
  InsightsAdGroupWithInsights,
} from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { useAtomValue } from 'jotai';
import { InsightsTableColorSchema } from '@magicbrief/common';
import { Key } from 'react-aria-components';
import {
  InsightsSort,
  useInsightsDisplay,
  useInsightsGraphMetrics,
} from '../../util/useInsightsPersistentState';
import { INSIGHTS_TABLE_SELECTION_MAX } from '../const';
import { presetAtom } from '../InsightsTable/TableMetricPresets.atoms';
import { tableColorPreferenceAtom } from '../../Insights.atoms';

interface InsightsAdBreakdownContextType {
  chartMetrics: string[];
  setChartMetrics: Dispatch<SetStateAction<string[]>>;
  tableMetrics: string[];
  setTableMetrics: Dispatch<SetStateAction<string[]>>;
  selectedKeys: Array<string | null>;
  setSelectedKeys: Dispatch<SetStateAction<Array<string | null>>>;
  preset: Key;
  setPreset: Dispatch<SetStateAction<Key>>;
  tableColorPreference: InsightsTableColorSchema;
  setTableColorPreference: Dispatch<SetStateAction<InsightsTableColorSchema>>;
  sort: Array<InsightsSort>;
  setSort: Dispatch<SetStateAction<Array<InsightsSort>>>;
  zIndex: number;
}

// eslint-disable-next-line react-refresh/only-export-components
export const InsightsAdBreakdownContext = createContext<
  InsightsAdBreakdownContextType | undefined
>({
  chartMetrics: [],
  setChartMetrics: () => {},
  tableMetrics: [],
  setTableMetrics: () => {},
  selectedKeys: [],
  setSelectedKeys: () => {},
  preset: 'custom',
  setPreset: () => {},
  tableColorPreference: 'positive-neutral',
  setTableColorPreference: () => {},
  sort: [],
  setSort: () => {},
  zIndex: 0,
});

interface InsightsAdBreakdownProviderProps extends PropsWithChildren {
  ads?: Array<InsightsAdWithInsights | InsightsAdGroupWithInsights>;
  sort: Array<InsightsSort>;
  setSort: Dispatch<SetStateAction<Array<InsightsSort>>>;
  zIndex: number;
}

export const InsightsAdBreakdownProvider = ({
  ads = [],
  sort,
  setSort,
  zIndex,
  children,
}: InsightsAdBreakdownProviderProps) => {
  const initialChartMetrics = useInsightsGraphMetrics();
  const initialTableMetrics = useInsightsDisplay();
  const initialPreset = useAtomValue(presetAtom);
  const initialTableColorPreference = useAtomValue(tableColorPreferenceAtom);

  const initialSelectedKeys = useMemo(
    () =>
      ads
        ?.slice(0, INSIGHTS_TABLE_SELECTION_MAX)
        ?.map((ad) => (ad.type === 'ad' ? ad.uuid : ad.group)) ?? [],
    [ads]
  );

  const [chartMetrics, setChartMetrics] =
    useState<string[]>(initialChartMetrics);
  const [tableMetrics, setTableMetrics] =
    useState<string[]>(initialTableMetrics);
  const [selectedKeys, setSelectedKeys] =
    useState<Array<string | null>>(initialSelectedKeys);
  const [preset, setPreset] = useState(initialPreset);
  const [tableColorPreference, setTableColorPreference] = useState(
    initialTableColorPreference
  );

  const value = {
    chartMetrics,
    setChartMetrics,
    tableMetrics,
    setTableMetrics,
    selectedKeys,
    setSelectedKeys,
    preset,
    setPreset,
    tableColorPreference,
    setTableColorPreference,
    zIndex,
    sort,
    setSort,
  };

  return (
    <InsightsAdBreakdownContext.Provider value={value}>
      {children}
    </InsightsAdBreakdownContext.Provider>
  );
};
