import { HTMLAttributes, PropsWithChildren } from 'react';
import { Button as AriaButton, Text } from 'react-aria-components';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { Spinner } from '@magicbrief/ui/src/components/spinner';
import {
  UPDATING_PAGE_PERMISSIONS_LINK,
  type ArrayElement,
} from '@magicbrief/common';
import { Button } from '@magicbrief/ui/src/components/button';
import { Link } from 'react-router-dom';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import { Icon } from 'src/components/Icon';
import Image from 'src/components/Image';
import PlayCircle from 'src/assets/svgicons/solid/play-circle.svg';
import SlashCircle01 from 'src/assets/svgicons/solid/slash-circle-01.svg';
import Share03 from 'src/assets/svgicons/solid/share-03.svg';
import { useFacebook } from 'src/utils/useFacebook';
import { FacebookAdAccountSelectModal } from 'src/components/FacebookConnect/FacebookAdAccountSelectModal';
import { trpc } from 'src/lib/trpc';
import AlertTriangle from 'src/assets/svgicons/line/alert-triangle.svg';
import type { InsightsAdFacebookAssetsList } from '@magicbrief/server/src/insights/types';

export function InsightsMediaPreview({
  asset,
  display,
  openAdPreview,
  viewAdsManagerUrl,
}: {
  asset: ArrayElement<InsightsAdFacebookAssetsList>;
  display: 'full' | 'grid' | 'table';
  openAdPreview: () => void;
  viewAdsManagerUrl?: string;
}) {
  const url = asset.cachedUrl || asset.url;
  const isCachedVideo = asset.mediaType === 'video' && url != null;
  const isIframeVideo =
    asset.mediaType === 'video' && url == null && asset.embedHtml != null;

  if (display === 'table') {
    switch (asset.assetState) {
      case 'initial':
      case 'processing':
      case 'recoverable_error':
      case 'unrecoverable_error':
      case 'not_found':
      case 'page_access_error': {
        return <TableMediaPlaceholder assetState={asset.assetState} />;
      }
      case 'ready':
      case 'completed': {
        return (
          <div className="flex-centered relative flex aspect-square overflow-hidden rounded-md">
            <Button
              className="group/thumbnail relative bg-gray-50 hover:cursor-zoom-in focus:outline-none"
              onPress={openAdPreview}
            >
              <Image
                className="w-full"
                src={
                  asset.cachedThumbnailUrl ??
                  asset.thumbnailUrl ??
                  (asset.mediaType === 'image'
                    ? (asset.cachedUrl ?? asset.url)
                    : null) ??
                  ''
                }
                fallbackSrc={asset.thumbnailUrl ?? ''}
                alt="thumbnail"
              />
            </Button>
          </div>
        );
      }

      default: {
        const _exhaustiveCheck: never = asset.assetState;
        throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
      }
    }
  }

  if (display === 'grid' || display === 'full') {
    switch (asset.assetState) {
      case 'initial':
      case 'processing': {
        return <SyncingMediaPreview />;
      }
      case 'recoverable_error':
      case 'unrecoverable_error':
      case 'not_found': {
        return (
          <ErrorMediaPreview
            assetState={asset.assetState}
            viewAdsManagerUrl={viewAdsManagerUrl}
          />
        );
      }
      case 'page_access_error': {
        return <ReauthMediaPreview />;
      }
      case 'ready':
      case 'completed': {
        return (
          <div className="relative flex aspect-square overflow-hidden rounded-md">
            <Button
              className="group/thumbnail relative bg-gray-50 hover:cursor-zoom-in focus:outline-none"
              onPress={openAdPreview}
            >
              {(isCachedVideo || isIframeVideo) && display === 'grid' && (
                <Icon
                  className={cn(
                    'absolute left-1/2 top-1/2 z-[1] size-14 -translate-x-1/2 -translate-y-1/2 rounded-full text-white drop-shadow-lg transition-all duration-300',
                    'group-hover/thumbnail:bg-white group-hover/thumbnail:text-primary'
                  )}
                >
                  <PlayCircle className="size-14" />
                </Icon>
              )}

              <Image
                className="w-full"
                src={
                  asset.cachedThumbnailUrl ??
                  asset.thumbnailUrl ??
                  (asset.mediaType === 'image'
                    ? (asset.cachedUrl ?? asset.url)
                    : null) ??
                  ''
                }
                fallbackSrc={asset.thumbnailUrl ?? ''}
                alt="thumbnail"
              />
            </Button>
          </div>
        );
      }

      default: {
        const _exhaustiveCheck: never = asset.assetState;
        throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
      }
    }
  }
}

function SyncingMediaPreview() {
  return (
    <MediaPreviewLayout>
      <Spinner className="size-5 text-gray-700/50" />
      <div className="flex flex-col items-center">
        <Text className="text-sm font-medium text-gray-800">
          Syncing ad content
        </Text>
        <Text className="text-xs font-medium text-gray-800/50">
          Please check back shortly
        </Text>
      </div>
    </MediaPreviewLayout>
  );
}

function ErrorMediaPreview({
  assetState,
  viewAdsManagerUrl,
}: {
  assetState: 'recoverable_error' | 'unrecoverable_error' | 'not_found';
  viewAdsManagerUrl?: string;
}) {
  const getLabel = () => {
    switch (assetState) {
      case 'recoverable_error':
        return 'We had issues loading this media, please attempt another sync';
      case 'unrecoverable_error':
      case 'not_found':
        return 'This media is no longer available on facebook';
      default: {
        const _exhaustiveCheck: never = assetState;
        throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
      }
    }
  };

  return (
    <MediaPreviewLayout>
      <div className="size-10 rounded-full bg-gray-100 p-2 text-gray-700/50">
        <SlashCircle01 className="size-6" />
      </div>
      <div className="flex flex-col items-center">
        <Text className="text-sm font-medium text-gray-800">
          Preview not available
        </Text>
        <Text className="text-center text-xs font-medium text-gray-800/50">
          {getLabel()}
        </Text>
      </div>
      <Link to={viewAdsManagerUrl ?? '/'} target="_blank" className="w-full">
        <Button
          variant="text"
          size="small"
          className="w-full hover:bg-purple-100/75"
        >
          View in Ads Manager
          <Share03 className="size-4" />
        </Button>
      </Link>
    </MediaPreviewLayout>
  );
}

function ReauthMediaPreview() {
  const trpcUtils = trpc.useUtils();
  const {
    authenticate,
    authState: facebookAuthState,
    setAuthState: setFacebookAuthState,
  } = useFacebook();

  return (
    <>
      <MediaPreviewLayout>
        <div className="size-10 rounded-full bg-gray-100 p-2 text-gray-700/50">
          <AlertTriangle className="size-6" />
        </div>
        <div className="flex flex-col items-center">
          <Text className="text-center text-sm font-medium text-gray-800">
            MagicBrief needs permission to view this asset
          </Text>
          <Text className="text-center text-xs font-medium text-gray-800/50">
            Please connect the Facebook page or Instagram account running this
            ad
          </Text>
        </div>
        <Link
          to={UPDATING_PAGE_PERMISSIONS_LINK}
          target="_blank"
          className="w-full"
        >
          <Button variant="secondary" size="small" className="w-full">
            How to fix this
            <Share03 className="size-4" />
          </Button>
        </Link>
        <Button
          size="small"
          className="w-full"
          onPress={() => void authenticate()}
        >
          Edit page permissions
          <Share03 className="size-4" />
        </Button>
      </MediaPreviewLayout>

      {facebookAuthState?.status !== 'init' && (
        <FacebookAdAccountSelectModal
          facebookAuthState={facebookAuthState}
          onClose={() => {
            setFacebookAuthState({ status: 'init' });
            void trpcUtils.user.getUserAndOrganisation.invalidate();
            setTimeout(() => {
              void trpcUtils.insightsAccounts.getManyAdAccounts.refetch();
            }, 3000);
          }}
        />
      )}
    </>
  );
}

function MediaPreviewLayout({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  return (
    <div
      className={cn(
        'flex aspect-square w-full flex-col items-center justify-center gap-3 rounded-md border border-solid border-transparent bg-gray-50 px-6 py-3',
        /* Hover Insights Card */
        'group-hover:border-gray-200',
        className
      )}
    >
      {children}
    </div>
  );
}

interface TableMediaPlaceholderProps extends HTMLAttributes<HTMLDivElement> {
  assetState:
    | 'initial'
    | 'processing'
    | 'recoverable_error'
    | 'unrecoverable_error'
    | 'not_found'
    | 'page_access_error';
}

function TableMediaPlaceholder({ assetState }: TableMediaPlaceholderProps) {
  switch (assetState) {
    case 'initial':
    case 'processing': {
      return (
        <TooltipTrigger delay={200} closeDelay={0}>
          <AriaButton className="flex min-h-8 min-w-8 items-center justify-center rounded-md bg-gray-50">
            <Spinner className="size-4 text-gray-700/50" />
          </AriaButton>
          <Tooltip placement="right">
            <div className="flex flex-col">
              <span className="text-xs">Syncing ad content</span>
              <span className="text-xs text-white/75">
                Please check back shortly
              </span>
            </div>
          </Tooltip>
        </TooltipTrigger>
      );
    }

    case 'recoverable_error':
    case 'unrecoverable_error':
    case 'not_found': {
      const getLabel = () => {
        switch (assetState) {
          case 'recoverable_error':
            return 'We had issues loading this media, please attempt another sync';
          case 'unrecoverable_error':
          case 'not_found':
            return 'This media is no longer available on facebook';

          default: {
            const _exhaustiveCheck: never = assetState;
            throw new Error(`Unhandled asset state: ${_exhaustiveCheck}`);
          }
        }
      };

      return (
        <TooltipTrigger delay={200} closeDelay={0}>
          <AriaButton className="flex min-h-8 min-w-8 items-center justify-center rounded-md bg-gray-50">
            <SlashCircle01 className="size-4 text-gray-700/50" />
          </AriaButton>
          <Tooltip placement="right">
            <div className="flex flex-col">
              <span className="text-xs">Preview not available</span>
              <span className="text-xs text-white/75">{getLabel()}</span>
            </div>
          </Tooltip>
        </TooltipTrigger>
      );
    }

    case 'page_access_error': {
      return (
        <TooltipTrigger delay={200} closeDelay={0}>
          <AriaButton className="flex min-h-8 min-w-8 items-center justify-center rounded-md bg-gray-50">
            <AlertTriangle className="size-4 text-gray-700/50" />
          </AriaButton>
          <Tooltip placement="right">
            <div className="flex flex-col">
              <span className="text-xs">
                MagicBrief needs permission to view this asset
              </span>
              <span className="text-xs text-white/75">
                Please connect the Facebook page or Instagram account running
                this ad
              </span>
            </div>
          </Tooltip>
        </TooltipTrigger>
      );
    }

    default:
      return null;
  }
}
