import React from 'react';
import classNames from 'classnames';
import { motion } from 'motion/react';
import { Popover, PopoverProps } from 'react-aria-components';
import { PopoverAnimationVariants } from './usePopoverState';

const MotionPopover = motion(Popover);

type Props = Omit<PopoverProps, 'className' | 'children' | 'style'> & {
  state: keyof typeof PopoverAnimationVariants | 'unmounted';
  className?: string;
  onStateChange: (
    state: React.SetStateAction<
      keyof typeof PopoverAnimationVariants | 'unmounted'
    >
  ) => void;
  children: React.ReactNode;
  style?: React.CSSProperties;
};

export function AriaPopover({
  state,
  onStateChange,
  className,
  ...rest
}: Props): JSX.Element {
  return (
    <MotionPopover
      onAnimationComplete={(definition) => {
        onStateChange((s) =>
          definition === 'initial' && s === 'initial' ? 'unmounted' : s
        );
      }}
      isExiting={state === 'initial'}
      variants={PopoverAnimationVariants}
      initial="initial"
      animate={state}
      className={classNames(className)}
      {...rest}
    />
  );
}
