import { Button, Heading, Text } from 'react-aria-components';
import { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import { Tooltip, TooltipTrigger } from '@magicbrief/ui/src/components/tooltip';
import { InsightsFacebookGroup } from '@magicbrief/common';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import LayersThree01 from 'src/assets/svgicons/line/layers-three-01.svg';
import { useI18nContext } from 'src/i18n/i18n-react';

export const InsightsGroupHeader = ({
  adGroup,
  group,
}: {
  adGroup: InsightsAdGroupWithInsights;
  group: InsightsFacebookGroup | null;
}) => {
  const { LL } = useI18nContext();

  const { count, name } = adGroup;

  const groupByLabel =
    group != null && group != 'true'
      ? `Grouped by ${LL.insights.groups[group]()}`
      : '';
  const countLabel = count === 1 ? `${count} ad` : `${count} ads`;

  return (
    <div className="flex items-center justify-between bg-purple-900 px-4 py-3">
      <div className="flex items-center gap-4">
        <Button
          className="size-8 rounded-lg bg-primary p-2 transition-colors hover:bg-purple-700"
          slot="close"
        >
          <XClose className="size-4 text-white" />
        </Button>

        <div className="space-y-0.5">
          <TooltipTrigger>
            <Button className="line-clamp-1">
              <Heading className="text-start text-base font-semibold text-white">
                {name}
              </Heading>
            </Button>
            <Tooltip placement="bottom" offset={4} className="max-w-[50%]">
              {name}
            </Tooltip>
          </TooltipTrigger>
          <div className="flex items-center text-xs font-medium text-white">
            <LayersThree01 className="mr-1.5 size-[14px]" />
            <Text className="mr-1">{groupByLabel}</Text>
            <span className="mr-1 text-sm">&middot;</span>
            <Text className="mr-4">{countLabel}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};
