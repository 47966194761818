import { ComponentProps, forwardRef } from 'react';
import classNames from 'classnames';
import { useI18nContext } from 'src/i18n/i18n-react';

type Props = {
  label: string;
  optional?: boolean;
  error?: string | null;
  name: string;
  count?: string;
} & Omit<ComponentProps<'textarea'>, 'name'>;

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { label, name, className, optional, error, placeholder, count, ...rest },
    ref
  ) => {
    const { LL } = useI18nContext();
    return (
      <div>
        <div className="flex items-center justify-between gap-2">
          {label && (
            <label
              htmlFor={name}
              className="mb-1 block text-xs font-semibold text-primary sm:text-sm"
            >
              {label}
              {optional && (
                <span className="text-[#9176DD]"> {LL.optional()}</span>
              )}
            </label>
          )}
          {count ? (
            <span className="text-xs font-normal text-primary/50">{count}</span>
          ) : null}
        </div>
        <textarea
          ref={ref}
          className={classNames(
            'form-textarea block w-full flex-1 rounded-md border border-solid border-purple-300 text-base text-primary shadow-sm placeholder:text-primary/50 focus:border-purple-500 focus:ring-purple-500 disabled:cursor-not-allowed disabled:border-gray-300 disabled:bg-gray-50 disabled:text-gray-500 sm:text-sm',
            className
          )}
          name={name}
          id={name}
          placeholder={placeholder}
          aria-describedby={error ? `${name}-error` : undefined}
          aria-invalid={!!error}
          {...rest}
        />
        {error && (
          <p
            className="mt-2 text-xs text-danger sm:text-sm"
            id={`${name}-error`}
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

export default TextArea;
