import React, { useMemo } from 'react';
import { Provider, atom } from 'jotai';
import { AppRouter } from '@magicbrief/server/src/trpc/router';
import { inferProcedureOutput } from '@trpc/server';
import {
  InsightsPersistentStateContext,
  InsightsView,
  createInsightsAtom,
} from '../util/useInsightsPersistentState';
import { useInsightsSearchParams } from '../util/useInsightsSearchParams';

type InsightsPersistentStateContextStandardParam = {
  platform: 'facebook' | 'tiktok';
  type: 'standard';
  accountUuid: string;
  view: InsightsView;
};

type InsightsPersistentStateContextReportParam = {
  platform: 'facebook' | 'tiktok';
  type: 'report';
  reportUuid: string;
  initialState: inferProcedureOutput<
    AppRouter['insightsReports']['getInsightsReport']
  >['config'];
};

type InsightsPersistentStateContextComparisonReportParam = {
  platform: 'facebook' | 'tiktok';
  type: 'comparison-report';
  reportUuid: string;
  initialState: inferProcedureOutput<
    AppRouter['insightsComparisonReports']['getOne']
  >;
};

type InsightsPersistentStateContextParams =
  | InsightsPersistentStateContextStandardParam
  | InsightsPersistentStateContextReportParam
  | InsightsPersistentStateContextComparisonReportParam;

export const InsightsPersistentStateProvider: React.FC<
  React.PropsWithChildren<InsightsPersistentStateContextParams>
> = ({ children, ...rest }) => {
  const { getParsedValues } = useInsightsSearchParams();

  const type = rest.type;
  const platform = rest.platform;
  const view = rest.type === 'standard' ? rest.view : 'overview';
  const accountUuid = rest.type === 'standard' ? rest.accountUuid : '';
  const reportUuid = rest.type !== 'standard' ? rest.reportUuid : '';
  const initialState = rest.type !== 'standard' ? rest.initialState : null;

  const groupParam = getParsedValues().group;
  const sortParam = getParsedValues().sort;
  const attributionWindowParam = getParsedValues().attributionWindow;

  const atoms = useMemo(() => {
    const all = createInsightsAtom(
      platform,
      type === 'standard'
        ? {
            type,
            accountUuid,
            view,
            initialState: {
              ...(attributionWindowParam
                ? { attributionWindow: attributionWindowParam }
                : {}),
              ...(groupParam ? { group: groupParam } : {}),
              ...(sortParam ? { sort: sortParam } : {}),
            },
          }
        : type === 'report'
          ? {
              type,
              reportUuid,
              initialState: {
                ...initialState,
                filter: {
                  ...(initialState && 'filter' in initialState
                    ? initialState.filter
                    : {}),
                  ...(sortParam ? { sort: sortParam } : {}),
                },
                ...(attributionWindowParam
                  ? { attributionWindow: attributionWindowParam }
                  : {}),
                ...(groupParam ? { group: groupParam } : {}),
              } as InsightsPersistentStateContextReportParam['initialState'],
            }
          : {
              type,
              reportUuid,
              initialState: {
                ...initialState,
                ...(sortParam ? { sort: sortParam } : {}),
                ...(attributionWindowParam
                  ? { attributionWindow: attributionWindowParam }
                  : {}),
              } as InsightsPersistentStateContextComparisonReportParam['initialState'],
            }
    );
    const filter = atom((get) => get(all).filter);
    const display = atom((get) => get(all).display);
    const sort = atom((get) => get(all).sort);
    const group = atom((get) => get(all).group);
    const columnSizing = atom((get) => get(all).columnSizing);
    const graphMetrics = atom((get) => get(all).graphMetrics);
    const gridMetrics = atom((get) => get(all).gridMetrics);
    const selected = atom((get) => get(all).selected);
    const selectedGrid = atom((get) => get(all).selectedGrid);
    const layout = atom((get) => get(all).layout);
    const tableColorScheme = atom((get) => get(all).tableColorScheme);
    const comparisons = atom((get) => get(all).comparisons);
    const attributionWindow = atom((get) => get(all).attributionWindow);

    return {
      all,
      platform,
      filter,
      display,
      sort,
      group,
      columnSizing,
      graphMetrics,
      gridMetrics,
      selected,
      selectedGrid,
      layout,
      tableColorScheme,
      comparisons,
      attributionWindow,
    };
  }, [
    accountUuid,
    attributionWindowParam,
    groupParam,
    initialState,
    platform,
    reportUuid,
    sortParam,
    type,
    view,
  ]);

  return (
    <Provider>
      <InsightsPersistentStateContext.Provider value={atoms}>
        {children}
      </InsightsPersistentStateContext.Provider>
    </Provider>
  );
};
