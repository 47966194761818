import { useState } from 'react';
import { useAtom } from 'jotai';
import { CSS } from '@dnd-kit/utilities';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Button, Group, Label, Separator, Text } from 'react-aria-components';
import { useParams } from 'react-router-dom';
import ChevronDown from 'src/assets/svgicons/duocolor/chevron-down.svg';
import Plus from 'src/assets/svgicons/line/plus.svg';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import DotsGrid from 'src/assets/svgicons/solid/dots-grid.svg';
import { MagicBriefButton } from 'src/components/Button/MagicBriefButton';
import { Icon } from 'src/components/Icon';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from 'src/components/Popover/AriaPopover';
import { useI18nContext } from 'src/i18n/i18n-react';
import { cn } from 'src/lib/cn';
import { tableColorPreferenceAtom } from '../../Insights.atoms';
import {
  useInsightsDisplay,
  useInsightsPlatform,
  useInsightsStoreDispatch,
  useInsightsTableColorScheme,
} from '../../util/useInsightsPersistentState';
import { useInsightsAdAccount } from '../../util/useInsightsQueries';
import { useParseMetric } from '../../util/useParseMetric';
import { InsightsDisplayMetricModal } from '../InsightsFilter/InsightsFilterDisplayMenuV2';
import { COLOR_SWATCHES, ColorSwatchOption } from './const';
import type { InsightsTableColorSchema } from '@magicbrief/common';

export function TableSettingsPopover() {
  const { LL } = useI18nContext();
  const platform = useInsightsPlatform();
  const display = useInsightsDisplay();

  const [isOpen, setIsOpen] = useState(false);
  const [isMetricsModalOpen, setIsMetricsModalOpen] = useState(false);
  const [localDisplay, setLocalDisplay] = useState(display);

  const dispatch = useInsightsStoreDispatch();
  const reportTableColorScheme = useInsightsTableColorScheme();
  const [tableColorPreference, setTableColorPreference] = useAtom(
    tableColorPreferenceAtom
  );

  const handleSetColorScheme = (color: ColorSwatchOption) => {
    if (reportTableColorScheme != null) {
      dispatch({
        type: 'setTableColorScheme',
        value: color.key as InsightsTableColorSchema,
      });
    } else {
      setTableColorPreference(color.key as InsightsTableColorSchema);
    }
  };

  return (
    <>
      <PopoverTrigger
        isOpen={isOpen}
        onOpenChange={(val) => {
          setIsOpen(val);
          // Reset the local display state when closing the table settings popover
          if (!val && localDisplay !== display) {
            setTimeout(() => {
              setLocalDisplay(display);
            }, 300);
          }
        }}
      >
        <Button
          className={cn(
            /* Base */
            'flex min-h-9 w-fit items-center gap-1 text-nowrap rounded-md border border-solid border-purple-200 bg-white px-2.5 py-2 shadow-xs transition-colors focus:outline-none',
            /* Typography */
            'text-xs font-semibold text-primary',
            /* Hover */
            'hover:bg-purple-50'
          )}
        >
          {LL.insights.table.tableSettings()}
          <Icon className="size-4">
            <ChevronDown />
          </Icon>
        </Button>
        <Popover placement="bottom left" offset={8}>
          <PopoverDialog className="flex w-[250px] flex-col rounded-md bg-white p-0">
            <Group className="flex items-center justify-between gap-1 px-3 py-2">
              <Label className="text-xs font-medium text-primary">
                {LL.insights.table.colorFormatting()}
              </Label>
              <div className="flex items-center gap-1">
                {COLOR_SWATCHES.map((color) => (
                  <ColorSwatch
                    key={color.key}
                    color={color}
                    tableColorScheme={tableColorPreference}
                    handleSetColorScheme={handleSetColorScheme}
                  />
                ))}
              </div>
            </Group>
            <Separator className="bg-purple-200" />
            <Group className="w-full space-y-2 px-3 py-2">
              <DraggableTableMetrics />
            </Group>
            <Separator className="bg-purple-200" />
            <Group className="w-full space-y-2 px-3 py-1">
              <MagicBriefButton
                variant="text"
                className="h-8 rounded p-0 text-xs"
                onPress={() => {
                  setIsOpen(false);
                  setTimeout(() => setIsMetricsModalOpen(true), 100);
                }}
              >
                <Icon className="size-4">
                  <Plus />
                </Icon>
                Add Metric
              </MagicBriefButton>
            </Group>
          </PopoverDialog>
        </Popover>
      </PopoverTrigger>

      <InsightsDisplayMetricModal
        platform={platform}
        isOpen={isMetricsModalOpen}
        setIsOpen={setIsMetricsModalOpen}
      />
    </>
  );
}

export function ColorSwatch({
  color,
  tableColorScheme,
  handleSetColorScheme,
}: {
  color: (typeof COLOR_SWATCHES)[number];
  tableColorScheme: InsightsTableColorSchema;
  handleSetColorScheme: (color: ColorSwatchOption) => void;
}) {
  const isCurrentPreference = tableColorScheme === color.key;

  return (
    <Button
      className={cn(
        'flex size-6 rounded border border-solid border-purple-200 transition-opacity hover:opacity-75 focus:outline-none',
        isCurrentPreference ? 'border-purple-500' : ''
      )}
      onPress={() => handleSetColorScheme(color)}
    >
      {color.palette.map((col, index) => (
        <div
          key={`${color.key}_${col}`}
          className={cn(
            'size-full',
            col,
            index === 0 ? 'rounded-l' : '',
            index === color.palette.length - 1 ? 'rounded-r' : ''
          )}
        />
      ))}
    </Button>
  );
}

function DraggableTableMetrics() {
  const dispatch = useInsightsStoreDispatch();
  const display = useInsightsDisplay();

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {})
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      const oldIndex = display.indexOf(active.id as string);
      const newIndex = display.indexOf(over.id as string);
      const newDisplayOrder = arrayMove(display, oldIndex, newIndex);

      dispatch({ type: 'setDisplay', value: newDisplayOrder });
    }
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={display} strategy={verticalListSortingStrategy}>
        <div className="max-h-[300px] space-y-0 overflow-x-hidden">
          {display.map((id) => (
            <DraggableMetric key={id} id={id} />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

function DraggableMetric({ id }: { id: string }) {
  const dispatch = useInsightsStoreDispatch();
  const platform = useInsightsPlatform();
  const { accountUuid } = useParams();
  const { getMetricLabelAsText } = useParseMetric();
  const { accountCustomEvents, accountCustomConversions } =
    useInsightsAdAccount({
      accountUuid,
    });
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id,
  });

  const styles: React.CSSProperties = {
    zIndex: isDragging ? 1 : 0,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? 0.8 : 1,
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      className="flex items-center gap-1.5 rounded-md py-1.5 hover:cursor-grabbing"
      style={styles}
      aria-label="selected display metric"
      {...attributes}
      {...listeners}
    >
      <button className="cursor-grab text-purple-300 active:cursor-grabbing focus:outline-none">
        <Icon className="size-4">
          <DotsGrid />
        </Icon>
      </button>
      <Text
        slot="label"
        className="grow truncate text-xs font-medium text-primary"
      >
        {getMetricLabelAsText(
          platform,
          id,
          accountCustomEvents ?? [],
          accountCustomConversions ?? []
        )}
      </Text>
      <Button
        onPress={() => dispatch({ type: 'toggleDisplay', value: id })}
        className="size-4 rounded-sm text-primary/50 outline-none hover:bg-purple-50"
      >
        <Icon className="size-4 text-primary opacity-50">
          <XClose />
        </Icon>
      </Button>
    </div>
  );
}
