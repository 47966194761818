import React, { forwardRef } from 'react';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { motion } from 'motion/react';
import { ModalOverlay as AriaModalOverlay } from 'react-aria-components';

const MotionAriaModalOverlay = motion.create(AriaModalOverlay);

export const MotionModalOverlay = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MotionAriaModalOverlay>
>(({ className, children, ...props }, ref) => {
  return (
    <MotionAriaModalOverlay
      {...props}
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{
        opacity: 100,
        transition: { ease: 'easeOut', duration: 0.3 },
      }}
      exit={{ opacity: 0, transition: { ease: 'easeIn', duration: 0.2 } }}
      className={cn(
        'fixed inset-0 z-[100000] flex min-h-full items-center justify-center overflow-y-auto bg-gray-900/60',
        className
      )}
    >
      {children}
    </MotionAriaModalOverlay>
  );
});

MotionModalOverlay.displayName = 'MotionModalOverlay';
