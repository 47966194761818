/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs';
import BigIntSupport from 'dayjs/plugin/bigIntSupport';
import RelativeTime from 'dayjs/plugin/relativeTime';
import QuarterOfYear from 'dayjs/plugin/quarterOfYear';
import Utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';

dayjs.extend(BigIntSupport);
dayjs.extend(RelativeTime);
dayjs.extend(QuarterOfYear);
dayjs.extend(Utc);
dayjs.extend(Timezone);

export default dayjs;
