import React, { forwardRef } from 'react';
import { Modal as AriaModal } from 'react-aria-components';
import { motion } from 'motion/react';
import { cn } from '@magicbrief/ui/src/lib/cn';

const MotionAriaModal = motion.create(AriaModal);

export const MotionModal = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof MotionAriaModal>
>(({ className, children, ...props }, ref) => {
  return (
    <MotionAriaModal
      {...props}
      ref={ref}
      layout
      initial={{ scale: 0.95 }}
      animate={{
        scale: 1,
        transition: { duration: 0.3, ease: 'easeOut' },
      }}
      exit={{
        scale: 0.95,
        transition: { duration: 0.2, ease: 'easeIn' },
      }}
      transition={{
        layout: {
          duration: 0.1,
        },
      }}
      className={cn('shadow-xl', className)}
    >
      {children}
    </MotionAriaModal>
  );
});

MotionModal.displayName = 'MotionModal';
