import React, { useRef, useState } from 'react';
import {
  Button,
  Heading,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from 'react-aria-components';
import { toast } from 'react-toastify';
import { SimpleModal } from 'src/components/AriaModal';
import { AriaButton } from 'src/components/Button';
import { Icon } from 'src/components/Icon';
import { trpc } from 'src/lib/trpc';
import Trash01 from 'src/assets/svgicons/line/trash-01.svg';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from 'src/components/Popover/AriaPopover';
import Input from 'src/components/Input';
import { useUserAndOrganisation } from 'src/utils/useUserAndOrganisation';

interface InsightsAdminModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const InsightsAdminModal: React.FC<InsightsAdminModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <SimpleModal title="Insights Admin" show={isOpen} onClose={onClose}>
      <InsightsAdminModalContent onClose={onClose} />
    </SimpleModal>
  );
};

interface InsightsAdminModalContentProps {
  onClose: () => void;
}

const InsightsAdminModalContent: React.FC<InsightsAdminModalContentProps> = ({
  onClose,
}) => {
  const [tab, setTab] = useState('list');

  const demoAccounts =
    trpc.insightsAccounts.getAccountsForDemonstration.useQuery();

  const initInsightsDemoAccount =
    trpc.insightsAccounts.cloneAccountForDemonstration.useMutation();

  const deleteInsightsDemoAccounts =
    trpc.insightsAccounts.deleteAccountForDemonstration.useMutation();

  const user = useUserAndOrganisation();

  const utils = trpc.useUtils();

  const createButtonRef = useRef<HTMLButtonElement | null>(null);

  const uuidRef = useRef<HTMLInputElement | null>(null);
  const nameRef = useRef<HTMLInputElement | null>(null);

  const [isCreateConfirmationPopoverOpen, setIsCreateConfirmationPopoverOpen] =
    useState(false);

  const create = () => {
    setIsCreateConfirmationPopoverOpen(false);

    if (!uuidRef.current?.value) {
      toast('You must provide a target organisation account UUID', {
        className: 'toast-danger',
      });
      return;
    }

    initInsightsDemoAccount.mutate(
      {
        targetOrganisationAdAccountUuid: uuidRef.current.value,
        name: nameRef.current?.value,
      },
      {
        onSuccess: () => {
          setTab('list');
          void utils.insightsAccounts.getAccountsForDemonstration.invalidate();
          toast('Cloning started...', {
            className: 'toast-success',
          });
        },
        onError: (e) => {
          toast(e.message, {
            className: 'toast-danger',
          });
        },
      }
    );
  };

  return (
    <div>
      <Tabs
        selectedKey={tab}
        defaultSelectedKey="list"
        onSelectionChange={(key) => {
          setTab(key as typeof tab);
        }}
        className="flex min-h-0 flex-col gap-2"
      >
        <TabList
          aria-label="Ad Insights"
          className="flex flex-row gap-3 text-xs font-semibold text-primary/40"
        >
          <Tab
            className="cursor-pointer outline-none focus:outline-none focus-visible:outline-none selected:text-purple-800 disabled:text-gray-400"
            id="list"
          >
            Demo Account(s)
          </Tab>
          <Tab
            id="create"
            className="cursor-pointer outline-none focus:outline-none focus-visible:outline-none selected:text-purple-800"
          >
            Create New Demo Account
          </Tab>
        </TabList>
        <TabPanel id="list" className="flex min-h-0 flex-auto">
          <div className="flex flex-col gap-2">
            {demoAccounts.data?.length === 0 && (
              <p className="text-xs text-text-secondary">
                There are no demo accounts
              </p>
            )}
            {!demoAccounts.isLoading && (
              <div className="flex w-full flex-row">
                <AriaButton
                  onPress={() => demoAccounts.refetch()}
                  size="smallest"
                  variant="secondary"
                  loading={demoAccounts.isRefetching}
                >
                  Refresh
                </AriaButton>
              </div>
            )}
            {demoAccounts.isLoading && <p>Loading...</p>}
            {!!demoAccounts.data?.length &&
              demoAccounts.data.map((x) => (
                <div
                  key={x.uuid}
                  className="flex flex-row gap-2 rounded border border-solid border-purple-200 p-2 shadow-sm"
                >
                  <div className="flex flex-col gap-1">
                    <Heading className="text-md font-semibold text-primary">
                      {x.name}
                    </Heading>
                    <span className="text-xs text-text-secondary">
                      Clone of{' '}
                      <strong>{x.mirrorOf?.name ?? '[DELETED]'}</strong>
                    </span>
                    <span className="text-xs text-text-secondary">
                      Demonstratable from organisation(s):{' '}
                      <strong>{x.organisations.join(', ')}</strong>
                    </span>

                    {x.progress !== undefined && (
                      <div className="flex w-full flex-row items-center gap-2">
                        <span className="text-xxs text-text-secondary">
                          Cloning...
                        </span>
                        <progress
                          className="flex-1"
                          max="100"
                          value={x.progress}
                        >
                          {x.progress}%
                        </progress>
                      </div>
                    )}
                  </div>

                  {x.progress === undefined && (
                    <PopoverTrigger>
                      <Button className="text-danger">
                        <Icon>
                          <Trash01 />
                        </Icon>
                      </Button>
                      <Popover>
                        <PopoverDialog className="flex flex-col gap-2">
                          <Heading className="text-sm font-semibold text-primary">
                            Delete Account
                          </Heading>
                          <p className="text-sm text-text-secondary">
                            Delete this demo account permanently?
                          </p>
                          <div className="flex w-full flex-row gap-2">
                            <AriaButton
                              className="w-1/2"
                              size="smallest"
                              variant="secondary"
                              onPress={onClose}
                            >
                              Cancel
                            </AriaButton>
                            <AriaButton
                              loading={deleteInsightsDemoAccounts.isLoading}
                              onPress={() => {
                                if (!x.uuid) return;

                                deleteInsightsDemoAccounts.mutate(
                                  {
                                    uuid: x.uuid,
                                  },
                                  {
                                    onSuccess: () => {
                                      void utils.insightsAccounts.getAccountsForDemonstration.invalidate();
                                      setTab('list');
                                      toast('Account deleted', {
                                        className: 'toast-success',
                                      });
                                    },
                                    onError: (e) => {
                                      toast(e.message, {
                                        className: 'toast-danger',
                                      });
                                    },
                                  }
                                );
                              }}
                              className="w-1/2"
                              size="smallest"
                              colour="danger"
                            >
                              Delete
                            </AriaButton>
                          </div>
                        </PopoverDialog>
                      </Popover>
                    </PopoverTrigger>
                  )}
                </div>
              ))}
          </div>
        </TabPanel>
        <TabPanel id="create" className="flex min-h-0 flex-auto">
          <div className="flex flex-col gap-2">
            <p className="text-primary">
              Submitting this form will automatically create a new demo account
              by cloning and scrubbing data from the target account.
            </p>
            <p className="text-primary">
              The new account will be created in your current organisation,
              which is <strong>{user.data?.organisation.name}</strong>.
            </p>
            <p className="text-primary">
              <strong>
                If this is not correct{' '}
                <span className="text-danger">stop now</span>
              </strong>{' '}
              and switch to the intended organisation.
            </p>
            <div className="flex flex-col gap-1">
              <Input
                ref={uuidRef}
                name="targetOrganisationAccountUuid"
                label="Target Insights Facebook Organisation Account UUID"
                placeholder="UUID of account that will be cloned"
              />
              <p className="text-xs text-primary">
                Ask an engineering team member to provide you with a target
                organisation account UUID.
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <Input
                ref={nameRef}
                name="name"
                label="Name for Insights Account (Optional)"
                placeholder="The name to give the cloned insights account"
              />

              <p className="text-xs text-primary">
                If no name is provided, a random one will be generated.
              </p>
            </div>

            <AriaButton
              ref={createButtonRef}
              onPress={() => setIsCreateConfirmationPopoverOpen(true)}
              loading={initInsightsDemoAccount.isLoading}
            >
              Create
            </AriaButton>
            <Popover
              triggerRef={createButtonRef}
              isOpen={isCreateConfirmationPopoverOpen}
              onOpenChange={(isOpen) =>
                setIsCreateConfirmationPopoverOpen(isOpen)
              }
            >
              <PopoverDialog className="flex flex-col gap-2">
                <Heading className="text-sm font-semibold text-primary">
                  Caution!
                </Heading>
                <p className="text-sm text-primary">
                  You are creating a demonstration Insights account in the
                  organisation <strong>{user.data?.organisation.name}</strong>.
                  Are you sure?
                </p>
                <div className="flex w-full flex-row gap-2">
                  <AriaButton
                    className="w-1/2"
                    size="smallest"
                    variant="secondary"
                    onPress={() => setIsCreateConfirmationPopoverOpen(false)}
                  >
                    Cancel
                  </AriaButton>
                  <AriaButton
                    loading={initInsightsDemoAccount.isLoading}
                    onPress={create}
                    className="w-1/2"
                    size="smallest"
                  >
                    Continue
                  </AriaButton>
                </div>
              </PopoverDialog>
            </Popover>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};
