import {
  FieldErrorProps,
  InputProps,
  LabelProps,
  FieldError as RACFieldError,
  Input as RACInput,
  Label as RACLabel,
  Text,
  TextProps,
} from 'react-aria-components';
import { cn } from '../lib/cn';
import { forwardRef } from 'react';

export function Label(props: LabelProps) {
  return (
    <RACLabel
      {...props}
      className={cn(
        'w-fit cursor-default text-xs font-medium leading-none text-primary',
        /* Disabled */
        'data-[disabled]:cursor-not-allowed data-[disabled]:opacity-70',
        /* Invalid */
        'group-data-[invalid]:text-red-500',
        props.className
      )}
    />
  );
}

export function Description(props: TextProps) {
  return (
    <Text
      {...props}
      slot="description"
      className={cn('text-xs font-medium text-primary/50', props.className)}
    />
  );
}

export function FieldError(props: FieldErrorProps) {
  return (
    <RACFieldError
      {...props}
      className={cn('text-xs font-medium text-red-500', props.className)}
    />
  );
}

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <RACInput
      {...props}
      ref={ref}
      className={cn(
        'flex h-9 w-full rounded-lg border border-purple-200 bg-white px-3 py-1 text-sm text-primary shadow-sm transition-colors placeholder:text-primary/50',
        /* Focused */
        'focus:ring-1 focus:ring-purple-500',
        /* Disabled */
        'disabled:cursor-not-allowed disabled:opacity-50',
        /* Resets */
        'focus:outline-none',
        props.className
      )}
    />
  );
});
Input.displayName = 'Input';
