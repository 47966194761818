import { Dispatch, SetStateAction } from 'react';
import { Dialog, Modal } from 'react-aria-components';
import { cn } from 'src/lib/cn';
import { InsightsGroupHeader } from './components/InsightsGroupHeader';
import { InsightsGroupAssetView } from './components/InsightsGroupAssetView';
import { InsightsGroupSidebar } from './components/InsightsGroupSidebar';
import type { InsightsAdGroupWithInsights } from '@magicbrief/server/src/insights/classes/platform-services/abstract-insights-service';
import type { InsightsFacebookGroup } from '@magicbrief/common';

export function InsightsGroupDetailView({
  adGroup,
  currency,
  selectedSummary,
  setSelectedSummary,
  group,
}: {
  adGroup: InsightsAdGroupWithInsights;
  currency: string;
  selectedSummary: string | null;
  setSelectedSummary: Dispatch<SetStateAction<string | null>>;
  group: InsightsFacebookGroup | null;
}) {
  if (selectedSummary !== adGroup.group) return null;

  return (
    <Modal
      isOpen={!!selectedSummary}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setSelectedSummary('');
        }
      }}
      isDismissable={true}
      className={cn([
        /* Base */
        'fixed inset-0 z-[9999] size-full',
        /* Entering */
        'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95',
        /* Exiting */
        'data-[exiting]:duration-200 data-[exiting]:animate-out data-[exiting]:fade-out-0',
      ])}
      aria-label="Insights group detail view"
    >
      <Dialog className="size-full" aria-label="Insights group detail view">
        <InsightsGroupHeader adGroup={adGroup} group={group} />
        <div className="flex h-full">
          <InsightsGroupAssetView adGroup={adGroup} />
          <InsightsGroupSidebar
            adGroup={adGroup}
            currency={currency}
            group={group}
          />
        </div>
      </Dialog>
    </Modal>
  );
}
