import { createTRPCReact, httpBatchLink, httpLink } from '@trpc/react-query';
import { createTRPCProxyClient, splitLink } from '@trpc/client';
import superjson from 'superjson';
import { appConfigs } from 'src/configs';
import { auth } from './firebase';
import type { AppRouter } from '@magicbrief/server/src/trpc/router';

export const trpc = createTRPCReact<AppRouter>();

const link = splitLink<AppRouter>({
  condition(op) {
    // check for context property `skipBatch`
    return op.context.skipBatch === true;
  },
  // when condition is true, use normal request
  true: httpLink({
    url: appConfigs.trpcHost,
    async headers() {
      const firebaseToken = await auth.currentUser?.getIdTokenResult();
      const authToken = `Bearer ${firebaseToken?.token}`;
      if (!firebaseToken) {
        return {};
      }
      return {
        Authorization: authToken,
      };
    },
  }),
  // when condition is false, use batching (default behavior)
  false: httpBatchLink({
    url: appConfigs.trpcHost,
    async headers() {
      const firebaseToken = await auth.currentUser?.getIdTokenResult();
      const authToken = `Bearer ${firebaseToken?.token}`;
      if (!firebaseToken) {
        return {};
      }
      return {
        Authorization: authToken,
      };
    },
  }),
});

export const trpcProxyClient = createTRPCProxyClient<AppRouter>({
  transformer: superjson,
  links: [link],
});

export const trpcReactClient = trpc.createClient({
  transformer: superjson,
  links: [link],
});
