import {
  ModalOverlay,
  Modal as RACModal,
  type ModalOverlayProps,
} from 'react-aria-components';
import { cn } from '../lib/cn';

const Modal = ({ isDismissable = true, ...props }: ModalOverlayProps) => {
  return (
    <ModalOverlay
      isDismissable={isDismissable}
      className={cn([
        /* Base */
        'h-(--visual-viewport-height) fixed inset-0 z-[9999] bg-gray-900/60',
        /* Entering */
        'data-[entering]:animate-[overlayShow_300ms_ease-in-out]',
        /* Exiting */
        'data-[exiting]:animate-[overlayHide_300ms_ease-in-out]',
      ])}
      {...props}
    >
      <RACModal
        className={cn([
          /* Base */
          'fixed left-[50%] top-[50%] z-[9999] translate-x-[-50%] translate-y-[-50%]',
          /* Entering */
          'data-[entering]:duration-300 data-[entering]:animate-in data-[entering]:fade-in-0 data-[entering]:zoom-in-95 data-[entering]:slide-in-from-left-1/2 data-[entering]:slide-in-from-top-[48%]',
          /* Exiting */
          'data-[exiting]:duration-300 data-[exiting]:animate-out data-[exiting]:fade-out-0 data-[exiting]:zoom-out-95 data-[exiting]:slide-out-to-left-1/2 data-[exiting]:slide-out-to-top-[48%]',
        ])}
        {...props}
      />
    </ModalOverlay>
  );
};

export { Modal };
