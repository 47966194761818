import {
  ListBox as AriaListBox,
  ListBoxProps as AriaListBoxProps,
  ListBoxItem as AriaListBoxItem,
  ListBoxItemProps as AriaListBoxItemProps,
  composeRenderProps,
} from 'react-aria-components';
import { cn } from '../lib/cn';

const ListBox = <T extends object>(props: AriaListBoxProps<T>) => (
  <AriaListBox
    {...props}
    className={cn(
      'max-h-[inherit] overflow-auto p-1 outline-none [clip-path:inset(0_0_0_0_round_calc(var(--radius)-2px))]',
      props.className
    )}
  />
);

const ListBoxItem = <T extends object>({
  className,
  children,
  ...props
}: AriaListBoxItemProps<T>) => {
  return (
    <AriaListBoxItem
      textValue={
        props.textValue || (typeof children === 'string' ? children : undefined)
      }
      className={composeRenderProps(className, (className) =>
        cn(
          'relative flex w-full shrink-0 cursor-default select-none items-center rounded-md px-3 py-2 text-sm font-normal text-primary outline-none',
          /* Hovered */
          'data-[hovered]:bg-purple-100',
          /* Focus */
          'data-[focused]:bg-purple-100',
          /* Disabled */
          'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
          className
        )
      )}
      {...props}
    >
      {composeRenderProps(children, (children, renderProps) => (
        <>
          {renderProps.isSelected && (
            <span className="absolute right-2 flex size-4 items-center justify-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 6L9 17L4 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          )}
          {children}
        </>
      ))}
    </AriaListBoxItem>
  );
};

export { ListBox, ListBoxItem };
