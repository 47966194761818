import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Button } from '@magicbrief/ui/src/components/button';
import { Dialog, DialogTrigger } from '@magicbrief/ui/src/components/dialog';
import { Modal } from '@magicbrief/ui/src/components/modal';
import { Form, Heading, Separator, Text } from 'react-aria-components';
import { Link, useParams } from 'react-router-dom';
import { FieldError, Input, Label } from '@magicbrief/ui/src/components/field';
import { TextField } from '@magicbrief/ui/src/components/text-field';
import {
  SelectButton,
  SelectContent,
  SelectItem,
} from '@magicbrief/ui/src/components/select';
import { ChevronDown } from '@magicbrief/ui/src/icons/chevron-down';
import {
  ComboBox,
  ComboBoxButton,
  ComboBoxContent,
  ComboBoxInput,
  ComboBoxItem,
} from '@magicbrief/ui/src/components/combo-box';
import {
  convertFiltersV1ToV2,
  COPILOT_HELP_LINK,
  getAllMetricsAndAttributesForPlatform,
  getIsMetricEnumerable,
  getMetricFormat,
  InsightsFilterV2,
  insightsFilterV2,
  InsightsMetricOperationV2,
} from '@magicbrief/common';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
  FieldErrorsImpl,
} from 'react-hook-form';
import * as Sentry from '@sentry/react';
import {
  Popover,
  PopoverDialog,
  PopoverTrigger,
} from '@magicbrief/ui/src/components/popover';
import { ListBox, ListBoxItem } from '@magicbrief/ui/src/components/list-box';
import { cn } from '@magicbrief/ui/src/lib/cn';
import { NumberField } from '@magicbrief/ui/src/components/number-field';
import { toast } from 'react-toastify';
import { InsightsFilterNumeric } from '@magicbrief/common/src/insights/contracts_v2';
import Settings01 from 'src/assets/svgicons/line/settings-01.svg';
import InfoCircle from 'src/assets/svgicons/line/info-circle.svg';
import { trpc } from 'src/lib/trpc';
import Plus from 'src/assets/svgicons/line/plus.svg';
import {
  useInsightsAdAccount,
  useInsightsAds,
} from 'src/pages/Insights/util/useInsightsQueries';
import { Select, SelectValue } from 'src/components/AriaSelect/Select';
import { useInsightsPlatform } from 'src/pages/Insights/util/useInsightsPersistentState';
import {
  getMetricLabelAsText,
  parseValueForMetric,
} from 'src/pages/Insights/util/useParseMetric';
import { getOperationsAndLabelsForField } from 'src/pages/Insights/util/insightsFilterOperations';
import XClose from 'src/assets/svgicons/line/x-close.svg';
import {
  transformInsightsFilterMetric,
  transformInsightsFilterOperation,
} from 'src/pages/Insights/util/useFilterFormV2';
import { useI18nContext } from 'src/i18n/i18n-react';
import useNewAnalyticsEvent from 'src/utils/useNewAnalyticsEvent';
import { useInsightsJob } from 'src/pages/Insights/util/useInsightsJob';

type Sprint = 'new' | 'scaling' | 'winners' | 'losers';

const schema = z.record(
  z.enum(['new', 'scaling', 'winners', 'losers']),
  z.array(insightsFilterV2)
);

type FormSchema = z.infer<typeof schema>;

export const InsightsAnalysisSettings = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DialogTrigger>
      <Button variant="secondary" size="small" onPress={() => setIsOpen(true)}>
        <Settings01 className="size-4" />
        Settings
      </Button>
      <InsightsAnalysisSettingsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </DialogTrigger>
  );
};

export const InsightsAnalysisSettingsModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { accountUuid } = useParams();
  const { recordEvent } = useNewAnalyticsEvent();
  const platform = useInsightsPlatform();
  const trpcUtils = trpc.useUtils();
  const { accountCustomEvents, accountCustomConversions, currency } =
    useInsightsAdAccount({ accountUuid });

  const { data } = trpc.insights.getSprintSettings.useQuery(
    { uuid: accountUuid ?? '' },
    { enabled: !!accountUuid, keepPreviousData: true }
  );

  const updateSprintSettings = trpc.insights.updateSprintSettings.useMutation();

  const defaultValues = useMemo(() => {
    return data?.sprints.reduce<Record<Sprint, Array<InsightsFilterV2>>>(
      (acc, curr) => {
        acc[curr.id] = convertFiltersV1ToV2(curr.filter);
        return acc;
      },
      { new: [], scaling: [], winners: [], losers: [] }
    );
  }, [data?.sprints]);

  const methods = useForm<FormSchema>({
    resolver: zodResolver(schema),
    mode: 'all',
    reValidateMode: 'onBlur',
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormSchema> = async (data) => {
    if (accountUuid) {
      const spend = data.scaling?.find(
        (x): x is InsightsFilterNumeric => 'field' in x && x.field === 'spend'
      );

      if (!spend || !('value' in spend)) {
        throw new Error('Scaling needs to have a spend filter');
      }

      const winners = data.winners?.map((x) =>
        'value' in x && typeof x.value === 'number' && x.field === 'spend'
          ? { ...x, value: spend.value }
          : x
      );

      const settings = {
        new: data.new,
        scaling: data.scaling,
        losers: data.losers,
        winners,
      };

      updateSprintSettings.mutate(
        {
          sprintSettings: settings,
          insightsAdAccountFacebookUuid: accountUuid,
        },
        {
          onSuccess: (data) => {
            void trpcUtils.insights.getSprintCounts.invalidate();
            void trpcUtils.insights.getSprintSettings.setData(
              { uuid: accountUuid },
              data
            );
            void recordEvent({
              action: 'Settings Changed',
              target: 'Insights Copilot',
              metadata: {
                ...data,
                insightsAdAccountFacebookUuid: accountUuid,
              },
            });
            setIsOpen(false);

            data.sprints.forEach((sprint) => {
              methods.setValue(
                sprint.id,
                sprint.filter as Array<InsightsFilterV2>
              );
            });
          },
          onError: (e, opts) => {
            toast.error(e instanceof Error ? e.message : 'Unknown error');
            Sentry.captureException(e, {
              contexts: { opts },
            });
          },
        }
      );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          methods.reset(defaultValues);
        }
        setIsOpen(isOpen);
      }}
    >
      <Dialog
        className="flex h-[673px] w-[724px] flex-col rounded-xl bg-white"
        aria-label="analysis settings dialog"
      >
        <FormProvider {...methods}>
          <Form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex flex-1 flex-col overflow-hidden"
          >
            <div className="flex-none border-b border-solid border-b-purple-200 px-6 py-5">
              <Heading className="text-xl font-semibold text-primary">
                Configure your Copilot settings
              </Heading>
              <Text className="text-sm font-medium text-primary/50">
                Input your spend benchmark, and set your target metrics. These
                benchmarks will determine how Copilot analyses your performance
                for each creative.
              </Text>
            </div>

            <div className="flex-1 overflow-y-auto px-6 py-4">
              <div className="flex flex-col gap-4">
                <AnalysisSpendSettings currency={currency || ''} />
                <AnalysisWinnerSettings
                  platform={platform}
                  customEvents={accountCustomEvents || []}
                  customConversions={accountCustomConversions || []}
                  currency={currency || ''}
                />
                <AnalysisNeedsImprovementSettings
                  platform={platform}
                  customEvents={accountCustomEvents || []}
                  customConversions={accountCustomConversions || []}
                  currency={currency || ''}
                />
              </div>
            </div>
            <div className="flex flex-none items-center justify-between border-t border-solid border-t-purple-200 px-6 py-4">
              <Link
                to={COPILOT_HELP_LINK}
                target="_blank"
                className="group flex items-center gap-1.5"
              >
                <InfoCircle className="size-4 text-primary/50" />
                <Text className="text-xs font-medium text-primary/50 underline-offset-4 group-hover:underline">
                  Learn how to setup your Copilot settings
                </Text>
              </Link>
              <div className="flex items-center gap-2.5">
                <Button
                  variant="secondary"
                  size="small"
                  type="button"
                  slot="close"
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  loading={updateSprintSettings.isLoading}
                >
                  Apply
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      </Dialog>
    </Modal>
  );
};

const AnalysisSpendSettings = ({ currency }: { currency: string }) => {
  const { control } = useFormContext<FormSchema>();

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-solid border-purple-200 px-4 pb-6 pt-3 focus-within:border-purple-300 focus-within:shadow">
      <div className="flex gap-3">
        <Text className="mt-0.5 text-[24px] leading-6">💰</Text>
        <div className="flex flex-col">
          <Text className="text-sm font-semibold text-primary">Scaling</Text>
          <Text className="text-xs font-medium text-primary/50">
            {`Set the minimum spend for ads to be considered "scaling",
                      we recommend more than $500`}
          </Text>
        </div>
      </div>
      <Separator className="bg-purple-200" />

      <Controller
        control={control}
        name="scaling.0.value"
        render={({ field, formState: { errors } }) => {
          const scalingError = errors?.scaling?.[0] as FieldErrorsImpl<{
            field: 'spend';
            operation: '>=';
            value: number;
          }>;
          const errorMessage = scalingError?.value?.message;

          return (
            <NumberField isInvalid={!!errorMessage}>
              <Label>Threshold</Label>
              <div className="flex items-center rounded-md shadow-sm">
                <div className="flex h-9 items-center rounded-l-md border-y border-l border-solid border-purple-200 px-3 py-1">
                  <Text
                    className="text-sm font-medium text-primary"
                    slot="description"
                  >
                    {currency}
                  </Text>
                </div>
                <Input
                  ref={field.ref}
                  name={field.name}
                  value={field.value as number}
                  onChange={(ev) => field.onChange(parseFloat(ev.target.value))}
                  onBlur={field.onBlur}
                  disabled={field.disabled}
                  type="number"
                  className="rounded-l-none border-l-0 shadow-none data-[focused]:rounded-l-md"
                  placeholder="Value"
                />
              </div>
              <FieldError>Spend required</FieldError>
            </NumberField>
          );
        }}
      />
    </div>
  );
};

const AnalysisWinnerSettings = ({
  platform,
  customEvents,
  customConversions,
  currency,
}: {
  platform: 'facebook' | 'tiktok';
  customEvents: Array<string>;
  customConversions: Array<{ facebookId: string; name: string }>;
  currency: string;
}) => {
  const { append } = useFieldArray({ name: 'winners' });
  const { watch } = useFormContext<FormSchema>();

  const winners = watch('winners');
  const spendValue = watch('scaling.0.value');

  const spendLabel = spendValue
    ? `Spend greater than or equal to ${parseValueForMetric(
        platform,
        'spend',
        spendValue as string | number | Date,
        currency
      )}`
    : 'Check your scaling setting for errors';

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-solid border-purple-200 px-4 py-3 focus-within:border-purple-300 focus-within:shadow">
      <div className="flex gap-3">
        <Text className="mt-0.5 text-[24px] leading-6">🏆</Text>
        <div className="flex flex-col">
          <Text className="text-sm font-semibold text-primary">Winners</Text>
          <Text className="text-xs font-medium text-primary/50">
            Define performance benchmarks for ads ready to scale up and iterate
          </Text>
        </div>
      </div>
      <Separator className="bg-purple-200" />
      <div
        className={cn(
          'flex h-9 w-full items-center gap-2 rounded-lg border border-solid border-purple-200 bg-white px-3 py-1 text-sm text-primary shadow-sm transition-colors placeholder:text-primary/50',
          /* Focused */
          'focus:ring-1 focus:ring-purple-500',
          /* Disabled */
          'disabled:cursor-not-allowed disabled:opacity-50',
          /* Resets */
          'focus:outline-none'
        )}
      >
        <Text>Scaling</Text>
        <Separator
          orientation="vertical"
          className="h-3/4 w-px bg-purple-200"
        />
        <Text
          className={cn(
            'text-xs',
            spendValue ? 'text-primary/50' : 'text-red-500'
          )}
        >
          {spendLabel}
        </Text>
      </div>
      {winners?.map((filter, idx) => {
        // Still map spend to preserve indexes for adding/removing metrics instead of filtering it
        if (filter.field === 'spend') return null;

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${filter.field}-${idx}`}
            className={cn(
              'grid grid-cols-[repeat(3,1fr)_auto] gap-2',
              filter.operation === 'between' ||
                filter.operation === 'notBetween'
                ? 'grid-cols-[repeat(4,1fr)_auto]'
                : 'grid-cols-[repeat(3,1fr)_auto]'
            )}
          >
            <AnalysisFilterMetricPicker
              platform={platform}
              filter={filter}
              name="winners"
              index={idx}
              customEvents={customEvents}
              customConversions={customConversions}
              currency={currency}
            />
            <AnalysisFilterOperationSelect
              platform={platform}
              filter={filter}
              name="winners"
              index={idx}
            />
            <AnalysisFilterValueInputs
              platform={platform}
              filter={filter}
              name="winners"
              index={idx}
            />
            <RemoveFilterButton name="winners" index={idx} />
          </div>
        );
      })}
      <Button
        variant="text"
        size="small"
        type="button"
        onPress={() => {
          append({
            field: 'adName',
            operation: 'contains',
            value: '',
          });
        }}
      >
        <Plus className="size-4" />
        Add Metric
      </Button>
    </div>
  );
};

const AnalysisNeedsImprovementSettings = ({
  platform,
  customEvents,
  customConversions,
  currency,
}: {
  platform: 'facebook' | 'tiktok';
  customEvents: Array<string>;
  customConversions: Array<{ facebookId: string; name: string }>;
  currency: string;
}) => {
  const { append } = useFieldArray({ name: 'losers' });
  const { watch } = useFormContext<FormSchema>();

  const improvements = watch('losers');

  return (
    <div className="flex flex-col gap-3 rounded-lg border border-solid border-purple-200 px-4 py-3 focus-within:border-purple-300 focus-within:shadow">
      <div className="flex gap-3">
        <Text className="mt-0.5 text-[24px] leading-6">⚠️</Text>
        <div className="flex flex-col">
          <Text className="text-sm font-semibold text-primary">
            Needs Improvement
          </Text>
          <Text className="text-xs font-medium text-primary/50">
            Set benchmarks to identify low-performing creatives for improvement
            opportunities
          </Text>
        </div>
      </div>
      <Separator className="bg-purple-200" />
      {improvements?.map((filter, idx) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${filter.field}-${idx}`}
            className={cn(
              'grid gap-2',
              filter.operation === 'between' ||
                filter.operation === 'notBetween'
                ? 'grid-cols-[repeat(4,1fr)_auto]'
                : 'grid-cols-[repeat(3,1fr)_auto]'
            )}
          >
            <AnalysisFilterMetricPicker
              platform={platform}
              filter={filter}
              name="losers"
              index={idx}
              customEvents={customEvents}
              customConversions={customConversions}
              currency={currency}
            />
            <AnalysisFilterOperationSelect
              platform={platform}
              filter={filter}
              name="losers"
              index={idx}
            />
            <AnalysisFilterValueInputs
              platform={platform}
              filter={filter}
              name="losers"
              index={idx}
            />
            <RemoveFilterButton name="losers" index={idx} />
          </div>
        );
      })}

      <Button
        variant="text"
        size="small"
        type="button"
        onPress={() => {
          append({
            field: 'adName',
            operation: 'contains',
            value: '',
          });
        }}
      >
        <Plus className="size-4" />
        Add Metric
      </Button>
    </div>
  );
};

interface AnalysisFilterMetricSelectProps {
  platform: 'facebook' | 'tiktok';
  filter: InsightsFilterV2;
  name: Sprint;
  index: number;
  customEvents: Array<string>;
  customConversions: Array<{ facebookId: string; name: string }>;
  currency: string;
}

const AnalysisFilterMetricPicker = ({
  platform,
  filter,
  name,
  index,
  customEvents,
  customConversions,
  currency,
}: AnalysisFilterMetricSelectProps) => {
  const { accountUuid } = useParams();
  const job = useInsightsJob();

  const { control } = useFormContext<FormSchema>();

  const { ads } = useInsightsAds({
    type: 'account',
    uuid: accountUuid,
    jobStatus: job?.status,
  });

  const referenceMetrics = ads.data?.pages?.[0]?.[0]?.metrics
    ? ads.data.pages[0][0].metrics
    : {};

  const allMetrics = getAllMetricsAndAttributesForPlatform(platform).filter(
    (t) => {
      switch (true) {
        case t === 'tag':
          // Sprint counts does not support tag filtering
          return false;
        case t === 'spend' && name === 'winners':
          // Spend is already set by the scaling filter, this will cause form issues if user can select this metric
          return false;
        default:
          return true;
      }
    }
  );

  const metrics = [
    ...allMetrics.map((x) => ({ id: x })),
    ...(customEvents?.flatMap((x) => [
      { id: `action:cost_per:${x}` },
      { id: `action:total:${x}` },
      { id: `action:value:${x}` },
    ]) ?? []),
    ...(customConversions?.flatMap((x) => [
      { id: `action:cost_per:offsite_conversion.custom.${x.facebookId}` },
      { id: `action:total:offsite_conversion.custom.${x.facebookId}` },
      { id: `action:value:offsite_conversion.custom.${x.facebookId}` },
    ]) ?? []),
  ];

  return (
    <Controller
      control={control}
      name={`${name}.${index}`}
      render={({ field: { onChange, value }, fieldState: { invalid } }) => (
        <ComboBox
          className="flex-1"
          defaultSelectedKey={filter.field}
          onSelectionChange={(selection) => {
            // Set the entire entry when changing the metric as each type has different operations
            const newValue = transformInsightsFilterMetric(
              platform,
              selection as string,
              value.operation
            );
            onChange(newValue);
          }}
          isInvalid={invalid}
          aria-label="select metric"
        >
          <div className="relative">
            <ComboBoxInput className="pr-8" />
            <ComboBoxButton className="absolute right-1 top-1/2 -translate-y-1/2" />
          </div>
          <ComboBoxContent
            className="max-h-[250px]"
            popoverClassName="w-[500px] max-h-[100px]"
            items={metrics.map((m) => ({
              id: m.id,
              name: getMetricLabelAsText(
                platform,
                m.id,
                customEvents ?? [],
                customConversions ?? []
              ),
            }))}
          >
            {(item) => {
              const referenceMetric =
                referenceMetrics[item.id] &&
                currency &&
                item.id &&
                !Array.isArray(referenceMetrics[item.id])
                  ? parseValueForMetric(
                      platform,
                      item.id,
                      referenceMetrics[item.id] as string | number | Date,
                      currency
                    )
                  : '-';

              return (
                <ComboBoxItem
                  key={`${name}-${item.id}`}
                  id={item.id}
                  textValue={item.name || ''}
                  className="justify-between data-[selected]:pr-8"
                >
                  <Text>{item.name}</Text>
                  <Text className="text-xs text-primary/50">
                    {referenceMetric}
                  </Text>
                </ComboBoxItem>
              );
            }}
          </ComboBoxContent>
        </ComboBox>
      )}
    />
  );
};

const AnalysisFilterOperationSelect = ({
  platform,
  filter,
  name,
  index,
}: {
  platform: 'facebook' | 'tiktok';
  filter: InsightsFilterV2;
  name: Sprint;
  index: number;
}) => {
  const { control } = useFormContext<FormSchema>();
  const { options, labels } = getOperationsAndLabelsForField(
    platform,
    filter.field
  );

  return (
    <Controller
      control={control}
      name={`${name}.${index}`}
      render={({ field: { onChange, value }, fieldState: { invalid } }) => (
        <Select
          className="flex-1"
          aria-label="select metric operation"
          defaultSelectedKey={filter.operation}
          onSelectionChange={(selection) => {
            const nextFilter = transformInsightsFilterOperation(
              platform,
              selection as InsightsMetricOperationV2,
              value
            );
            onChange(nextFilter);
          }}
          isInvalid={invalid}
        >
          <SelectButton>
            <SelectValue className="text-ellipsis" />
            <ChevronDown className="size-4 text-primary/50" />
          </SelectButton>
          <SelectContent
            popoverClassName="w-[250px]"
            items={options.map((opt) => ({
              id: opt,
              name: labels[opt as keyof typeof labels],
            }))}
          >
            {(item) => (
              <SelectItem key={`${name}-${item.id}`} id={item.id}>
                {item.name}
              </SelectItem>
            )}
          </SelectContent>
        </Select>
      )}
    />
  );
};

const AnalysisFilterValueInputs = ({
  platform,
  filter,
  name,
  index,
}: {
  platform: 'facebook' | 'tiktok';
  filter: InsightsFilterV2;
  name: Sprint;
  index: number;
}) => {
  const { control, watch } = useFormContext<FormSchema>();

  const enumerable = getIsMetricEnumerable(platform, filter.field);

  if (enumerable) {
    return (
      <AnalysisEnumerableInput
        enumerable={enumerable}
        name={name}
        index={index}
      />
    );
  }

  const format = getMetricFormat(platform, filter.field);

  switch (format) {
    case 'currency':
    case 'numeric':
    case 'percentage': {
      if (filter.operation === 'between' || filter.operation === 'notBetween') {
        return (
          <>
            <Controller
              control={control}
              name={`${name}.${index}.min`}
              render={({ field, formState: { errors } }) => {
                const value = watch(`${name}.${index}.min`);
                const error = errors?.[name]?.[index] as FieldErrorsImpl<{
                  field: string;
                  operation: string;
                  min: number;
                  max: number;
                }>;
                const errorMessage = error?.min?.message;

                return (
                  <NumberField
                    className="flex-1"
                    aria-label="enter filter value"
                    isInvalid={!!errorMessage}
                  >
                    <Input
                      ref={field.ref}
                      name={field.name}
                      value={value}
                      onChange={(ev) =>
                        field.onChange(parseFloat(ev.target.value))
                      }
                      onBlur={field.onBlur}
                      disabled={field.disabled}
                      type="number"
                      placeholder="Min"
                      step={0.01}
                    />
                    <FieldError>Required</FieldError>
                  </NumberField>
                );
              }}
            />
            <Controller
              control={control}
              name={`${name}.${index}.max`}
              render={({ field, formState: { errors } }) => {
                const value = watch(`${name}.${index}.max`);
                const error = errors?.[name]?.[index] as FieldErrorsImpl<{
                  field: string;
                  operation: string;
                  min: number;
                  max: number;
                }>;
                const errorMessage = error?.max?.message;

                return (
                  <NumberField
                    className="flex-1"
                    aria-label="enter filter value"
                    isInvalid={!!errorMessage}
                  >
                    <Input
                      ref={field.ref}
                      name={field.name}
                      value={value}
                      onChange={(ev) =>
                        field.onChange(parseFloat(ev.target.value))
                      }
                      onBlur={field.onBlur}
                      disabled={field.disabled}
                      type="number"
                      placeholder="Max"
                      step={0.01}
                    />
                    <FieldError>Required</FieldError>
                  </NumberField>
                );
              }}
            />
          </>
        );
      }

      return (
        <Controller
          control={control}
          name={`${name}.${index}.value`}
          render={({ field, formState: { errors } }) => {
            const value = watch(`${name}.${index}.value`);
            const error = errors?.[name]?.[index] as FieldErrorsImpl<{
              field: string;
              operation: string;
              value: number;
            }>;
            const errorMessage = error?.value?.message;

            return (
              <NumberField
                className="flex-1"
                aria-label="enter filter value"
                isInvalid={!!errorMessage}
              >
                <Input
                  ref={field.ref}
                  name={field.name}
                  value={Number(value)}
                  onChange={(ev) => field.onChange(parseFloat(ev.target.value))}
                  onBlur={field.onBlur}
                  disabled={field.disabled}
                  placeholder="Value"
                  type="number"
                  step={0.01}
                />
                <FieldError>Required</FieldError>
              </NumberField>
            );
          }}
        />
      );
    }
    case 'text': {
      return (
        <Controller
          name={`${name}.${index}.value`}
          control={control}
          render={({ field }) => {
            const value = watch(`${name}.${index}.value`);
            return (
              <TextField className="flex-1" aria-label="enter filter value">
                <Input
                  name={field.name}
                  value={value.toString()}
                  placeholder="Value"
                  type="text"
                  onChange={(e) => field.onChange(e.target.value)}
                />
              </TextField>
            );
          }}
        />
      );
    }
    case 'date': {
      return null;
    }
    default: {
      format satisfies never;
      return null;
    }
  }
};

const AnalysisEnumerableInput = ({
  enumerable,
  name,
  index,
}: {
  enumerable: Record<string, string>;
  name: Sprint;
  index: number;
}) => {
  const { LL } = useI18nContext();
  const { control, watch } = useFormContext<FormSchema>();

  const items = Object.entries(enumerable).map(([id, name]) => ({
    id,
    name,
  }));

  return (
    <Controller
      control={control}
      name={`${name}.${index}.values`}
      render={({ field: { onChange } }) => {
        const values = watch(`${name}.${index}.values`);
        const showPlaceholder = values.length === 0;
        const label = showPlaceholder
          ? LL.insights.metrics.multiSelectHint()
          : values
              .map((val) =>
                LL.insights.creativeType[
                  val as keyof typeof LL.insights.creativeType
                ]()
              )
              .join(', ');

        return (
          <PopoverTrigger>
            <SelectButton className={showPlaceholder ? 'text-primary/50' : ''}>
              {label}
              <ChevronDown className="size-4 text-primary/50" />
            </SelectButton>
            <Popover className="w-[250px]">
              <PopoverDialog>
                <ListBox
                  selectedKeys={values}
                  items={items}
                  selectionMode="multiple"
                  onSelectionChange={(selection) => {
                    onChange(Array.from(selection));
                  }}
                  aria-label="select enumerable value"
                >
                  {(item) => (
                    <ListBoxItem key={item.id} id={item.id}>
                      {item.name}
                    </ListBoxItem>
                  )}
                </ListBox>
              </PopoverDialog>
            </Popover>
          </PopoverTrigger>
        );
      }}
    />
  );
};

const RemoveFilterButton = ({
  name,
  index,
}: {
  name: Sprint;
  index: number;
}) => {
  const { remove } = useFieldArray({ name });

  return (
    <Button
      variant="text"
      size="small"
      type="button"
      className="mt-1.5 flex size-6 items-center justify-center rounded-md p-0"
      onPress={() => remove(index)}
    >
      <XClose className="size-4" />
    </Button>
  );
};
